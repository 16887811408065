import React, { FC, useEffect, useRef, useState } from 'react'
import CustomTable from 'components/reusable/table'
import { ColumnType } from 'antd/lib/table'
import BoldColumnTitle from 'components/shared/BoldColumnTitle';
import { useRootStore } from '../../../../root/RootStoreHook';
import { FilterOutlined, SearchOutlined } from '@ant-design/icons';
import { Tag, Input, Space, Button } from 'antd';
import { reportsInstance } from 'http/agent';
import DownloadCsvButton from 'components/reusable/downloadCsvButton';

interface IUsageData {
    ProfileId: number,
    FirstName: string | null,
    LastName: string | null,
    Email: string | null,
    Description: string | null,
    Percentange: number,
    CommunityId?: number,
    fullName?: string | null,
    key?: number
}
const UsageReport: FC = () => {

    const [reportData, setReportData] = useState<IUsageData[]>([])
    const [searchText, updateSearchText] = useState<string | null>(null)
    const { dropdownOptions } = useRootStore();
    const {
        communityFilters,
        getCommunityValue,
        studiosForDropdown,
        getStudioValue
    } = dropdownOptions!;

    const getColumnSearch = ({ dataIndex, title, searchInput, handleSearch, handleReset, selectFilterText, data }: any) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${title.toLowerCase()}`}
                    value={selectedKeys[0]}
                    onChange={(e: any) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, clearFilters)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex, clearFilters, "reset")}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex, clearFilters, "search")}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: any) => <SearchOutlined className={filtered ? 'filterIcon_filtered' : 'filterIcon'} />,
        onFilter: (value: any, record: any) => {
            return record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : undefined;
        },
        onFilterDropdownVisibleChange: (visible: any) => {
            if (visible) setTimeout(() => (searchInput.current as any).select());
        },
    });


    const searchReportInfo = async () => {
        try {
            const response = await reportsInstance.get('/usage')
            const data: IUsageData[] = response.data?.rows
            const withFullNameInfo = data.map((element, index) => { return { ...element, key: index, fullName: `${element.FirstName} ${element.LastName}` } })
            setReportData(withFullNameInfo)
        } catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        searchReportInfo()
    }, [])
    const selectFilterText = () => !searchText;
    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any, clearFilters: any, selectedButton: string) => {
        if (selectedButton === "reset") {
            handleReset(dataIndex, clearFilters);
        }
        confirm();
        updateSearchText(selectedKeys[0]);
    };
    const handleReset = (_dataIndex: any, clearFilters: any) => {
        updateSearchText('');
        clearFilters();
    };


    const studioFilter = studiosForDropdown.map(element => { return { value: element.id, text: element.description } })

    const searchInput = useRef(null);
    const headers = [{ label: "Full Name", key: 'fullName' }, { label: "Email", key: 'Email' }, { label: "Community", key: 'communityId' }, { label: "Studio", key: 'Description' }, { label: "% Completion", key: 'Percentange' }]

    const tableColumns: ColumnType<any>[] = [

        {
            title: <BoldColumnTitle title="Full Name" />,
            ...getColumnSearch({ dataIndex: 'fullName', title: 'Full Name', searchInput, handleSearch, handleReset, selectFilterText, data: reportData }),
            dataIndex: 'fullName',
            defaultSortOrder: 'ascend',
            sorter: (a: IUsageData, b: IUsageData) => {
                if (!a.fullName || !b.fullName)
                    return 1

                return a.fullName.localeCompare(b.fullName)
            },
            ellipsis: true,
            width: '16%',
        },
        {
            title: <BoldColumnTitle title="Email" />,
            dataIndex: 'Email',
            sorter: (a: IUsageData, b: IUsageData) => {
                if (!a.Email || !b.Email)
                    return 1

                return a.Email.localeCompare(b.Email);
            },
            width: '180px',
            ellipsis: true
        },
        {
            title: <BoldColumnTitle title="Community" />,
            dataIndex: 'communityId',
            render: (text: string, item: string) => (
                <Tag color="#2E308E" className='table-tag-max-height'>
                    {getCommunityValue(item)}
                </Tag>
            ),

            filters: communityFilters,
            onFilter: (value: any, item: object) => {
                return item === value
            },

            filterIcon: (filtered: any) => <FilterOutlined className={filtered ? 'filterIcon_filtered' : 'filterIcon'} />,
            width: '100px',
        },
        {
            title: <BoldColumnTitle title="Studio" />,
            dataIndex: 'Description',
            render: (text: string, item: Record<string, any>) => {

                return (
                    <Tag color="#2E308E" className='table-tag-max-height'>
                        {item.Description}
                    </Tag>
                )
            },
            filters: studioFilter,
            onFilter: (value: any, item: Record<string, any>) => {
                return item.Description.includes(getStudioValue(value))
            },

            filterIcon: (filtered: any) => <FilterOutlined className={filtered ? 'filterIcon_filtered' : 'filterIcon'} />,
            width: '180px',
        },
        {
            title: <BoldColumnTitle title="% Completion" />,
            dataIndex: 'Percentange',
            width: '180px',
            sorter: (a: IUsageData, b: IUsageData) => {
                return a.Percentange - b.Percentange
            },
        },
    ];

    return <>
        <DownloadCsvButton data={reportData} headers={headers} filename={'usage_report.csv'} />
        <CustomTable tableColumns={tableColumns} data={reportData} size="reports table" />
    </>

}

export default UsageReport
