import React, { FC } from 'react';
import { Form } from 'antd';
import CustomFormForModal from '../forms/CustomFormForModal';
import CustomModal from '../shared/CustomModal';
import { CustomInput } from '../forms/CustomInput';
import { CustomInputNumber } from '../forms/CustomInputNumber';
import CustomLevelSlider from '../forms/custom-level-slider/CustomLevelSlider';
import { useRootStore } from '../root/RootStoreHook';
import { LevelHelper } from '../forms/custom-level-slider/Level';
import { CustomSelect } from '../forms/CustomSelect';
import { sortBy } from '../app/Utils';
import { patterns } from 'components/profile/models/Config';
interface IProps {
    visible: boolean;
}

const SkillsModal: FC<IProps> = ({ visible }) => {
    const { dropdownOptions, profile } = useRootStore();

    const { selectedItem, initialValues, config, addOrUpdate, closeModal } = profile!.skill!;
    const [form] = Form.useForm();

    const onOk = () => {
        form
            .validateFields()
            .then((values: any) => {
                values.skillLevel = LevelHelper.getLevelValue(values.skillLevel);
                addOrUpdate(values);
            })
            .catch(() => { });
    };

    return (
        <CustomModal visible={visible} title={selectedItem ? 'Update' : 'Add'} onOk={onOk} onCancel={() => closeModal()}>
            <CustomFormForModal form={form} initialValues={initialValues}>
                <CustomSelect
                    name="skillClassificationId"
                    label="Classification"
                    options={dropdownOptions!.skillClassificationOptions.sort(sortBy('description'))}
                    config={config.skillClassificationId}
                />

                <CustomInput
                    name="skillName"
                    label="Skill"
                    config={config.skillName}
                    onChange={(evt: any) => evt.currentTarget.value}
                    placeholder="e.g C#"
                    pattern={patterns.text.technology}                    
                />
                <CustomSelect
                    name="skillCategoryId"
                    label="Category"
                    options={dropdownOptions!.skillCategoryOptions.sort(sortBy('description'))}
                    config={config.skillCategoryId}
                />

                <CustomLevelSlider name="skillLevel" label="Level" />
                <CustomInputNumber name="yearsExperience" label="Years of Experience" config={config.yearsExperience} />
            </CustomFormForModal>
        </CustomModal>
    );
};

export default SkillsModal;
