import { Instance, types, flow, cast } from 'mobx-state-tree';
import { autorun } from 'mobx';
import { axiosInstance } from '../../http/agent';
import { getRootStore } from '../root/RootStoreUtils';
import { getProfile } from '../profile/ProfileStore';
import { IFilters, IUserPagination } from '../admin/profiles/models/IUserPagination';
import { UserItem } from '../admin/profiles/models/UserItem';
import openNotification from '../notifications/Notification';
import { STORAGE_ENV } from 'constants/constants';

export function setToken(token: string) {
    window.sessionStorage.setItem(STORAGE_ENV + 'token', token);
    localStorage.setItem(STORAGE_ENV + 'token', token);
}

export function getToken() {
    var token: string | null = window.sessionStorage.getItem(STORAGE_ENV + 'token');
    if (!token) {
        token = localStorage.getItem(STORAGE_ENV + 'token');
    }

    token = token ? token : '';

    if (isTokenExpiredOrInvalid(token)) {
        removeToken();
        return null;
    }

    return token;
}

export function removeToken() {
    localStorage.removeItem(STORAGE_ENV + 'token');
    return window.sessionStorage.removeItem(STORAGE_ENV + 'token');
}

export function clearCurrentSession() {
    localStorage.removeItem(STORAGE_ENV + 'userdata');
    removeToken();
}

export function isTokenExpiredOrInvalid(token: string) {
    try {
        return Date.now() >= (JSON.parse(atob(token.split('.')[1]))).exp * 1000
    } catch (ex) {
        return true;
    }
}

export const SelectedUser = types.model('SelectedUser', {
    order: types.number,
    page: types.number,
    ...UserItem.properties,
});
export interface SelectedUser extends Instance<typeof SelectedUser> { }

export const defaultPageSizeOptions = ['10', '20', '30', '40', '50'];
export const defaultUserPagination = {
    pageIndex: 1,
    pageSize: parseInt(defaultPageSizeOptions[0], 10),
    filters: {},
};

export const UserStore = types
    .model('UserStore', {
        items: types.array(UserItem),
        total: types.maybe(types.number),
        currentPage: types.maybe(types.number),
        loading: types.optional(types.boolean, false),
        searchText: types.optional(types.string, ' '),
        searchEmailText: types.optional(types.string, ' '),
        searchedColumn: types.optional(types.string, ''),
        pageSize: types.optional(types.number, defaultUserPagination.pageSize),
        selectedUsers: types.optional(types.array(SelectedUser), []),
        displayName: types.maybe(types.string),
        token: types.maybe(types.string),
        rol: types.maybe(types.string),
        id: types.maybe(types.number),
        idProfile: types.maybe(types.number),
        password: types.maybe(types.string),
        passwordReset: types.maybe(types.boolean),
        email: types.maybe(types.string),
        completed: types.array(types.boolean),
    })
    .views((self) => ({
        get itemsForTable() {
            return self.items.map((x: UserItem) => ({ key: x.id, ...x }));
        },
        get sortedItems() {
            return self.selectedUsers.slice().sort((a: any, b: any) => a.order - b.order);
        },
        get selectedUsersIds() {
            return self.selectedUsers.map((x) => x.id);
        },
        getPageSizeOptions() {
            const { total } = self;
            if (!total) return [];

            return defaultPageSizeOptions.filter((option) => {
                const parsedOption = parseInt(option, 10);
                return (parsedOption > total && parsedOption - total < 10) || parsedOption <= total;
            });
        },
        getCleanFullName(rawText: string) {
            return rawText ? rawText.trim().replace(/\s{2,}/g, ' ') : ' ';
        },
        getFilters(filters: any): IFilters {
            const result: IFilters = {};

            if (filters) {
                result.communityIds = filters.communityId ? filters.communityId.map((x: any) => Number(x)) : undefined;
                result.positionIds = filters.positionId ? filters.positionId.map((x: any) => Number(x)) : undefined;
                result.addPositionIds = filters.addPositionIds ? filters.addPositionIds.map((x: any) => Number(x)) : undefined;
                result.fullName = filters.fullName ? this.getCleanFullName(filters.fullName[0]) : undefined;
                result.email = filters.email ? this.getCleanFullName(filters.email[0]) : undefined;
                result.completed = filters.profileCompleted ? filters.profileCompleted[0] : null;
                result.roleIds = filters.roleIds ? filters.roleIds : null;
            }
            return result;
        },
    }))
    .actions((self) => ({
        update(
            displayName: string,
            token: string,
            passwordReset: boolean,
            email: string,
            password: string,
            rol: string,
            id: number,
            idProfile: number
        ): void {
            self.displayName = displayName;
            self.rol = rol;
            self.id = id;
            self.token = token;
            self.passwordReset = passwordReset;
            self.email = email;
            self.password = password;
            self.idProfile = idProfile;
        },
        getCurrent: flow(function* getCurrent(): any {
            const { showLoading, hideLoading } = getRootStore(self);
            showLoading();
            const [profile] = yield Promise.all([getProfile()]);
            try {
                const { data } = yield axiosInstance.get('/user');
                self.token = data.token;
                self.rol = data.rol;
                self.id = data.id;
                self.idProfile = profile.id;
                self.email = data.email;
                self.displayName = data.displayName;
            } catch (error) {
                console.log(error);
            } finally {
                hideLoading();
            }
        }) as () => Promise<void>,

        afterCreate() {
            autorun(() => {
                if (self.token) {
                    setToken(self.token);
                }
            });
        },
        updateSearchText(dataIndex: string, value: string) {
            if (dataIndex === 'fullName') {
                self.searchText = value;
            } else {
                self.searchEmailText = value;
            }
        },
        updateSearchedColumn(value: string) {
            self.searchedColumn = value;
        },
        getResults: flow(function* getResults(userPagination: IUserPagination) {
            try {
                const { data } = yield axiosInstance.post('/user/all', userPagination);

                self.items = cast(data.results);
                self.currentPage = data.currentPage;
                self.total = cast(data.rowCount);
                self.pageSize = cast(data.pageSize);
            } catch (error) {
                console.log(error);
            } finally {
                self.loading = false;
            }
        }),
        deleteUser: flow(function* deleteUser(email: string) {
            try {
                const { status } = yield axiosInstance.delete(`/user/delete/${email}`);
                if (status === 200) {
                    const filteredItems = self.items.filter((user) => user.email !== email);
                    self.items = cast(filteredItems);
                    const { data } = yield axiosInstance.post('/user/all', defaultUserPagination);
                    self.items = cast(data.results);
                    openNotification('Success', 'This user was successfully deleted', 'success', 5, 'bottomRight');
                } else {
                    openNotification('Error', 'An error ocurred while trying to delete this user', 'error', 5, 'bottomRight');
                }
            } catch (error) {
                console.log(error);
            } finally {
                self.loading = false;
            }
        }),
        setUserState: flow(function* enrollUser(
            email: string,
            roles: [number],
            successMessage?: string,
            errorMessage?: string
        ): any {
            try {
                self.loading = true;
                const { status } = yield axiosInstance.post('/admin/set-user-roles/', {
                    Email: email,
                    RoleIds: roles,
                });
                if (status >= 200 && status < 300) {
                    const { data } = yield axiosInstance.post('/user/all', defaultUserPagination);
                    self.items = cast(data.results);
                    openNotification('Success', successMessage ?? '', 'success', 5, 'bottomRight');
                    window.location.reload();
                } else {
                    openNotification('Error', errorMessage ?? '', 'error', 5, 'bottomRight');
                }
            } catch (error) {
                console.log(error);
            } finally {
                self.loading = false;
            }
        }),
        editUserPersonalInfo: flow(function* editUserPersonalInfo(
            email: string,
            firstName: string,
            lastName: string,
            positionId: string,
            additionalPositionId: string,
            studioId: string,
            communityId: string,
            cognizantId: string
        ): any {
            try {
                self.loading = true;
                const { status } = yield axiosInstance.put('/admin/edit-user-info', {
                    Email: email,
                    Firstname: firstName,
                    Lastname: lastName,
                    PositionId: positionId,
                    AdditionalPositionId: additionalPositionId,
                    StudioID: studioId,
                    CommunityId: communityId,
                    CognizantId: cognizantId
                });
                if (status >= 200 && status < 300) {
                    const { data } = yield axiosInstance.post('/user/all', defaultUserPagination);
                    self.items = cast(data.results);
                    openNotification('Success', 'Information was successfully updated!', 'success', 5, 'bottomRight');
                } else {
                    openNotification(
                        'Error',
                        'An error ocurred while trying to update this user information',
                        'error',
                        5,
                        'bottomRight'
                    );
                }
            } catch (error) {
                openNotification(
                    'Error',
                    'An error ocurred while trying to update this user information',
                    'error',
                    5,
                    'bottomRight'
                );
            } finally {
                self.loading = false;
            }
        }),
        getUserPersonalInfo: flow(function* getUserPersonalInfo(email: string) {
            try {
                self.loading = true;
                const { data } = yield axiosInstance.get(`/admin/full?Email=${email}`);
                return data;
            } catch (error) {
                openNotification(
                    'Error',
                    'An error ocurred while trying to load the user information',
                    'error',
                    5,
                    'bottomRight'
                );
            } finally {
                self.loading = false;
            }
            return [];
        }),
    }));

export interface UserStore extends Instance<typeof UserStore> { }
