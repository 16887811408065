import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useRootStore } from '../../../root/RootStoreHook';
import { options } from '../DropdownOptionsDisplay';
import DropdownOptionsEntry from '../DropdownOptionsEntry';
import PositionModal from './PositionModal';

const PositionEntry: FC = () => {
  const { dropdownOptions } = useRootStore();

  return useObserver(() => (
    <>
      <DropdownOptionsEntry option={options.POSITION} dropdownItems={dropdownOptions!.positionsForDropdown} />
      {dropdownOptions!.showPositionModal && <PositionModal visible={dropdownOptions!.showPositionModal} />}
    </>
  ));
};

export default PositionEntry;
