/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Instance, types, flow } from 'mobx-state-tree';
import { PersonalInfoStore } from '../personal-info/PersonalInfoStore';
import { HighlightsStore } from '../highlights/HighlightsStore';
import { AdditionalPositionProfileStore } from '../additionalPositionByPr/AdditionalPositionProfileStore';
import { SkillsStore } from '../skills/SkillsStore';
import { SkillSummaryStore } from '../skills/skillSummary/SkillSummaryStore';
import { EducationStore } from '../education/EducationStore';
import { CertificationStore } from '../certification/CertificationStore';
import { SummaryStore } from '../summary/SummaryStore';
import { HistoriesStore } from '../histories/HistoriesStore';
import { axiosInstance } from '../../http/agent';
import { getRootStore } from '../root/RootStoreUtils';

export const ProfileStore = types
  .model('ProfileStore', {
    id: types.identifierNumber,
    cognizantId: types.maybeNull(types.string),
    userId: types.maybe(types.number),
    completed: types.maybe(types.boolean),
    personalInfo: types.maybe(PersonalInfoStore),
    addPositionProfile: types.maybe(AdditionalPositionProfileStore),
    highlight: types.maybe(HighlightsStore),
    skillSummary: types.maybe(SkillSummaryStore),
    skill: types.maybe(SkillsStore),
    education: types.maybe(EducationStore),
    certification: types.maybe(CertificationStore),
    summary: types.maybe(SummaryStore),
    history: types.maybe(HistoriesStore),
  })
  .actions((self) => ({
    updateProfile: flow(function* updateProfile(servicePath: string, isProfileComplete: boolean): any {
      const { showLoading, hideLoading } = getRootStore(self);
      const { personalInfo, highlight, education, skill, skillSummary, summary, history, certification } = self;

      const data = {
        id: self.id,
        cognizantId: `${self.cognizantId || ''}`,
        personalInfo,
        summary: {
          ...summary!.dataToSubmit,
        },
        highlight: {
          ...highlight!.dataToSubmit,
        },
        skill: {
          ...skill!.dataToSubmit,
        },
        skillSummary: {
          ...skillSummary!.dataToSubmit,
        },
        education: {
          ...education!.dataToSubmit,
        },
        history: {
          ...history!.dataToSubmit,
        },
        certification: {
          ...certification!.dataToSubmit,
        },
      };
      showLoading?.();
      try {
        yield axiosInstance.put(servicePath, data);
        self.completed = isProfileComplete;
        return Promise.resolve(true);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        return Promise.resolve(false);
      } finally {
        hideLoading?.();
      }
    }) as (servicePath: string, isProfileComplete: boolean) => Promise<boolean>,
    partialSave: function partialSave() {
      const servicePath = 'profile/partialSave/';
      return this.updateProfile(servicePath, false);
    },
    save: function save() {
      const servicePath = 'profile/';
      return this.updateProfile(servicePath, true);
    },
    getPercentageBySection(): any {
      const profilePercentage = {
        personalInformation: 0,
        highlights: 0,
        skills: 0,
        professionalHistory: 0,
        educationCertifications: 0,
        summary: 0,
      };

      const profile = { ...self };

      const getPercengateInProgress = (fields: any): number => {
        let percentage = 0;
        let totalProperties = 0;
        let valueNoValid = 0;

        for (let i = 0, keys = Object.keys(fields); i < keys.length; i++) {
          const property = keys[i];
          const value = fields[property];
          totalProperties = keys.length;
          if (value === undefined || value === null || value === '') valueNoValid++;
        }

        percentage = totalProperties && (100 / totalProperties) * (totalProperties - valueNoValid);

        return percentage;
      };

      if (profile!.personalInfo!.formValues) {
        const fields = profile!.personalInfo!.formValues;
        const { additionalPositionId, phone, ...newFields } = fields;
        profilePercentage.personalInformation = getPercengateInProgress(newFields);
      }

      if (profile!.summary!.formValues) {
        const fields = profile!.summary!.formValues;
        profilePercentage.summary = getPercengateInProgress(fields);
      }

      if (profile?.highlight?.items.length) profilePercentage.highlights = 100;

      if (profile?.skillSummary?.items.length) profilePercentage.skills += 50;
      if (profile?.skill?.items.length) profilePercentage.skills += 50;

      if (
        (profile?.education?.items.length && !profile?.certification?.items.length) ||
        (!profile?.education?.items.length && profile?.certification?.items.length)
      )
        profilePercentage.educationCertifications += 80;
      if (profile?.education?.items.length && profile?.certification?.items.length)
        profilePercentage.educationCertifications += 100;

      if (profile?.history?.items.length) profilePercentage.professionalHistory = 100;

      return profilePercentage;
    },
    getPercentageTotal(profilePercentage: any): number {
      let percentageTotal = 0;
      let percentage = 0;
      const sections = Object.keys(profilePercentage);
      for (let i = 0, max = sections.length; i < max; i++) {
        percentage += profilePercentage[sections[i]];
      }
      percentageTotal = Math.round(percentage / sections.length);

      return percentageTotal;
    },
  }));

export interface ProfileStore extends Instance<typeof ProfileStore> {}

export async function getProfile(profileId?: number) {
  const url = profileId ? `/profile/${profileId}` : '/profile';
  const { data } = await axiosInstance.get(url);
  const {
    id,
    completed,
    personalInfo,
    highlight,
    addPositionProfile,
    summary,
    skill,
    userId,
    skillSummary,
    education,
    certification,
    history,
    cognizantId,
  } = data;
  const _personalInfo = { ...personalInfo, cognizantId: `${personalInfo.cognizantId || ''}` };
  return {
    id,
    completed,
    personalInfo: _personalInfo,
    highlight,
    addPositionProfile,
    summary,
    userId,
    skillSummary,
    skill,
    education,
    certification,
    history,
    cognizantId: `${cognizantId || ''}`,
  };
}
