import React, { FC} from 'react';
import { Steps } from 'antd';

import { useRootStore } from '../../root/RootStoreHook';

import { IStep } from '../models/Step';
import { steps } from '../models/StepsConfig';

const StepperHeader: FC = () => {
    const { stepper } = useRootStore();
    return (
      <Steps current={stepper?.currentStep} type="navigation" size="small" onChange={(step) => stepper.changeStep(step)}>
        {steps.map((item: IStep) => (
          <Steps.Step
            key={item.title}
            title={item.title}
            className={item.module <= stepper.highestStep ? 'normal-step' : 'disabled-step'}
          />
        ))}
      </Steps>
    );
  };

  export default StepperHeader;