import React, { FC } from 'react';
import { Modal, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './CustomModal.css'

interface IProps {
  visible: boolean;
  title: string;
  okText?: string | JSX.Element;
  onOk?: (e: React.MouseEvent<HTMLElement>) => void;
  onCancel?: (e: React.MouseEvent<HTMLElement>) => void;
  width?: string | number;
  footer?: any;
  disabled?: boolean;
}

const CustomModal: FC<IProps> = ({ title, visible, onOk, onCancel, okText, children, width, footer, disabled }) => {
  return (
    <Modal
      className='modal-container'
      closeIcon={<CloseOutlined />}
      visible={visible}
      title={
        <Typography.Title level={4} className='typography-title'>
          {title}
        </Typography.Title>
      }
      okText={okText ?? 'Save'}
      cancelText="Cancel"
      onOk={onOk}
      onCancel={onCancel}
      footer={footer}
      maskClosable={false}
      width={width}
      okButtonProps={{ disabled: disabled}}
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
