import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { Form, Checkbox } from 'antd';
// import { Rule } from 'antd/lib/form';
// import { Config } from '../profile/models/Config';

export interface IProps {
  name: string;
  label: string;
  // config?: Config;
  onChange?: (value: any) => void;
  checked: boolean;
  disabled?: boolean;
}

export const CustomCheckbox: FC<IProps> = ({ name, label, onChange, checked, disabled }) => {
  // function getRules(): Rule[] {
  //   const { required } = config;
  //   const rules: Rule[] = [{ required }];
  //   return rules;
  // }

  return useObserver(() => (
    <Form.Item valuePropName="checked" name={name}>
      <Checkbox onChange={onChange} checked={checked} disabled={disabled}>
        {label}
      </Checkbox>
    </Form.Item>
  ));
};
