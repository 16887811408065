import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { List } from 'antd';
import { EducationItem } from './EducationStore';
import { useRootStore } from '../root/RootStoreHook';
import CustomCard from '../shared/CustomCard';
import CertificationDisplay from '../certification/CertificationDisplay';
import { Routes } from '../app/routing/Routes';
import rol, { isRol } from '../login/roles';
import './EducationDisplay.css';

interface IProps {
  infoExtra?: any | undefined;
  sameId?:boolean | undefined;
}

const EducationsDisplay: FC<IProps> = ({ infoExtra, sameId }) => {
  const { profile, user } = useRootStore();
  const userRole = user?.rol;
  const { education, certification } = profile!;

  return useObserver(() => (
    <CustomCard
      title="Education & Certifications"
      hasData={education!.items.length > 0 || certification!.items.length > 0}
      pathForEdit={Routes.Education}
      subTitle={infoExtra}
      canEdit={isRol([rol.ADMIN, rol.USER], userRole) || sameId}
      noDataDescription="You didn’t add any education yet."
    >
      {education!.items.length > 0 && (
        <List>
          <h3 className='education-list-title'>Education</h3>
          {education!.sortedItems.map((item: EducationItem) => (
            <List.Item key={item.id} className='education-list-item'>
              {item.start && item.end && item.end === item.start ? <>{item.start}: </> : null}
              {item.start && item.end && item.end !== item.start ? (
                <>
                  {item.start} - {item.end}:{' '}
                </>
              ) : null}
              {item.start && !item.end ? <>{item.start}: </> : null}
              {!item.start && item.end ? <>{item.end}: </> : null}
              {item.career ? <>{item.career}</> : null}
              {item.institution && item.career ? <> - </> : null}
              {item.institution ? <>{item.institution}</> : null}
              {item.location ? <>, {item.location}</> : null}
            </List.Item>
          ))}
        </List>
      )}
      <br />
      {certification!.items.length > 0 && <CertificationDisplay />}
      <br />
    </CustomCard>
  ));
};

export default EducationsDisplay;
