import { Instance, types, flow, onSnapshot } from 'mobx-state-tree';
import { axiosInstance } from '../../../http/agent';
import { getRootStore } from '../../root/RootStoreUtils';
import { SkillSummaryItem } from './SkillSummaryItem';
import { GeneratorHelper } from '../../shared/Helpers';

export const SkillSummaryStore = types
  .model('SkillSummaryStore', {
    items: types.array(SkillSummaryItem),
    showModal: types.optional(types.boolean, false),
    selectedItem: types.maybe(types.reference(SkillSummaryItem))
  })
  .views((self) => ({
    get initialValues() {
      return {
        skill: self.selectedItem?.skill ?? '',
        description: self.selectedItem?.description ?? '',
      };
    },
    get sortedItemsForTable() {
      return this.sortedItems.map((x: SkillSummaryItem) => ({ key: x.id, ...x }));
    },
    get canContinue(): boolean {
      return true;
    },
    get sortedItems(): SkillSummaryItem[] {
      return self.items.slice().sort((a: SkillSummaryItem, b: SkillSummaryItem) => a.order - b.order);
    },
    get dataToSubmit() {
      return {
        items: this.sortedItems.map((item, index) => ({
          skill: item.skill,
          description: item.description,
          order: index + 1,
        })),
      };
    },
  }))
  .actions((self) => ({
    addOrUpdate(skillSummaryItem: SkillSummaryItem) {
      if (self.selectedItem) {
        this.update(skillSummaryItem);
      } else {
        this.add(skillSummaryItem);
      }
      this.closeModal();
    },
    openModal() {
      self.showModal = true;
    },
    closeModal() {
      self.showModal = false;
    },
    delete(id: number) {
      const item = self.items.find((x: SkillSummaryItem) => x.id === id)!;
      self.items.remove(item);
    },
    setSelectedItem(item?: SkillSummaryItem) {
      self.selectedItem = item;
    },
    add(skillSummaryItem: SkillSummaryItem) {
      const item = SkillSummaryItem.create({
        id: GeneratorHelper.getNextId(self.items.slice()),
        skill: skillSummaryItem.skill,
        description: skillSummaryItem.description,
        order: GeneratorHelper.getNextOrder(self.items.slice()),
      });

      self.items.push(item);
    },
    update(skillSummaryItem: SkillSummaryItem) {
      const index = self.items.findIndex((x: SkillSummaryItem) => x === self.selectedItem);
      self.items[index].skill = skillSummaryItem.skill;
      self.items[index].description = skillSummaryItem.description;
    },
    create() {
      this.setSelectedItem();
      this.openModal();
    },
    edit(id: number) {
      const item = self.items.find((x: SkillSummaryItem) => x.id === id);
      this.setSelectedItem(item);
      this.openModal();
    },
    save: flow(function* save(): any {
      const { profile, showLoading, hideLoading, setShouldReloadProfile } = getRootStore(self);
      const data = {
        profileId: profile!.id,
        ...self.dataToSubmit,
      };
      showLoading();
      try {
        yield axiosInstance.put(`/skillSummary/${profile!.id}`, data);
        self.selectedItem = undefined;
        setShouldReloadProfile(false);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      } finally {
        hideLoading();
      }
    }),
    afterCreate() {
      const { setShouldReloadProfile } = getRootStore(self);
      onSnapshot(self.items, () => {
        setShouldReloadProfile(true);
      });
    },
  }));

export interface SkillSummaryStore extends Instance<typeof SkillSummaryStore> {}
