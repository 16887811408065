import { Instance, types } from 'mobx-state-tree';

export const SkillItem = types.model('SkillItem', {
    id: types.identifierNumber,
    skillName: types.string,
    skillLevel: types.string,
    yearsExperience: types.number,
    skillClassificationId: types.maybeNull(types.number),
    skillCategoryId: types.maybeNull(types.number),
    order: types.number,
});

export interface SkillItem extends Instance<typeof SkillItem> { }
