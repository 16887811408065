import React, { FC, useEffect } from 'react';
import {  LineChartOutlined,SettingOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import './admin-sidebar.css';
import * as AntdIcons from '@ant-design/icons';
import { getConfig,IInnerSidebarItemConfig} from './inner-sidebar-items-config';
import InnerSidebarItem from './InnerSidebarItem';

const InnerSidebar: FC<any> = ({ closeSettingsMenu, isOpen, type, handleSidebarOpposite }) => {
  const {icon,name,config}=getConfig(type)
  const sideID= `${name}-inner-sidebar`

  useEffect(() => {
    const sidebar = document.getElementById(sideID) as HTMLFormElement;
    const sidebarOpposite = document.getElementById('admin-sidebar-opposite') as HTMLFormElement;
    if (isOpen) {
      sidebar.classList.add('opened-admin-sidebar');
    } else {
      sidebar.classList.remove('opened-admin-sidebar');
    }
    handleSidebarOpposite(sidebarOpposite)
  }, [isOpen]); 

  let IconToRender = SettingOutlined
  if(icon.includes('LineChartOutlined')){
    IconToRender = LineChartOutlined
  }

  return (
    <>
      <div className="sidebar-item-gradient" />
      <div
        className="sidebar-item"
        onClick={closeSettingsMenu}
        onKeyPress={closeSettingsMenu}
        role="button"
        tabIndex={0}
        aria-label="Settings"
      >
        {icon.includes('LineChartOutlined')?<LineChartOutlined/>:<SettingOutlined/>}
        <span>{name}</span>
        <div className={`admin-sidebar-arrows ${isOpen ? 'close' : 'open'}`}>
          <DownOutlined className='down-outlined'/>
          <UpOutlined className='up-outlined'/>
        </div>
      </div>

      <div className="admin-sidebar" id={sideID}>
        {config.map((config: IInnerSidebarItemConfig) => (
          <InnerSidebarItem
            route={config.route}
            text={config.text}
            closeSettingsMenu={closeSettingsMenu}
            key={config.route}
          />
        ))}
      </div>
    </>
  );
};

export default InnerSidebar;
