import axios, { AxiosInstance } from 'axios';
import showErrorModal from '../components/notifications/ErrorModal';
import { getToken } from '../components/user/UserStore';

export const axiosInstance = axios.create({
  baseURL: '/api',
});

export const reportsInstance = axios.create({
  baseURL: process.env.REACT_APP_REPORTS_API_URL || '/report',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'Access-Control-Allow-Headers': 'Content-Type, Authorization, Content-Length, X-Requested-With',
    'Access-Control-Allow-Credentials': 'true',
  },
});

const applyInterceptors = (axios: AxiosInstance) => {
  axios.interceptors.request.use(
    (config) => {
      const token = getToken();
      if (token) {
        config.headers!.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
  
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error && error.response && error.response.status >= 400) {
        showErrorModal(error.response.data);
      }
  
      return Promise.reject(error);
    }
  );
}

applyInterceptors(axiosInstance);
applyInterceptors(reportsInstance);
