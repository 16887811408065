/* eslint-disable no-alert */
import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { Button, Space } from 'antd';
import { useWizardMode } from '../stepper/WizardMode';
import { useRedirect } from '../hooks/HooksUtil';
import { useRootStore } from '../root/RootStoreHook';
import './CustomButtons.css';

interface IProps {
  onSubmit: (e: any) => void;
  shouldReload?: boolean;
}

const CustomButtons: FC<IProps> = ({ onSubmit, shouldReload }) => {
  const redirect = useRedirect();
  const wizardMode = useWizardMode();
  const shouldReloadProfile = shouldReload ?? useRootStore().shouldReloadProfile;

  const styles = {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 24,
  } as React.CSSProperties;

  const openPrompt = async () => {
    // eslint-disable-next-line
    if (confirm('Are you sure you want to leave without saving?')) redirect.back();
  };

  return useObserver(() =>
    wizardMode ? (
      <></>
    ) : (
      <Space className='custom-button-container'>
        <Button
          onClick={() => {
            if (shouldReloadProfile) openPrompt();
            else redirect.back();
          }}
        >
          Back
        </Button>
        <Button type="primary" onClick={onSubmit}>
          Save
        </Button>
      </Space>
    )
  );
};

export default CustomButtons;
