import React, { FC, useEffect, useRef, useState } from 'react';
import { Table, Tag, Input, Space, Button, Tooltip } from 'antd';
import { useObserver } from 'mobx-react-lite';
import { SearchOutlined, DownloadOutlined, FilterOutlined } from '@ant-design/icons';
import CustomHeader from '../../../../forms/CustomHeader';
import { useRootStore } from '../../../../root/RootStoreHook';
import { ProfileItem } from '../../../profiles/models/ProfileItem';
import CheckSectionModal from '../../../../document/CheckSectionModal';
import LoadingIcon from '../../../../shared/LoadingIcon';
import { defaultProfilePagination } from '../../../profiles/ProfilesStore';
import styles from '../../../profiles/icon.module.css';
import ProfilesModal from '../../../profiles/ProfilesModal';
import BoldColumnTitle from '../../../../shared/BoldColumnTitle';
import rol, { isRol } from '../../../../login/roles';
import '../../../profiles/profiles.css';
import getColumnSearch from '../../../helpers'
let userLoggedCommunityId: number | null | undefined;

const MultipleDownloadReports: FC = () => {
  const { profiles, dropdownOptions, document, profile: userLogged, user } = useRootStore();
  const userRole = user?.rol;
  const {
    positionFilters,
    additionalPositionFilters,
    communityFilters,
    getCommunityValue,
    getPositionValue,
    getAdditionalPositionValue,
  } = dropdownOptions!;
  const rootStore = useRootStore();
  const { updateSelectedProfiles } = profiles!;
  const { profile} = rootStore;
  const { resetValues } = document!;
  const searchInput = useRef(null);
  const [showCheckSectionModal, setCheckSectionModal] = useState<boolean>(false);
  const [showProfilesModal, setProfilesModal] = useState<boolean>(false);

  useEffect(() => {
    const UserEmailLogged = user?.displayName ? user?.displayName : ""
    if (UserEmailLogged !== profile?.personalInfo?.email && !isRol([rol.ADMIN, rol.MANAGER, rol.LEAD], userRole)) getUserLogged();
  }, []);

  useEffect(() => {
    if (isRol([rol.LEAD], userRole)) {
      userLoggedCommunityId = userLogged?.personalInfo?.communityId;
      if (userLoggedCommunityId)
        defaultProfilePagination.filters = {
          CommunityIds: [userLoggedCommunityId],
        };
    }
    profiles!.getResults(defaultProfilePagination);

    return () => {
      defaultProfilePagination.filters = {};
    };
  }, [userRole, profiles, userLogged?.personalInfo?.communityId]);

  const closeCheckSectionModal = () => {
    setCheckSectionModal(false);
    resetValues();
  };

  const getUserLogged = async () => {
    rootStore.getProfileById(user?.idProfile)
  };

  const closeProfilesModal = () => {
    setProfilesModal(false);
  };

  const handleNextModal = () => {
    setProfilesModal(false);
    setCheckSectionModal(true);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    if (isRol([rol.LEAD], userRole)) {
      if (filters.communityId === null) filters.communityId = [];
      filters.communityId.push(userLoggedCommunityId);
    }
    const profilePagination = {
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      filters: profiles!.getFilters(filters),
    };

    profiles!.getResults(profilePagination);
  };

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any, clearFilters: any,  selectedButton: string) => {
    if (selectedButton === "reset"){
      handleReset(dataIndex, clearFilters);
    }
      confirm();
      profiles!.updateSearchText(selectedKeys[0]);
      profiles!.updateSearchedColumn(dataIndex);
  };

  const handleReset = (_dataIndex: any, clearFilters: any) => {
    profiles!.updateSearchText('');
    clearFilters();
  };

  const areProfilesSelected = (): boolean => {
    return profiles.selectedProfiles.length > 0;
  };

  const handleProfilesModal = () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (areProfilesSelected()) setProfilesModal(true);
  };

  const handleOnDownload = () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (areProfilesSelected()) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      profiles.selectedProfiles.length && profiles.selectedProfiles.length > 1
        ? handleProfilesModal()
        : setCheckSectionModal(true);
    }
  };

  const rowSelection = {
    selectedRowKeys: profiles.selectedProfilesIds,
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      updateSelectedProfiles(selectedRows);
    },
  };

  const selectFilterText = () => !profiles.searchText;

  const tableColumns = [
    {
      title: <BoldColumnTitle title="Full Name" />,
      dataIndex: 'fullName',
      ...getColumnSearch({dataIndex: 'fullName', title: 'Full Name', searchInput, handleSearch, handleReset, selectFilterText, data: profiles}),
      ellipsis: true,
      width: '16%',
    },
    {
      title: <BoldColumnTitle title="Position" />,
      dataIndex: 'positionId',
      render: (_text: string, profile: ProfileItem) => (
        <Tag color="#2E308E" className='table-tag-max-height'>
          {getPositionValue(profile!.positionId)}
        </Tag>
      ),
      filters: positionFilters,
      onFilter: (value: any, profile: ProfileItem) => profile.positionId === Number(value),
      filterIcon: (filtered: any) => <FilterOutlined className={filtered ? 'filterIcon_filtered' : 'filterIcon'} />,
      width: '180px',
    },
    {
      title: <BoldColumnTitle title="Additional Position" />,
      dataIndex: 'AdditionalPositionId',
      render: (text: string, profile: ProfileItem) => (
        <Tag color="#2E308E" className='table-tag-max-height'>
          {getAdditionalPositionValue(profile!.additionalPositionId)}
        </Tag>
      ),
      filters: additionalPositionFilters,
      onFilter: (value: any, profile: ProfileItem) => profile.additionalPositionId === Number(value),
      filterIcon: (filtered: any) => <FilterOutlined className={filtered ? 'filterIcon_filtered' : 'filterIcon'} />,
      width: '180px',
    },
    {
      title: <BoldColumnTitle title="Community" />,
      dataIndex: 'communityId',
      render: (text: string, profile: ProfileItem) => (
        <Tag color="#2E308E" className='table-tag-max-height'>
          {getCommunityValue(profile!.communityId)}
        </Tag>
      ),
      filters: isRol([rol.ADMIN, rol.MANAGER], userRole) ? communityFilters : [],
      onFilter: (value: any, profile: ProfileItem) => profile.communityId === Number(value),
      filterIcon: (filtered: any) => <FilterOutlined className={filtered ? 'filterIcon_filtered' : 'filterIcon'} />,
      width: '180px',
    },
  ];

  return useObserver(() => {
    const tableLoading = {
      spinning: profiles!.loading,
      indicator: <LoadingIcon />,
    };
    const pageSizeOptions = profiles.getPageSizeOptions();
    const showSizeChanger = pageSizeOptions.length > 1;

    return (
      <div className="reports table">
        <div className="admin-header">
          <CustomHeader title="Reports" />
        </div>
        <span>{profiles.selectedProfiles.length} selected profiles</span>
        <Tooltip title="Download selected profiles">
          <DownloadOutlined
            onClick={() => handleOnDownload()}
            className={`${styles.icon} ${areProfilesSelected() ? styles.enabled : styles.disabled}`}
          />
        </Tooltip>
        <Table
          loading={tableLoading}
          pagination={{
            position: ['bottomRight'],
            pageSize: profiles!.pageSize,
            pageSizeOptions,
            total: profiles!.total,
            showSizeChanger,
          }}
          columns={tableColumns}
          onChange={handleTableChange}
          dataSource={profiles!.itemsForTable}
          rowSelection={rowSelection}
        />
        {showCheckSectionModal && (
          <CheckSectionModal
            profilesIds={profiles.sortedItems.map((x) => x.id)}
            visible={showCheckSectionModal}
            closeModal={() => closeCheckSectionModal()}
            downloadWithOptions={true}
          />
        )}
        {showProfilesModal && (
          <ProfilesModal visible={showProfilesModal} closeModal={closeProfilesModal} onOk={handleNextModal} />
        )}
      </div>
    );
  });
};

export default MultipleDownloadReports;
