import React, { FC } from 'react';
import { Form } from 'antd';
import { useObserver } from 'mobx-react-lite';
import { FormInstance } from 'antd/lib/form/Form';
import validateMessages from './CustomValidationMessages';

interface IProps {
  initialValues: any;
  form: FormInstance;
}

const CustomFormForModal: FC<IProps> = ({ form, children, initialValues }) =>
  useObserver(() => (
    <Form
      name="editForm"
      layout="vertical"
      form={form}
      validateMessages={validateMessages}
      initialValues={initialValues}
      scrollToFirstError
    >
      {children}
    </Form>
  ));

export default CustomFormForModal;
