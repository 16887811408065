import { Instance, types, flow, onSnapshot } from 'mobx-state-tree';
import { axiosInstance } from '../../http/agent';
import { getRootStore } from '../root/RootStoreUtils';
import { Config } from '../profile/models/Config';
import { AddPositionProfileItem, AddPositionProfile } from './models/AddPositionProfile';
import { GeneratorHelper } from '../shared/Helpers';

export const AddPositionProfileConfig = types.model('AddPositionProfile', {
  description: Config,
});

export const AdditionalPositionProfileStore = types
  .model('AdditionalPositionProfileStore', {
    items: types.array(AddPositionProfileItem),
    showModal: types.optional(types.boolean, false),
    minLength: types.optional(types.number, 1),
    selectedItem: types.maybe(types.reference(AddPositionProfileItem)),
    config: types.optional(AddPositionProfileConfig, {
      description: { required: true },
    }),
  })
  .views((self) => ({
    get initialValues() {
      return {
        additionalPositionId: self.selectedItem?.additionalPositionId ?? '',
      };
    },
    get canContinue(): boolean {
      return self.items.length >= self.minLength;
    },
    get validationMessage(): string {
      return `At least ${self.minLength} AddPositionProfile is required.`;
    },
    get sortedItemsForTable() {
      return this.sortedItems.map((x: AddPositionProfile) => ({ key: x.id, ...x }));
    },
    get sortedItems(): AddPositionProfile[] {
      return self.items.slice().sort((a: AddPositionProfile, b: AddPositionProfile) => a.id - b.id);
    },
    get dataToSubmit() {
      return { items: this.sortedItems.map((item, index) => ({ additionalPositionPersonalInfoId: item.additionalPositionId})) };
    },
  }))
  .actions((self) => ({
    addOrUpdate(additionalPosition: AddPositionProfile) {
      if (self.selectedItem) {
        this.update(additionalPosition.additionalPositionId);
      } else {
        this.add(additionalPosition.additionalPositionId);
      }
      this.closeModal();
    },
    openModal() {
      self.showModal = true;
    },
    closeModal() {
      self.showModal = false;
    },
    delete(id: number) {
      const item = self.items.find((x: AddPositionProfile) => x.id === id)!;
      self.items.remove(item);
    },
    setSelectedItem(item?: AddPositionProfile) {
      self.selectedItem = item;
    },
    add(additionalPositionId: number) {
      const item = AddPositionProfileItem.create({
        id: GeneratorHelper.getNextId(self.items.slice()),
        additionalPositionId,
      });

      self.items.push(item);
    },
    create() {
      this.setSelectedItem();
      this.openModal();
    },
    update(additionalPositionId: number) {
      const index = self.items.findIndex((x: AddPositionProfile) => x === self.selectedItem);
      self.items[index].additionalPositionId = additionalPositionId;
    },
    edit(id: number) {
      const item = self.items.find((x: AddPositionProfile) => x.id === id);
      this.setSelectedItem(item);
      this.openModal();
    },
    save: flow(function* save(): any {
      const { profile, showLoading, hideLoading, setShouldReloadProfile } = getRootStore(self);

      const data = {
        profileId: profile!.id,
        ...self.dataToSubmit,
      };

      showLoading();
      try {
        yield axiosInstance.put(`/highlight/${profile!.id}`, data);
        self.selectedItem = undefined;
        setShouldReloadProfile(false);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      } finally {
        hideLoading();
      }
    }),
    afterCreate() {
      const { setShouldReloadProfile } = getRootStore(self);
      onSnapshot(self.items, () => {
        setShouldReloadProfile(true);
      });
    },
  }));

export interface AdditionalPositionProfileStore extends Instance<typeof AdditionalPositionProfileStore> {}
