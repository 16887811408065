import React, { FC, ReactText, useState } from 'react';
import { Tag } from 'antd';
import { useObserver } from 'mobx-react-lite';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TableCurrentDataSource, TablePaginationConfig, SorterResult } from 'antd/lib/table/interface';
import { CloseOutlined } from '@ant-design/icons';
import { useRootStore } from '../../root/RootStoreHook';
import CustomModal from '../../shared/CustomModal';
import CustomDragableTable from '../../shared/CustomDragableTable';
import { ProfileItem } from './models/ProfileItem';
import { SelectedProfile } from './ProfilesStore';
import BoldColumnTitle from '../../shared/BoldColumnTitle';
import './ProfilesModal.css';

interface IProps {
  visible: boolean;
  closeModal: () => void;
  onOk: () => void;
}

const ProfilesModal: FC<IProps> = ({ visible, closeModal, onOk }) => {
  const root = useRootStore();
  const { profiles, dropdownOptions } = root;
  const { getCommunityValue, getPositionValue, getAdditionalPositionValue } = dropdownOptions!;
  const [sortedInfo, setSortedInfo] = useState<any>();

  const onCancel = () => {
    closeModal();
  };

  const columns = [
    {
      title: <BoldColumnTitle title="Full Name" />,
      dataIndex: 'fullName',
      key: 'fullName',
      sortOrder: sortedInfo && sortedInfo.columnKey === 'fullName' && sortedInfo.order,
      sorter: (a: ProfileItem, b: ProfileItem) => a.fullName.localeCompare(b.fullName),
    },
    {
      title: <BoldColumnTitle title="Position" />,
      dataIndex: 'positionId',
      key: 'position',
      sortOrder: sortedInfo && sortedInfo.columnKey === 'position' && sortedInfo.order,
      render: (text: string, profile: ProfileItem) => (
        <Tag color="#2E308E">{getPositionValue(profile!.positionId)}</Tag>
      ),
      sorter: (a: ProfileItem, b: ProfileItem) =>
        getPositionValue(a.positionId).localeCompare(getPositionValue(b.positionId)),
    },
    {
      title: <BoldColumnTitle title="Additional Position" />,
      dataIndex: 'AdditionalPositionId',
      key: 'additionalPosition',
      sortOrder: sortedInfo && sortedInfo.columnKey === 'additionalPosition' && sortedInfo.order,
      render: (text: string, profile: ProfileItem) => (
        <Tag color="#2E308E">{getAdditionalPositionValue(profile!.additionalPositionId)}</Tag>
      ),
      sorter: (a: ProfileItem, b: ProfileItem) =>
        getAdditionalPositionValue(a.additionalPositionId).localeCompare(
          getAdditionalPositionValue(b.additionalPositionId)
        ),
    },
    {
      title: <BoldColumnTitle title="Community" />,
      dataIndex: 'communityId',
      key: 'community',
      sortOrder: sortedInfo && sortedInfo.columnKey === 'community' && sortedInfo.order,
      render: (text: string, profile: ProfileItem) => (
        <Tag color="#2E308E">{getCommunityValue(profile!.communityId)}</Tag>
      ),
      sorter: (a: ProfileItem, b: ProfileItem) =>
        getCommunityValue(a.communityId).localeCompare(getCommunityValue(b.communityId)),
    },
    {
      render: (text: string, profile: SelectedProfile) => (
        <CloseOutlined className='close-outlined' onClick={() => profiles.deleteSelectedProfile(profile)} />
      ),
    },
  ];

  const handleMove = (dragIndex: number, hoverIndex: number) => {
    root!.dragAndDrop(dragIndex, hoverIndex, profiles.selectedProfiles);
    setSortedInfo(null);
  };

  const handleOnChange = (
    sorter: SorterResult<any> | SorterResult<any>[],
    currentTable: TableCurrentDataSource<any>
  ) => {
    setSortedInfo(sorter);
    profiles.orderSelectedProfiles(currentTable.currentDataSource);
  };

  return useObserver(() => (
    <CustomModal
      okText="Next"
      onOk={onOk}
      visible={visible}
      title="Order Profiles"
      onCancel={onCancel}
      width="max-content"
    >
      <DndProvider backend={HTML5Backend}>
        <CustomDragableTable
          style={{}}
          draggableKey="Profiles"
          columns={columns}
          dataSource={profiles.sortedItems}
          moveRow={handleMove}
          onChange={(
            pagination: TablePaginationConfig,
            filter: Record<string, ReactText[] | null>,
            sorter: SorterResult<any> | SorterResult<any>[],
            currentTable: TableCurrentDataSource<any>
          ) => handleOnChange(sorter, currentTable)}
        />
      </DndProvider>
    </CustomModal>
  ));
};
export default ProfilesModal;
