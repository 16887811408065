/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useRef, useState } from 'react';
import { Table, Tag, Popover, Menu, Divider } from 'antd';
import { useObserver } from 'mobx-react-lite';
import {
  FileWordOutlined,
  FilePptOutlined,
  EditOutlined,
  DownloadOutlined,
  FilterOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import CustomHeader from '../../forms/CustomHeader';
import { useRootStore } from '../../root/RootStoreHook';
import { ProfileItem } from './models/ProfileItem';
import { useRedirect } from '../../hooks/HooksUtil';
import { Routes } from '../../app/routing/Routes';
import LoadingIcon from '../../shared/LoadingIcon';
import { defaultProfilePagination } from './ProfilesStore';
import BoldColumnTitle from '../../shared/BoldColumnTitle';
import './profiles.css';
import rol, { isRol } from '../../login/roles';
import { tabKeys } from '../../sidebar/Sidebar';
import BoldColumnTitleTooltip from '../../shared/BoldColumTitleTooltip';
import getColumnSearch from '../helpers';
import { useLocation } from 'react-router-dom';
import showErrorModal from '../../../components/notifications/ErrorModal';

const scrollToTop = () => {
  window.scrollTo(0, 0);
};
let userLoggedCommunityId: number | null | undefined;

const Profiles: FC = () => {
  const rootStore = useRootStore();

  const { profiles, dropdownOptions, document, profile: userLogged, user, updateForcedTab } = rootStore;
  const userRole = user?.rol;
  const { communityFilters, positionFilters, getCommunityValue, getPositionValue } = dropdownOptions!;
  const searchInput = useRef(null);
  const redirect = useRedirect();

  const location = useLocation();
  const profileIdTocheck = Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1));
  const [notAllowUser, setNotAllowUser] = useState(false);
  useEffect(() => {
    if (isRol([rol.USER], userRole) && user?.id != profileIdTocheck) {
      setNotAllowUser(true);
    }
    if (isRol([rol.LEAD], userRole)) {
      userLoggedCommunityId = userLogged?.personalInfo?.communityId;
      if (userLoggedCommunityId)
        defaultProfilePagination.filters = {
          CommunityIds: [userLoggedCommunityId],
        };
    }
    profiles!.getResults(defaultProfilePagination);

    return () => {
      defaultProfilePagination.filters = {};
    };
  }, []);

  const handleRedirectProfile = (profileId: number) => {
    rootStore.getProfileById(profileId).finally(() => {
      redirect.go(Routes.Profile, profileId);
      updateForcedTab(tabKeys.PROFILES);
    });
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    console.log('handleTableChange', pagination, filters, sorter, extra);
    /* FILTERS
        communityId: null
        fullName: null
        positionId: null
      */
    if (isRol([rol.LEAD], userRole)) {
      if (filters.communityId === null) filters.communityId = [];
      filters.communityId.push(userLoggedCommunityId);
    }

    const profilePagination = {
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      filters: profiles!.getFilters(filters),
    };

    profiles!.getResults(profilePagination);
    scrollToTop();
  };

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any, clearFilters: any, selectedButton: string) => {
    if (selectedButton === 'reset') {
      handleReset(dataIndex, clearFilters);
    }
    confirm();
    profiles!.updateSearchText(selectedKeys[0]);
    profiles!.updateSearchedColumn(dataIndex);
  };

  const handleReset = (_dataIndex: any, clearFilters: any) => {
    profiles!.updateSearchText('');
    clearFilters();
  };

  const selectFilterText = () => !profiles.searchText;

  const tableColumns = [
    {
      title: (
        <BoldColumnTitleTooltip
          name="Full Name"
          tootltip="Please take into consideration special characters when searching"
        />
      ),
      dataIndex: 'fullName',
      ...getColumnSearch({
        dataIndex: 'fullName',
        title: 'Full Name',
        searchInput,
        handleSearch,
        handleReset,
        selectFilterText,
        data: profiles,
      }),
      width: '16%',
      ellipsis: true,
    },
    {
      title: <BoldColumnTitle title="Email" />,
      dataIndex: 'email',
      width: '16%',
      ellipsis: true,
    },
    {
      title: <BoldColumnTitle title="Community" />,
      dataIndex: 'communityId',
      render: (text: string, profile: ProfileItem) => (
        <Tag color="#2E308E" className="table-tag-max-height">
          {getCommunityValue(profile!.communityId)}
        </Tag>
      ),
      filters: isRol([rol.ADMIN, rol.MANAGER], userRole) ? communityFilters : [],
      onFilter: (value: any, profile: ProfileItem) => profile.communityId === Number(value),
      filterIcon: (filtered: any) => <FilterOutlined className={filtered ? 'filterIcon_filtered' : 'filterIcon'} />,
      width: '180px',
    },
    {
      title: <BoldColumnTitle title="Position" />,
      dataIndex: 'positionId',
      render: (text: string, profile: ProfileItem) => (
        <Tag color="#2E308E" className="table-tag-max-height">
          {getPositionValue(profile!.positionId)}
        </Tag>
      ),
      filters: isRol([rol.ADMIN, rol.MANAGER], userRole) ? positionFilters : [],
      onFilter: (value: any, profile: ProfileItem) => profile.positionId === Number(value),
      filterIcon: (filtered: any) => <FilterOutlined className={filtered ? 'filterIcon_filtered' : 'filterIcon'} />,
      width: '180px',
    },
    {
      title: <BoldColumnTitle title="Actions" />,
      dataIndex: 'cv',
      render: (text: string, profile: ProfileItem) => {
        const content = (
          <Menu className="download-profile-document">
            <Menu.Item onClick={() => document!.downloadDocument([profile.id], 'docx')}>
              <FileWordOutlined /> Download Profile Document
            </Menu.Item>
            <Menu.Item className="download-profile-Slide">
              <a href={`/api/document/ppt/${profile!.id}`} download>
                <FilePptOutlined /> Download Profile Slide
              </a>
            </Menu.Item>
          </Menu>
        );

        return (
          <>
            {(isRol([rol.MANAGER, rol.LEAD], userRole) && Number(profile?.id) === Number(user?.idProfile)) ||
            isRol([rol.ADMIN], userRole) ? (
              <Popover content="Edit" placement="top">
                <EditOutlined onClick={() => handleRedirectProfile(profile!.id)} />
              </Popover>
            ) : (
              <Popover content="View" placement="top">
                <EyeOutlined onClick={() => handleRedirectProfile(profile!.id)} />
              </Popover>
            )}
            <Divider type="vertical" />
            <Popover content={content} placement="top">
              <DownloadOutlined className="popover" />
            </Popover>
          </>
        );
      },
      width: '80px',
    },
  ];

  return useObserver(() => {
    const tableLoading = {
      spinning: profiles!.loading,
      indicator: <LoadingIcon />,
    };
    const pageSizeOptions = profiles.getPageSizeOptions();
    const showSizeChanger = pageSizeOptions.length > 1;
    if (notAllowUser) {
      console.log('not allow user');
      const onOk = () => {
        redirect.go(Routes.Profile);
      };
      showErrorModal({
        Title: 'Warning',
        Errors: 'You don’t have permissions to see other profiles. Please, contact your admin.',
        onOk,
      });
      return null;
    } else {
      return (
        <div className="profiles table">
          <div className="admin-header">
            <CustomHeader title="Profiles" />
          </div>
          <div className="profiles-table">
            <Table
              loading={tableLoading}
              pagination={{
                position: ['bottomRight'],
                pageSize: profiles!.pageSize,
                pageSizeOptions,
                total: profiles!.total,
                showSizeChanger,
              }}
              columns={tableColumns}
              onChange={handleTableChange}
              dataSource={profiles!.itemsForTable}
              scroll={{ x: true }}
            />
          </div>
          <div className="mobile-text">
            <p>The profiles section is not available in this resolution, please switch to tablet or desktop.</p>
          </div>
        </div>
      );
    }
  });
};

export default Profiles;
