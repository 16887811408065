import { Instance, types, flow } from 'mobx-state-tree';
import { axiosInstance } from '../../../http/agent';
import { Config } from '../../profile/models/Config';
import { TemplateItem } from './models/TemplateItem';
import { GeneratorHelper } from '../../shared/Helpers';

export const TemplateConfig = types.model('TemplateItem', {
  description: Config,
  address: Config,
});

export const TemplateStore = types
  .model('TemplateStore', {
    templates: types.array(TemplateItem),
    showModal: types.optional(types.boolean, false),
    templateType: types.maybe(types.string),
    minLength: types.optional(types.number, 1),
    selectedItem: types.maybe(types.reference(TemplateItem)),
    config: types.optional(TemplateConfig, {
      description: { required: true },
      address: { required: true },
    }),
  })
  .views((self) => ({
    get initialValues() {
      return {
        description: self.selectedItem?.description ?? '',
        address: self.selectedItem?.address ?? '',
        status: self.selectedItem?.status ?? false,
        default: self.selectedItem?.default ?? false,
      };
    },
    get validationMessage(): string {
      return `At least ${self.minLength} Template is required.`;
    },
    get docxForTable() {
      return self.templates.map((x: TemplateItem) => ({ key: x.id, ...x })).filter((x) => x.type === 'docx');
    },
    get docxForDropDown() {
      return self.templates
        .map((x: TemplateItem) => ({ key: x.id, ...x }))
        .filter((x) => x.type === 'docx' && x.status === true);
    },
    get pptxForTable() {
      return self.templates.map((x: TemplateItem) => ({ key: x.id, ...x })).filter((x) => x.type === 'pptx');
    },
    get defaultDocx() {
      if (self.templates.find((x) => x.type === 'docx' && x.default === true)) {
        return true;
      }
      return false;
    },
    get defaultPptx() {
      if (self.templates.find((x) => x.type === 'pptx' && x.default === true)) {
        return true;
      }
      return false;
    },
    getdefaultTemplate() {
      if(self.selectedItem?.default){
        return false;
      }
      if (self.templateType === 'docx') {
        return this.defaultDocx;
      }
      return this.defaultPptx;
    },
    getTemplateValues(id: number): string {
      return self.templates.find((x) => x.id === id)?.description || '';
    },
  }))
  .actions((self) => ({
    addOrUpdate(item: TemplateItem) {
      if (self.selectedItem) {
        this.update(item);
      } else {
        this.add(item);
      }
      this.closeModal();
    },
    openModal() {
      self.showModal = true;
    },
    closeModal() {
      self.showModal = false;
    },
    deleteTemplate(item: TemplateItem) {
      self.templates.remove(item);
      self.selectedItem = undefined;
    },
    addTemplate(item: TemplateItem) {
      self.templates.push(item);
    },
    async delete(id: number) {
      try {
        const item = self.templates.find((x: TemplateItem) => x.id === id)!;
        if (item.default) {
          return;
        }
        await axiosInstance.delete(`template/delete/${id}`);
        this.deleteTemplate(item);
      } catch (error) {
        console.error(error);
      }
    },
    setSelectedItem(item?: TemplateItem) {
      self.selectedItem = item;
    },
    setTemplateType(type: string) {
      self.templateType = type;
    },
    async add(item: TemplateItem) {
      const id = GeneratorHelper.getNextId(self.templates.slice());
      const data = {
        id,
        description: item.description,
        address: item.address,
        type: self.templateType!,
        status: item.status,
        default: item.default,
      };
      console.log('add', data);
      try {
        await axiosInstance.post('/template/add', data);
        this.addTemplate(data);
      } catch (error) {
        console.error(error);
      }
    },
    create(type: string) {
      this.setSelectedItem();
      this.openModal();
      this.setTemplateType(type);
    },
    update: flow(function* update(item: TemplateItem): any {
      // eslint-disable-next-line no-console
      console.log('update', item);
      const data = item;
      Object.assign(data, {
        id: self.selectedItem!.id,
        type: self.templateType,
      });
      try {
        yield axiosInstance.put('/template/update', data);
        const index = self.templates.findIndex((x: TemplateItem) => x === self.selectedItem);
        self.templates[index].description = item.description;
        self.templates[index].address = item.address;
        self.templates[index].status = item.status;
        self.templates[index].default = item.default;
      } catch (error) {
        console.error(error);
      }
    }),
    edit(id: number) {
      const item = self.templates.find((x: TemplateItem) => x.id === id);
      this.setSelectedItem(item);
      this.openModal();
      this.setTemplateType(item!.type);
    },
  }));

export interface TemplateStore extends Instance<typeof TemplateStore> {}

export async function getTemplates() {
  const { data } = await axiosInstance.get('/template/all');
  const { templates } = data;

  return {
    templates,
  };
}
