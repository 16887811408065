import React, { FC, useState } from 'react';
import { Menu, Popover, Form, Button } from 'antd';
import { useObserver } from 'mobx-react-lite';
import { ColumnProps } from 'antd/lib/table';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useRootStore } from '../root/RootStoreHook';
import CustomModal from '../shared/CustomModal';
import CustomDragableTable from '../shared/CustomDragableTable';
import { SectionItem } from './DocumentStore';
import BoldColumnTitle from '../shared/BoldColumnTitle';
import { CustomSelect } from '../forms/CustomSelect';
import './CheckSectionModal.css';

interface IProps {
  visible: boolean;
  closeModal: () => void;
  profilesIds: number[];
  downloadWithOptions?: boolean;
}

const CheckSectionModal: FC<IProps> = ({ visible, closeModal, profilesIds, downloadWithOptions }) => {
  const root = useRootStore();
  const { document, templates } = root;
  const [showRequiredWarning, setShowRequiredWarning] = useState(false);
  const [templateId, setTemplateId] = useState(undefined);
  const { downloadDocument, updateSelectedSections, isFormValid } = document!;

  const onOk = (documentType: string) => {
    setShowRequiredWarning(!isFormValid());
    if (isFormValid()) {
      downloadDocument(profilesIds, documentType, true, templateId);
      closeModal();
    }
  };

  const onCancel = () => {
    closeModal();
  };

  const rowSelection = (selectedRows: any[]) => {
    updateSelectedSections(selectedRows);
    setShowRequiredWarning(!isFormValid());
  };

  const moveRow = async (fromIndex: number, toIndex: number) => {
    root!.dragAndDrop(fromIndex, toIndex, document!.items);
  };

  const columns: ColumnProps<SectionItem>[] = [
    {
      title: <BoldColumnTitle title="All" />,
      dataIndex: 'section',
      key: 'section',
    },
  ];

  const downloadOptions = (
    <Menu className='download-option-menu'>
      <Menu.Item className='download-option-menu-item' onClick={() => onOk('docx')}>
        Docx
      </Menu.Item>
      <Menu.Item onClick={() => onOk('json')}>Json</Menu.Item>
    </Menu>
  );
  const downloadButton = (
    <Popover content={downloadOptions} placement="top">
      <span>Download</span>
    </Popover>
  );
  const onSelect = (value: any) => setTemplateId(value);

  const defaultValueItems = (items: any[]): number | undefined => {
    if (items.length === 1) {
      return items[0].id;
    }
    return undefined;
  };

  return useObserver(() => (
    <Form>
      <CustomModal
        visible={visible}
        title="Select Sections"
        onOk={downloadWithOptions ? () => {} : () => onOk('docx')}
        onCancel={onCancel}
        footer={
          <div className='custom-select-container'>
            <div className='custom-select'>
              <CustomSelect
                onSelect={onSelect}
                name="template"
                label="Templates"
                options={templates!.docxForDropDown}
                config={{ required: true }}
                defaultValue={defaultValueItems(templates!.docxForDropDown)}
              />
            </div>
            <div>
              <Button onClick={onCancel}>Cancel</Button>
              <Button>{downloadWithOptions ? downloadButton : 'Download'}</Button>
            </div>
          </div>
        }
      >
        <DndProvider backend={HTML5Backend}>
          <CustomDragableTable
            style={{}}
            draggableKey="Section"
            columns={columns}
            dataSource={document.sortedItemsForTable}
            moveRow={moveRow}
            selectedRowKeys={document.selectedSectionsIds}
            rowSelection={rowSelection}
          />
        </DndProvider>
        {showRequiredWarning && <span className='required-warning'>At least one section is required</span>}
      </CustomModal>
    </Form>
  ));
};
export default CheckSectionModal;
