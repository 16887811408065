/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { FC, useEffect, useRef, useState } from 'react';
import { Table, Tag, Button, Popover, Popconfirm, Checkbox, Divider } from 'antd';
import { useObserver } from 'mobx-react-lite';
import {
  FilterOutlined,
  UserSwitchOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import CustomHeader from '../../forms/CustomHeader';
import { useRootStore } from '../../root/RootStoreHook';
import { useRedirect } from '../../hooks/HooksUtil';
import LoadingIcon from '../../shared/LoadingIcon';
import { defaultUserPagination } from '../../user/UserStore';
import BoldColumnTitle from '../../shared/BoldColumnTitle';
import { ProfileItem } from './models/ProfileItem';
import { UserItem } from './models/UserItem';
import UserEnrollmentModal from './UserEnrollmentModal';
import UserInfoModal from './modals/UserInfoModal';
import RolesModal from './modals/RolesModal';
import './profiles.css';
import BoldColumnTitleTooltip from '../../shared/BoldColumTitleTooltip';
import openNotification from 'components/notifications/Notification';
import getColumnSearch from '../helpers'

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

const UserManagement: FC = () => {
  const { dropdownOptions, user, getProfileById } = useRootStore();
  const redirect = useRedirect();
  const {
    communityFilters,
    positionFilters,
    rolesFilters,
    getCommunityValue,
    getPositionValue,
    getRoleValue,
  } = dropdownOptions!;
  const searchInput = useRef(null);
  const profileCompletedOptions = [
    {
      value: true,
      text: 'Completed',
    },
    {
      value: false,
      text: 'Not Completed',
    },
  ];
  const [showRolesModal, setRolesModal] = useState(false);
  const [showEnrollmentModal, setShowEnrollmentModal] = useState(false);
  const [showUserInfoModal, setUserInfoModal] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState('');
  const [userData, setUserData] = useState({ email: '', roleId: '' });
  const [userPersonalInfo, setUserPersonalInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    positionId: '',
    communityId: '',
    additionalPositionId: '',
    studioId: '',
    cognizantId: '',
  });
  const [currentFilter, setCurrentFilter] = useState(defaultUserPagination);

  useEffect(() => {
    user!.getResults(defaultUserPagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedEmail) {
      user!.getUserPersonalInfo(selectedEmail)
        .then((res) =>
          setUserPersonalInfo(res)
        );
    }
  }, [user, selectedEmail]);

  const closeModal = () => {
    setShowEnrollmentModal(false);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    console.log('handleTableChange', pagination, filters, sorter, extra);

    const userPagination = {
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      filters: user!.getFilters(filters),
    };
    user!.getResults(userPagination);
    scrollToTop();

    setCurrentFilter(userPagination);
  };

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any, clearFilters: any, selectedButton: string) => {
    if (selectedButton === "reset"){
      handleReset(dataIndex, clearFilters);
    }
      confirm();
      user!.updateSearchText(dataIndex, selectedKeys[0]);
      user!.updateSearchedColumn(dataIndex);
  };

  const handleReset = (dataIndex: string, clearFilters: any) => {
    user!.updateSearchText(dataIndex, '');
    clearFilters();
  };

  const selectFilterText = (dataIndex: string) => {
    return dataIndex === 'fullName' ? !user?.searchText : !user?.searchEmailText;
  };

  return useObserver(() => {
    const tableLoading = {
      spinning: user!.loading,
      indicator: <LoadingIcon />,
    };
    const pageSizeOptions = user!.getPageSizeOptions();
    const showSizeChanger = pageSizeOptions.length > 1;

    const clickChangeRoles = (userItem: UserItem) => {
      setUserData({ email: userItem.email, roleId: getRoleValue(userItem!.roleIds[0]) });
      setRolesModal(true);
    };

    const clickEditUserInfo = async (userItem: UserItem) => {
      setSelectedEmail(userItem.email);
      setUserPersonalInfo({
        firstName: '',
        lastName: '',
        email: '',
        positionId: '',
        communityId: '',
        additionalPositionId: '',
        studioId: '',
        cognizantId: '',
      });
      setUserInfoModal(true);
    };

    const deleteUser = (email: string, id: any) => {
      if(id !== user?.id){
        user?.deleteUser(email);
      }
      else{
        openNotification('Error', 'Its not possible to delete your own user', 'error', 5, 'bottomRight');
      }
    };
    const tableColumns = [
      {
        title: <BoldColumnTitleTooltip name='Full Name' tootltip='Please take into consideration special characters when searching' />,
        dataIndex: 'fullName',
        ...getColumnSearch({dataIndex: 'fullName', title: 'Full Name', searchInput, handleSearch, handleReset, selectFilterText, data: user}),
        width: '18%',
        ellipsis: true,
      },
      {
        title: <BoldColumnTitle title="Email" />,
        dataIndex: 'email',
        ...getColumnSearch({dataIndex: 'email', title: 'Email', searchInput, handleSearch, handleReset, selectFilterText, data: user}),
        width: '18%',
        ellipsis: true,
      },
      {
        title: <BoldColumnTitle title="Role" />,
        dataIndex: 'roleIds',
        width: '12%',
        render: (text: string, userItem: UserItem) =>
          userItem!.roleIds.map((roleId, i) => (
            <Tag
              color="#2E308E"
              className='role-column-tag'
              key={i}
            >
              {getRoleValue(roleId)}
            </Tag>
          )),
        filters: rolesFilters,
        onFilter: (value: any, userItem: UserItem) => userItem.roleIds.includes(Number(value)),
        filterIcon: (filtered: any) => <FilterOutlined className={filtered ? 'filterIcon_filtered' : 'filterIcon'} />,
      },
      {
        title: <BoldColumnTitle title="Community" />,
        dataIndex: 'communityId',
        width: '12%',
        render: (text: string, userItem: UserItem) => (
          <Tag color="#2E308E" className='column-tag'>
            {getCommunityValue(userItem!.communityId)}
          </Tag>
        ),
        filters: communityFilters,
        onFilter: (value: any, userItem: UserItem) => userItem.communityId === Number(value),
        filterIcon: (filtered: any) => <FilterOutlined className={filtered ? 'filterIcon_filtered' : 'filterIcon'} />,
      },
      {
        title: <BoldColumnTitle title="Position" />,
        dataIndex: 'positionId',
        width: '12%',

        render: (text: string, userItem: UserItem) => (
          <Tag color="#2E308E" className='column-tag'>
            {getPositionValue(userItem!.positionId)}
          </Tag>
        ),
        filters: positionFilters,
        onFilter: (value: any, userItem: UserItem) => userItem.positionId === Number(value),
        filterIcon: (filtered: any) => <FilterOutlined className={filtered ? 'filterIcon_filtered' : 'filterIcon'} />,
      },
      {
        title: (
          <div className='completed-rofile-title'>
            <BoldColumnTitle title="Completed Profile" />
          </div>
        ),
        dataIndex: 'profileCompleted',
        width: '160px',
        render: (completedProfile: boolean) => (
          <div className='title-checkbox'>
            <Checkbox checked={completedProfile}>{completedProfile}</Checkbox>
          </div>
        ),
        filters: profileCompletedOptions,
        onFilter: (value: any, userItem: UserItem) => userItem.profileCompleted === value,
        filterIcon: (filtered: any) => <FilterOutlined className={filtered ? 'filterIcon_filtered' : 'filterIcon'} />,
      },
      {
        title: <BoldColumnTitle title="Actions" />,
        dataIndex: 'cv',
        width: '15%',
        // eslint-disable-next-line no-shadow
        render: (profile: ProfileItem, userItem: UserItem) => (
          <>
            <Popover content="Edit User" placement="top">
              <EditOutlined
                className='actions-icon'
                onClick={() => {
                  clickEditUserInfo(userItem);
                }}
              />
            </Popover>
            <Divider type="vertical" />
            <Popover content="Change Roles" placement="top">
              <UserSwitchOutlined
                className='actions-icon'
                onClick={() => {
                  clickChangeRoles(userItem);
                }}
              />
            </Popover>
            <Divider type="vertical" />
            <Popover content="Delete User" placement="top">
              <Popconfirm
                title="Are you sure you want to delete this user?"
                onConfirm={() => {
                  deleteUser(userItem.email, userItem.id);
                }}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined />
              </Popconfirm>
            </Popover>
          </>
        ),
      },
    ];

    return (
      <div className="user-management table">
        <div className="admin-header">
          <CustomHeader title="User Management" />
        </div>
        <Button className='user-managment-table-button' type="primary" onClick={() => setShowEnrollmentModal(true)}>
          Enroll User
        </Button>
        <Table
          loading={tableLoading}
          pagination={{
            position: ['bottomRight'],
            pageSize: user!.pageSize,
            pageSizeOptions,
            total: user!.total,
            showSizeChanger,
          }}
          columns={tableColumns}
          onChange={handleTableChange}
          dataSource={user!.itemsForTable}
        />

        <UserEnrollmentModal
          userData={userData}
          visible={showEnrollmentModal}
          closeModal={closeModal}
          refreshData={() => user!.getResults(currentFilter)}
        />
        <RolesModal
          visible={showRolesModal}
          closeModal={() => setRolesModal(false)}
          userData={userData}
          refreshData={() => user!.getResults(currentFilter)}
        />
        <UserInfoModal
          visible={showUserInfoModal}
          closeModal={() => {
            setUserInfoModal(false);
            setSelectedEmail('');
          }}
          userPersonalInfo={userPersonalInfo}
          userItem={selectedEmail}
          refreshData={() => user!.getResults(currentFilter)}
        />
      </div>
    );
  });
};

export default UserManagement;
