import React, { FC, useRef } from 'react';
import { useDrag, useDrop, DropTargetMonitor, DragSourceMonitor } from 'react-dnd';
import './dragable.css';

const DragableBodyRow: FC = ({ index, moveRow, className, draggableKey, style, ...restProps }: any) => {
  const ref = useRef(null);
  const type = draggableKey as string;

  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor: DropTargetMonitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) return {};

      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward'
      };
    },
    drop: (item: any) => {
      moveRow(item.index, index);
    },
    canDrop(props: any, monitor: DropTargetMonitor) {
      return type === monitor.getItemType();
    }
  });

  const [, drag] = useDrag({
    item: { type, index },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
};

export default DragableBodyRow;
