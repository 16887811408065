/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { FC } from 'react';
import { Form } from 'antd';
import CustomFormForModal from '../../forms/CustomFormForModal';
import { useRootStore } from '../../root/RootStoreHook';
import CustomModal from '../../shared/CustomModal';
import RichText from '../../richtext/richtext';
import './ResponsabilitiesModal.css';

interface IProps {
  visible: boolean;
}

const ResponsabilitiesModal: FC<IProps> = ({ visible }) => {
  const { profile } = useRootStore();
  const [form] = Form.useForm();
  const { selectedItem } = profile!.history!;

  let nameFormatedValue = '';

  const onOk = () => {
    form
      .validateFields()
      .then((values: any) => {
        values.description = nameFormatedValue;
        selectedItem?.addOrUpdateResponsability(nameFormatedValue);
      })
      .catch(() => {});
  };

  function initialValues() {
    return {
      description: selectedItem?.selectedResponsabilityItem ? selectedItem!.selectedResponsabilityItem.name : ''
    };
  }

  const onValueChange = (value: any) => {
    nameFormatedValue = value;
  };

  return (
    <CustomModal
      visible={visible}
      title={selectedItem?.selectedResponsabilityItem ? 'Update responsibility' : 'Add responsibility'}
      onOk={onOk}
      onCancel={() => selectedItem!.closeResponsabilityModal()}
    >
      <p className='custom-form-modal-paragraph'>Add each of your responsibilities in separate items.</p>
      <CustomFormForModal form={form} initialValues={initialValues}>
        <RichText description={initialValues().description} onValueChange={onValueChange} />
      </CustomFormForModal>
    </CustomModal>
  );
};

export default ResponsabilitiesModal;
