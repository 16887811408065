import React from 'react';
import { notification } from 'antd';

const openNotification = (
  message: string,
  description: string | any,
  type: 'success' | 'info' | 'warning' | 'error',
  duration?: number,
  placement?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | undefined,
  style?: React.CSSProperties
) => {
  notification[type]({
    key: 'updatable',
    message,
    description,
    duration,
    placement,
    style,
  });
};

export default openNotification;
