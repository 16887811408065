import { Instance, types } from 'mobx-state-tree';
import { getRootStore } from '../root/RootStoreUtils';
import { Module } from './models/Enums';
import openNotification from '../notifications/Notification';
import openNotificationModal from 'components/notifications/NotificationModal';

export const StepperStore = types
  .model('StepperStore', {
    currentStep: types.optional(types.number, 0),
    canContinue: types.optional(types.boolean, false),
    highestStep: types.optional(types.number, 0),
  })
  .views((self) => ({
    isFirstStep(): boolean {
      return self.currentStep === 0;
    },
    isLastStep(steps: number): boolean {
      return self.currentStep === steps - 1;
    },
  }))
  .actions((self) => ({
    changeStep(step: number): void {
      if (step <= self.highestStep) {
        self.currentStep = step;
      }
    },
    setHighestStep(): void {
      self.highestStep = Math.max(self.highestStep, self.currentStep);
    },
    previousStep(): void {
      self.currentStep -= 1;
    },
    validate(module: Module): void {
      const { highlight, skill, history, education } = getRootStore(self).profile!;
      switch (module) {
        case Module.Highlights: {
          self.canContinue = highlight!.canContinue;
          if (!self.canContinue) {
            openNotification('Highlights Validation', highlight!.validationMessage, 'error');
          }
          break;
        }
        case Module.Skills: {
          self.canContinue = skill!.canContinue;
          if (!self.canContinue) {
            openNotification('Skills Validation', skill!.validationMessage, 'error');
          }
          break;
        }
        case Module.Education: {
          self.canContinue = education!.canContinue;
          if (!self.canContinue) {
            openNotification('Education Validation', education!.validationMessage, 'error');
          }
          break;
        }
        case Module.History: {
          self.canContinue = history!.canContinue;
          if (!self.canContinue) {
            openNotification('Work Experience Validation', history!.validationMessage, 'error');
          }
          break;
        }

        default:
          self.canContinue = true;
          break;
      }
    },
    partialSave(module: Module): void {
      this.validate(module);
      if (self.canContinue) {
         getRootStore(self).profile!.partialSave().then(result => {
          if (result) {
            openNotification('Success', 'Saved Successfully', 'success');
          } else {
            openNotification('Error', 'An error ocurred while trying to save the changes', 'error');
          }
         });
      }
    },
    save(module: Module, ok: () => void): void {
      this.validate(module);
      if (self.canContinue) {
         getRootStore(self).profile!.save().then(result => {
          if (result) {
            openNotificationModal('Success', 'Saved Successfully', 'success','', ok);
          } else {
            openNotification('Error', 'An error ocurred while trying to save the changes', 'error');
          }
         });
      }
    },
    nextStep(module: Module): void {
      if (self.currentStep <= self.highestStep) {
        this.validate(module);
        if (self.canContinue) {
          self.currentStep += 1;
          this.setHighestStep();
        }
      }
    },
    resetStep(): void {
      self.currentStep = 0;
      self.highestStep = 0;
    },
  }));

export interface StepperStore extends Instance<typeof StepperStore> {}
