import { types, Instance } from 'mobx-state-tree';
import { Config } from '../profile/models/Config';

export const PersonalInfoConfig = types.model('PersonalInfoConfig', {
  firstName: Config,
  lastName: Config,
  positionId: Config,
  studioId: Config,
  communityId: Config,
  communityPersonalInfoId: Config,
  additionalPositionId: Config,
  additionalPositionPersonalInfoId : Config,
  email: Config,
  phone: Config,
  cognizantId: Config
});

export interface PersonalInfoConfig extends Instance<typeof PersonalInfoConfig> {}
