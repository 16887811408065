import React, { FC, ReactNode } from 'react';
import { Row, Col } from 'antd';

interface IProps {
  first: ReactNode;
  second: ReactNode;
}
const TwoColumnsRow: FC<IProps> = ({ first, second }) => (
  <Row justify="space-around" gutter={[16, 8]}>
    <Col span={12}>{first}</Col>
    <Col span={12}>{second}</Col>
  </Row>
);

export default TwoColumnsRow;
