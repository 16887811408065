import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React, { FC } from 'react';
import './BoldColumTitleTooltip.css';

interface IProps {
  name: string;
  tootltip: string
}

const BoldColumnTitleTooltip: FC<IProps> = ({ name, tootltip }) => {
  return (
    <div className='bold-column-title-tooltip-container'>
      <div className='bold-column-title'>{name}</div>
      <Tooltip title={tootltip}><ExclamationCircleOutlined className='exclamation-circle-outlined'/></Tooltip>
    </div>);
};

export default BoldColumnTitleTooltip;
