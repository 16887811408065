import React, { FC, useState } from 'react';
import { Form } from 'antd';
import CustomFormForModal from '../forms/CustomFormForModal';
import { CustomInput } from '../forms/CustomInput';
import { useRootStore } from '../root/RootStoreHook';
import CustomModal from '../shared/CustomModal';
import { patterns, textConfig } from '../profile/models/Config';
import { isValidRange } from './helpers';
import ThreeColumnsRow from 'components/forms/ThreeColumnsRow';

interface IProps {
  visible: boolean;
}
const currentDate = new Date();

const CertificationModal: FC<IProps> = ({ visible }) => {
  const { profile } = useRootStore();
  const certification = profile!.certification!;
  const [form] = Form.useForm();

  const {
    text,
    date: { endFormat, startFormat },
  } = patterns;

  const [dateValidation, setDateValidation] = useState<'success' | 'error' | undefined>(undefined);
  const [locationValidation, setLocationValidation] = useState<'success' | 'error' | undefined>(undefined);
  const [dateErrorMessage, setDateErrorMessage] = useState<'Input a valid date range' | 'Date format should be the same in both fields' | undefined>();
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const onDateChange = () => {
    const values = form.getFieldsValue();
    const { start, end } = values;
    if (startFormat.test(start) && endFormat.test(end)) {
      if (isValidRange(start, end, currentDate)) {
        setDateValidation('success');
        setDateErrorMessage(undefined);
        setButtonDisabled(false);
      } else {
        setDateValidation('error');
        setDateErrorMessage('Input a valid date range');
        setButtonDisabled(true);
      }
      if (start.includes('/') && !end.includes('/') || !start.includes('/') && end.includes('/')) {
        setDateValidation('error');
        setDateErrorMessage('Date format should be the same in both fields');
        setButtonDisabled(true);
      }
    } else {
      setDateValidation(undefined);
      setDateErrorMessage(undefined);
      setButtonDisabled(true);
    }
  };

  const onLocationChange = () => {
    const values = form.getFieldsValue();
    const { location } = values;

    if (location !== '') {
      setLocationValidation('success');
      setButtonDisabled(false);
    } else {
      setLocationValidation('error');
      setButtonDisabled(true);
    }
  };

  const onOk = () => {
    if (
      dateValidation === 'success' ||
      (dateValidation === undefined && locationValidation === 'success') ||
      locationValidation === undefined
    ) {
      form
        .validateFields()
        .then((values: any) => certification.addOrUpdate(values))
        .catch(() => {});
    }
  };

  return (
    <CustomModal
      visible={visible}
      title={certification.selectedItem ? 'Update certification or course' : 'Add certification or course'}
      onOk={onOk}
      onCancel={() => certification.closeModal()}
      disabled={buttonDisabled}
    >
      <CustomFormForModal form={form} initialValues={certification.initialValues}>
        <CustomInput name="career" label="Certification / Course" config={textConfig(150)} pattern={text.technology} />
        <CustomInput name="institution" label="Institution" config={textConfig()} pattern={text.nonSpecial} />
        <ThreeColumnsRow
          first={
            <CustomInput
              name="location"
              label="Location"
              required
              validateStatus={locationValidation}
              onChange={onLocationChange}
              config={textConfig()}
              pattern={text.nonSpecial}
              toolTipText="Buenos Aires or Rio de Janeiro, Brasil or Online - CDMX"
            />
          }
          second={
            <CustomInput
              name="start"
              label="Start"
              required
              pattern={startFormat}
              validateStatus={dateValidation}
              onChange={onDateChange}
              config={textConfig(10)}
              toolTipText="YYYY or YYYY/MM"
            />
          }
          third={
            <CustomInput
              name="end"
              label="End"
              required
              pattern={endFormat}
              validateStatus={dateValidation}
              onChange={onDateChange}
              config={textConfig(10)}
              help={dateErrorMessage}
              toolTipText="YYYY, YYYY/MM or Ongoing"
            />
          }
        />
      </CustomFormForModal>
    </CustomModal>
  );
};

export default CertificationModal;
