import React, { FC } from 'react';
import { Form, Slider } from 'antd';
import { LEVEL } from './Level';

interface IProps {
  name: string;
  label: string;
}

const CustomLevelSlider: FC<IProps> = ({ name, label }) => (
  <Form.Item name={name} label={label}>
    <Slider marks={LEVEL as any} step={null} tooltipVisible={false} />
  </Form.Item>
);

export default CustomLevelSlider;
