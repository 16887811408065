import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { Table, Space, Tooltip } from 'antd';
import { useRootStore } from '../root/RootStoreHook';
import CustomCard from '../shared/CustomCard';
import { SkillItem } from './models/SkillItem';
import { Routes } from '../app/routing/Routes';
import SkillSummaryDisplay from './skillSummary/SkillSummaryDisplay';
import PossiblyEmptyField from '../shared/PossiblyEmptyField';
import BoldColumnTitle from '../shared/BoldColumnTitle';
import rol, { isRol } from '../login/roles';
import './SkillsDisplay.css';

interface IProps {
    infoExtra?: any | undefined;
    sameId?: boolean | undefined;
}
const SkillsDisplay: FC<IProps> = ({ infoExtra, sameId }) => {
    const { profile, dropdownOptions, user } = useRootStore();
    const userRole = user?.rol;
    const skill = profile!.skill!;
    const { getLevelValue } = dropdownOptions!;
    const { getSkillClassificationValue, getSkillCategoryValue } = dropdownOptions!;

    const columns = [
        {
            title: <BoldColumnTitle title="Classification" />,
            dataIndex: 'skillClassificationId',
            key: 'skillClassificationId',
            render: (text: string, item: SkillItem) => <PossiblyEmptyField item={getSkillClassificationValue(item!.skillClassificationId!)} />,
        },
        {
            title: <BoldColumnTitle title="Skill" />,
            dataIndex: 'skillName',
            key: 'skillName',
            ellipsis: {
                showTitle: false,
            },
            render: (data: string) => (
                <Tooltip placement="topLeft" title={data}>
                    {data}
                </Tooltip>
            ),
        },
        {
            title: <BoldColumnTitle title="Category" />,
            dataIndex: 'skillCategoryId',
            key: 'skillCategoryId',
            render: (text: string, item: SkillItem) => <PossiblyEmptyField item={getSkillCategoryValue(item!.skillCategoryId!)} />,
        },
        {
            title: <BoldColumnTitle title="Level" />,
            dataIndex: 'skillLevel',
            key: 'skillLevel',
            render: (text: string, item: SkillItem) => <PossiblyEmptyField item={item.skillLevel.toString()} />,
        },
        {
            title: <BoldColumnTitle title="Years of Experience" />,
            dataIndex: 'yearsExperience',
            key: 'yearsExperience',
            render: (text: string, item: SkillItem) => <PossiblyEmptyField item={item.yearsExperience.toString()} />,
        },
    ];

    return useObserver(() => (
        <CustomCard
            title="Skills"
            hasData={skill.items.length > 0}
            pathForEdit={Routes.Skills}
            subTitle={infoExtra}
            canEdit={isRol([rol.ADMIN, rol.USER], userRole) || sameId}
            noDataDescription="You didn’t add any skills yet."
        >
            <Space direction="vertical" className='flex-width-full'>
                {profile!.skillSummary!.items.length > 0 && <SkillSummaryDisplay />}
                <br />
                <h3 className='skills-title'>Skills</h3>
                <Table
                    className="table-alternative skills-table-labels"
                    columns={columns}
                    dataSource={skill.sortedItemsForTable}
                    pagination={false}
                    scroll={{ y: 240 }}
                />
            </Space>
        </CustomCard>
    ));
};

export default SkillsDisplay;
