import { useContext } from 'react';
import { RootStore } from './RootStore';
import { RootStoreContext } from './RootStoreProvider';

export function useRootStore(): RootStore {
  const root = useContext(RootStoreContext);
  if (root === undefined) {
    throw Error('You need to set the root context with the provider before you can use it');
  }
  return root;
}
