import { REPORTS, SETTINGS } from 'constants/constants';
import { Routes } from '../../app/routing/Routes';

export interface IInnerSidebarItemConfig {
  route: Routes;
  text: string;
}

export interface IInnerSideBarConfig {
  icon: string;
  name: string,
  config: Array<IInnerSidebarItemConfig>
}

const settingRoutes:Array<IInnerSidebarItemConfig>= [
  { route: Routes.UserManagement, text: 'User Management' },
  { route: Routes.Dropdown, text: 'Dropdown Options' },
  { route: Routes.Template, text: 'Template Options' },
];

const reportRoutes:Array<IInnerSidebarItemConfig>= [
  { route: Routes.MultipleDownload, text: 'Multiple Download Resumes' },
  { route: Routes.Completion, text: '% Completion' },
  { route: Routes.Access, text: "Users who haven't accessed" },
  { route: Routes.CustomSearch, text: "Custom search" },
];



export const  getConfig = (type:string ):IInnerSideBarConfig =>{
 
  if(type === REPORTS){
    const element = 'LineChartOutlined'
    return {
      icon: element,
      name: 'Reports',
      config: reportRoutes

    }
  }else {
    return {
      icon: 'SettingOutlined',
      name: 'Settings',
      config: settingRoutes
    } 
  }
}