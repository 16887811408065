export enum Routes {
  Main = '/',
  PersonalInfo = '/personal-info',
  Profile = '/profile',
  Profiles = '/profiles',
  Summary = '/summary',
  Highlights = '/highlights',
  Skills = '/skills',
  Histories = '/histories',
  Education = '/education',
  Wizard = '/wizard',
  Login = '/login',
  Dropdown = '/dropdown-options',
  Position = '/position',
  AdditionalPosition = '/additional-position',
  Community = '/community',
  Level = '/level',
  Template = '/template',
  PageNotFound = '/page-not-found',
  UserManagement = '/user-management',
  MultipleDownload = '/reports/multiple',
  Completion = '/reports/usage',
  Access = '/reports/access',
  CustomSearch = '/reports/custom',
  LandingPage = '/shareurl',
}
