import { Instance, types } from 'mobx-state-tree';

export const Config = types.model('Config', {
  min: types.optional(types.maybe(types.number), undefined),
  max: types.optional(types.maybe(types.number), undefined),
  required: types.optional(types.boolean, false),
  email: types.optional(types.boolean, false),
  whitespace: types.optional(types.boolean, true)
});

export interface Config extends Instance<typeof Config> {}

function textConfig(): Config;
function textConfig(max: number): Config;
function textConfig(max: number, optional: boolean): Config;
function textConfig(max: number, optional: boolean, min: number): Config;
function textConfig(max: number = 0, optional: boolean = false, min: number = 0): Config {
  return {
    min: min || defaults.text.minLenght,
    max: max || defaults.text.maxLength,
    required: !optional,
  } as Config;
}
const textAreaConfig = (isOptional: boolean = false) => textConfig(defaults.textArea.maxLength, isOptional);

const defaults = {
  text: { minLenght: 2, maxLength: 50 },
  textArea: { minLenght: 2, maxLength: 150 },
};

export { textConfig, textAreaConfig };

const patterns = {
  date: {
    startFormat: /^(19[789][0-9]|20[012][0-9])\/(0[1-9]|1[012])$|^(19[789][0-9]|20[012][0-9])$/i,
    endFormat: /^(19[789][0-9]|20[012][0-9])\/(0[1-9]|1[012])$|^(19[789][0-9]|20[012][0-9])$|^\bongoing\b$/i,
  },
  text: {
    nonSpecial: /^[a-zA-Z0-9_ &,.á-úÁ-ÚüÜçÇÂ-Û\-ºª\(\)]+$/i,
    technology: /^[a-zA-Z0-9_ &,.á-úÁ-ÚüÜçÇÂ-Û\-ºª\(\)\#]+$/i,
    phone: /^((\+)?\d{1,3}(\s)?)?(\d{1,4}(\s)?)+$/i,
    email: /\b[\w.-]+@softvision.com|@cognizant.com\b/i,
  },
  numbers: /^[0-9]+$/i,
};

export { patterns, defaults };
