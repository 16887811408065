import { Instance, types } from 'mobx-state-tree';

export const SkillSummaryItem = types.model('SkillSummaryItem', {
  id: types.identifierNumber,
  skill: types.string,
  description: types.string,
  order: types.number,
});

export interface SkillSummaryItem extends Instance<typeof SkillSummaryItem> {}
