
const rol = {
  ADMIN:'Admin', // all permisions
  MANAGER:'Delivery Manager / Delivery Director', // can see and download the CVs all of the company but doesn't edit it
  LEAD:'Community Manager / Community Lead', // can see and download the CVs just of your own comunity (can't edit it) 
  USER:'User'  // can see and download only its CV
};

function isRol(roles: string[], userRole: string | undefined ){
  // last code: const { role:userRole } = JSON.parse(atob(token!.split('.')[1]));
  return roles.some( eachRol => eachRol === userRole); 
}

export default rol;
export { isRol };

