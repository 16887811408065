import React, { FC } from 'react';
import { Form } from 'antd';
import CustomFormForModal from '../../../forms/CustomFormForModal';
import CustomModal from '../../../shared/CustomModal';
import { CustomInput } from '../../../forms/CustomInput';
import { useRootStore } from '../../../root/RootStoreHook';
import { options } from '../DropdownOptionsDisplay';

interface IProps {
  visible: boolean;
}

const CommunityModal: FC<IProps> = ({ visible }) => {
  const { dropdownOptions } = useRootStore();
  const { selectedCommunityItem, config, addOrUpdateCommunity } = dropdownOptions!;
  const [form] = Form.useForm();

  const onOk = () => {
    form
      .validateFields()
      .then((values: any) => {
        addOrUpdateCommunity(values);
      })
      .catch(() => {});
  };

  const getTitle = (selectedCommunityItem ? 'Update ' : 'Add ') + options.COMMUNITY;

  return (
    <CustomModal
      visible={visible}
      title={getTitle}
      onOk={onOk}
      onCancel={() => dropdownOptions!.closeModal(options.COMMUNITY)}
    >
      <CustomFormForModal form={form} initialValues={selectedCommunityItem}>
        <CustomInput name="description" label="Description" config={config.id} />
      </CustomFormForModal>
    </CustomModal>
  );
};

export default CommunityModal;
