export const IS_LOCAL = /^localhost/i.test(window.location.hostname)
export const STORAGE_ENV = getCurrentEnv(); 
export const LOGIN_ON = !!process.env.REACT_APP_LOGIN_ON ? process.env.REACT_APP_LOGIN_ON === 'true' : true;
export const ROL_ADMIN = !!process.env.REACT_APP_ROL_ADMIN ? process.env.REACT_APP_ROL_ADMIN === 'true' : true;
export const ROL_MANAGER = !!process.env.REACT_APP_ROL_MANAGER ? process.env.REACT_APP_ROL_MANAGER === 'true' : true;
export const ROL_USER = !!process.env.REACT_APP_ROL_USER ? process.env.REACT_APP_ROL_USER === 'true' : true;
export const ROL_LEAD = !!process.env.REACT_APP_ROL_LEAD ? process.env.REACT_APP_ROL_LEAD === 'true' : true;

export const REPORTS = 'REPORTS_MENU'
export const SETTINGS = 'SETTINGS_MENU'

export function getCurrentEnv() {
    switch(window.location.hostname.split(".")[0]){
        case 'localhost':
            return 'local_';
        case 'dev-cvcreator':
            return 'dev_';
        case 'qa-cvcreator':
            return 'qa_';
        case 'uat-cvcreator':
            return 'uat_';
        case 'cvcreator':
            return "prod_";
        default:
            return 'local_';
    }
}