interface ILevel {
  [key: number]: string;
}

export const LEVEL = { 0: 'Trainee', 25: 'Intermediate', 50: 'Advanced', 75: 'Proficient', 100: 'Master' } as ILevel;

function getLevelValue(index: keyof ILevel): string {
  return LEVEL[index];
}

function getLevelKey(value: string): number {
  let result = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const key in LEVEL) {
    if (LEVEL[key] === value) {
      result = Number(key);
      break;
    }
  }
  return result;
}

export const LevelHelper = {
  getLevelValue,
  getLevelKey,
};
