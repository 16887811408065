/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { Form, Row } from 'antd';
import { useRootStore } from '../../root/RootStoreHook';
import CustomModal from '../../shared/CustomModal';
import { CustomInput } from '../../forms/CustomInput';
import { CustomSelect } from '../../forms/CustomSelect';
import { sortBy } from '../../app/Utils';
import { patterns } from 'components/profile/models/Config';

interface IProps {
  visible: boolean;
  closeModal: () => void;
  userData: { email: string; roleId: string };
  refreshData: () => void;
}

const UserEnrollmentModal: FC<IProps> = ({ visible,  closeModal }) => {
  const rootStore = useRootStore();
  const [form] = Form.useForm();
  const { dropdownOptions } = rootStore;
  const { profile } = useRootStore();
  const personalInfoConfig = profile?.personalInfo?.config;
  const onSubmit = async () => {
    form
      .validateFields()
      .then(async (values: any) => {
        const { email, roleId, cognizantId } = values;
        await rootStore.enrollUser(email, email, cognizantId).then((data:any) => {
          rootStore.getProfileById(data?.idProfile).finally(() => {
            form.resetFields();
            // redirect.go(Routes.Wizard, data?.idProfile);
            closeModal();
          });          
        });
        await rootStore.user?.setUserState(
          email,
          [roleId],
          'User was successfully created',
          'An error ocurred while trying to create user'
        );
      })
 
      .catch((err) => {
        console.log(`%cERROR : ${err.message}`, 'background:red; padding:5px;');
        rootStore.setError(err.message);
      });
  };

  const onCancel = () => {
    closeModal();
    form.resetFields();
  };

  const onChangeInput = (evt: any) => {
    rootStore.error && rootStore.setError();
    return evt.target.value;
  };

  return useObserver(() => (
    <CustomModal
      okText="Create user"
      visible={visible}
      title="User Enrollment"
      onCancel={onCancel}
      width="472px"
      onOk={onSubmit}
    >
      <div>
        <Row justify="center" className="createUserFormContainer">
          <Form
            name="createUserForm"
            onFinish={onSubmit}
            layout="vertical"
            form={form}
            initialValues={{ email: '', roleId: dropdownOptions!.roleOptions[1].id }}
          >
            <CustomInput
              name="email"
              label="Email"
              required
              pattern={patterns.text.email}
              config={personalInfoConfig?.email}
              toolTipText="Only Cognizant emails are allowed"
              onChange={onChangeInput}
            />

            <CustomSelect
              name="roleId"
              label="Roles"
              options={dropdownOptions!.roleOptions.sort(sortBy('description'))}
              config={{
                required: true,
              }}
            />
            <CustomInput
              name="cognizantId"
              label="Cognizant Id"
              config={personalInfoConfig?.cognizantId}
              pattern={patterns.numbers}
              onChange={onChangeInput}
            />
          </Form>
        </Row>
        {rootStore.error && (
          <p>
            <strong>{rootStore.errorMessage}</strong>
          </p>
        )}
      </div>
    </CustomModal>
  ));
};
export default UserEnrollmentModal;
