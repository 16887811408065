import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useRootStore } from '../../../root/RootStoreHook';
import { options } from '../DropdownOptionsDisplay';
import DropdownOptionsEntry from '../DropdownOptionsEntry';
import StudioModal from './StudioModal';

const StudioEntry: FC = () => {
  const { dropdownOptions } = useRootStore();

  return useObserver(() => (
    <>
      <DropdownOptionsEntry option={options.STUDIO} dropdownItems={dropdownOptions!.studiosForDropdown} />
      {dropdownOptions!.showStudioModal && <StudioModal visible={dropdownOptions!.showStudioModal} />}
    </>
  ));
};

export default StudioEntry;
