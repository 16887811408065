import { types, Instance } from 'mobx-state-tree';
import { Config } from '../profile/models/Config';

export const SkillsConfig = types.model('SkillsConfig', {
    skillName: Config,
    skillLevel: Config,
    yearsExperience: Config,
    skillClassificationId: Config,
    skillCategoryId: Config,
});

export interface SkillsConfig extends Instance<typeof SkillsConfig> { }
