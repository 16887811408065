import React, { FC } from 'react';
import { Prompt } from 'react-router-dom';

interface IProps {
  when: boolean;
}
const CustomPrompt: FC<IProps> = ({ when }) => {
  if (when) {
    return <Prompt message="Are you sure you want to leave without save?" />;
  }
  return <></>;
};

export default CustomPrompt;
