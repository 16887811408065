import React, { FC } from 'react';
import { Row, Button, Col } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import './CustomAddRow.css';

interface IProps {
  disabled?: boolean;
  onCreate: () => void;
  title?: string;
  optionTitleStyle?: boolean;
  id?: string
}

const CustomAddRow: FC<IProps> = ({ disabled, onCreate, title, optionTitleStyle, id }) => {
  const AddButton = () => (
    <Button disabled={disabled} className='custom-add-row' type="primary" onClick={() => onCreate()} id={id}>
      <PlusCircleFilled />
      Add
    </Button>
  );

  if (title) {
    return (
      <Row justify="space-between" className={optionTitleStyle ? 'option-title-style-true-margin-top' : 'option-title-style-false-margin-top'}>
        <Col className={optionTitleStyle ? 'option-column-true' : 'option-column-false'}>
          {title && <div>{title}</div>}
        </Col>
        <Col>{AddButton()}</Col>
      </Row>
    );
  }
  return <Row justify="end">{AddButton()}</Row>;
};

export default CustomAddRow;
