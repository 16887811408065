import React, { FC } from 'react';
import styles from './loading.module.css';
import LaodingIcon from '../shared/LoadingIcon';

interface IProps {
  show: boolean;
}

const Loading: FC<IProps> = ({ show }) => {
  if (!show) return <></>;

  return (
    <div className={styles.spinner}>
      <LaodingIcon />
    </div>
  );
};

export default Loading;
