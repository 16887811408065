import React, { useState } from 'react';
import { useRedirect } from './HooksUtil';
import { Routes } from '../app/routing/Routes';
import { useRootStore } from '../root/RootStoreHook';
import openNotification from '../notifications/Notification';

export const useSessionOut = (timer?: number) => {
  const timerToUse =  timer || 1200000;
  const rootStore = useRootStore();
  const [timerOut, setTimerOut] = useState<number>();
  const redirect = useRedirect();

  const timeInactiveOn = () => {
    window.clearTimeout(timerOut);
    console.log('inactive');
    if (rootStore.user) {
      rootStore!.logOut();
      redirect.go(Routes.Login);
      openNotification(
        'Session ended',
        <>
          <p>There was no activity for a while so we closed the session.</p>
        </>,
        'info',
        1200000,
        'bottomRight',
        { marginRight: 26 }
      );
    }
  };
    
  const startCounter = () => {
    setTimerOut(window.setTimeout(timeInactiveOn, timerToUse));
  };
    
  const resetCounter = () => {
    window.clearTimeout(timerOut);
    startCounter();
  };
    
  return { resetCounter };
};