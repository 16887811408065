import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Space, Card } from 'antd';
import { EditOutlined, HolderOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import { useRootStore } from '../../root/RootStoreHook';
import DeleteItemConfirm from '../../shared/DeleteItemConfirm';
import CustomAddRow from '../../shared/CustomAddRow';
import CustomDragableTable from '../../shared/CustomDragableTable';
import { TemplateItem } from './models/TemplateItem';
import TemplateModal from './TemplateModal';
import openNotification from '../../notifications/Notification';

const DocxEntry: FC = () => {
  const rootStore = useRootStore();
  const { templates } = rootStore;

  const onDelete = async (item: TemplateItem) => {
    if (item.default) {
      openNotification('Cannot delete this template', 'This template is being used as default option.', 'warning');
    } else {
      templates!.delete(item.id);
    }
  };

  const columns: ColumnProps<TemplateItem>[] = [
    {
      title: 'Document',
      dataIndex: 'description',
      key: 'id',
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'right',
      render: (item: TemplateItem) => (
        <Space>
          <EditOutlined onClick={() => templates!.edit(item.id)} />
          <HolderOutlined />
        </Space>
      ),
    },
  ];

  // const moveRow = (fromIndex: number, toIndex: number) => {
  //   rootStore.dragAndDrop(fromIndex, toIndex, templates!.templates);
  // };


  return useObserver(() => (
    <div className='width-full'>
      <DndProvider backend={HTML5Backend}>
        <Card>
          <CustomAddRow title="Documents" disabled={true} onCreate={() => templates!.create('docx')} optionTitleStyle />
          <div className='card-container'>
            <CustomDragableTable
              draggableKey=""
              columns={columns}
              dataSource={templates!.docxForTable}
              moveRow={() => {}}
              showHeader={false}
              className="template-drag-icon"
            />
          </div>
        </Card>
        {templates!.showModal && <TemplateModal visible={templates!.showModal} />}
      </DndProvider>
    </div>
  ));
};

export default DocxEntry;
