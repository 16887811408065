import React, { FC } from 'react';
import { Form } from 'antd';
import CustomFormForModal from '../../../forms/CustomFormForModal';
import CustomModal from '../../../shared/CustomModal';
import { CustomInput } from '../../../forms/CustomInput';
import { useRootStore } from '../../../root/RootStoreHook';
import { options } from '../DropdownOptionsDisplay';

interface IProps {
  visible: boolean;
}

const LevelModal: FC<IProps> = ({ visible }) => {
  const { dropdownOptions } = useRootStore();
  const { selectedLevelItem, config, addOrUpdateLevel } = dropdownOptions!;
  const [form] = Form.useForm();

  const onOk = () => {
    form
      .validateFields()
      .then((values: any) => {
        addOrUpdateLevel(values);
      })
      .catch(() => {});
  };

  const getTitle = (selectedLevelItem ? 'Update ' : 'Add ') + options.LEVEL;

  return (
    <CustomModal
      visible={visible}
      title={getTitle}
      onOk={onOk}
      onCancel={() => dropdownOptions!.closeModal(options.LEVEL)}
    >
      <CustomFormForModal form={form} initialValues={selectedLevelItem}>
        <CustomInput name="description" label="Description" config={config.id} />
      </CustomFormForModal>
    </CustomModal>
  );
};

export default LevelModal;
