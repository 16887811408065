import { Instance, types, applySnapshot, flow } from 'mobx-state-tree';
import { AxiosRequestConfig } from 'axios';
import { axiosInstance } from '../../http/agent';
import { DocumentSection } from './models/Sections';
import { getProfile } from '../profile/ProfileStore';

export const SectionItem = types.model('SectionItem', {
  id: types.identifierNumber,
  section: types.string,
  order: types.number,
});

export interface SectionItem extends Instance<typeof SectionItem> {}

const orderedSections: SectionItem[] = [
  { section: DocumentSection.Summary, order: 1, id: 1 },
  { section: DocumentSection.Highlights, order: 2, id: 2 },
  { section: DocumentSection.Skills, order: 3, id: 3 },
  { section: DocumentSection.Histories, order: 4, id: 4 },
  { section: DocumentSection.Education, order: 5, id: 5 },
  { section: DocumentSection.Certifications, order: 6, id: 6 },
];

function download(data: any, fileName: string): void {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
}

export const DocumentStore = types
  .model('DocumentStore', {
    selectedSections: types.optional(
      types.array(types.reference(SectionItem)),
      orderedSections.map((x) => x.id)
    ),
    items: types.optional(types.array(SectionItem), orderedSections),
  })
  .views((self) => ({
    isFormValid() {
      return self.selectedSections.length > 0;
    },
    get sortedItems() {
      return self.items.slice().sort((a: SectionItem, b: SectionItem) => a.order - b.order);
    },
    get sortedItemsForTable() {
      return this.sortedItems.map((x: SectionItem) => ({ key: x.id, ...x }));
    },
    get selectedSectionsIds() {
      return self.selectedSections.map((x) => x.id);
    },
    sortedSelectedSections() {
      return self.selectedSections.slice().sort((a: SectionItem, b: SectionItem) => a.order - b.order);
    },
  }))
  .actions((self) => ({
    updateSelectedSections(sectionsIds: number[]) {
      const selectedSections = self.items.filter((s) => sectionsIds.includes(s.id));
      applySnapshot(self.selectedSections, []);
      selectedSections.forEach((x) => {
        self.selectedSections.push(x);
      });
    },
    resetValues() {
      /* eslint-disable no-return-assign */
      self.items.map((x) => (x.order = x.id));
      applySnapshot(
        self.selectedSections,
        self.items.map((x) => x.id)
      );
    },
  }))
  .actions((self) => ({
    downloadDocument: flow(function* downloadDocument(
      profilesIds: number[],
      documentType: string,
      custom?: boolean,
      templateId?: number,
    ): any {
      const config: AxiosRequestConfig = {
        responseType: 'blob',
      };
      const dataToSubmit = {
        profilesIds,
        templateId:0,
        sections: custom
          ? self.sortedSelectedSections().map((s) => (s.section.replace(' & ', 'And')).replace(' ', '') as DocumentSection)
          : self.items.filter((x) => (x.section.replace(' & ','And')).replace(' ', '') !== DocumentSection.Summary).map((y) => (y.section.replace(' & ', 'And')).replace(' ','') as DocumentSection),
      };
      try {
        const { data } = yield axiosInstance.post(`/document/${documentType}`, dataToSubmit, config);
        if (profilesIds!.length > 1) {
          download(data, `Report.${documentType}`);
        } else {
          const profile = yield getProfile(profilesIds[0]);
          download(data, `${profile.personalInfo!.firstName} ${profile.personalInfo!.lastName}.${documentType}`);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      } finally {
        self.resetValues();
      }
    }),
  }));

export interface DocumentStore extends Instance<typeof DocumentStore> {}
