import React, { FC, useEffect, useState } from 'react';
import { Form } from 'antd';
import { useObserver } from 'mobx-react-lite';

import rol, { isRol } from '../login/roles';
import { ProfileStore, getProfile } from '../profile/ProfileStore';
import { WizardModeContext } from './WizardMode';
import { useRootStore } from '../root/RootStoreHook';

import { steps } from './models/StepsConfig';
import StepperHeader from './components/StepperHeader';
import StepperBody from './components/StepperBody';
import StepperButtons from './components/StepperButtons';
import './stepper.css';

const getAltProfile: any = (profileId: number, setProfile: any) => {
  getProfile(profileId).then((res) => {
    const newProfile = ProfileStore.create(res);
    setProfile(newProfile);
  });
};

const Stepper: FC = ({ match }: any) => {
  const profileId: any = match?.params?.profileId || '';
  const { stepper, profile: profileFromRoot, user } = useRootStore();
  const userRole = user?.rol;
  const [profile, setProfile] = useState(profileFromRoot);

  useEffect(() => {
    if (isRol([rol.ADMIN], userRole) && profileId && profileId.toString() !== profile?.id.toString()) {
      getAltProfile(profileId, setProfile);
    }
  }, [profile]);

  const [form] = Form.useForm();

  return useObserver(() => (
    <div className="stepper-container">
      <StepperHeader />
      <WizardModeContext.Provider value>
        <div className="wizard-mode-context-container">
          <StepperBody form={form} module={steps[stepper!.currentStep].module}/>
          <StepperButtons form={form}/>
        </div>
      </WizardModeContext.Provider>
    </div>
  ));
};
export default Stepper;
