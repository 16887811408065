import React from 'react';
import { Input, Space, Button} from 'antd';
import {
  SearchOutlined,
} from '@ant-design/icons';

const getColumnSearch = ({dataIndex, title, searchInput, handleSearch, handleReset, selectFilterText, data}: any) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${title.toLowerCase()}`}
          value={selectedKeys[0]}
          onChange={(e: any) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, clearFilters)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex, clearFilters, "reset")}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex, clearFilters, "search")}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => <SearchOutlined className={filtered ? 'filterIcon_filtered' : 'filterIcon'} />,
    onFilter: (value: any, record: any) => {
      const cleanValue = data!.getCleanFullName(value.toLowerCase());
      return record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(cleanValue) : undefined;
    },
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) setTimeout(() => (searchInput.current as any).select());
    },
  });

  export default getColumnSearch