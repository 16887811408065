import React from 'react';
import { Button } from 'antd';

const CopyButton: React.FC<{ cognizantId: string }> = ({ cognizantId }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(`${window.location.origin}/shareurl/${cognizantId}`).catch((error) => {
      console.error('Failed to copy text:', error);
    });
  }

    return (
    <div >
    <Button className='user-managment-table-button' type="primary" onClick={() => copyToClipboard()}>
      Copy CVSHAREURL
    </Button>  
    </div >)
  
}

export default CopyButton