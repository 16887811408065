/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { FC } from 'react';
import { Button, FormInstance } from 'antd';

import { useRootStore } from '../../root/RootStoreHook';
import { useRedirect } from '../../hooks/HooksUtil';

import { Routes } from 'components/app/routing/Routes';
import { Module } from '../models/Enums';
import { steps } from '../models/StepsConfig';
import openNotification from 'components/notifications/Notification';

const StepperButtons: FC<{ form: FormInstance<any> }> = ({ form }) => {
  const { stepper, profile } = useRootStore();
  const redirect = useRedirect();

  async function isValid(module: Module) {
    if (![Module.Summary, Module.PersonalInfo].includes(module)) {
      return true;
    }

    try {
      const values = await form.validateFields();
      if (module == Module.Summary) {
        profile!.summary!.updateStore(values);
      } else {
        profile!.personalInfo!.updateStore(values);
      }
      return true;
    } catch (errorData) {
      const moduleTitle = steps[stepper!.currentStep].title;
      // const errorMessages = errorData.errorFields?.flatMap?.((field: any) => field.errors);
      // errorMessages?.forEach((error: string) => {
      //   openNotification(`${moduleTitle}  Validation`, error, 'error');
      // });
      openNotification(`${moduleTitle}  Validation`, 'Please review the required fields.', 'error');
      return false;
    }
  }

  const afterSave = () => redirect.go(Routes.Profile);

  async function save(module: Module) {
    if (await isValid(module)) {
      stepper.save(module, afterSave);
    }
  }

  async function next(module: Module, isPrevious: boolean) {
    if (await isValid(module)) {
      if (isPrevious) stepper!.previousStep();
      else stepper!.nextStep(module);
    }
  }

  async function saveValid(module: Module) {
    if (await isValid(module)) {
      stepper.partialSave(module);
    }
  }
  return (
    <div className="wizard-mode-context-header">
      <div className="wizard-mode-context-item">
        {!stepper.isFirstStep() && (
          <Button
            className="wizard-step-previous-button"
            onClick={() => next(steps[stepper!.currentStep].module, true)}
            id="previous-button"
          >
            Previous
          </Button>
        )}
        {stepper.isFirstStep() && <Button className="wizard-step-first-step-button" disabled />}
        <Button
          onClick={() => saveValid(steps[stepper!.currentStep].module)}
          className="btnSaveChanges wizard-step-save-button"
          id="save-changes-button"
        >
          Save Changes
        </Button>

        {!stepper!.isLastStep(steps.length) && (
          <Button
            className="wizard-step-next-button"
            type="primary"
            onClick={() => next(steps[stepper!.currentStep].module, false)}
            id="next-button"
          >
            Next
          </Button>
        )}
        {stepper!.isLastStep(steps.length) && (
          <Button
            className="wizard-step-done-button"
            type="primary"
            onClick={() => save(steps[stepper!.currentStep].module)}
          >
            Done
          </Button>
        )}
      </div>
    </div>
  );
};

export default StepperButtons;
