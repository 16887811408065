import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useRootStore } from '../../../root/RootStoreHook';
import { options } from '../DropdownOptionsDisplay';
import DropdownOptionsEntry from '../DropdownOptionsEntry';
import CommunityModal from './CommunityModal';

const CommunityEntry: FC = () => {
  const { dropdownOptions } = useRootStore();

  return useObserver(() => (
    <>
      <DropdownOptionsEntry option={options.COMMUNITY} dropdownItems={dropdownOptions!.communitiesForDropdown} />
      {dropdownOptions!.showCommunityModal && <CommunityModal visible={dropdownOptions!.showCommunityModal} />}
    </>
  ));
};

export default CommunityEntry;
