import { Module } from './Enums';
import { IStep } from './Step';

export const steps = [
  {
    title: 'Personal Information',
    module: Module.PersonalInfo,
  },
  {
    title: 'Summary',
    module: Module.Summary,
  },
  {
    title: 'Experience Highlights',
    module: Module.Highlights,
  },
  {
    title: 'Skills',
    module: Module.Skills,
  },
  {
    title: 'Education',
    module: Module.Education,
  },
  {
    title: 'Work Experience',
    module: Module.History,
  },
] as IStep[];
