import React, { FC, useState } from 'react';
import { Form } from 'antd';
import CustomFormForModal from '../../forms/CustomFormForModal';
import CustomModal from '../../shared/CustomModal';
import { CustomInput } from '../../forms/CustomInput';
import { useRootStore } from '../../root/RootStoreHook';
import { CustomCheckbox } from '../../forms/CustomCheckbox';

interface IProps {
  visible: boolean;
}

const TemplateModal: FC<IProps> = ({ visible }) => {
  const { templates } = useRootStore();
  const { selectedItem, config, closeModal, addOrUpdate } = templates!;
  const [form] = Form.useForm();
  const [checkStatus, setCheckStatus] = useState(false);
  const [checkDefault, setCheckDefault] = useState(false);

  const onOk = () => {
    form
      .validateFields()
      .then((values: any) => {
        addOrUpdate(values);
      })
      .catch(() => {});
  };

  const onCheckStatus = (e: any) => {
    setCheckStatus(e.target.checked);
  };
  const onCheckDefault = (e: any) => {
    setCheckDefault(e.target.checked);
  };

  return (
    <CustomModal visible={visible} title={selectedItem ? 'Update' : 'Add'} onOk={onOk} onCancel={() => closeModal()}>
      <CustomFormForModal form={form} initialValues={templates?.initialValues}>
        <CustomInput name="description" label="Description" config={config.description} />
        <CustomInput name="address" label="Address" config={config.address} />
        <div>
          <CustomCheckbox onChange={onCheckStatus} name="status" label="Available" checked={checkStatus} />
          <CustomCheckbox
            disabled={templates!.getdefaultTemplate()}
            onChange={onCheckDefault}
            name="default"
            label="Default"
            checked={checkDefault}
          />
        </div>
      </CustomFormForModal>
    </CustomModal>
  );
};

export default TemplateModal;
