import React, { FC, useEffect } from 'react';
import { useObserver } from 'mobx-react-lite';
import TagManager from 'react-gtm-module';
import AppRouter from './AppRouter';
import { useRootStore } from '../root/RootStoreHook';
import Loading from '../loading/Loading';
import LoginEntry from '../login/LoginEntry';
import { STORAGE_ENV } from 'constants/constants';

const tagManagerArgs = {
  gtmId: 'GTM-KRMB4DQ',
};
TagManager.initialize(tagManagerArgs);

const App: FC = () => {
  const rootStore = useRootStore();
  const logOut=()=>{
    if(rootStore.isUserLogged)
      {
        let userData=window.localStorage.getItem(STORAGE_ENV + "userdata");
        if(!userData){          
          rootStore.logOut();
        }
      }
  };

  useEffect(()=>{
    document.addEventListener("visibilitychange",logOut);
  return () => {
    document.removeEventListener("visibilitychange",logOut);
  };
  },[]);

  return useObserver(() => {
    if (rootStore.isLoading) return <Loading show={rootStore.isLoading} />;

    if (!rootStore.isUserLogged) return <LoginEntry />;

    return <AppRouter />;
  });
};

export default App;
