import React, { FC, ReactText } from 'react';
import { Table, Empty } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { TableCurrentDataSource, TablePaginationConfig, SorterResult } from 'antd/lib/table/interface';
import DragableBodyRow from '../dragable/DraggableBodyRow';

interface IProps {
  columns: ColumnsType<any>;
  dataSource: any[];
  moveRow: (dragIndex: number, hoverIndex: number) => void;
  draggableKey: string;
  showHeader?: boolean;
  rowSelection?: (selectedRows: any[]) => void;
  selectedRowKeys?: ReactText[];
  templateDefaultBold?: boolean;
  onChange?: (
    pagination: TablePaginationConfig,
    filter: Record<string, ReactText[] | null>,
    sorter: SorterResult<any> | SorterResult<any>[],
    currentTable: TableCurrentDataSource<any>
  ) => void;
  className?: string;
  locale?: string;
  style?: object;
}

const CustomDragableTable: FC<IProps> = ({
  columns,
  dataSource,
  draggableKey,
  moveRow,
  showHeader,
  selectedRowKeys,
  rowSelection,
  onChange,
  templateDefaultBold,
  className,
  locale,
  style,
}) => (
  <Table
    scroll={{ y: 300 }}
    onChange={onChange as undefined}
    showHeader={showHeader ?? true}
    columns={columns}
    dataSource={dataSource}
    components={{
      body: {
        row: (props: any) => <DragableBodyRow {...props} draggableKey={draggableKey} style={style} />,
      },
    }}
    locale={{ emptyText: <Empty description={locale || 'No data'} image={Empty.PRESENTED_IMAGE_SIMPLE} /> }}
    onRow={
      (_, index: any) =>
        ({
          index,
          moveRow,
        } as any)
      // eslint-disable-next-line react/jsx-curly-newline
    }
    pagination={false}
    rowKey={(record) => record.id}
    rowSelection={rowSelection ? { selectedRowKeys, onChange: rowSelection } : undefined}
    className={className}
  />
);

export default CustomDragableTable;
