import React, { FC, useState, useEffect } from 'react';
import { useObserver, useAsObservableSource } from 'mobx-react-lite';
import { Menu, Button } from 'antd';
import { useLocation } from 'react-router-dom';
import {
  LineChartOutlined,
  ProfileOutlined,
  TeamOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { useRootStore } from '../root/RootStoreHook';
import rol, { isRol } from '../login/roles';
import { useRedirect } from '../hooks/HooksUtil';
import { Routes } from '../app/routing/Routes';
import InnerSidebar from '../reusable/innerSideBar/InnerSidebar';
import './sidebar.css';
import { REPORTS, SETTINGS } from 'constants/constants';

export const tabKeys = {
  FOUR_OH_FOUR: '',
  PROFILE: '1',
  PROFILES: '2',
  REPORTS: '3',
  SETTINGS: '4',
  LOGOUT: '5',
};

const Sidebar: FC = () => {
  const rootStore = useRootStore();
  const { profile, setIsSidebarOpen, user } = rootStore;
  const userRole = user?.rol;
  const redirect = useRedirect();
  const { pathname } = useLocation();
  const data = useAsObservableSource({
    tabKey: rootStore!.forcedTab,
    isSidebarOpen: rootStore.isSidebarOpen,
    isInProfile: rootStore.isInProfile,
  });
  const [isOpenSettingsSidebar, setIsOpenSettingsSidebar] = useState(false);
  const [isOpenReportsSidebar, setIsOpenReportsSidebar] = useState(false);

  useEffect(() => {
    const { body } = document;
    if (rootStore.isSidebarOpen) {
      body.classList.add('opened-admin-sidebar');
    } else {
      body.classList.remove('opened-admin-sidebar');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.isSidebarOpen]);

  useEffect(() => {
    switch (pathname) {
      case Routes.Profiles:
        rootStore.updateForcedTab(tabKeys.PROFILES);
        break;
      case Routes.PersonalInfo:
        rootStore.updateForcedTab(tabKeys.PROFILE);
        break;
      case Routes.Highlights:
        rootStore.updateForcedTab(tabKeys.PROFILE);
        break;
      case Routes.Skills:
        rootStore.updateForcedTab(tabKeys.PROFILE);
        break;
      case Routes.Histories:
        rootStore.updateForcedTab(tabKeys.PROFILE);
        break;
      case Routes.Education:
        rootStore.updateForcedTab(tabKeys.PROFILE);
        break;
      case Routes.Summary:
        rootStore.updateForcedTab(tabKeys.PROFILE);
        break;
      case Routes.MultipleDownload:
        rootStore.updateForcedTab(tabKeys.REPORTS);
        break;
      case Routes.Dropdown:
        rootStore.updateForcedTab(tabKeys.SETTINGS);
        break;
      default: {
        const routes = Object.values(Routes);
        const isValidRoute = routes.filter((path) => path === pathname)[0];
        if (isValidRoute) {
          rootStore.updateForcedTab(tabKeys.SETTINGS);
        } else if (!data.isInProfile) {
          rootStore.updateForcedTab(tabKeys.FOUR_OH_FOUR);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleSelect = (key: string) => rootStore!.updateForcedTab(key);

  const handleLogOut = () => {
    rootStore!.logOut();
    redirect.go(Routes.Login);
    window.location.reload();
  };

  const closeSettingsMenu = () => {
    setIsOpenSettingsSidebar(!isOpenSettingsSidebar);
  };
  const closeReportsMenu = () => {
    setIsOpenReportsSidebar(!isOpenReportsSidebar)
  };
 const handleSidebarOpposite = (element:HTMLFormElement )=>{
    if(!isOpenSettingsSidebar && !isOpenReportsSidebar){
      element.classList.remove('opened-admin-sidebar');
    }

    if((isOpenSettingsSidebar ||isOpenReportsSidebar) && !element.classList.contains('opened-admin-sidebar')){
      element.classList.add('opened-admin-sidebar');
    }
 }
  

  return useObserver(() => {
    const tabKey = rootStore!.forcedTab;

    return (
      <div className={`sidebar${rootStore.isSidebarOpen ? ' opened-sidebar' : ''}`}>
        <div
          id="sidebar-overlay"
          className={`sidebar-overlay${rootStore.isSidebarOpen ? ' opened-admin-sidebar' : ' delay'}`}
          onClick={setIsSidebarOpen}
          onKeyPress={setIsSidebarOpen}
          role="button"
          tabIndex={0}
          aria-label="Overlay"
        />

        <Menu
          theme="light"
          mode="vertical"
          defaultSelectedKeys={[isRol([rol.ADMIN], userRole) ? tabKeys.PROFILES : tabKeys.PROFILE]}
          selectedKeys={[tabKey]}
          onSelect={({ key }) =>
            key !== tabKeys.SETTINGS && key !== tabKeys.LOGOUT && !key && handleSelect(String(key))}
        >
          <div className="sidebar-menu">
            <Button className="burger" onClick={setIsSidebarOpen}>
              <div className="line" />
              <div className="line" />
              <div className="line" />
            </Button>
          </div>
          <Menu.Item
            key={tabKeys.PROFILE}
            onClick={() => {
              rootStore.getSession();
              if (rootStore.isSidebarOpen) setIsSidebarOpen();              
              redirect.go(Routes.Profile, user?.idProfile);
              setIsOpenSettingsSidebar(false);
              setIsOpenReportsSidebar(false);              
            }}
            className="sidebar-item-container"
          >
            <div className="sidebar-item-gradient" />
            <div className="sidebar-item">
              <ProfileOutlined />
              <span> My profile </span>
            </div>
          </Menu.Item>
          {!isRol([rol.USER], userRole) && (
            <Menu.Item
              key={tabKeys.PROFILES}
              onClick={() => {
                rootStore.getSession();
                if (rootStore.isSidebarOpen) setIsSidebarOpen();                
                redirect.go(Routes.Profiles);
                setIsOpenSettingsSidebar(false);
                setIsOpenReportsSidebar(false);

              }}
              className="sidebar-item-container"
            >
              <div className="sidebar-item-gradient" />
              <div className="sidebar-item">
                <TeamOutlined />
                <span>Profiles</span>
              </div>
            </Menu.Item>
          )}
          {isRol([rol.ADMIN, rol.LEAD, rol.MANAGER], userRole) && (
            <Menu.Item
              key={tabKeys.REPORTS}
              className="sidebar-item-container"
              onClick={()=>{
                rootStore.getSession();
                setIsOpenSettingsSidebar(false);
                }
              }
            >
              <InnerSidebar closeSettingsMenu={closeReportsMenu} isOpen={isOpenReportsSidebar} type={REPORTS} handleSidebarOpposite={handleSidebarOpposite}/>
            </Menu.Item>
          )}
          {isRol([rol.ADMIN], userRole) && (
            <Menu.Item key={tabKeys.SETTINGS} className="sidebar-item-container" onClick={()=>{
              rootStore.getSession();
              setIsOpenReportsSidebar(false);
              }}>
              <InnerSidebar closeSettingsMenu={closeSettingsMenu} isOpen={isOpenSettingsSidebar} type={SETTINGS} handleSidebarOpposite={handleSidebarOpposite}/>
            </Menu.Item>
          )}
          <Menu.Item
            key={tabKeys.LOGOUT}
            onClick={() => {
              if (rootStore.isSidebarOpen) setIsSidebarOpen();
              handleLogOut();
            }}
            className="sidebar-item-container"
          >
            <div className="sidebar-item">
              <LogoutOutlined />
              <span>Logout</span>
            </div>
          </Menu.Item>
        </Menu>
        
      </div>
    );
  });
};

export default Sidebar;
