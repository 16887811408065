import { Instance, types, flow, cast, applySnapshot } from 'mobx-state-tree';
import { axiosInstance } from '../../../http/agent';
import { IProfilePagination, IFilters } from './models/IProfilePagination';
import { ProfileItem } from './models/ProfileItem';
import { GeneratorHelper } from '../../shared/Helpers';

export const SelectedProfile = types.model('SelectedProfile', {
  order: types.number,
  page: types.number,
  ...ProfileItem.properties,
});
export interface SelectedProfile extends Instance<typeof SelectedProfile> { }

export const defaultPageSizeOptions = ['10', '20', '30', '40', '50'];
export const defaultProfilePagination = {
  pageIndex: 1,
  pageSize: parseInt(defaultPageSizeOptions[0], 10),
  filters: {},
};

export const ProfilesStore = types
  .model('ProfilesStore', {
    items: types.array(ProfileItem),
    total: types.maybe(types.number),
    currentPage: types.maybe(types.number),
    loading: types.optional(types.boolean, false),
    searchText: types.optional(types.string, ''),
    searchedColumn: types.optional(types.string, ''),
    pageSize: types.optional(types.number, defaultProfilePagination.pageSize),
    selectedProfiles: types.optional(types.array(SelectedProfile), []),
  })
  .views((self) => ({
    get itemsForTable() {
      return self.items.map((x: ProfileItem) => ({ key: x.id, ...x }));
    },
    get sortedItems() {
      return self.selectedProfiles.slice().sort((a: any, b: any) => a.order - b.order);
    },
    get selectedProfilesIds() {
      return self.selectedProfiles.map((x) => x.id);
    },
    getProfileByEmail(email: string) {
      return self.items.find((x) => x.email === email);
    },
    getPageSizeOptions() {
      const { total } = self;
      if (!total) return [];

      return defaultPageSizeOptions.filter((option) => {
        const parsedOption = parseInt(option, 10);
        return (parsedOption > total && parsedOption - total < 10) || parsedOption <= total;
      });
    },
    getCleanFullName(rawText: string) {
      return rawText.trim().replace(/\s{2,}/g, ' ');
    },
    getFilters(filters: any): IFilters {
      const result: IFilters = {};

      if (filters) {
        result.communityIds = filters.communityId ? filters.communityId.map((x: any) => Number(x)) : undefined;
        result.positionIds = filters.positionId ? filters.positionId.map((x: any) => Number(x)) : undefined;
        result.addPositionIds = filters.addPositionIds ? filters.addPositionIds.map((x: any) => Number(x)) : undefined;
        result.fullName = filters.fullName ? this.getCleanFullName(filters.fullName[0]) : undefined;
      }

      return result;
    },
  }))
  .actions((self) => ({
    updateSearchText(value: string) {
      self.searchText = value;
    },
    updateSearchedColumn(value: string) {
      self.searchedColumn = value;
    },
    getResults: flow(function* getResults(profilePagination: IProfilePagination) {
      try {
        self.loading = true;
        const { data } = yield axiosInstance.post('/profile/all', profilePagination);
        // TODO : WARNING
        // this code is hard-coded to simulate the "roleId" property
        data.results.forEach((d: { roleId: number }) => {
          d.roleId = 1;
        });
        // ---
        self.items = cast(data.results);
        self.currentPage = data.currentPage;
        self.total = cast(data.rowCount);
        self.pageSize = cast(data.pageSize)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      } finally {
        self.loading = false;
      }
    }),
    updateSelectedProfiles(selectedProfiles: any[]) {
      let newSelectedProfiles: SelectedProfile[] = [];
      newSelectedProfiles = self.selectedProfiles.filter((x) => x.page !== self.currentPage);
      selectedProfiles.map((x) =>
        newSelectedProfiles.push({
          page: self.currentPage,
          order: GeneratorHelper.getNextOrder(newSelectedProfiles),
          ...x,
        })
      );
      applySnapshot(self.selectedProfiles, newSelectedProfiles);
    },
    deleteSelectedProfile(profile: SelectedProfile) {
      self.selectedProfiles.remove(profile);
    },
    orderSelectedProfiles(selectedProfiles: any[]) {
      /* eslint-disable no-return-assign */
      self.selectedProfiles.map((x) => (x.order = selectedProfiles.findIndex((y) => y.id === x.id)));
    },
  }));

export interface ProfilesStore extends Instance<typeof ProfilesStore> { }
