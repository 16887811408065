import { Instance, types } from 'mobx-state-tree';

export const TemplateItem = types.model('TemplateItem', {
  id: types.identifierNumber,
  description: types.string,
  address: types.string,
  type: types.string,
  status: types.boolean,
  default: types.boolean,
});

export interface TemplateItem extends Instance<typeof TemplateItem> {}
