const INCOMPLETE_DATA = {
  COLOR: { YELLOW: '#faad14' },
  MESSAGE: 'You need to improve this section. :( ',
};

const COMPLETE_DATA = {
  COLOR: { GREEN: '#52c41a' },
  MESSAGE: 'Congratulations, this section is completed!!',
};

const PARCIAL_DATA = {
  COLOR: { RED: '#cb1d32' },
  MESSAGE: (data: string) => `This section has ${data}% completed. Come on! you can improve it!`,
};

const EMPTY_DATA = {
    COLOR: { RED: '#cb1d32' },
    MESSAGE: 'You need to improve this section. :( ',
  };

const INFO_TO_DISPLAY = {
    INCOMPLETE_DATA,
    COMPLETE_DATA,
    PARCIAL_DATA,
    EMPTY_DATA
}

export default INFO_TO_DISPLAY;
