import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { Form, InputNumber } from 'antd';
import { Config } from '../profile/models/Config';

interface IProps {
  name: string;
  label: string;
  config: Config;
}

export const CustomInputNumber: FC<IProps> = ({ name, label, config }) => {
  function renderInput() {
    const { min, max, required } = config;
    return <InputNumber min={min} max={max} required={required} />;
  }

  return useObserver(() => (
    <Form.Item name={name} label={label} required={config.required}>
      {renderInput()}
    </Form.Item>
  ));
};
