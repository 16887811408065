import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { CustomInput } from '../forms/CustomInput';
import { useRootStore } from '../root/RootStoreHook';
import CustomForm from '../forms/CustomForm';
import { useWizardMode } from '../stepper/WizardMode';
import { Routes } from '../app/routing/Routes';
import { useRedirect } from '../hooks/HooksUtil';
import { patterns } from 'components/profile/models/Config';

const FormControls: FC = () => {
  const { profile } = useRootStore();
  const { personalInfo, summary } = profile!;
  const { config } = summary!;

  return (
    <>
      <CustomInput
        type="textarea"
        name="aboutMe"
        label={`About ${personalInfo?.firstName}`}
        config={config.aboutMe}
        onChange={(evt: any) => evt.currentTarget.value}
        placeholder="Describe yourself in a few words."
      />
    </>
  );
};

const SummaryEntry: FC = () => {
  const { profile } = useRootStore();
  const { summary } = profile!;
  const redirect = useRedirect();
  const wizardMode = useWizardMode();

  const onSubmit = async (values: any) => {
    await summary!.save(values);
    redirect.go(Routes.Profile);
  };

  return useObserver(() =>
    wizardMode ? (
      <FormControls />
    ) : (
      <CustomForm title="Summary" name="summary" onSubmit={onSubmit} initialValues={summary?.formValues}>
        <FormControls />
      </CustomForm>
    )
  );
};

export default SummaryEntry;
