import React, { FC } from 'react';
import { Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

interface IProps {
  onConfirm: (e?: React.MouseEvent<HTMLElement>) => void;
}
const DeleteItemConfirm: FC<IProps> = ({ onConfirm }) => (
  <Popconfirm title="Are you sure delete this item?" onConfirm={onConfirm} okText="Yes" cancelText="No">
    <DeleteOutlined />
  </Popconfirm>
);

export default DeleteItemConfirm;
