import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { List } from 'antd';
import { CertificationItem } from './CertificationStore';
import { useRootStore } from '../root/RootStoreHook';
import './CertificationDisplay.css';

const CertificationDisplay: FC = () => {
  const { profile } = useRootStore();
  const certification = profile?.certification;

  return useObserver(() => (
    <List>
      <h3 className='certifications-title'>Courses & Certifications</h3>
      {certification!.sortedItems.map((item: CertificationItem) => (
        <List.Item key={item.id} className='certifications-list-item'>
          {item.start && item.end && item.end === item.start ? <>{item.start}: </> : null}
          {item.start && item.end && item.end !== item.start ? (
            <>
              {item.start} - {item.end}:{' '}
            </>
          ) : null}
          {item.start && !item.end ? <>{item.start}: </> : null}
          {!item.start && item.end ? <>{item.end}: </> : null}
          {item.career ? <>{item.career}</> : null}
          {item.institution && item.career ? <> - </> : null}
          {item.institution ? <>{item.institution}</> : null}
          {item.location ? <>, {item.location}</> : null}
        </List.Item>
      ))}
    </List>
  ));
};

export default CertificationDisplay;
