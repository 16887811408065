import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useRootStore } from '../root/RootStoreHook';
import { CustomInput } from '../forms/CustomInput';
import CustomForm from '../forms/CustomForm';
import { CustomSelect } from '../forms/CustomSelect';
import { useWizardMode } from '../stepper/WizardMode';
import { Routes } from '../app/routing/Routes';
import { useRedirect } from '../hooks/HooksUtil';
import { sortBy } from '../app/Utils';
import { patterns } from 'components/profile/models/Config';

const FormControls: FC = () => {
  const { dropdownOptions, profile } = useRootStore();
  const { config, cognizantId } = profile!.personalInfo!;

  return (
    <>
      <CustomInput
        name="cognizantId"
        label="Cognizant Id"
        disabled={!!cognizantId}
        config={config.cognizantId}
        pattern={patterns.numbers}
        onChange={(evt: any) => evt.currentTarget.value}
      />
      <CustomInput
        name="firstName"
        label="First Name"
        config={config.firstName}
        pattern={patterns.text.nonSpecial}
        onChange={(evt: any) => evt.currentTarget.value}
      />
      <CustomInput
        name="lastName"
        label="Last Name"
        config={config.lastName}
        pattern={patterns.text.nonSpecial}
        onChange={(evt: any) => evt.currentTarget.value}
      />
      <CustomSelect
        name="positionId"
        label="Position"
        options={dropdownOptions!.positionOptions.sort(sortBy('description'))}
        config={config.positionId}
      />
      <CustomSelect
        name="studioId"
        label="Studio"
        options={dropdownOptions!.studioOptions.sort(sortBy('description'))}
        config={config.studioId}
      />
      <CustomSelect
        name="communityPersonalInfoId"
        label="Community"
        options={dropdownOptions!.communityOptions.sort(sortBy('description'))}
        config={config.communityPersonalInfoId}
        selectMode="multiple"
      />
      <CustomSelect
        name="additionalPositionPersonalInfoId"
        label="Additional Position"
        options={dropdownOptions!.additionalPositionOptions.sort(sortBy('description'))}
        config={config.additionalPositionPersonalInfoId}
        selectMode="multiple"
        placeholder="Select an option if applicable"
      />

      <CustomInput
        name="email"
        label="Email"
        config={config.email}
        onChange={(evt: any) => evt.currentTarget.value}
        placeholder="e.g. email@cognizant.com"
        pattern={patterns.text.email}
      />
      <CustomInput
        name="phone"
        label="Phone"
        config={config.phone}
        pattern={patterns.text.phone}
        onChange={(evt: any) => evt.currentTarget.value}
        placeholder="e.g. +54 9 1123456789"
      />
    </>
  );
};

const PersonalInfoEntry: FC = () => {
  const { profile } = useRootStore();
  const { personalInfo } = profile!;
  const addPostionProfile = profile?.addPositionProfile;
  const wizardMode = useWizardMode();
  const redirect = useRedirect();
  const arrayOfPostion = addPostionProfile?.items.flatMap((eachItem) => eachItem.additionalPositionId) || [];
  const newObject = { ...personalInfo?.formValues };
  const onSubmit = async (values: any) => {
    await personalInfo!.save(values);
    redirect.go(Routes.Profile);
  };

  return useObserver(() =>
    wizardMode ? (
      <FormControls />
    ) : (
      <CustomForm title="Personal Information" name="personalInfo" onSubmit={onSubmit} initialValues={newObject}>
        <FormControls />
      </CustomForm>
    )
  );
};

export default PersonalInfoEntry;
