/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { FC } from 'react';
import { Form } from 'antd';
import CustomFormForModal from '../forms/CustomFormForModal';
import { useRootStore } from '../root/RootStoreHook';
import CustomModal from '../shared/CustomModal';
import { CustomInput } from '../forms/CustomInput';
import { patterns, textAreaConfig } from 'components/profile/models/Config';

interface IProps {
  visible: boolean;
  onCancel?: () => void;
}

const HighlightModal: FC<IProps> = ({ visible, ...otros }) => {
  const { profile } = useRootStore();
  const highlight = profile!.highlight!;
  const [form] = Form.useForm();
  const { onCancel } = otros;

  const onOk = () => {
    form
      .validateFields()
      .then((values: any) => highlight.addOrUpdate(values))
      .catch(() => {});
  };

  const handleCancelBtn = () => {
    onCancel?.();
    highlight.closeModal();
  };

  return (
    <CustomModal visible={visible} title="Add highlight" onOk={onOk} onCancel={handleCancelBtn}>
      <CustomFormForModal form={form} initialValues={highlight.initialValues}>
        <CustomInput
          placeholder="e.g. Team management experience"
          type="textarea"
          name="description"
          label="Description"
          onChange={(evt: any) => evt.currentTarget.value}
          config={textAreaConfig()}
          pattern={patterns.text.nonSpecial}
          required
        />
      </CustomFormForModal>
    </CustomModal>
  );
};

export default HighlightModal;
