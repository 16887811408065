import { Instance, types } from 'mobx-state-tree';

export const UserItem = types.model('UserItem', {
  id: types.identifierNumber,
  fullName: types.optional(types.string, ''),
  email: types.string,
  communityId: types.number,
  positionId: types.number,
  additionalPositionId: types.number,
  profileCompleted: types.boolean,
  roleIds: types.array(types.number),
  idProfile: types.maybe(types.number),
});

export interface UserItem extends Instance<typeof UserItem> {}
