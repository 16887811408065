/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { FC, useState } from 'react';
import { useObserver } from 'mobx-react-lite';
import { Card, Divider } from 'antd';
import { EditOutlined, HolderOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import { useRootStore } from '../root/RootStoreHook';
import CustomHeader from '../forms/CustomHeader';
import CertificationModal from './CertificationModal';
import { CertificationItem } from './CertificationStore';
import DeleteItemConfirm from '../shared/DeleteItemConfirm';
import CustomButtons from '../shared/CustomButtons';
import { Routes } from '../app/routing/Routes';
import { useRedirect } from '../hooks/HooksUtil';
import CustomAddRow from '../shared/CustomAddRow';
import CustomDragableTable from '../shared/CustomDragableTable';
import BoldColumnTitle from '../shared/BoldColumnTitle';
import CustomPrompt from '../shared/CustomPrompt';

interface IProps {
  id?: any;
}

const CertificationEntry: FC = () => {
  const root = useRootStore();
  const { certification } = root.profile!;
  const redirect = useRedirect();
  const [formChanged, setFormChanged] = useState<boolean>(false);

  const handleDeleteItem: FC<IProps> = ({ id }) => {
    setFormChanged(true);
    certification?.delete(id);
    return null;
  };

  function openModal(itemId?: number) {
    const item = certification!.items.find((x: CertificationItem) => x.id === itemId);
    certification!.setSelectedItem(item);
    certification!.openModal();
  }

  const onSubmit = async () => {
    await certification!.save();
    redirect.go(Routes.Profile);
  };

  const columns: ColumnProps<CertificationItem>[] = [
    {
      title: <BoldColumnTitle title="Start" />,
      dataIndex: 'start',
      key: 'start',
    },
    {
      title: <BoldColumnTitle title="End" />,
      dataIndex: 'end',
      key: 'end',
    },
    {
      title: <BoldColumnTitle title="Career" />,
      dataIndex: 'career',
      key: 'career',
    },
    {
      title: <BoldColumnTitle title="Institution" />,
      dataIndex: 'institution',
      key: 'institution',
    },
    {
      title: <BoldColumnTitle title="Location" />,
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: <BoldColumnTitle title="Actions" />,
      key: 'actions',
      render: (item: CertificationItem) => (
        <>
          <EditOutlined onClick={() => openModal(item.id)} />
          <Divider type="vertical" />
          <DeleteItemConfirm onConfirm={() => handleDeleteItem({id:item.id})} />
          <Divider type="vertical" />
          <HolderOutlined />
        </>
      ),
    },
  ];

  const moveRow = (fromIndex: number, toIndex: number) => {
    root.dragAndDrop(fromIndex, toIndex, certification!.items);
  };

  return useObserver(() => (
    <div onChange={() => setFormChanged(true)}>
      <CustomHeader title="Courses & Certifications" />
      <CustomPrompt when={formChanged} />
      <Card>
        <CustomAddRow onCreate={() => openModal(undefined)} id="certification"/>
        <CustomDragableTable
          style={{}}
          draggableKey="Certification"
          columns={columns}
          dataSource={certification!.sortedItemsForTable}
          moveRow={moveRow}
          className="table-alternative education-table-labels"
          locale="You didn’t add any courses & certifications yet."
        />
      </Card>
      <CustomButtons onSubmit={onSubmit} />
      {certification!.showModal && <CertificationModal visible={certification!.showModal} />}
    </div>
  ));
};

export default CertificationEntry;
