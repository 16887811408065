export const isValidRange = (start: string, end: string, date: Date | null = null): boolean => {
  const currentDate = date || new Date();
  const currentYear = currentDate.getUTCFullYear();
  const currentMonth = currentDate.getUTCMonth() + 1;

  const [yearStart, monthStart] = start.split('/');
  const [yearEnd, monthEnd] = end.split('/');



  const numericYearStart = Number(yearStart);
  const numericMonthStart = Number(monthStart);

  const numericYearEnd = Number(yearEnd);
  const numericMonthEnd = Number(monthEnd);

  const isOngoing = /ongoing/i.test(yearEnd);

  const validYearStart = currentYear >= numericYearStart;

  const validMonthStart = !monthStart || currentYear > numericYearStart || currentMonth >= numericMonthStart;

  const validStart = validYearStart && validMonthStart;

  const validYearEnd = isOngoing || numericYearEnd >= numericYearStart;

  const validMonths =
    (!monthEnd && !monthStart) ||
    isOngoing ||
    (!!monthEnd && !!monthStart && (numericYearEnd !== numericYearStart || numericMonthEnd >= numericMonthStart));
  const isValid = validStart && validYearEnd && validMonths;

  return isValid;
};
