import React, { FC, Suspense } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useObserver } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';

import { Layout } from 'antd';
import Sidebar from '../sidebar/Sidebar';
import Navbar from '../navbar/Navbar';

import { useRootStore } from '../root/RootStoreHook';
import { useSessionOut } from '../hooks/useSessionOut';
import { ModalFeedback } from '../modalFeedback/ModalFeedback';

import Loading from '../loading/Loading';
import LoginEntry from '../login/LoginEntry';
import LandingPage from 'components/landing-page/LandingPage';

import { Routes } from 'components/app/routing/Routes';

import './layout.css';

const { Content } = Layout;

export const AppLayout: FC = ({ children }) => {
  const rootStore = useRootStore();
  const { resetCounter } = useSessionOut();

  function setLoginBackground() {
    return rootStore.isLoading ? 'login-loading' : 'login-content';
  }
  const location = useLocation();

  const isLanding = location.pathname.indexOf(Routes.LandingPage) !== -1;

  return useObserver(() => (
    <Suspense fallback={<Loading show />}>
      {!isLanding && rootStore.isUserLogged && (
        <Layout
          className="layout-master-container"
          onMouseMove={resetCounter}
          onMouseDown={resetCounter}
          onKeyPress={resetCounter}
          onTouchMove={resetCounter}
          onScroll={resetCounter}
          onClick={resetCounter}
        >
          <Navbar />
          <div className="layout-container">
            <Sidebar />
            <Content className="layout-content" id="admin-sidebar-opposite">
              <div className="inner-content">
                <DndProvider backend={HTML5Backend}>
                  <>{children}</>
                </DndProvider>
              </div>
            </Content>
            <ModalFeedback />
          </div>
        </Layout>
      )}
      {!isLanding && !rootStore.isUserLogged && (
        <Layout className="layout-master-container">
          {<Content className={setLoginBackground()}>{!rootStore.isLoading && <LoginEntry />}</Content>}
        </Layout>
      )}
      {isLanding && (
        <Layout className="layout-master-container">
          <Content>
            <LandingPage />
          </Content>
        </Layout>
      )}
    </Suspense>
  ));
};
