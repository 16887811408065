/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Instance, types, flow } from 'mobx-state-tree';
import { notification } from 'antd';
import openNotification from '../notifications/Notification';
import { UserStore, getToken, setToken, removeToken, clearCurrentSession, isTokenExpiredOrInvalid } from '../user/UserStore';
import { axiosInstance } from '../../http/agent';
import { ProfileStore, getProfile } from '../profile/ProfileStore';
import { DropdownOptionsStore, getDropdownOptions } from '../admin/dropdown-options/DropdownOptionsStore';
import { StepperStore } from '../stepper/StepperStore';
import { ProfilesStore } from '../admin/profiles/ProfilesStore';
import { TemplateStore, getTemplates } from '../admin/document-templates/TemplateStore';
import { DocumentStore } from '../document/DocumentStore';
import { tabKeys } from '../sidebar/Sidebar';
import { IS_LOCAL, STORAGE_ENV, LOGIN_ON, ROL_ADMIN, ROL_LEAD, ROL_MANAGER } from '../../constants/constants';
import mockedLoginInitialState from '../../constants/user-mock';
import rolUsers from '../../components/login/roles';
export interface IDraggable {
  id: number;
  order: number;
}
export const RootStore = types
  .model('RootStore', {
    user: types.maybe(UserStore),
    profile: types.maybe(ProfileStore),
    dropdownOptions: types.maybe(DropdownOptionsStore),
    templates: types.maybe(TemplateStore),
    stepper: types.optional(StepperStore, () => StepperStore.create()),
    profiles: types.optional(ProfilesStore, () => ProfilesStore.create()),
    document: types.optional(DocumentStore, () => DocumentStore.create()),
    isLoading: types.optional(types.boolean, false),
    userRole: types.maybe(types.string),
    adminEditMode: types.optional(types.boolean, false),
    shouldReloadProfile: types.optional(types.boolean, false),
    loginError: types.optional(types.boolean, false),
    resetError: types.optional(types.boolean, false),
    restoreError: types.optional(types.boolean, false),
    forcedTab: types.optional(types.string, tabKeys.PROFILE),
    isSidebarOpen: types.optional(types.boolean, false),
    isInProfile: types.optional(types.boolean, false),
    shouldResetPassword: types.optional(types.boolean, false),
    autogeneratedPassword: types.maybe(types.string),
    enrolledUserEmail: types.maybe(types.string),
    enrolledUserPassword: types.maybe(types.string),
    resetPasswordFullName: types.maybe(types.string),
    shouldShowRestorePasswordModal: types.optional(types.boolean, false),
    shouldShowEnrollModal: types.optional(types.boolean, false),
    shouldShowEnrolledModal: types.optional(types.boolean, false),
    error: types.optional(types.boolean, false),
    errorMessage: types.optional(types.string, ''),
  })
  .views((self) => ({
    get isUserLogged(): boolean {
      return self.user !== undefined && !self.shouldResetPassword;
    },
  }))
  .actions((self) => ({
    resetRestorePasswordModal() {
      self.autogeneratedPassword = '';
      self.resetPasswordFullName = '';
      self.shouldShowRestorePasswordModal = false;
    },
    resetUserEnrolledModal() {
      self.enrolledUserEmail = '';
      self.enrolledUserPassword = '';
      self.shouldShowEnrolledModal = false;
    },
    setIsSidebarOpen() {
      self.isSidebarOpen = !self.isSidebarOpen;
    },
    setIsInProfile(value: boolean) {
      self.isInProfile = value;
    },
    updateForcedTab(value: string) {
      self.forcedTab = value;
    },
    showLoading() {
      self.isLoading = true;
    },
    hideLoading() {
      self.isLoading = false;
    },   
    logOut() {
      localStorage.removeItem(STORAGE_ENV + 'userdata');
      removeToken();
      self.user = undefined;
      self.profile = undefined;
      self.dropdownOptions = undefined;
    },    
    getProfileById: flow(function* getProfileById(profileId?: number): any {
      try {
        self.isLoading = true;
        const url = profileId ? `/profile/${profileId}` : '/profile';
        const { data } = yield axiosInstance.get(url);
        const {
          id,
          completed,
          personalInfo,
          addPositionProfile,
          highlight,
          summary,
          userId,
          skill,
          skillSummary,
          education,
          certification,
          history,
          cognizantId
        } = data;
        const _personalInfo = { ...personalInfo, cognizantId: `${personalInfo.cognizantId || ''}` };
        // const addPosProArray = addPositionProfile.items.map((eachItem : any) => eachItem.additionalPositionId);

        self.profile = ProfileStore.create({
          id,
          completed,
          personalInfo: _personalInfo,
          addPositionProfile,
          highlight,
          summary,
          userId,
          skill,
          skillSummary,
          education,
          certification,
          history,
          cognizantId: `${cognizantId || ''}`
        });

        self.adminEditMode = profileId !== undefined;
        self.forcedTab = tabKeys.PROFILE;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        self.forcedTab = tabKeys.FOUR_OH_FOUR;
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    restorePassword: flow(function* restorePassword(email: string): any {
      try {
        self.isLoading = true;
        self.restoreError = false;

        const { data } = yield axiosInstance.post('/admin/restore-password', {
          email: email,
        });

        self.autogeneratedPassword = data;
        self.shouldShowRestorePasswordModal = true;
      } catch (error) {
        self.restoreError = true;
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    resetPassword: flow(function* resetPassword(currentPassword: string, newPassword: string): any {
      try {
        self.isLoading = true;
        self.resetError = false;

        yield axiosInstance.post('/user/reset-password', {
          email: self.user?.email,
          currentPassword,
          password: newPassword,
        });

        removeToken();
        self.user = undefined;
        self.profile = undefined;
        self.dropdownOptions = undefined;
        self.shouldResetPassword = false;
      } catch (error) {
        self.resetError = true;
        openNotification(
          'Error',
          'The Current Password you entered was incorrect. Please enter the correct password.',
          'error',
          5,
          'bottomRight',
          { marginRight: 26 }
        );
        // TODO: handle validation/reset error
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    enrollUser: flow(function* enrollUser(userName: string, email: string, cognizantId: number): any {
      try {
        self.isLoading = true;

        const { data } = yield axiosInstance.post('/user/create', {
          userName,
          email,
          cognizantId
        });

        if (data.error) throw new Error(data.errorMessage);

        self.enrolledUserEmail = email;
        self.enrolledUserPassword = data.password;
        self.shouldShowEnrolledModal = true;
        return data;
      } finally {
        self.isLoading = false;
      }
    }),
    setError(errorMessage = '') {
      self.error = !!errorMessage;
      self.errorMessage = errorMessage;
    },
    showEnrollModal(value: boolean) {
      self.shouldShowEnrollModal = value;
      !value && this.setError();
    },
    showEnrolledModal(value: boolean) {
      self.shouldShowEnrolledModal = value;
    },
    sendEmailToReset: flow(function* login(email: string): any {
      try {
        self.isLoading = true;
        self.loginError = false;
        notification.destroy();

        const { data } = yield axiosInstance.post('/restore-password', {
          Email: email,
        });
      } catch (error) {
        //
      } finally {
        self.isLoading = false;
      }
    }),
    login: flow(function* login(email, password, data): any {
      try {
        notification.destroy();
        const { displayName, token, passwordReset, rol, id } = data;
        setToken(token);
        window.localStorage.setItem('rol', rol);
        self.userRole = rol;
        const [templates, dropdownOptions, profile] = yield Promise.all([
          getTemplates(),
          getDropdownOptions(),
          getProfile(),
        ]);
        const idProfile = profile.id;
        if (self.user) {
          self.user.update(displayName, token, passwordReset, email, password, rol, id, idProfile);
        } else {
          self.user = UserStore.create({
            displayName,
            token,
            rol,
            id,
            idProfile,
            passwordReset,
            email,
            password,
          });
        }

        self.dropdownOptions = DropdownOptionsStore.create(dropdownOptions);
        self.templates = TemplateStore.create(templates);
        self.profile = ProfileStore.create(profile);
        self.shouldResetPassword = passwordReset;
      } catch (error) {
        self.loginError = true;
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    loginWithGoogle: flow(function* loginWithGoogle(email: string, googleToken: string): any {
      try {
        self.isLoading = true;
        self.loginError = false;
        notification.destroy();
        const { data } = yield axiosInstance.post('/user/googleLogin', {
          Email: email,
          Token: googleToken,
        });
        const { displayName, token, rol, id } = data;
        // disable password reset for google login
        const disabledPasswordReset = false;
        setToken(token);
        window.localStorage.setItem('rol', rol);
        self.userRole = rol;
        const [templates, dropdownOptions, profile] = yield Promise.all([
          getTemplates(),
          getDropdownOptions(),
          getProfile(),
        ]);

        const idProfile = profile.id;

        if (IS_LOCAL && LOGIN_ON) {
          const initialState =  mockedLoginInitialState
          const { displayName, id, idProfile, passwordReset, email } = initialState.user;
          const rolUser = ROL_ADMIN ? rolUsers.ADMIN : ROL_LEAD ? rolUsers.LEAD : ROL_MANAGER ? rolUsers.MANAGER : rolUsers.USER
          const {
            completed,
            personalInfo,
            addPositionProfile,
            highlight,
            summary,
            userId,
            skill,
            skillSummary,
            education,
            certification,
            history,
          } = initialState.profile;

          self.user = UserStore.create({
            displayName,
            token,
            rol: rolUser,
            id,
            idProfile,
            passwordReset,
            email,
            password: "",
          });

          self.profile = ProfileStore.create({
            id,
            completed,
            personalInfo: { ...personalInfo },
            addPositionProfile,
            highlight,
            summary,
            userId,
            skill,
            skillSummary,
            education,
            certification,
            history,
          });

          self.dropdownOptions = DropdownOptionsStore.create(initialState.dropdownOptions);

          window.localStorage.setItem('rol', rolUser);
          self.userRole = rolUser;

        } else {
          if (self.user) {
            self.user.update(displayName, token, disabledPasswordReset, email, '', rol, id, idProfile);
          } else {
            self.user = UserStore.create({
              displayName,
              token,
              rol,
              id,
              idProfile,
              passwordReset: disabledPasswordReset,
              email,
            });
          }

          self.dropdownOptions = DropdownOptionsStore.create(dropdownOptions);
          self.templates = TemplateStore.create(templates);
          self.profile = ProfileStore.create(profile);
          self.shouldResetPassword = disabledPasswordReset;
        }
      } catch (error) {
        self.loginError = true;
        openNotification(
          'Error',
          'An error occurred while trying to login with your Google account',
          'error',
          5,
          'bottomRight',
          { marginRight: 26 }
        );
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    setShouldReloadProfile(value: boolean) {
      self.shouldReloadProfile = value;
    },
    dragAndDrop(fromIndex: number, toIndex: number, items: IDraggable[]) {
      if (fromIndex === toIndex) return;

      const moveDown = fromIndex > toIndex;
      const sortedItems = items.slice().sort((a: any, b: any) => a.order - b.order);
      const fromItem = sortedItems[fromIndex];
      const toItem = sortedItems[toIndex];
      const destinyOrder = items.find((x: any) => x.id === toItem.id)!.order;

      items
        .filter((x: any) => fromItem.id !== x.id)
        .forEach((y: any) => {
          if (moveDown) {
            if (y.order >= toItem.order && y.order < fromItem.order) items.find((z: any) => z === y)!.order += 1;
          } else if (y.order <= toItem.order && y.order > fromItem.order) {
            items.find((z: any) => z === y)!.order -= 1;
          }
        });
      items.find((x: any) => x.id === fromItem.id)!.order = destinyOrder;
      },
    getSession() {   
      const session =  localStorage.getItem(STORAGE_ENV + 'userdata');
      
      if(!session){
        if(getToken()) this.logOut();
        return;
      }

      const currentHost = `${window.location.protocol}//${window.location.hostname}`;
      const key =`${process.env.REACT_APP_ENC_KEY} ${currentHost}`;
      try {
        const CryptoJs = require("crypto-js");
        let bytes = CryptoJs.AES.decrypt(`${session}`,key);        
        let userJson = JSON.parse(bytes.toString(CryptoJs.enc.Utf8));      
        return userJson;      
      } catch(ex) {
        return;
      }
      },
    setSession(user: any) {
    const currentHost = `${window.location.protocol}//${window.location.hostname}`;
    const key =`${process.env.REACT_APP_ENC_KEY} ${currentHost}`;
    const CryptoJs = require("crypto-js");

    let userData=JSON.stringify(user);           
    let chipertext = CryptoJs.AES.encrypt(`${userData}`,key);

        localStorage.setItem(STORAGE_ENV + 'userdata', chipertext);
    }
  }));

export interface RootStore extends Instance<typeof RootStore> {}

export async function createRootStore(): Promise<RootStore> {
  const token = getToken();

  if (token && !isTokenExpiredOrInvalid(token)) {
    try {
      const { data } = await axiosInstance.get('/user');

      const [templates, dropdownOptions, profile] = await Promise.all([
        getTemplates(),
        getDropdownOptions(),
        getProfile(),
      ]);
  
      return RootStore.create({
        user: {
          displayName: data.displayName,
          rol: data.rol,
          token: data.token,
          idProfile: profile.id,
          id: data.id,
          passwordReset: data.passwordReset,
        },
        dropdownOptions,
        profile,
        templates,
      });      
    }
    catch (error)
    {
      console.log(error);
      clearCurrentSession();
    }
  }
  else
  {
    clearCurrentSession();
  }

  return RootStore.create({});
}

