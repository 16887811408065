import React, { FC } from 'react';
import { Row, Typography } from 'antd';
import './CustomHeader.css';

const { Title } = Typography;

interface IProps {
  title: string;
}
const CustomHeader: FC<IProps> = ({ title }) => (
  <Row className='custom-header-row'>
    <div className='custom-header-item'>
      <Title level={4} className='custom-header-item-title'>
        {title}
      </Title>
    </div>
  </Row>
);

export default CustomHeader;
