import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { FormInstance } from 'antd/lib/form';
import { Module } from './models/Enums';
import PersonalInfoEntry from '../personal-info/PersonalInfoEntry';
import HighlightsEntry from '../highlights/HighlightsEntry';
import SummaryEntry from '../summary/SummaryEntry';
import SkillsEntry from '../skills/SkillsEntry';
import EducationEntry from '../education/EducationEntry';
import HistoriesEntry from '../histories/HistoriesEntry';
import CustomFormForStepper from '../forms/CustomFormForStepper';

interface IProps {
  module: Module;
  form: FormInstance;
  initialValues?: any;
  onUpdate?: () => void;
}

const ModuleRender: FC<IProps> = ({ module, form, initialValues, onUpdate }) => {
  if (initialValues != null && Object.keys(initialValues).length) {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    form?.setFieldsValue(initialValues);
  }

  return useObserver(() => {
    switch (module) {
      case Module.PersonalInfo:
        return (
          <CustomFormForStepper
            title="Personal Information"
            form={form}
            onFieldsChange={onUpdate}
            initialValues={initialValues}
          >
            <PersonalInfoEntry />
          </CustomFormForStepper>
        );
      case Module.Summary:
        return (
          <CustomFormForStepper title="Summary" form={form} onFieldsChange={onUpdate} initialValues={initialValues}>
            <SummaryEntry />
          </CustomFormForStepper>
        );
      case Module.Highlights:
        return <HighlightsEntry />;
      case Module.Skills:
        return <SkillsEntry />;
      case Module.Education:
        return <EducationEntry />;
      case Module.History:
        return <HistoriesEntry />;

      default:
        // eslint-disable-next-line no-console
        console.error(`UNIMPLEMENTED MODULE ${module}`);
        return <></>;
    }
  });
};

export default ModuleRender;
