import css from './login.css';
import styles from './styles.css';
import Validator from './validator.js';

/**
 * @param {{ html: string, css: string }} param0
 * @returns A <template> element
 */
export function createTemplateForWebComponent({ html, css }) {
  const template = document.createElement('template');
  template.innerHTML = generateHTMLelementInnerHTML(css, html);
  return template;
}

/**
 * Get the inner html for a HTMLElement
 * @param {string} css   classes for the shadow root
 * @param {string} html     html for the shadow root
 * @param {string} html     formatted html with styles
 */
export const generateHTMLelementInnerHTML = (css, html) => {
  return `
  <style>
  @font-face {
    font-display: swap;
    font-family: Gellix;
    font-style: normal;
    font-weight: 400;
    src: url(https://localhost:5001/fonts/gellix-regular_r.woff2) format('woff2');
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-display: swap;
    font-family: Gellix;
    font-style: italic;
    font-weight: 400;
    src: url(https://localhost:5001/fonts/gellix-regularitalic_r.woff2) format('woff2');
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-display: swap;
    font-family: Gellix;
    font-style: normal;
    font-weight: 600;
    src: url(https://localhost:5001/fonts/gellix-semibold_r.woff2) format('woff2');
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-display: swap;
    font-family: Gellix;
    font-style: italic;
    font-weight: 600;
    src: url(https://localhost:5001/fonts/gellix-semibolditalic_r.woff2) format('woff2');
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-display: swap;
    font-family: Gellix;
    font-style: normal;
    font-weight: 300;
    src: url(https://localhost:5001/fonts/gellix-light_r.woff2) format('woff2');
    text-rendering: optimizeLegibility;
  }
  @font-face {
    font-display: swap;
    font-family: Gellix;
    font-style: italic;
    font-weight: 300;
    src: url(https://localhost:5001/fonts/gellix-lightitalic_r.woff2) format('woff2');
    text-rendering: optimizeLegibility;
  }
  .light {
    font-weight: 300;
  }
  .regular {
    font-weight: 400;
  }
  .semi-bold {
    font-weight: 600;
  }
  .italic {
    font-style: italic;
  }
  h1 {
    font-size: clamp(2.25rem, var(--preferredH1FontSize), 5.25rem);
    line-height: clamp(2.25rem, var(--preferredH1LineHeight), 5.775rem);
  }
  h2 {
    font-size: clamp(2rem, var(--preferredH2FontSize), 3.937rem);
    line-height: clamp(2rem, var(--preferredH2LineHeight), 4.331rem);
  }
  h3 {
    font-size: clamp(1.75rem, var(--preferredH3FontSize), 2.937rem);
    line-height: clamp(1.75rem, var(--preferredH3LineHeight), 3.525rem);
  }
  h4 {
    font-size: clamp(1.562rem, var(--preferredH4FontSize), 2.25rem);
    line-height: clamp(1.875rem, var(--preferredH4LineHeight), 2.7rem);
  }
  h5 {
    font-size: clamp(1.375rem, var(--preferredH5FontSize), 1.687rem);
    line-height: clamp(1.65rem, var(--preferredH5LineHeight), 2.025rem);
  }
  h6 {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  p {
    font-size: clamp(1rem, var(--preferredParagraphFontSize), 1.25rem);
    line-height: clamp(1.3rem, var(--preferredSmallParagraphLineHeight), 1.625rem);
  }

  .span-new-user {
    font-size: 16px;
    color: #53565A;
    font-weight: 700;
  }

  .span-error-message {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 100%;
    text-align: start;
    padding: 16px;
  } 

  [type='email'],
  input[type='text'] {
    border: none;
    border-bottom: 1px solid rgba(34, 34, 34, 0.6);
    box-sizing: border-box;
    margin: 2px;
    padding: 12px 0;
    width: 100%;
  }
  input,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
  }
  fieldset {
    position: relative;
  }
  legend {
    bottom: 0;
    position: absolute;
    right: 0;
  }
  label {
    color: var(--dark-gray);
    display: inline-block;
    font: 0.8em Gellix, sans-serif;
  }
  [type='email'],
  [type='password'],
  input[type='text'] {
    background-color: var(--lightest-gray);
    border: 1px solid var(--light-gray);
    border-radius: 8px;
    box-sizing: border-box;
    color: var(--midnight-blue);
    margin: 2px;
    padding: 18px 16px;
    width: 100%;
  }
  input:focus {
    box-shadow: 0 0 0 2px var(--dark-blue);
  }
  input:disabled {
    background-color: var(--lightest-gray);
    border-color: transparent;
    color: var(--medium-gray);
  }
  input::placeholder {
    color: var(--midnight-blue);
  }
  input:disabled::placeholder {
    color: var(--medium-gray);
  }
  textarea {
    border: 1px solid rgba(34, 34, 34, 0.6);
    border-radius: 4px;
    box-sizing: border-box;
    height: 150px;
    margin-top: 5px;
    padding: 12px 20px;
    resize: none;
    width: 100%;
  }
  form {
    display: flex;
    flex-direction: column;
    gap: var(--small-gap);
    width: 90ch;
  }
  .btn {
    font-size: 20px;
    font-weight: 600;
    gap: 15px;
    height: 52px;
    padding: var(--btn-padding-v, 32px) var(--btn-padding-h, 12px);
  }
  .btn-secondary {
    --btn-svg-color: var(--btn-secondary-color);
    background-color: var(--btn-secondary-background-color);
    border: var(--btn-border-width, 2px) solid var(--btn-secondary-border-color);
    color: var(--btn-secondary-color);
  }
  .btn-secondary:hover {
    --btn-svg-color: var(--midnight-blue);
    border: var(--btn-border-width, 2px) solid var(--midnight-blue);
    color: var(--midnight-blue);
  }
  .btn-secondary:active {
    --btn-svg-color: var(--btn-secondary-color);
    border: var(--btn-border-width, 3px) solid var(--btn-secondary-border-color);
    color: var(--btn-secondary-color);
  }
  .btn-link {
    --btn-svg-color: var(--link-color);
    background-color: transparent;
    border: 2px solid transparent;
    color: var(--link-color);
    height: max-content;
    padding: 4px 8px;
    width: max-content;
  }
  .btn-link:hover:enabled {
    --btn-svg-color: var(--midnight-blue);
    color: var(--midnight-blue);
  }
  .btn-link:disabled {
    color: var(--medium-gray);
  }
  .btn-primary > svg > path {
    stroke: var(--btn-primary-color);
  }
  .btn-secondary > svg > path {
    stroke: var(--btn-secondary-color);
  }
  .btn-link > svg > path {
    stroke: var(--link-color);
  }
  .link {
    color: var(--link-color);
    cursor: pointer;
    text-decoration: underline;
  }
  .btn-arrow-left {
    padding-left: 21px;
  }
  .btn-arrow-left:before {
    background-color: var(--btn-svg-color);
    content: '';
    height: 20px;
    mask: url(https://rd-latam.cognizantsoftvision.com/img/btn-arrow-left.svg) no-repeat center;
    -webkit-mask: url(https://rd-latam.cognizantsoftvision.com/img/btn-arrow-left.svg) no-repeat center;
    width: 10px;
  }
  .btn-arrow-right {
    padding-right: 21px;
  }
  .btn-arrow-right:after {
    background-color: var(--btn-svg-color);
    content: '';
    height: 20px;
    mask: url(https://rd-latam.cognizantsoftvision.com/img/btn-arrow-right.svg) no-repeat center;
    -webkit-mask: url(https://rd-latam.cognizantsoftvision.com/img/btn-arrow-right.svg) no-repeat center;
    width: 10px;
  }
  .btn {
    align-items: center;
    border-radius: var(--btn-border-radius, 3px);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-family: Gellix;
    font-size: 16px;
    font-weight: 400;
    gap: 10px;
    justify-content: center;
    line-height: 20px;
    padding: var(--btn-padding-h, 12px) var(--btn-padding-v, 32px);
    text-decoration: none;
  }
  .btn-primary {
    background-color: var(--btn-primary-background-color);
    border: var(--btn-border-width, 3px) solid var(--dark-blue);
    border-color: transparent;
    color: var(--midnight-blue);
    font-weight: 700;
  }
  .btn-primary:hover {
    background-color: var(--accent3-light);
    border: var(--btn-border-width, 3px) solid var(--white);
    border-color: transparent;
    color: var(--midnight-blue);
  }
  .btn-primary:active:enabled {
    box-shadow: 0 0 0 2px var(--dark-blue);
  }
  .btn-primary:disabled,
  .btn-primary:disabled.btn-primary:active {
    background-color: var(--lightest-gray);
    color: var(--medium-gray);
  }
  .btn-primary:disabled.btn-primary:active {
    border-color: transparent;
  }
  .btn-secondary {
    border: var(--btn-border-width, 3px) solid var(--dark-blue);
  }
  .btn-secondary,
  .btn-secondary:hover {
    background-color: var(--white);
    color: var(--dark-blue);
  }
  .btn-secondary:hover {
    border: var(--btn-border-width, 3px) solid var(--white);
  }
  .btn-secondary:active {
    background-color: var(--ultra-light-blue);
    border: var(--btn-border-width, 3px) solid var(--dark-blue);
    color: var(--dark-blue);
  }
  .btn-primary > svg > path {
    fill: var(--light-blue);
  }
  .btn-primary:active > svg > path {
    fill: var(--medium-blue);
  }
  .btn-secondary > svg > path {
    fill: var(--dark-blue);
  }
  .btn-sm {
    height: max-content;
    padding: 5px 10px;
  }
  .btn-compact {
    padding: 0;
  }
  :root {
    --black: #000;
    --ultra-light-blue: #e0ebf6;
    --pressed: #222;
    --very-light-gray: var(--lighter-gray);
    --ultra-light-gray: var(--lightest-gray);
    --highlight-red: var(--red);
    --highlight-yellow: var(--yellow);
    --form-element-border: #22222299;
    --form-element-label: #c4c4c4;
    --nightmode-dropdown: #00006c;
    --nightmode-dropdown-hover: #343695;
    --multi-checkbox-text: #6c757d;
    --dropdown-shadow: #00000040;
    --dropdown-option-border: #f2f2f2;
    --dropdown-option-hover: #fafafa;
    --white: #fff;
    --midnight-blue: #000048;
    --dark-plum: #2e308e;
    --medium-plum: #7373d8;
    --light-plum: #85a0f9;
    --dark-blue: #2f78c4;
    --medium-blue: #6aa2dc;
    --light-blue: #92bbe6;
    --dark-teal: #06c7cc;
    --medium-teal: #26efe9;
    --light-teal: #97f5f7;
    --dark-gray: #53565a;
    --medium-gray: #97999b;
    --light-gray: #d0d0ce;
    --lighter-gray: #e8e8e6;
    --lightest-gray: #f7f7f5;
    --red: #b81f2d;
    --yellow: #e9c71d;
    --green: #2db81f;
    --primary: var(--midnight-blue);
    --accent1-dark: var(--dark-plum);
    --accent1-medium: var(--medium-plum);
    --accent1-light: var(--light-plum);
    --accent2-dark: var(--dark-blue);
    --accent2-medium: var(--medium-blue);
    --accent2-light: var(--light-blue);
    --accent3-dark: var(--dark-teal);
    --accent3-medium: var(--medium-teal);
    --accent3-light: var(--light-teal);
    --background-color: var(--white);
    --p-color: var(--primary);
    --h1-color: var(--primary);
    --h2-color: var(--primary);
    --h3-color: var(--primary);
    --h4-color: var(--accent2-dark);
    --h5-color: var(--accent2-dark);
    --h6-color: var(--accent2-dark);
    --link-color: var(--dark-blue);
    --btn-primary-background-color: var(--medium-teal);
    --btn-primary-background-color-hover: var(--accent3-light);
    --btn-primary-border-color: transparent;
    --btn-primary-color: var(--primary);
    --btn-secondary-background-color: transparent;
    --btn-secondary-border-color: var(--accent2-dark);
    --btn-secondary-color: var(--accent2-dark);
    --link-color: var(--accent2-dark);
  }
  .darkmode-theme {
    --background-color: var(--primary);
    --p-color: var(--white);
    --h1-color: var(--white);
    --h2-color: var(--white);
    --h3-color: var(--white);
    --h4-color: var(--white);
    --h5-color: var(--white);
    --h6-color: var(--white);
    --btn-secondary-background-color: transparent;
    --btn-secondary-border-color: var(--accent3-medium);
    --btn-secondary-color: var(--accent3-medium);
    --link-color: var(--accent3-medium);
    --btn-primary-background-color: var(--medium-teal);
  }
  :root {
    --big-gap: 50px;
    --medium-gap: 25px;
    --small-gap: 10px;
    --btn-padding-h: 25px;
    --btn-padding-v: 16px;
    --btn-border-width: 3px;
    --btn-border-radius: 1000px;
    --multiselect-width: 300px;
    --multiselect-height: 58px;
    --multiselect-title-height: 24px;
    --multiselect-toggle-height: 34px;
    --multiselect-border-size: 1px;
    --multiselect-item-height: 56px;
    --checkbox-icon-width: 32px;
    --checkbox-icon-height: 32px;
    --preferredH1FontSize: 4.2rem;
    --preferredH2FontSize: 3.56rem;
    --preferredH3FontSize: 2.92rem;
    --preferredH4FontSize: 2.28rem;
    --preferredH5FontSize: 1.64rem;
    --preferredParagraphFontSize: 1rem;
  }
  .stepper-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .step {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    font-size: 12px;
    font-weight: 600;
    position: relative;
    transition: color 0.3s ease 0s;
  }
  .step:before {
    content: '';
    left: -50%;
    position: absolute;
    top: 20px;
    width: 100%;
    z-index: 2;
  }
  .stepper-wrapper .step:not(:last-child):after {
    border-bottom: 4px solid #ccc;
    content: '';
    left: 50%;
    position: absolute;
    top: 13px;
    width: 100%;
    z-index: 2;
  }
  .step.done:after {
    border-color: var(--dark-plum);
  }
  .step .step-bullet {
    align-items: center;
    border: 2px solid var(--dark-plum);
    border-radius: 50%;
    display: flex;
    height: 26px;
    justify-content: center;
    margin-bottom: 6px;
    position: relative;
    width: 26px;
    z-index: 5;
  }
  .step.done .step-bullet {
    background-color: var(--white);
    color: var(--dark-plum);
  }
  .step.done .step-bullet:after {
    background: transparent;
    border-color: var(--dark-plum);
    border-style: none none solid solid;
    border-width: 2px;
    content: ' ';
    display: block;
    height: 4px;
    position: relative;
    transform: rotate(-45deg);
    transition: -webkit-transform 0.3s ease 0s, transform 0.3s ease 0s, height 0.3s ease 0s, width 0.3s ease 0s;
    width: 8px;
  }
  .step.done .step-description {
    color: var(--very-dark-gray);
  }
  .step.active .step-bullet {
    background-color: var(--dark-plum);
    color: var(--white);
  }
  .step.active .step-description {
    color: var(--dark-plum);
  }
  .step.inactive .step-bullet {
    background-color: var(--white);
    border: 1px solid var(--light-gray);
    color: var(--light-gray);
    margin-bottom: 8px;
  }
  .step.inactive .step-description {
    color: var(--medium-gray);
    font-weight: 400;
  }
  .spinner {
    font-size: 0;
  }
  .spinner:after {
    animation: spin 0.8s linear infinite;
    border: 3px solid #fff;
    border-color: var(--midnight-blue) rgba(0, 0, 72, 0.8) rgba(0, 0, 72, 0.5) rgba(0, 0, 72, 0.1);
    border-radius: 50%;
    content: ' ';
    display: block;
    height: 30px;
    width: 30px;
  }
  @keyframes spin {
    to {
      transform: rotate(1turn);
    }
  }
  .spinner.spinner--light:after {
    border-color: #fff hsla(0, 0%, 100%, 0.8) hsla(0, 0%, 100%, 0.5) hsla(0, 0%, 100%, 0.1);
  }
  
  </style>
    <style>
    /* 
    Images and fonts provided on index.
    In the future, there will be a CDN for that.
  */
  
  h1 {
      font-size: 40px;
      text-align: center;
    }
     .googleSignIn {
      cursor: pointer;
     }
     .customGoogleSSOBtn {
      background-color: rgb(255, 255, 255);
      display: inline-flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.54);
      box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
        rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
      padding: 0px;
      border-radius: 2px;
      border: 1px solid transparent;
      font-size: 14px;
      font-weight: 500;
      font-family: Roboto, sans-serif;
      cursor: pointer;
     }
     .customGoogleSSOBtn--disabled {
      cursor: auto
     }
    
    .wrapper > .conatiner {
      overflow: auto;
    }
    
    .conatiner {
      margin: 0;
      padding: 0;
      font-family: Gellix, sans-serif;
    }
    
    /* Mobile  */
    
    .right-image-container {
      display: none;
    }
    
    .circle {
      display: none;
    }
    
    .login-container {
      width: 100vw;
      background-color: #ffff;
      min-height: 100vh;
      background-attachment: fixed;
      background-size: contain;
    }
    
    .side-container {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      /* justify-content: center; */
      align-items: center;
      min-height: 100vh;
    }
    
    .title-container {
      display: flex;
      flex-direction: column;
      width: 100vw;
      align-items: center;
      position: relative;
      margin-top: 100px;
    }
    
    .logo {
      height: fit-content;
      padding: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    
    .cvCreator-logo {
      width: 100px;
    }
    
    .signin-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 85%;
      text-align: center;
    }
    
    .signin-container h2 {
      margin-bottom: 10px;
      font-size: 32px;
      font-family: Gellix, sans-serif;
      font-weight: bold;
      line-height: 36px;
      color: #000048;
    }
    
    .signin-container p {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 0px;
    }
    
    .form-container {
      width: 100%;
    }
    .form {
      width: 100%;
    }
    .buttons-group {
      margin-top: 20px;
      display: flex;
      justify-content: space-between
      
    }
    .buttons-group .btn {
      min-width: 30%;
      max-width: 50%;
    }
    .formLogin .btn {
      min-width: 30%;
    }
    .formLogin .btn-link {
      align-self: flex-end;
    }
    
    
    
    /* STATE RELATED STYLES
    
    possible states::
      default-idle
      default-error
      forgot-idle
      forgot-success
      forgot-error
      change-idle
      change-success
      change-error
    
    */
    .subtext-container {
      display: none;
    }
    .resetSent, .changeSent, .createSent {
      display: none;
    }

    .container-error-messages {
      background: #FCEEEF;
      border: 1px solid #B81F2D;
      border-radius: 4px;
      width: 100%;
      display: none;
      flex-direction: row;
      justify-content: flex-start;

    }

    .container-text-elements {
      display: flex;
    }

    .container-error-elements {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .close-button {
      background-color: transparent;
      border: none;
      cursor: pointer;
    }

    .container-text-errors {
      padding: 20px;
    }

    .error-messages {
      color: var(--black);
      font-weight: 600;
      display: none;
      justify-content: flex-start;
      padding-bottom: 0;
      height: 10px;
      padding: 0;
      padding-top: 0;
      height: 10px;
    }

    .error-message2 {
      font-weight: 300;
      padding-bottom: 10px;
      padding-top: 10px;
    }

    /* [data-state='forgot-error'] .error-messages, [data-state='default-error'] .error-messages , [data-state='change-error'] .error-messages {
      display: block;
    } */
    [data-state$='error'] .error-messages {
      display: flex;
      line-height: 10px;
    }
    
    [data-state='default-error'] .container-error-messages {
      display: flex;
    }

    [data-state='create-error'] .container-error-messages {
      display: flex;
    }

    [data-state='forgot-error'] .container-error-messages {
      display: flex;
    }

    [data-state='forgot-success'] .resetSent {
      display: block;
    }

    [data-state='create-success'] .createSent {
      display: block;
    }
    
    [data-state='forgot-success'] #inputReset, [data-state='forgot-success'] #resetSubmit{
      display: none;
    }

    [data-state='create-success'] #inputCreate, [data-state='create-success'] #createSubmit{
      display: none;
    }

    [data-state='default-success'] .signin-container{
      display: none;
    }
    
    [data-state='change-success'] #createyourpassword,
    [data-state='change-success'] #inputChangePass,
    [data-state='change-success'] #inputChangePassRepeat, 
    [data-state='change-success'] #eyeButton, 
    [data-state='change-success'] #eyeButtonRepeat, 
    [data-state='change-success'] #submitChange {
      display: none;
    }

    [data-state='change-success'] .changeSent{
      display: block;
    }
    
    .formReset, .formChange, .formLogin, .formCreateNewPassword {
      display: none;
    }
    
    [data-state|='default'] .subtext-container {
      display: block;
    }

    
    [data-state|='default'] .formLogin, [data-state|='change'] .formChange, [data-state|='forgot'] .formReset, [data-state|='create'] .formCreateNewPassword {
      display: flex;
    }
    
    
    /* Desktop */
    
    @media only screen and (min-width: 768px) {
      .wrapper > .conatiner {
        overflow: auto;
      }
    
      .side-container {
        max-width: 468px;
        margin: 0 auto;
      }
    
      .circle {
        display: block;
        position: absolute;
        bottom: 50px;
        right: 0px;
      }
    
      .signin-container {
        /* margin-bottom: 100px; */
        width: 100%;
      }
    
      .signin-container h2 {
        font-size: 25px;
        line-height: 28px;
      }
    
      .signin-container p {
        /* width: 357px; */
        font-size: 22px;
        line-height: 32px;
      }
    
    }
    
    @media only screen and (min-width: 1024px) {
      .wrapper > .conatiner {
              overflow: auto;
      }
    }
    
    @media only screen and (min-width: 1200px) {
      .wrapper > .conatiner {
        overflow: auto;
      }
    
      .login-container {
        display: flex;
        flex-direction: row;
      }
    
      .right-image-container {
        display: block;
        width: 55%;
        background-color: #00043e;
        background-image: url("https://rd-latam.cognizantsoftvision.com/img/login/login.png");
        background-position: 0% 50%;
        background-size: cover;
        background-repeat: no-repeat;
      }
      .right-image-filter {
        background-color: rgba(6, 18, 55, 0.8);
        backdrop-filter: blur(2px);
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }
      .message {
        font-family: 'Gellix';
        font-style: normal;
        font-weight: 600;
        font-size: 84px;
        line-height: 92.4%;
        color: var(--white);
        letter-spacing: -1.5px;
        margin-top: 20%;
        margin-left: 97px;
        margin-right: 169px;
        width: 442px;
      }
    
      .side-container {
        width: 40%;
      }
    }
    
    .cvCreator-logo {
      width: 300px;
      margin-bottom: 20px;
    }
    
    .cognizant-logo {
      width: 200px;
      height: 100px;
    }
    
    @media only screen and (min-width: 1824px) {
      .wrapper > .conatiner {
        overflow: auto;
      }
    
      .right-image-container {
        display: block;
        width: 55%;
        background-color: #00043e;
        background-position: 0% 50%;
        background-repeat: no-repeat;
      }
    }
    
    @media /* (min-height: 8000px) and */ (max-height: 8000px) {
      .titleText {
        display: none;
      }
      .titleLogo {
        margin-top: 0;
      }
    }
    /* For landscape mobile and tablet sizes */
    @media screen and (orientation: landscape) and (min-device-width: 319px) and (max-device-width: 780px) {
      .title-container {
        padding: 0;
      }
    
      .title-logo {
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
    
      .cvCreator-logo {
        width: 40%;
      }
    
      .circle {
        display: none;
      }
    
      .signin-container {
        /* margin-bottom: 20px; */
        padding: 0;
      }
    
      .signin-container h2 {
        font-size: 30px;
        line-height: 44px;
        padding: 0;
        margin: 0;
      }
    
      .signin-container p {
        font-size: 15px;
        line-height: 28px;
      }
    }
    
    @media screen and (min-device-width: 780px) and (max-device-width: 1400px) and (orientation: landscape) {
      .cvCreator-logo {
        width: 40%;
      }
    
      .title-container {
        padding: 0;
      }
    
      .logo {
        padding: 5px;
        display: flex;
        justify-content: center;
      }
    
      .signin-container {
        /* margin-bottom: 20px; */
        padding: 0;
      }
    
      .signin-container h2 {
        font-size: 20px;
        line-height: 44px;
        margin: 0;
        padding: 0;
      }
    
      .signin-container p {
        font-size: 15px;
        line-height: 28px;
        /* margin-bottom: 15px; */
        padding: 0;
      }
    
      .circle {
        display: none;
      }
    
      .right-image-container {
        display: none;
      }
    }
  
    .change-password-rules-container {
      background:#E6EFF9;
      border:1px solid #2F78C4;
      border-radius:5px;
      display:flex;
      padding :20px;
    }

    .change-password-exclamation-icon {
      padding-right: 8px;
    }

    .change-password-rules-header {
      text-align: left;
      padding-bottom:12px;
      justify-content: space-between;
      display: flex;
    }

    .change-password-rules-list {
      text-align: left; 
      margin-left:-20px
    }

    .change-password-rule-container {
      display:flex; 
      flex-direction:row;
    }

    .change-password-rule-item {
      padding-right:10px; 
      width:20px;
    }

    .change-password-error-item {
      padding-left:10px; 
      padding-right:10px; 
      width:15px;
    }

    .login-user-error-item {
      padding-left:10px; 
      padding-right:10px; 
      width:15px;
    }

    .login-error-mark {
      padding-left:10px; 
      padding-right:10px; 
      width:20px;
    }

    .change-password-eye-button {
      margin-left: -40px; 
      display:inline; 
      margin-bottom:-11px;
      width:30px;
    }
   
    .change-password-submit-button-container {
      display:flex;
      flex-direction : column;
      padding-top: 32px;
    }

    .change-password-submit-button {
      width:100%
    }

    .change-password-close-rules-button {
      border-width: 0px;
      background: none;
    }

    .change-password-error-container {
      display: none
    }

    .login-user-error-container {
      display: none
    }

    .change-password-validation-error-item {
      color: red;
    }

    .login-user-validation-error-item {
      color: red;
      text-align: start;
      font-size: 12px;
    }

    .change-password-input-background-color {
      background: var(--lightest-gray);
      border:'#1px solid var(--light-gray)'
    }

    .change-password-input-background-color-error {
      background: #FCEEEF;
      border:'#1px solid #97999B'
    }

    .login-user-input-background-color-error {
      background: #FCEEEF;
      border:'#1px solid #ff0000';
      color: #ff0000;
      font-size: 14px;
    }

    .change-password-go-to-login {
      border:1px solid #2F78C4;
      height: 56px;
      color: #2F78C4;
      font-size: 16px;
      border-radius: var(--btn-border-radius, 3px);
      min-width: -webkit-fill-available;
      cursor: pointer;
    }    

    .change-password-go-to-login-container {
      display: flex;
    }

    .change-password-go-to-login-arrow {
      width:34px;
      margin-left: -328px;
    }   
    
    .change-password-ok-sign {
      width: 120px;
    }

    .reset-password-go-to-login-arrow {
      width:34px;
      margin-left: -612px;
    }

    .resend-email-button {
      text-align: -webkit-center;
      padding-top: 20px;
    }

    .create-passord-error-title {
      padding-bottom:10px
    }

    </style>
    ${html}
  `;
};

/**
 * Debounce function generator
 * @returns Function receiving a callback and delay in ms that will execute passed the delay time
 */
export const debounce = () => {
  let timer;
  return (callback, delay = 500) => {
    clearTimeout(timer);
    timer = setTimeout(callback, delay);
  };
};
/**
 * Logs to the console if env is local and local storage value is turned on
 * @param {any} args receives any number of arguments similar to the console.log function
 */

const html = `<div class="wrapper" data-state="default-idle">
    <div class="conatiner">
      <noscript>You need to enable JavaScript to run this app</noscript>
      <div>
        <section style="min-height: 100vh; height: 100vh">
          <main class="login-content">
            <div class="login-container">
              <div class="right-image-container">
                <div class="right-image-filter">
                  <div id="message" class="message">Welcome to R&D Latam</div>
                </div>
              </div>
              <section class="side-container">
                <!-- TITLE LOGO SLOT -->
                <div class="title-logo">
                  <slot name="titleLogo"></slot>
                </div>
                <h1 class="titleText" id="titleText"></h1>
                <div class="signin-container">
                  <div class="subtext-container">
                    <h2 id="textPrompt">Sign in with your account</h2>
                    <p id="descriptionText">
                      Easily create and edit your resume to fulfill our client
                      needs.
                    </p>
                  </div>
                  <!-- TODO: add and format error section -->
                 
                  <!-- BUTTON SLOT -->
                  <div id="formContainer" class="form-container">
                    
                    <slot name="loginSlot">
                      <!-- STANDARD LOGIN FLOW -->
                      <form id="loginForm" class="form formLogin" action="">
                      <div class="container-error-messages">
                        <div class="container-error-elements">
                        <div class="container-text-elements">
                          <img src='/img/exclamation-mark-red.svg' class='login-error-mark' />
                            <div class="container-text-errors">
                              <span id="errorMessages" class="error-messages span-error-message"></span>
                              <span id="errorMessages2" class="error-messages span-error-message error-message2"></span>
                            </div>
                          </div>
                          <button class="close-button" id="closeButton" type="button"><img src='/img/close-icon.svg' class='login-user-error-item' /></button>
                        </div>
                      </div>
                        <input id="inputLoginEmail" data-qa="inputLoginEmail" name="inputLoginEmail" type="email" placeholder="Email">
                        <div class='login-user-error-container' id='loginUserError1'>
                              <img src='/img/exclamation-mark-red.svg' class='login-user-error-item'/>
                              <div class='login-user-validation-error-item'>Please use your cognizant mail in the format: name.lastname@cognizant.com</div>
                        </div>
                        <input id="inputLoginPass" data-qa="inputLoginPass" name="inputLoginPass" type="password" placeholder="Password">
                        <button id="forgot" data-qa="forgot" class="btn btn-link btn-compact" type="button">Forgot password?</button>
                        <button id="signIn" data-qa="signIn" class="btn btn-primary" type="submit" disabled>Sign In</button>
                        <span class="span-new-user">New to CV Creator?</span>
                        <button id="createPass" data-qa="createPass" class="btn btn-secondary" type="button">Create Password</button>
                      </form>


                      <!-- CREATE PASSWORD FLOW -though params- -->
                      <form id="createForm" class="form formCreateNewPassword" action="">
                          <h2>Create password</h2>
                          <div id="createDescriptionText">
                            <p> First time in CV Creator? Enter your Cognizant email and we'll send you a link to create your password </p>
                            <div class="container-error-messages">
                              <div class="container-error-elements">
                              <div class="container-text-elements">
                                <img src='/img/exclamation-mark-red.svg' class='login-error-mark' />
                                  <div class="container-text-errors">
                                    <span id="errorMessagesCreatePassword" class="error-messages span-error-message create-passord-error-title"></span>
                                    <span id="errorMessages2CreatePassword" class="error-messages span-error-message error-message2"></span>
                                  </div>
                                </div>
                                <button class="close-button" id="closeButtonCreatePassword" type="button"><img src='/img/close-icon.svg' class='login-user-error-item' /></button>
                              </div>
                            </div>
                            <p>
                            <input id="inputCreate" data-qa="inputCreate" name="resetEmail" type="email" placeholder="Cognizant Email">
                            <div class='login-user-error-container' id='createPassError'>
                              <img src='/img/exclamation-mark-red.svg' class='login-user-error-item' /><div class='login-user-validation-error-item'>Please use your cognizant mail in the format: name.lastname@cognizant.com</div>
                            </div>
                          </div>
                          <div id="createButtonsGroup" class="buttons-group">
                            <button id="returnFromChange" data-qa="returnFromChange" class="btn btn-link" type="button">Return</button>
                            <button id="createSubmit" data-qa="createSubmit" class="btn btn-primary" type="submit" disabled>Send Email</button>
                          </div>
                          <p>
                            <div class="createSent">
                              <div>
                                  <h7>Request to change password to given email submited. <b>If account is authorized</b>, we will be sending an email in the next couple of minutes.</h7>
                              </div>
                              <div class="change-password-submit-button-container">
                                <div class='change-password-go-to-login-container buttons-group'>
                                  <button id="backToSignInFromCreate" data-qa="backToSignInFromCreate" class="change-password-go-to-login" type="button">Back to sign in</button>
                                </div>
                              </div>
                              <div class="resend-email-button">
                                <button id="resendEmail" data-qa="resendEmail" class="btn btn-link btn-compact" type="button">Resend Email</button>
                              </div>
                            </div>
                          </p>
                          
                      </form>

                      
                      <!-- FORGOT PASSWORD FLOW -->
                      <form id="resetForm" class="form formReset" action="">
                      <div class="container-error-messages">
                        <div class="container-error-elements">
                        <div class="container-text-elements">
                          <img src='/img/exclamation-mark-red.svg' class='login-error-mark' />
                            <div class="container-text-errors">
                              <span id="errorMessages3" class="error-messages span-error-message"></span>
                              <span id="errorMessages4" class="error-messages span-error-message error-message2"></span>
                            </div>
                          </div>
                          <button class="close-button" id="closeButton2" type="button"><img src='/img/close-icon.svg' class='login-user-error-item' /></button>
                        </div>
                      </div>
                        <h2>Reset password</h2>
                        <input id="inputReset" data-qa="inputReset" name="resetEmail" type="email" placeholder="Enter email to recover password">
                        <div class='login-user-error-container' id='emailFormatErrorForgot'>
                              <img src='/img/exclamation-mark-red.svg' class='login-user-error-item'/>
                              <div class='login-user-validation-error-item'>Please use your cognizant mail in the format: name.lastname@cognizant.com</div>
                        </div>
                        <div id="resetButtonsGroup" class="buttons-group">
                            <button id="return" data-qa="return" class="btn btn-link " type="button">Return</button>
                            <button id="resetSubmit" data-qa="resetSubmit" class="btn btn-primary" type="submit">Send Email</button>
                         </div>
                        <p>
                          <div class="resetSent">
                            <div>
                                <h7>Check your email. A message with a link to reset your password has been sent.</h7>
                            </div>
                            <div class="change-password-submit-button-container">
                              <div class='change-password-go-to-login-container buttons-group'>
                                <button id="backToSignInFromReset" data-qa="backToSignInFromReset" class="change-password-go-to-login" type="button">Back to sign in</button>
                              </div>
                            </div>
                            <div class="resend-email-button">
                              <button id="resendEmail" data-qa="resendEmail" class="btn btn-link btn-compact" type="button">Resend Email</button>
                            </div>
                          </div>
                        </p>
                      </form>

                      <!-- CHANGE PASSWORD FLOW -though params -->
                      <form id="changeForm" class="form formChange" action="">
                        <h2>Create password</h2>
                        <h7 id="createyourpassword">Create your password</h7>
                        <div class='change-password-rules-container' id = 'changePasswordRules'>
                          <div>
                            <img src='/img/exclamation-mark.svg' width='25px;' class='change-password-exclamation-icon'>
                          </div>
                          <div>
                            <div class='change-password-rules-header'>
                              <div>
                                <strong>Please follow the rules below:</strong>
                              </div>
                              <div>
                                <button class='change-password-close-rules-button' id="changePasswordRulesClose"><img width=20px src='/img/close-bold.svg'></button>
                              </div>
                            </div>
                            <div class='change-password-rules-list'>
                              <div>
                                <div class='change-password-rule-container'>
                                  <img src='/img/bullet.svg' class='change-password-rule-item' id='changePasswordValidation1'/><div>Use at least 6 characters.</div>
                                </div>
                                <div class='change-password-rule-container'>
                                  <img src='/img/bullet.svg' class='change-password-rule-item' id='changePasswordValidation2'/><div>Use both upper and lower characters.</div>
                                </div>
                                <div class='change-password-rule-container'>
                                  <img src='/img/bullet.svg' class='change-password-rule-item' id='changePasswordValidation3'/><div>Include at least one symbol (!"$%&# etc...).</div>
                                </div>
                                <div class='change-password-rule-container'>
                                  <img src='/img/bullet.svg' class='change-password-rule-item' id='changePasswordValidation4'/><div>Include at least one number.</div>
                                </div>
                                <div class='change-password-rule-container'>
                                  <img src='/img/bullet.svg' class='change-password-rule-item' id='changePasswordValidation5'/><div>Should NOT contain 2 identical conseutive characters (11, zz, aa, 55, etc)</div>
                                </div>
                                <div class='change-password-rule-container'>
                                  <img src='/img/bullet.svg' class='change-password-rule-item' id='changePasswordValidation6'/><div>Both passwords must match</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <input id="inputChangePass" data-qa="inputChangePass" name="newPassword" type="password" placeholder="Create New Password">
                          <img src='/img/eye-closed-svgrepo-com.svg' class='change-password-eye-button' id='eyeButton'>
                        </div>
                        <div>
                          <input id="inputChangePassRepeat" data-qa="inputChangePassRepeat" name="repeatPassword" type="password" placeholder="Confirm New Password">
                          <img src='/img/eye-closed-svgrepo-com.svg' class='change-password-eye-button' id='eyeButtonRepeat'>
                        <div>

                        <div class='change-password-errors-list'>
                          <div>
                            <div class='change-password-error-container' id='changePasswordError1'>
                              <img src='/img/exclamation-mark-red.svg' class='change-password-error-item' /><div class='change-password-validation-error-item'>Use at least 6 characters.</div>
                            </div>
                            <div class='change-password-error-container' id='changePasswordError2'>
                              <img src='/img/exclamation-mark-red.svg' class='change-password-error-item' /><div class='change-password-validation-error-item'>Use both upper and lower characters.</div>
                            </div>
                            <div class='change-password-error-container' id='changePasswordError3'>
                              <img src='/img/exclamation-mark-red.svg' class='change-password-error-item' /><div class='change-password-validation-error-item'>Include at least one symbol (!"$%&# etc...).</div>
                            </div>
                            <div class='change-password-error-container' id='changePasswordError4'>
                              <img src='/img/exclamation-mark-red.svg' class='change-password-error-item' /><div class='change-password-validation-error-item'>Include at least one number.</div>
                            </div>
                            <div class='change-password-error-container' id='changePasswordError5'>
                              <img src='/img/exclamation-mark-red.svg' class='change-password-error-item' /><div class='change-password-validation-error-item'>Should NOT contain 2 identical conseutive characters</div>
                            </div>
                            <div class='change-password-error-container' id='changePasswordError6'>
                              <img src='/img/exclamation-mark-red.svg' class='change-password-error-item' /><div class='change-password-validation-error-item'>Both passwords must match</div>
                            </div>
                          </div>
                        </div>
                        <div>
                        <div class="change-password-submit-button-container">
                          <button disabled = "true" id="submitChange" data-qa="submitChange" class="btn btn-primary change-password-submit-button" type="submit">Create Password</button>
                        </div>
                        </div>
                        <div id="changeSuccessful">
                          <div>
                            <img src='/img/checkmark-ok.svg' class='change-password-ok-sign'/>
                          </div>
                          <div>
                              <h7>All done! You created your password succesfully. 
                              You can go back and Sign In.</h7>
                          </div>
                          <div class="change-password-submit-button-container">
                            <div class='change-password-go-to-login-container'>
                            <button id="backToSignIn" data-qa="backToSignIn" class="change-password-go-to-login" type="button">Back to sign in</button>
                            <img src='/img/triangle-left.svg' class='change-password-go-to-login-arrow' />
                            <div>
                          </div>
                        </div>
                      </form>                     
                    </slot>
                  </div>
                </div>
              </section>
            </div>
          </main>
        </section>
      </div>
    </div>
  </div>`;

const LoginTemplate = createTemplateForWebComponent({ html, css });

const validator = new Validator({
  inputLoginEmail: [Validator.required, Validator.email],
  inputLoginPass: [Validator.required, Validator.chars(5)],
  newPassword: [Validator.required, Validator.chars(5)],
  repeatPassword: [Validator.equals('newPassword')],
  resetEmail: [Validator.required, Validator.email],
});

const states = Object.freeze({
  default: {
    idle: 'default-idle',
    loading: 'default-loading',
    success: 'default-success',
    error: 'default-error',
  },
  forgotPass: {
    idle: 'forgot-idle',
    loading: 'forgot-loading',
    success: 'forgot-success',
    error: 'forgot-error',
  },
  changePass: {
    idle: 'change-idle',
    loading: 'change-loading',
    success: 'change-success',
    error: 'change-error',
  },
  createPass: {
    idle: 'create-idle',
    loading: 'create-loading',
    success: 'create-success',
    error: 'create-error',
  },
  resetEmail: {
    idle: 'create-idle',
    loading: 'create-loading',
    success: 'create-success',
    error: 'create-error',
  },
});
const errorMessages = Object.freeze({
  default: `Incorrect email or password.
  Please verify and try again.`,
  forgotPass: `Your email doesn't exist in the system/
  Please send an email to CVCreator@cognizant.com
  to be able to support you`,
  newUser: `You are not able to access to CV Creator. 
  Please send an email to CVCreator@cognizant.com to be able to support you.`,
  changePass: `Some of the following failed:
  *All fields are required
  *Passwords should match
  *Passwords should be at least 5 characters long`,
  createPass: `Your email doesn't exist in the system|. 
  Please send an email to CVCreator@cognizant.com to be able to support you| You are not able to create a new password`,
});

const logStateChange = (object, prop, value) => {
  const oldState = { ...object };
  const newState = { ...object };
  newState[prop] = value;
};

export class CwcLogin extends HTMLElement {
  static get observedAttributes() {
    return ['title', 'description', 'prompt', 'message'];
  }

  controller = new AbortController(); // for removing all anonymous listeners in one line

  state = new Proxy(
    { currentState: states.default.idle, errorMessage: '' },
    {
      set: (object, prop, value) => {
        logStateChange(object, prop, value);
        object[prop] = value;
        this._refresh();
        return true;
      },
    }
  );

  constructor() {
    super();

    this.attachShadow({ mode: 'open' });
    this.shadowRoot.appendChild(LoginTemplate.content.cloneNode(true));
  }

  attributeChangedCallback(name, _old, newVal) {
    // Invoked when one of the custom element's attributes is added, removed, or changed.
    if (this.titleText && name === 'title') {
      this.titleText.innerText = newVal;
    }
    if (this.descriptionText && name === 'description') {
      this.descriptionText.innerText = newVal;
    }
    if (this.textPrompt && name === 'prompt') {
      this.textPrompt.innerText = newVal;
    }
    if (this.message && name === 'message') {
      this.message.innerText = newVal;
    }
  }

  makeRequest(endpoint) {
    // can handle async!
  }

  connectedCallback() {
    const changePasswordValidation1 = this.shadowRoot.getElementById('changePasswordValidation1');
    const changePasswordValidation2 = this.shadowRoot.getElementById('changePasswordValidation2');
    const changePasswordValidation3 = this.shadowRoot.getElementById('changePasswordValidation3');
    const changePasswordValidation4 = this.shadowRoot.getElementById('changePasswordValidation4');
    const changePasswordValidation5 = this.shadowRoot.getElementById('changePasswordValidation5');
    const changePasswordValidation6 = this.shadowRoot.getElementById('changePasswordValidation6');
    const changePasswordRulesClose = this.shadowRoot.getElementById('changePasswordRulesClose');
    const changePasswordRulesWindow = this.shadowRoot.getElementById('changePasswordRules');

    const changePasswordError1 = this.shadowRoot.getElementById('changePasswordError1');
    const changePasswordError2 = this.shadowRoot.getElementById('changePasswordError2');
    const changePasswordError3 = this.shadowRoot.getElementById('changePasswordError3');
    const changePasswordError4 = this.shadowRoot.getElementById('changePasswordError4');
    const changePasswordError5 = this.shadowRoot.getElementById('changePasswordError5');
    const changePasswordError6 = this.shadowRoot.getElementById('changePasswordError6');

    const params = new URL(document.location).searchParams;

    changePasswordRulesClose.addEventListener('click', (e) => {
      e.preventDefault();
      changePasswordRulesWindow.style.display = 'none';
    });

    const addEyeIcon = (eyeButton, inputControl, isValidable) => {
      const eyeButtonElement = this.shadowRoot.getElementById(eyeButton);
      const inputElement = this.shadowRoot.getElementById(inputControl);

      eyeButtonElement.addEventListener('click', () => {
        inputElement.getAttribute('type') === 'password'
          ? inputElement.setAttribute('type', 'text')
          : inputElement.setAttribute('type', 'password');

        if (inputElement.getAttribute('type') === 'text') {
          eyeButtonElement.setAttribute('src', '/img/eye-open-svgrepo-com.svg');
        } else {
          eyeButtonElement.setAttribute('src', '/img/eye-closed-svgrepo-com.svg');
        }
      });

      inputElement.addEventListener('keyup', () => {
        let isFormValid = true;

        if (inputElement.value.length > 5) {
          changePasswordValidation1.setAttribute('src', '/img/checkmark.svg');
        } else {
          changePasswordValidation1.setAttribute('src', '/img/bullet.svg');
          isFormValid = false;
        }

        const hasUpperCaseRE = new RegExp('^(?=.*?[A-Z])');
        const hasLowerCaseRE = new RegExp('^(?=.*?[a-z])');

        const hasUpperCase = hasUpperCaseRE.test(inputElement.value);
        const hasLowerCase = hasLowerCaseRE.test(inputElement.value);

        if (hasUpperCase && hasLowerCase) {
          changePasswordValidation2.setAttribute('src', '/img/checkmark.svg');
        } else {
          changePasswordValidation2.setAttribute('src', '/img/bullet.svg');
          isFormValid = false;
        }

        var regex_symbols = /[-!¡$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/;

        const hasSpecialCharactersRE = new RegExp(regex_symbols);

        const hasSpecialCharacters = hasSpecialCharactersRE.test(inputElement.value);

        if (hasSpecialCharacters) {
          changePasswordValidation3.setAttribute('src', '/img/checkmark.svg');
        } else {
          changePasswordValidation3.setAttribute('src', '/img/bullet.svg');
          isFormValid = false;
        }

        const hasAtLeastOnedigitRE = new RegExp('^(?=.*?[0-9])');

        const hasAtLeastOnedigit = hasAtLeastOnedigitRE.test(inputElement.value);

        if (hasAtLeastOnedigit) {
          changePasswordValidation4.setAttribute('src', '/img/checkmark.svg');
        } else {
          changePasswordValidation4.setAttribute('src', '/img/bullet.svg');
          isFormValid = false;
        }

        let doesHaveTwoConsecutiveCharacters = false;

        if (inputElement.value.length > 0) {
          let currentCharacter = inputElement.value[0];
          for (let i = 1; i <= inputElement.value.length - 1; i++) {
            if (currentCharacter == inputElement.value[i]) {
              doesHaveTwoConsecutiveCharacters = true;
            }
            currentCharacter = inputElement.value[i];
          }
        }

        if (!doesHaveTwoConsecutiveCharacters) {
          changePasswordValidation5.setAttribute('src', '/img/checkmark.svg');
        } else {
          changePasswordValidation5.setAttribute('src', '/img/bullet.svg');
          isFormValid = false;
        }

        const password = this.shadowRoot.getElementById('inputChangePass');
        const confirmPassword = this.shadowRoot.getElementById('inputChangePassRepeat');
        if (password.value === confirmPassword.value && password.value !== '') {
          changePasswordValidation6.setAttribute('src', '/img/checkmark.svg');
        } else {
          changePasswordValidation6.setAttribute('src', '/img/bullet.svg');
          isFormValid = false;
        }

        const submitButton = this.shadowRoot.getElementById('submitChange');

        //submitButton.setAttribute('disabled','false');

        if (isFormValid) {
          submitButton.disabled = false;
          password.classList.remove('change-password-input-background-color-error');
          password.classList.add('change-password-input-background-color');
          confirmPassword.classList.remove('change-password-input-background-color-error');
          confirmPassword.classList.add('change-password-input-background-color');
        } else {
          submitButton.disabled = true;
          changePasswordRulesWindow.style.display = 'flex';
        }
      });
      //**************************************************************************************** */
      inputElement.addEventListener('change', () => {
        let isFormValid = true;

        if (inputElement.value.length > 5) {
          if (isValidable) changePasswordError1.style.display = 'none';
          inputElement.classList.remove('change-password-input-background-color-error');
          inputElement.classList.add('change-password-input-background-color');
        } else {
          if (isValidable) changePasswordError1.style.display = 'flex';
          isFormValid = false;
          inputElement.classList.remove('change-password-input-background-color');
          inputElement.classList.add('change-password-input-background-color-error');
        }

        const hasUpperCaseRE = new RegExp('^(?=.*?[A-Z])');
        const hasLowerCaseRE = new RegExp('^(?=.*?[a-z])');

        const hasUpperCase = hasUpperCaseRE.test(inputElement.value);
        const hasLowerCase = hasLowerCaseRE.test(inputElement.value);

        if (hasUpperCase && hasLowerCase) {
          if (isValidable) changePasswordError2.style.display = 'none';
          inputElement.classList.remove('change-password-input-background-color-error');
          inputElement.classList.add('change-password-input-background-color');
        } else {
          if (isValidable) changePasswordError2.style.display = 'flex';
          isFormValid = false;
          inputElement.classList.remove('change-password-input-background-color');
          inputElement.classList.add('change-password-input-background-color-error');
        }

        var regex_symbols = /[-!¡$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/;

        const hasSpecialCharactersRE = new RegExp(regex_symbols);

        const hasSpecialCharacters = hasSpecialCharactersRE.test(inputElement.value);

        if (hasSpecialCharacters) {
          if (isValidable) changePasswordError3.style.display = 'none';
          inputElement.classList.remove('change-password-input-background-color-error');
          inputElement.classList.add('change-password-input-background-color');
        } else {
          if (isValidable) changePasswordError3.style.display = 'flex';
          isFormValid = false;
          inputElement.classList.remove('change-password-input-background-color');
          inputElement.classList.add('change-password-input-background-color-error');
        }

        const hasAtLeastOnedigitRE = new RegExp('^(?=.*?[0-9])');

        const hasAtLeastOnedigit = hasAtLeastOnedigitRE.test(inputElement.value);

        if (hasAtLeastOnedigit) {
          if (isValidable) changePasswordError4.style.display = 'none';
          inputElement.classList.remove('change-password-input-background-color-error');
          inputElement.classList.add('change-password-input-background-color');
        } else {
          if (isValidable) changePasswordError4.style.display = 'flex';
          isFormValid = false;
          inputElement.classList.remove('change-password-input-background-color');
          inputElement.classList.add('change-password-input-background-color-error');
        }

        let doesHaveTwoConsecutiveCharacters = false;

        if (inputElement.value.length > 0) {
          let currentCharacter = inputElement.value[0];
          for (let i = 1; i <= inputElement.value.length - 1; i++) {
            if (currentCharacter == inputElement.value[i]) {
              doesHaveTwoConsecutiveCharacters = true;
            }
            currentCharacter = inputElement.value[i];
          }
        }

        if (!doesHaveTwoConsecutiveCharacters) {
          if (isValidable) changePasswordError5.style.display = 'none';
          inputElement.classList.remove('change-password-input-background-color-error');
          inputElement.classList.add('change-password-input-background-color');
        } else {
          if (isValidable) changePasswordError5.style.display = 'flex';
          isFormValid = false;
          inputElement.classList.remove('change-password-input-background-color');
          inputElement.classList.add('change-password-input-background-color-error');
        }

        const password = this.shadowRoot.getElementById('inputChangePass');
        const confirmPassword = this.shadowRoot.getElementById('inputChangePassRepeat');
        if (password.value === confirmPassword.value && password.value !== '') {
          changePasswordError6.style.display = 'none';
          inputElement.classList.remove('change-password-input-background-color-error');
          inputElement.classList.add('change-password-input-background-color');
        } else {
          changePasswordError6.style.display = 'flex';
          isFormValid = false;
          inputElement.classList.remove('change-password-input-background-color');
          inputElement.classList.add('change-password-input-background-color-error');
        }
      });
    };

    addEyeIcon('eyeButton', 'inputChangePass', true);
    addEyeIcon('eyeButtonRepeat', 'inputChangePassRepeat', false);

    /****** Shadow DOM Elements  ********/
    this.root = this.shadowRoot.querySelector('[data-state]');
    this.loginButton = this.shadowRoot.getElementById('customGoogleSSO'); // TODO: to be deprecated
    this.titleText = this.shadowRoot.getElementById('titleText');
    this.descriptionText = this.shadowRoot.getElementById('descriptionText');
    this.textPrompt = this.shadowRoot.getElementById('textPrompt');
    this.buttonText = this.shadowRoot.getElementById('buttonText');
    this.message = this.shadowRoot.getElementById('message');
    this.errorMessages = this.shadowRoot.getElementById('errorMessages');
    this.errorMessages2 = this.shadowRoot.getElementById('errorMessages2');
    this.errorMessagesCreatePassword = this.shadowRoot.getElementById('errorMessagesCreatePassword');
    this.errorMessages2CreatePassword = this.shadowRoot.getElementById('errorMessages2CreatePassword');    
    this.errorMessages3 = this.shadowRoot.getElementById('errorMessages3');
    this.errorMessages4 = this.shadowRoot.getElementById('errorMessages4');
    this.createDescriptionText = this.shadowRoot.getElementById('createDescriptionText');
    // FROMS
    this.loginForm = this.shadowRoot.getElementById('loginForm');
    this.resetForm = this.shadowRoot.getElementById('resetForm');
    this.changeForm = this.shadowRoot.getElementById('changeForm');
    this.createForm = this.shadowRoot.getElementById('createForm');
    // BUTTONS
    this.signInBtn = this.shadowRoot.getElementById('signIn');
    this.createPassBtn = this.shadowRoot.getElementById('createPass');
    this.forgotBtn = this.shadowRoot.getElementById('forgot');
    this.returnBtn = this.shadowRoot.getElementById('return');
    this.resetButtonsGroup = this.shadowRoot.getElementById('resetButtonsGroup');
    this.createButtonsGroup = this.shadowRoot.getElementById('createButtonsGroup');
    this.resetSubmitBtn = this.shadowRoot.getElementById('resetSubmit');
    this.createSubmitBtn = this.shadowRoot.getElementById('createSubmit');
    this.returnFromChangeBtn = this.shadowRoot.getElementById('returnFromChange');
    this.submitChangeBtn = this.shadowRoot.getElementById('submitChange');
    this.backToSignIn = this.shadowRoot.getElementById('backToSignIn');
    this.backToSignInFromReset = this.shadowRoot.getElementById('backToSignInFromReset');
    this.backToSignInFromCreate = this.shadowRoot.getElementById('backToSignInFromCreate');
    this.changeBtn = this.shadowRoot.getElementById('change');
    this.changePasswordRulesWindow = this.shadowRoot.getElementById('changePasswordRules');
    this.changeSuccessful = this.shadowRoot.getElementById('changeSuccessful');
    this.changeSuccessful.style.display = 'none';
    this.resendEmailButton = this.shadowRoot.getElementById('resendEmail');
    this.closeButton = this.shadowRoot.getElementById('closeButton');
    this.closeButtonCreatePassword = this.shadowRoot.getElementById('closeButtonCreatePassword');
    this.closeButton2 = this.shadowRoot.getElementById('closeButton2');
    // INPUTS
    this.inputLoginEmail = this.shadowRoot.getElementById('inputLoginEmail');
    this.inputLoginPass = this.shadowRoot.getElementById('inputLoginPass');
    this.inputReset = this.shadowRoot.getElementById('inputReset');
    this.inputCreate = this.shadowRoot.getElementById('inputCreate');
    this.inputChangePass = this.shadowRoot.getElementById('inputChangePass');
    this.inputChangePassRepeat = this.shadowRoot.getElementById('inputChangePassRepeat');

    this.changePassToken = new URL(window.location).searchParams.get('change-password-token');
    this.createPassToken = new URL(window.location).searchParams.get('create-password-token')

    //TODO: isNewLogin flag will be deprecated along google button
    this.isNewLogin = 'true'; //new URL(window.location).searchParams.get('new-login')

    if (this.isNewLogin === 'true') {
      console.log('%cNEW LOGIN (experimental)', 'font-weight: bold; background: yellowgreen; color: black');

      if (!!this.changePassToken || !!this.createPassToken) {
        this.state.currentState = states.changePass.idle;
        //GET TOKENS AND NEEDED DATA ()
        /*
           change pass token (jwt?) with email info and authorization to change
           passed as query param?
   
           from my understanding...
           email should be opaque from client view as it is data with expiration date that backend
           understands looking at the DB
   
           */
      }
    } else {
      // TODO: to be deprecated... remove this entire block and the flag functionality
      console.log(
        '%cMIDNIGHT DEPRECATION WARNING:',
        'font-weight: bold; background: yellow; color: black',
        '\nThe Google SignIn button will stop working soon',
        '\nPlease switch to new logIn feature or implement your own using the slot'
      );

      const logBtn = document.createElement('div');
      logBtn.id = 'GSIButton';
      logBtn.slot = 'loginSlot';
      logBtn.style = 'display: flex; justify-content: center';
    }

    this.inputLoginPass.addEventListener('keyup', () => {
      const containerError = this.shadowRoot.getElementById('loginUserError1');
      this._checkInputsForm({
        containerError,
        buttonSubmit: this.signInBtn,
        inputPass: this.inputLoginPass,
        inputEmail: this.inputLoginEmail,
      });
    });

    this.inputCreate.addEventListener('keyup', () => {
      const containerError = this.shadowRoot.getElementById('createPassError');
      this._checkInputsForm({ containerError, buttonSubmit: this.createSubmitBtn, inputEmail: this.inputCreate });
    });

    this.inputLoginEmail.addEventListener('keyup', () => {
      const containerError = this.shadowRoot.getElementById('loginUserError1');
      this._checkInputsForm({
        containerError,
        buttonSubmit: this.signInBtn,
        inputPass: this.inputLoginPass,
        inputEmail: this.inputLoginEmail,
      });
    });

    this.inputReset.addEventListener('change', () => {
      const containerError = this.shadowRoot.getElementById('emailFormatErrorForgot');
      this._checkInputsForm({
        containerError,
        buttonSubmit: this.resetSubmitBtn,
        inputPass: "",
        inputEmail: this.inputReset,
      });
    });

    this.closeButton.addEventListener('click', () => {
      this.state.currentState = states.default.idle;
    });

    this.closeButton2.addEventListener('click', () => {
      this.state.currentState = states.forgotPass.idle;
    });
    this.closeButtonCreatePassword.addEventListener('click', () => {
      this.state.currentState = states.createPass.idle
    });

    //aca

    // Invoked when the custom element is first connected to the document's DOM.

    // SUBMIT EVENTS
    this.loginForm.addEventListener('submit', this._logIn, { signal: this.controller.signal });
    this.resetForm.addEventListener('submit', this._resetSubmit, { signal: this.controller.signal });
    this.changeForm.addEventListener('submit', this._changeSubmit, { signal: this.controller.signal });
    this.createForm.addEventListener('submit', this._createSubmit, { signal: this.controller.signal });

    // NAV EVENTS
    this.forgotBtn.addEventListener(
      'click',
      () => {
        this.state.currentState = states.forgotPass.idle;
        this.resetSubmitBtn.disabled = true;
      },
      { signal: this.controller.signal }
    );

    this.createPassBtn.addEventListener(
      'click',
      () => {
        this.state.currentState = states.createPass.idle;
      },
      { signal: this.controller.signal }
    );

    this.returnBtn.addEventListener(
      'click',
      () => {
        this.state.currentState = states.default.idle;
        this.signInBtn.disabled = true;
      },
      { signal: this.controller.signal }
    );

    this.returnFromChangeBtn.addEventListener(
      'click',
      () => {
        this.state.currentState = states.default.idle;
      },
      { signal: this.controller.signal }
    );

    this.backToSignIn.addEventListener(
      'click',
      () => {
        this.state.currentState = states.default.idle;
        window.history.pushState(
          {},
          document.title,
          '/' + window.location.href.substring(window.location.href.lastIndexOf('/') + 1).split('?')[0]
        );
      },
      { signal: this.controller.signal }
    );

    this.resendEmailButton.addEventListener(
      'click',
      () => {
        const changeBody = JSON.stringify({
          Email: this.resetEmail,
        });

        const response = fetch('/api/admin/restore-password', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: changeBody,
        });
      },
      { signal: this.controller.signal }
    );

    this.backToSignInFromReset.addEventListener(
      'click',
      () => {
        window.location.href = window.location.href.substring(window.location.href.lastIndexOf('/') + 1).split('?')[0];
      },
      { signal: this.controller.signal }
    );

    this.backToSignInFromCreate.addEventListener(
      'click',
      () => {
        window.location.href = window.location.href.substring(window.location.href.lastIndexOf('/') + 1).split('?')[0];
      },
      { signal: this.controller.signal }
    );

    // TODO: to be deprecated
    if (!this.isNewLogin) {
      this.appendChild(this.loginBtnGSI);
    }
  }

  disconnectedCallback() {
    // Invoked when the custom element is disconnected from the document's DOM.
    this.controller.abort();
  }

  adoptedCallback() {
    // Invoked when the custom element is moved to a new document.
  }

  /* NOTE:
    for now just to keep it DRY
    It depends on name atribute on inputs to be the same as the one to be sumitted
  */
  _handleForm = async (form, step) => {
    const formData = new FormData(form);
    const validationResult = validator.validate(formData);
    let failed = !!validationResult.length;
    if (failed) {
      this.state.currentState = states[step].error;
      const firstMessage = errorMessages[step].split('.')[0];
      this.state.errorMessage = firstMessage;
      const secondMessage = errorMessages[step].split('.').slice(1)[0];
      this.state.errorMessage2 = secondMessage;
    } else {
      // CHANGE_ENDPOINT

      this.state.currentState = states[step].loading;
      try {
        if (step === 'resetEmail') {
          const email = form.inputReset.value;
          this.dispatchEvent(
            new CustomEvent('resetEmail', {
              detail: {
                user: { email },
              },
            })
          );
        } else if (step === 'changePass') {
          const changeBody = JSON.stringify({
            Token: this.changePassToken || this.createPassToken,
            Password: this.inputChangePass.value,
          });

          const response = await fetch('/api/user/change-password', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: changeBody,
          });

          if (response.status === 200) {
            this.changePasswordRulesWindow.style.display = 'none';
            this.changeSuccessful.style.display = 'block';
            this.state.currentState = states[step].success;
          } else {
            this.state.currentState = states[step].error;
          }
        } else if (step === 'forgotPass') {
          this.resetEmail = form.inputReset.value;
          const email = form.inputReset.value;

          const changeBody = JSON.stringify({
            Email: email,
          });

          const response = await fetch('/api/admin/restore-password', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: changeBody,
          });

          if (response.status === 200) {
            this.resetButtonsGroup.style.display = 'none';
            this.state.currentState = states[step].success;
          } else {
            this.state.currentState = states['forgotPass'].error;
            const firstMessage = errorMessages[step].split('/')[0];
            const secondMessage = errorMessages[step].split('/').slice(1)[0];
            this.state.errorMessage3 = firstMessage;
            this.state.errorMessage4 = secondMessage;
            this.state.currentState = states[step].error;
          }
        } else if (step === 'createPass') {
          this.resetEmail = form.inputCreate.value;
          const email = form.inputCreate.value;

          const changeBody = JSON.stringify({
            Email: email,
          });

          const response = await fetch('/api/admin/create-password', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: changeBody,
          });
          const errorState = states['createPass'].error;
          switch (response.status) {
            case 200:
              this.createButtonsGroup.style.display = 'none';
              this.createDescriptionText.style.display = 'none';
              this.state.currentState = states[step].success;
              break;
            case 404:
              this.state.currentState = errorState;
              this.state.errorMessagesCreatePassword = errorMessages[step].split('|')[0];
              this.state.errorMessages2CreatePassword = errorMessages[step].split('|')[1];
            break;
            case 400:
              this.state.currentState = errorState;
              this.state.errorMessagesCreatePassword = errorMessages[step].split('|')[2];
              this.state.errorMessages2CreatePassword = (await response.text())?.replaceAll('"','') || errorMessages[step].split('|')[1];
            break;
            default:
              this.state.currentState = errorState;
              this.state.errorMessagesCreatePassword = "Server Error";
              this.state.errorMessages2CreatePassword = "Error 500";
            break;
          }
        } else {
          // LOGIN
          const email = form.inputLoginEmail.value;
          const password = form.inputLoginPass.value;
          const body = { email, password };

          const response = await fetch('/api/user/login', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
          });

          if (response.status === 200) {
            const data = await response.json();
            this.dispatchEvent(
              new CustomEvent('success', {
                detail: {
                  user: { email, password, data },
                },
              })
            );
          } else {
            this.state.currentState = states['default'].error;
            let firstMessage = errorMessages[step].split('.')[0];
            let secondMessage = errorMessages[step].split('.').slice(1)[0];
            if (response.status === 404) {
              firstMessage = errorMessages.newUser.split('\n')[0];
              secondMessage = errorMessages.newUser.split('\n').slice(1)[0];
            }
            this.state.errorMessage = firstMessage;
            this.state.errorMessage2 = secondMessage;
          }
        }
      } catch (error) {
        this.state.currentState = states[step].error;
      }
    }
  };

  _logIn = (event) => {
    event.preventDefault();
    this._handleForm(event.target, 'default');
  };

  _resetSubmit = (event) => {
    event.preventDefault();
    this._handleForm(event.target, 'forgotPass');
  };

  _createSubmit = (event) => {
    event.preventDefault();
    this._handleForm(event.target, 'createPass');
  };

  _changeSubmit = (event) => {
    event.preventDefault();
    this._handleForm(event.target, 'changePass');
  };

  _resetEmail = (event) => {
    event.preventDefault();
    this._handleForm(event.target, 'resetEmail');
  };

  _isEmailValid = (email) => {
    const validEmailRE = new RegExp(/(?![0-9]+)[\w.-]+@cognizant.com$/i);
    const validEmail = validEmailRE.test(email);
    return validEmail;
  };

  _checkInputsForm = ({ containerError, buttonSubmit, inputPass, inputEmail }) => {
    if (this._isEmailValid(inputEmail.value)) {
      if (!inputPass) buttonSubmit.disabled = false;
      else if (inputPass.value.length > 5) {
        buttonSubmit.disabled = false;
      } else buttonSubmit.disabled = true;
      containerError.style.display = 'none';
      inputEmail.classList.remove('login-user-input-background-color-error');
    } else {
      buttonSubmit.disabled = true;
      containerError.style.display = 'flex';
      inputEmail.classList.add('login-user-input-background-color-error');
    }
  };

  _refresh() {
    /*
    More reactive UI (less listeners!)
    Find out how to make it more reactive... wanted to change form nodes, but found it hard to pass the mehthod reference to inputs with onclick on template literals

    Find out how to make it more reactive... wanted to change form nodes, but found it hard to pass the mehthod reference to inputs with onclick on template literals
      like this <button onclick="${this.methodName}">click me</button>
    Tried different sintaxes and only one that seems to work is by setting attributes independently before appending, like this
      newBtn.onclick = this.methodName,
    but that is tedious and kind of defeats the purpose, in that case adding event listeners is preferable
    */
    this.root.setAttribute('data-state', this.state.currentState);
    this.errorMessages.innerText = this.state.errorMessage;
    this.errorMessages2.innerText = this.state.errorMessage2;
    this.errorMessagesCreatePassword.innerText = this.state.errorMessagesCreatePassword;
    this.errorMessages2CreatePassword.innerText = this.state.errorMessages2CreatePassword;
    this.errorMessages3.innerText = this.state.errorMessage3;
    this.errorMessages4.innerText = this.state.errorMessage4;

    if (!/error|loading/.test(this.state.currentState)) {
      // clear inputs
      this.inputLoginEmail.value = '';
      this.inputLoginPass.value = '';
      this.inputChangePass.value = '';
      this.inputChangePassRepeat.value = '';
      this.inputReset.value = '';
      this.inputCreate.value = '';
    }
    if (/loading/.test(this.state.currentState)) {
      // disable interactions
      this.forgotBtn.setAttribute('disabled', true);
      this.returnBtn.setAttribute('disabled', true);
      this.returnFromChangeBtn.setAttribute('disabled', true);
      this.inputLoginEmail.setAttribute('disabled', true);
      this.inputLoginPass.setAttribute('disabled', true);
      this.inputChangePass.setAttribute('disabled', true);
      this.inputChangePassRepeat.setAttribute('disabled', true);
      this.inputReset.setAttribute('disabled', true);
      this.inputCreate.setAttribute('disabled', true);
      // submit buttons
      this.signInBtn.setAttribute('disabled', true);
      this.resetSubmitBtn.setAttribute('disabled', true);
      this.createSubmitBtn.setAttribute('disabled', true);
      this.submitChangeBtn.setAttribute('disabled', true);
      this.signInBtn.classList.add('spinner');
      this.resetSubmitBtn.classList.add('spinner');
      this.createSubmitBtn.classList.add('spinner');
      this.submitChangeBtn.classList.add('spinner');
    } else {
      this.signInBtn.removeAttribute('disabled');
      this.forgotBtn.removeAttribute('disabled');
      this.returnBtn.removeAttribute('disabled');
      this.returnFromChangeBtn.removeAttribute('disabled');
      this.resetSubmitBtn.removeAttribute('disabled');
      this.inputLoginEmail.removeAttribute('disabled');
      this.inputLoginPass.removeAttribute('disabled');
      this.inputChangePass.removeAttribute('disabled');
      this.inputChangePassRepeat.removeAttribute('disabled');
      this.inputReset.removeAttribute('disabled');
      this.inputCreate.removeAttribute('disabled');
      this.signInBtn.classList.remove('spinner');
      this.resetSubmitBtn.classList.remove('spinner');
      this.createSubmitBtn.classList.remove('spinner');
      this.submitChangeBtn.classList.remove('spinner');
    }
  }
}

export const init = () =>
  new Promise((resolve) => {
    customElements.define('cwc-login', CwcLogin);
  });
