import { Instance, types, applySnapshot, getParentOfType } from 'mobx-state-tree';
import { ResponsabilityItem } from './ResponsabilityItem';
import { TechnologyItem } from './TechnologyItem';
import { HistoriesStore } from '../HistoriesStore';
import { GeneratorHelper } from '../../shared/Helpers';

export const HistoryItem = types
  .model('HistoryItem', {
    id: types.identifierNumber,
    order: types.number,
    company: types.string,
    role: types.string,
    place: types.string,
    start: types.string,
    end: types.string,
    achievement: types.string,
    responsabilities: types.array(ResponsabilityItem),
    technologies: types.array(TechnologyItem),
    isShow: types.optional(types.boolean, false),
    selectedItem: types.optional(types.integer, 0),
    selectedResponsabilityItem: types.maybe(types.reference(ResponsabilityItem)),
    selectedTechnologyItem: types.maybe(types.reference(TechnologyItem)),
    showResponsabilityModal: types.optional(types.boolean, false),
    showTechnologyModal: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get sortedResponsabilities(): ResponsabilityItem[] {
      return self.responsabilities.slice().sort((a: ResponsabilityItem, b: ResponsabilityItem) => a.order - b.order);
    },
    get sortedResponsabilitiesForTable() {
      return this.sortedResponsabilities.map((x) => ({ key: x.id, ...x }));
    },
    get sortedTechnologies(): TechnologyItem[] {
      return self.technologies.slice().sort((a: TechnologyItem, b: TechnologyItem) => a.order - b.order);
    },
    get sortedTechnologiesForTable() {
      return this.sortedTechnologies.map((x) => ({ key: x.id, ...x }));
    },
  }))
  .actions((self) => ({
    openResponsabilityModal() {
      self.showResponsabilityModal = true;
    },
    closeResponsabilityModal() {
      self.showResponsabilityModal = false;
    },
    setSelectedResponsability(item?: ResponsabilityItem) {
      self.selectedResponsabilityItem = item;
    },
    addResponsability(name: string) {
      const item = ResponsabilityItem.create({
        id: GeneratorHelper.getNextId(self.responsabilities.slice()),
        name,
        historyId: self.id,
        order: GeneratorHelper.getNextOrder(self.responsabilities.slice()),
      });

      self.responsabilities.push(item);
    },
    updateResponsability(name: string) {
      const index = self.responsabilities.findIndex((x: ResponsabilityItem) => x === self.selectedResponsabilityItem);
      self.responsabilities[index].name = name;
    },
    addOrUpdateResponsability(name: string) {
      if (self.selectedResponsabilityItem) {
        this.updateResponsability(name);
      } else {
        this.addResponsability(name);
      }

      const history = getParentOfType(self, HistoriesStore);
      const itemsCopy = history!.items.slice();
      const index = history!.items.findIndex((x) => x.id === self.id);
      itemsCopy[index].responsabilities = self.responsabilities;

      applySnapshot(history!.items, itemsCopy as any);

      this.closeResponsabilityModal();
    },
    openTechnologyModal() {
      self.showTechnologyModal = true;
    },
    closeTechnologyModal() {
      self.showTechnologyModal = false;
    },
    setSelectedTechnology(item?: TechnologyItem) {
      self.selectedTechnologyItem = item;
    },
    addTechnology(name: string) {
      const item = TechnologyItem.create({
        id: GeneratorHelper.getNextId(self.technologies.slice()),
        name,
        order: GeneratorHelper.getNextOrder(self.technologies.slice()),
        historyId: self.id,
      });

      self.technologies.push(item);
    },
    updateTechnology(name: string) {
      const index = self.technologies.findIndex((x: TechnologyItem) => x === self.selectedTechnologyItem);
      self.technologies[index].name = name;
    },
    addOrUpdateTechnology(name: string) {
      if (self.selectedTechnologyItem) {
        this.updateTechnology(name);
      } else {
        this.addTechnology(name);
      }

      const history = getParentOfType(self, HistoriesStore);
      const itemsCopy = history!.items.slice();
      const index = history!.items.findIndex((x) => x.id === self.id);
      itemsCopy[index].technologies = self.technologies;

      applySnapshot(history!.items, itemsCopy as any);

      this.closeTechnologyModal();
    },
  }));

export interface HistoryItem extends Instance<typeof HistoryItem> {}
