import { Instance, types } from 'mobx-state-tree';

export const ProfileItem = types.model('ProfileItem', {
  id: types.identifierNumber,
  fullName: types.string,
  phone: types.maybeNull(types.string),
  email: types.string,
  communityId: types.number,
  positionId: types.number,
  additionalPositionId: types.number
});

export interface ProfileItem extends Instance<typeof ProfileItem> {}
