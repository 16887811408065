import React, {FC, useState} from 'react';
import {
  DownloadOutlined,
  FileWordOutlined,
  FileUnknownOutlined,
  FilePptOutlined
} from '@ant-design/icons';
import {Button, Tooltip} from 'antd';
import {SizeType} from 'antd/lib/config-provider/SizeContext';
import {ButtonType, ButtonShape} from 'antd/lib/button';
import {useRootStore} from '../root/RootStoreHook';
import CheckSectionModal from '../document/CheckSectionModal';
import './downloadMenu.css';

const DownloadMenu: FC = () => {
  const rootStore = useRootStore();
  const {profile, document: rootStoreDocument} = rootStore;
  const [shouldShowDownloadOptions, setShouldShowDownloadOptions] = useState(false);
  const [showModal, setModal] = useState<boolean>(false);
  const closeCheckSectionModal = () => {
    setModal(false);
    rootStoreDocument!.resetValues();
  };

  const getDownloadOptions = (size: SizeType, type: ButtonType, shape?: ButtonShape) => (
    <>
      <Tooltip placement="left" title="Download Document">
        <Button
          size={size}
          type={type}
          icon={<FileWordOutlined />}
          onClick={() => {
            if (shouldShowDownloadOptions) setShouldShowDownloadOptions(!shouldShowDownloadOptions);
            rootStoreDocument!.downloadDocument([profile!.id], 'docx', true);
          }}
          shape={shape}
        />
      </Tooltip>
      <Tooltip placement="left" title="Download Custom">
        <Button
          size={size}
          type={type}
          icon={<FileUnknownOutlined />}
          onClick={() => {
            if (shouldShowDownloadOptions) setShouldShowDownloadOptions(!shouldShowDownloadOptions);
            setModal(true);
          }}
          shape={shape}
        />
      </Tooltip>
      <Tooltip className="no-display" placement="left" title="Download Presentation">
        <a href={`/api/document/ppt/${profile!.id}`} download>
          <Button
            size={size}
            onClick={() => shouldShowDownloadOptions && setShouldShowDownloadOptions(!shouldShowDownloadOptions)}
            type={type}
            icon={<FilePptOutlined />}
            shape={shape}
          />
        </a>
      </Tooltip>
    </>
  );

  return (
    <>
      <div className="sidebar-download">
        <div className={shouldShowDownloadOptions ? 'show' : 'hide'}>
          {getDownloadOptions('middle', 'default', 'circle')}
        </div>
        <Button
          type="primary"
          shape="circle"
          size="large"
          onClick={() => setShouldShowDownloadOptions(!shouldShowDownloadOptions)}
        >
          <DownloadOutlined />
        </Button>
      </div>

      <div className="sidebar-downloads">
        <div>{getDownloadOptions('large', 'primary')}</div>
      </div>

      {showModal && (
        <CheckSectionModal
          profilesIds={[profile!.id]}
          visible={showModal}
          closeModal={() => closeCheckSectionModal()}
          downloadWithOptions={true}
        />
      )}
    </>
  );
};

export default DownloadMenu;