import { Instance, types, onSnapshot, flow } from 'mobx-state-tree';
import { getRootStore } from '../root/RootStoreUtils';
import { axiosInstance } from '../../http/agent';
import { GeneratorHelper } from '../shared/Helpers';

export const CertificationItem = types.model('CertificationItem', {
  id: types.identifierNumber,
  location: types.maybeNull(types.string),
  start: types.string,
  end: types.string,
  institution: types.string,
  career: types.string,
  order: types.number,
});

export interface CertificationItem extends Instance<typeof CertificationItem> {}

export const CertificationStore = types
  .model('CertificationStore', {
    items: types.array(CertificationItem),
    showModal: types.optional(types.boolean, false),
    selectedItem: types.maybe(types.reference(CertificationItem)),
  })
  .views((self) => ({
    get sortedItems(): CertificationItem[] {
      return self.items.slice().sort((a: CertificationItem, b: CertificationItem) => a.order - b.order);
    },
    get sortedItemsForTable() {
      return this.sortedItems.map((x: CertificationItem) => ({ key: x.id, ...x }));
    },
    get initialValues() {
      return {
        location: self.selectedItem?.location ?? '',
        start: self.selectedItem?.start ?? '',
        end: self.selectedItem?.end ?? '',
        career: self.selectedItem?.career ?? '',
        institution: self.selectedItem?.institution ?? '',
      };
    },
    get canContinue(): boolean {
      return true;
    },
    get dataToSubmit() {
      return {
        certificationItems: this.sortedItems.map((item, index) => ({
          career: item.career,
          institution: item.institution,
          location: item.location,
          start: item.start,
          end: item.end,
          order: index + 1,
        })),
      };
    },
  }))
  .actions((self) => ({
    openModal() {
      self.showModal = true;
    },
    closeModal() {
      self.showModal = false;
    },
    add(certificationItem: CertificationItem) {
      const item = CertificationItem.create({
        id: GeneratorHelper.getNextId(self.items.slice()),
        location: certificationItem.location,
        start: certificationItem.start,
        end: certificationItem.end,
        career: certificationItem.career,
        institution: certificationItem.institution,
        order: GeneratorHelper.getNextOrder(self.items.slice()),
      });

      self.items.push(item);
    },
    update(item: CertificationItem) {
      const index = self.items.findIndex((x: CertificationItem) => x === self.selectedItem);
      self.items[index].location = item.location;
      self.items[index].end = item.end;
      self.items[index].start = item.start;
      self.items[index].institution = item.institution;
      self.items[index].career = item.career;
    },
    delete(itemId: number) {
      const item = self.items.find((x: CertificationItem) => x.id === itemId);
      if (item) self.items.remove(item);
    },
    addOrUpdate(item: CertificationItem) {
      if (self.selectedItem) {
        this.update(item);
      } else {
        this.add(item);
      }
      this.closeModal();
    },
    save: flow(function* save(): any {
      const { profile, showLoading, hideLoading, setShouldReloadProfile } = getRootStore(self);
      const data = {
        profileId: profile!.id,
        ...self.dataToSubmit,
        ...profile!.education!.dataToSubmit,
      };
      showLoading();
      try {
        yield axiosInstance.put(`/education/${profile!.id}`, data);
        self.selectedItem = undefined;
        setShouldReloadProfile(false);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      } finally {
        hideLoading();
      }
    }),
    setSelectedItem(item?: CertificationItem) {
      self.selectedItem = item;
    },
    afterCreate() {
      const { setShouldReloadProfile } = getRootStore(self);
      onSnapshot(self.items, () => {
        setShouldReloadProfile(true);
      });
    },
  }));

export interface CertificationStore extends Instance<typeof CertificationStore> {}
