import React, { FC } from 'react';
import { Typography } from 'antd';
import { useObserver } from 'mobx-react-lite';
import { useRootStore } from '../root/RootStoreHook';
import CustomCard from '../shared/CustomCard';
import { Routes } from '../app/routing/Routes';
import rol, { isRol } from '../login/roles';
import './SummaryDisplay.css';

interface IProps {
  infoExtra?: any | undefined;
  sameId?: boolean | undefined;
}

const { Text } = Typography;

const SummaryDisplay: FC<IProps> = ({ infoExtra, sameId }) => {
  const { profile, user } = useRootStore();
  const userRole = user?.rol;
  const { summary, personalInfo } = profile!;

  return useObserver(() => (
    <CustomCard
      title="Summary"
      hasData={summary!.hasData}
      pathForEdit={Routes.Summary}
      subTitle={infoExtra}
      canEdit={isRol([rol.ADMIN, rol.USER], userRole) || sameId}
      noDataDescription="You didn’t add summary yet."
    >
      <>
        <p className='summary-name'>
          <Text strong>{`About ${personalInfo?.firstName} : `} </Text>
          {summary!.aboutMe}
        </p>
      </>
    </CustomCard>
  ));
};

export default SummaryDisplay;
