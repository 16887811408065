import React, { FC } from 'react';
import { Table, Space, Card } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { EditOutlined } from '@ant-design/icons';
import { useObserver } from 'mobx-react-lite';
import { useRootStore } from '../../root/RootStoreHook';
import { DropdownItem } from './DropdownOptionsStore';
import openNotification from '../../notifications/Notification';
import DeleteItemConfirm from '../../shared/DeleteItemConfirm';
import CustomAddRow from '../../shared/CustomAddRow';

interface IProps {
  option: string;
  dropdownItems: DropdownItem[];
}

const DropdownOptionsEntry: FC<IProps> = ({ option, dropdownItems }) => {
  const rootStore = useRootStore();
  const { dropdownOptions } = rootStore;

  const onDelete = async (item: DropdownItem) => {
    if (item.personalInfoIds.length > 0) {
      openNotification('Cannot delete this item', 'This item is currently asigned to another profile.', 'warning');
    } else {
      dropdownOptions!.deleteOption(option, item.id);
    }
  };

  const columns: ColumnProps<DropdownItem>[] = [
    {
      title: option,
      dataIndex: 'description',
      key: 'id',
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'right',
      render: (item: DropdownItem) => (
        <Space>
          <EditOutlined onClick={() => dropdownOptions!.openOptionModal(option, item.id)} />
          <DeleteItemConfirm onConfirm={() => onDelete(item)} />
        </Space>
      ),
    },
  ];

  return useObserver(() => {
    return (
      <Card>
        <CustomAddRow title={option} onCreate={() => dropdownOptions!.openOptionModal(option)} optionTitleStyle />
        <div className='card-container'>
          <Table columns={columns} dataSource={dropdownItems} showHeader={false} pagination={false} />
        </div>
      </Card>
    );
  });
};

export default DropdownOptionsEntry;
