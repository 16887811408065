import React, { FC, useEffect } from 'react';
import { useObserver } from 'mobx-react-lite';
import { Form, Row } from 'antd';
import CustomModal from '../../../shared/CustomModal';
import { CustomSelect } from '../../../forms/CustomSelect';
import { useRootStore } from '../../../root/RootStoreHook';
import { sortBy } from '../../../app/Utils';

interface IProps {
  visible: boolean;
  closeModal: () => void;
  refreshData: () => void;
  userData: { email: string; roleId: string };
}

const RolesModal: FC<IProps> = ({ visible, closeModal, userData, refreshData }) => {
  const [form] = Form.useForm();
  const { dropdownOptions } = useRootStore();
  const rootStore = useRootStore();

  useEffect(() => {
    form.resetFields();
  }, [userData, form]);

  const onSubmit = async () => {
    form
      .validateFields()
      .then(async (values: any) => {
        const { roleId } = values;
        const { email } = userData;
        await rootStore.user?.setUserState(
          email,
          [roleId],
          'Role was successfully changed',
          'An error ocurred while trying to change this role'
        );
        form.resetFields();
        closeModal();
        refreshData();
      })
      .catch(() => {});
  };

  const onCancel = () => {
    form.resetFields();
    closeModal();
  };

  return useObserver(() => (
    <CustomModal
      okText="Change roles"
      visible={visible}
      title="Change Roles"
      onCancel={onCancel}
      width="472px"
      onOk={onSubmit}
    >
      <div>
        <Row justify="center">
          <Form name="changeRoles" onFinish={onSubmit} layout="vertical" form={form} initialValues={userData}>
            <CustomSelect
              name="roleId"
              label="Role"
              options={dropdownOptions!.roleOptions.sort(sortBy('description'))}
              config={{
                min: undefined,
                max: undefined,
                required: true,
                email: true,
                whitespace: false,
              }}
            />
          </Form>
        </Row>
      </div>
    </CustomModal>
  ));
};
export default RolesModal;
