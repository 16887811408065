import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { Divider } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { EditOutlined, HolderOutlined } from '@ant-design/icons';
import { useRootStore } from '../root/RootStoreHook';
import DeleteItemConfirm from '../shared/DeleteItemConfirm';
import { ResponsabilityItem } from './models/ResponsabilityItem';
import { createBoldMarkup } from '../richtext/richtext';
import ResponsabilitiesModal from './modals/ResponsabilitiesModal';
import CustomDragableTable from '../shared/CustomDragableTable';
import CustomAddRow from '../shared/CustomAddRow';
import BoldColumnTitle from '../shared/BoldColumnTitle';

interface IProps {
  historyId: number;
}

const HistoryRowExpanded: FC<IProps> = ({ historyId }) => {
  const root = useRootStore();
  const { profile } = root;

  return useObserver(() => {
    const store = profile!.history!;
    const history = store.items.find((x) => x.id === historyId)!;

    const moveResponsability = (fromIndex: number, toIndex: number) => {
      root.dragAndDrop(fromIndex, toIndex, history.responsabilities);
    };

    const moveTechnology = (fromIndex: number, toIndex: number) => {
      root.dragAndDrop(fromIndex, toIndex, history.technologies);
    };

    const responsabilityColumns: ColumnProps<ResponsabilityItem>[] = [
      {
        title: <BoldColumnTitle title="Name" />,
        dataIndex: 'name',
        key: 'name',
        render: (text: string, record: ResponsabilityItem) => (
          // eslint-disable-next-line react/no-danger
          <div dangerouslySetInnerHTML={createBoldMarkup(record.name)} />
        ),
      },
      {
        title: <BoldColumnTitle title="Actions" />,
        key: 'actions',
        align: 'right',
        render: (responsability: ResponsabilityItem) => (
          <div className="histories-collapsable-expanded-row-actions optional-vertical-divider">
            <EditOutlined onClick={() => store.editResponsability(history.id, responsability.id)} />
            <Divider type="vertical" />
            <DeleteItemConfirm onConfirm={() => store.deleteResponsability(history.id, responsability.id)} />
            <Divider type="vertical" />
            <HolderOutlined />
          </div>
        ),
      },
    ];

    return (
      <>
        <CustomAddRow title="Responsibilities" onCreate={() => store.createResponsability(history.id)} />
        <CustomDragableTable
          style={{}}
          draggableKey={`Responsability${history.id}`}
          columns={responsabilityColumns}
          dataSource={history.sortedResponsabilitiesForTable}
          moveRow={moveResponsability}
          showHeader={false}
          locale="You didn’t add any responsibilities for this job position."
        />
        {history.showResponsabilityModal && <ResponsabilitiesModal visible={history.showResponsabilityModal} />}
      </>
    );
  });
};

export default HistoryRowExpanded;
