const mockedLoginInitialState = {
  templates: [
    {
      id: 2,
      description: 'Current pptx 2022',
      address: 'CSV.CeveCreator.Web.Data.template.pptx',
      type: 'pptx',
      status: true,
      default: true,
    },
    {
      id: 0,
      description: 'Current docx 2022',
      address: 'CSV.CeveCreator.Web.Data.templateSimpleHistory.docx',
      type: 'docx',
      status: true,
      default: true,
    },
    {
      id: 1,
      description: 'Current docx 2022 Purple',
      address: 'CSV.CeveCreator.Web.Data.templatePurple.docx',
      type: 'docx',
      status: false,
      default: false,
    },
  ],
  dropdownOptions: {
    positionOptions: [
      {
        id: 2,
        description: 'QA Engineer',
        personalInfoIds: [],
      },
      {
        id: 4,
        description: 'R&D Manager',
        personalInfoIds: [],
      },
      {
        id: 5,
        description: 'Delivery Manager',
        personalInfoIds: [],
      },
      {
        id: 6,
        description: 'Delivery Director',
        personalInfoIds: [],
      },
      {
        id: 7,
        description: 'Scrum Master',
        personalInfoIds: [],
      },
      {
        id: 8,
        description: 'Business Analyst',
        personalInfoIds: [],
      },
      {
        id: 9,
        description: 'Software Architect',
        personalInfoIds: [],
      },
      {
        id: 10,
        description: 'Project Manager',
        personalInfoIds: [],
      },
      {
        id: 12,
        description: 'Lead Art Director',
        personalInfoIds: [],
      },
      {
        id: 13,
        description: 'Technical Director',
        personalInfoIds: [],
      },
      {
        id: 11,
        description: 'UX/UI Designer',
        personalInfoIds: [],
      },
      {
        id: 14,
        description: 'DevOps Engineer',
        personalInfoIds: [],
      },
      {
        id: 15,
        description: 'IT Recruiter',
        personalInfoIds: [],
      },
      {
        id: 16,
        description: 'HR Analyst',
        personalInfoIds: [],
      },
      {
        id: 19,
        description: 'Office Assistant',
        personalInfoIds: [],
      },
      {
        id: 20,
        description: 'Trainee',
        personalInfoIds: [],
      },
      {
        id: 1,
        description: 'Software Engineer',
        personalInfoIds: [],
      },
      {
        id: 18,
        description: 'Associate',
        personalInfoIds: [],
      },
      {
        id: 21,
        description: 'Product Owner',
        personalInfoIds: [],
      },
    ],
    studioOptions: [
      {
        id: 1,
        description: 'Buenos Aires',
      },
      {
        id: 2,
        description: 'Cordoba',
      },
    ],
    communityOptions: [
      {
        id: 2,
        description: 'Full Stack Web',
        personalInfoIds: [],
      },
      {
        id: 3,
        description: 'Quality Engineering',
        personalInfoIds: [],
      },
      {
        id: 4,
        description: 'Product Delivery',
        personalInfoIds: [],
      },
      {
        id: 5,
        description: 'UI/UX',
        personalInfoIds: [],
      },
      {
        id: 6,
        description: 'Enterprise Coffee',
        personalInfoIds: [],
      },
      {
        id: 7,
        description: 'DevOps',
        personalInfoIds: [],
      },
      {
        id: 8,
        description: 'HR',
        personalInfoIds: [],
      },
      {
        id: 9,
        description: 'Administration',
        personalInfoIds: [],
      },
      {
        id: 10,
        description: 'Mobile',
        personalInfoIds: [],
      },
      {
        id: 11,
        description: 'Marketing',
        personalInfoIds: [],
      },
      {
        id: 1,
        description: 'Enterprise .NET',
        personalInfoIds: [],
      },
    ],
    additionalPositionOptions: [
      {
        id: 2,
        description: 'Lead',
        personalInfoIds: [],
      },
      {
        id: 3,
        description: 'Consultant',
        personalInfoIds: [],
      },
      {
        id: 4,
        description: 'Interviewer',
        personalInfoIds: [],
      },
      {
        id: 5,
        description: 'Project Manager',
        personalInfoIds: [],
      },
      {
        id: 6,
        description: 'Trainee',
        personalInfoIds: [],
      },
    ],
    levelOptions: [
      {
        id: 2,
        description: 'Pre-intermediate',
        personalInfoIds: [],
      },
      {
        id: 4,
        description: 'Upper-intermediate',
        personalInfoIds: [],
      },
      {
        id: 5,
        description: 'Advanced',
        personalInfoIds: [],
      },
      {
        id: 6,
        description: 'Beginner',
        personalInfoIds: [],
      },
      {
        id: 7,
        description: 'Intermediate',
        personalInfoIds: [],
      },
    ],
    roleOptions: [
      {
        id: 1,
        description: 'Admin',
      },
      {
        id: 2,
        description: 'User',
      },
      {
        id: 3,
        description: 'Community Manager / Community Lead',
      },
      {
        id: 4,
        description: 'Delivery Manager / Delivery Director',
      },
    ],
  },

  user: {
    items: [],
    loading: false,
    searchText: ' ',
    searchEmailText: ' ',
    searchedColumn: '',
    pageSize: 10,
    selectedUsers: [],
    displayName: 'test.test',
    rol: 'Admin',
    id: 296,
    idProfile: 296,
    passwordReset: true,
    completed: [],
    email: 'test.test@softvision.com',
  },
  profile: {
    id: 296,
    userId: 296,
    completed: true,
    personalInfo: {
      firstName: 'test',
      lastName: 'test',
      positionId: 9,
      studioId: 1,
      communityId: null,
      communityPersonalInfoId: [],
      additionalPositionId: null,
      additionalPositionPersonalInfoId: [],
      email: 'test.test@softvision.com',
      phone: '2333333333',
      cognizantId: '2333333333',
      config: {
        firstName: {
          max: 50,
          required: true,
          email: false,
          whitespace: true,
        },
        lastName: {
          max: 50,
          required: true,
          email: false,
          whitespace: true,
        },
        positionId: {
          required: true,
          email: false,
          whitespace: true,
        },
        studioId: {
          required: true,
          email: false,
          whitespace: true,
        },
        communityId: {
          required: true,
          email: false,
          whitespace: true,
        },
        communityPersonalInfoId: {
          required: false,
          email: false,
          whitespace: true,
        },
        additionalPositionId: {
          required: false,
          email: false,
          whitespace: true,
        },
        additionalPositionPersonalInfoId: {
          required: false,
          email: false,
          whitespace: true,
        },
        email: {
          max: 50,
          required: true,
          email: false,
          whitespace: true,
        },
        phone: {
          min: 8,
          max: 30,
          required: false,
          email: false,
          whitespace: true,
        },
        cognizantId: {required:true}
      },
    },
    addPositionProfile: {
      items: [],
      showModal: false,
      minLength: 1,
      config: {
        description: {
          required: true,
          email: false,
          whitespace: true,
        },
      },
    },
    highlight: {
      items: [
        {
          id: 898,
          description: 'TEST',
          order: 1,
        },
      ],
      showModal: false,
      minLength: 1,
      config: {
        description: {
          required: true,
          email: false,
          whitespace: true,
        },
      },
    },
    skillSummary: {
      items: [],
      showModal: false,
    },
    skill: {
      items: [
        {
          id: 1297,
          skillName: 'TEST',
          skillLevel: 'Proficient',
          skillClassificationId: 1,
          skillCategoryId: 1,
          yearsExperience: 1,
          order: 1,
        },
      ],
      showModal: false,
      minLength: 1,
      config: {
        skillName: {
          required: true,
          email: false,
          whitespace: true,
        },
        skillLevel: {
          required: true,
          email: false,
          whitespace: true,
        },
        yearsExperience: {
          min: 1,
          max: 30,
          required: true,
          email: false,
          whitespace: true,
        },
        skillClassificationId: {
          required: true,
          email: false,
          whitespace: true,
        },
        skillCategoryId: {
          required: true,
          email: false,
          whitespace: true,
        },
      },
    },
    education: {
      items: [
        {
          id: 375,
          start: '2016/03',
          end: '2023/03',
          institution: 'Test',
          career: 'Ingenieria Test',
          order: 1,
        },
      ],
      showModal: false,
      minLength: 1,
    },
    certification: {
      items: [],
      showModal: false,
    },
    summary: {
      aboutMe: 'Test',
      config: {
        aboutMe: {
          max: 800,
          required: true,
          email: false,
          whitespace: true,
        },
      },
    },
    history: {
      items: [
        {
          id: 1624,
          order: 1,
          company: 'test',
          role: 'test',
          place: 'test',
          start: '2016/03',
          end: '2023/03',
          achievement: 'Test',
          responsabilities: [],
          technologies: [],
          isShow: false,
          selectedItem: 0,
          showResponsabilityModal: false,
          showTechnologyModal: false,
        },
      ],
      showModal: false,
      minLength: 1,
    },
  },
};

export default mockedLoginInitialState;
