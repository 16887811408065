import { Instance, types, flow } from 'mobx-state-tree';
import { axiosInstance } from '../../http/agent';
import { getRootStore } from '../root/RootStoreUtils';
import { SummaryConfig } from './SummaryConfig';

export const SummaryStore = types
  .model('SummaryStore', {
    aboutMe: types.string,
    config: types.optional(SummaryConfig, {
      aboutMe: { required: true, max: 800 },
    }),
  })
  .actions((self) => ({
    updateStore(form: any): void {
      self.aboutMe = form.aboutMe;
    },
  }))
  .views((self) => ({
    get hasData(): boolean {
      return self.aboutMe?.length > 0;
    },
    get formValues(): any {
      return {
        aboutMe: self.aboutMe,
      };
    },
    get dataToSubmit() {
      return {
        aboutMe: self.aboutMe,
      };
    },
  }))
  .actions((self) => ({
    save: flow(function* save(values: any): any {
      self.updateStore(values);
      const { showLoading, hideLoading, profile } = getRootStore(self);
      const data = {
        ...self.dataToSubmit,
        profileId: profile!.id,
      };

      showLoading();

      try {
        yield axiosInstance.put(`/summary/${profile!.id}`, data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      } finally {
        hideLoading();
      }
    }),
  }));

export interface SummaryStore extends Instance<typeof SummaryStore> { }
