import React, { FC, useRef, useLayoutEffect, useState } from 'react';

const radix = 10;

const getLines = (divHeight: string, lineHeight: string): number => {
  const numericDivHeight = parseInt(divHeight, radix);
  const numericLineHeight = parseInt(lineHeight, radix);
  const lines = numericDivHeight / numericLineHeight;
  return lines;
};

const Achievements: FC = ({ children }) => {
  const achivRef = useRef<HTMLDivElement>(null);
  const [showMore, setShowMore] = useState(true);
  const [moreElement, setMoreElement] = useState(false);

  const handleMore = () => {
    setMoreElement(!moreElement);
  };

  useLayoutEffect(() => {
    setShowMore(false);
    if (achivRef.current) {
      const style = window.getComputedStyle(achivRef.current, null);
      const divHeight = style.getPropertyValue('height');
      const lineHeight = style.getPropertyValue('line-height');
      const lines = getLines(divHeight, lineHeight);
      const overflow = lines > 2;
      setShowMore(overflow);
      setMoreElement(overflow);
    }
  }, [achivRef, children]);

  const newElement = (
    <>
      <p ref={achivRef} className={`achivText ${moreElement && 'ellipseText'}`}>
        {children}
      </p>
      {showMore && (
        <span role="button" tabIndex={0} onKeyDown={handleMore} onClick={handleMore} className="showMoreButton">
          {moreElement ? 'Show More' : 'Show Less'}
        </span>
      )}
    </>
  );

  return newElement;
};

export default Achievements;
