import React, { FC, useState } from 'react';
import { useObserver } from 'mobx-react-lite';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Divider, Card, Tooltip } from 'antd';
import { EditOutlined, HolderOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import { useRootStore } from '../root/RootStoreHook';
import DeleteItemConfirm from '../shared/DeleteItemConfirm';
import SkillsModal from './SkillsModal';
import CustomHeader from '../forms/CustomHeader';
import { SkillItem } from './models/SkillItem';
import CustomButtons from '../shared/CustomButtons';
import CustomAddRow from '../shared/CustomAddRow';
import CustomDragableTable from '../shared/CustomDragableTable';
import { Routes } from '../app/routing/Routes';
import { useRedirect } from '../hooks/HooksUtil';
import SkillSummaryEntry from './skillSummary/SkillSummaryEntry';
import { useWizardMode } from '../stepper/WizardMode';
import PossiblyEmptyField from '../shared/PossiblyEmptyField';
import BoldColumnTitle from '../shared/BoldColumnTitle';
import CustomPrompt from '../shared/CustomPrompt';
import './SkillsEntry.css';

interface IProps {
    id?: any;
}

const SkillsEntry: FC = () => {
    const root = useRootStore();
    const { profile, dropdownOptions } = root;
    const skill = profile!.skill!;
    const redirect = useRedirect();
    const { getLevelValue } = dropdownOptions!;
    const wizardMode = useWizardMode();
    const [formChanged, setFormChanged] = useState<boolean>(false);
    const { getSkillClassificationValue, getSkillCategoryValue } = dropdownOptions!;

    const handleDeleteItem: FC<IProps> = ({ id }) => {
        setFormChanged(true);
        skill.delete(id);
        return null;
    };

    const onSubmit = async () => {
        await skill.save();
        await profile!.skillSummary!.save();
        redirect.go(Routes.Profile);
    };

    const columns: ColumnProps<SkillItem>[] = [
        {
            title: <BoldColumnTitle title="Classification" />,
            dataIndex: 'skillClassificationId',
            key: 'skillClassificationId',
            render: (text: string, item: SkillItem) => <PossiblyEmptyField item={getSkillClassificationValue(item!.skillClassificationId!)} />,
        },
        {
            title: <BoldColumnTitle title="Skill" />,
            dataIndex: 'skillName',
            key: 'skillName',
            ellipsis: {
                showTitle: false,
            },
            render: data => (
                <Tooltip placement="topLeft" title={data}>
                    {data}
                </Tooltip>
            ),
        },
        {
            title: <BoldColumnTitle title="Category" />,
            dataIndex: 'skillCategoryId',
            key: 'skillCategoryId',
            render: (text: string, item: SkillItem) => <PossiblyEmptyField item={getSkillCategoryValue(item!.skillCategoryId!)} />,
        },
        {
            title: <BoldColumnTitle title="Level" />,
            dataIndex: 'skillLevel',
            key: 'skillLevel',
            render: (text: string, item: SkillItem) => <PossiblyEmptyField item={item.skillLevel.toString()} />,
        },
        {
            title: <BoldColumnTitle title="Years of Experience" />,
            dataIndex: 'yearsExperience',
            key: 'yearsExperience',
            render: (text: string, item: SkillItem) => <PossiblyEmptyField item={item.yearsExperience.toString()} />,
        },
        {
            title: <BoldColumnTitle title="Actions" />,
            key: 'actions',
            render: (item: SkillItem) => (
                <>
                    <EditOutlined onClick={() => skill.edit(item.id)} />
                    <Divider type="vertical" />
                    <DeleteItemConfirm onConfirm={() => handleDeleteItem({ id: item.id })} />
                    <Divider type="vertical" />
                    <HolderOutlined />
                </>
            ),
        },
    ];

    const moveRow = (fromIndex: number, toIndex: number) => {
        root.dragAndDrop(fromIndex, toIndex, skill!.items);
    };

    return useObserver(() => (
        <div
            className={wizardMode ? 'largeMaxWidth' : 'mediumMaxWidth'}
            onChange={() => setFormChanged(true)}
        >
            <DndProvider backend={HTML5Backend}>
                <SkillSummaryEntry />
                <br />
                <div className='skill-level-title-container flex-width-full'>
                    <CustomHeader title="Skills" />
                    <p>This section lists the technologies you have experience with, regarding classification, categorization and level of experience for each technology.</p>
                </div>
                <CustomPrompt when={formChanged} />
                <Card>
                    <CustomAddRow onCreate={() => skill.create()} id="skill-level-button" />
                    <CustomDragableTable
                        style={{}}
                        draggableKey="Skill"
                        columns={columns}
                        dataSource={skill!.sortedItemsForTable}
                        moveRow={moveRow}
                        className="table-alternative skills-table-labels"
                        locale="You didn't add any skill level yet."
                    />
                </Card>
                {skill.showModal && <SkillsModal visible={skill.showModal} />}
                <CustomButtons shouldReload={root.shouldReloadProfile} onSubmit={onSubmit} />
            </DndProvider>
        </div>
    ));
};

export default SkillsEntry;
