/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-case-declarations */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { Form, Input, Tooltip } from 'antd';
import { Rule } from 'antd/lib/form';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Config } from '../profile/models/Config';
import './forms.css';

interface IProps {
  config?: Config;
  label: string;
  disabled?: boolean;
  name: string;
  pattern?: RegExp;
  required?: boolean;
  toolTipText?: string;
  type?: 'textarea' | 'search';
  validateStatus?: '' | 'success' | 'warning' | 'error' | 'validating' | undefined;
  style?: {};
  onChange?: (evt?: any) => any;
  placeholder?: string;
  help?: string;
  maxLength?: number;
}

export const CustomInput: FC<IProps> = ({
  config,
  name,
  disabled,
  label,
  pattern,
  toolTipText,
  type,
  validateStatus,
  onChange,
  placeholder,
  help,
  maxLength
}) => {

  function getRules(): Rule[] {
    const rules: Rule[] = [];

    if (config) {
      const { required, min, max, email, whitespace} = config;
      rules.push({ required, message: 'This field is required' }, { max }, { min }, { whitespace });

      if (email) {
        rules.push({ type: 'email' });
      }
    }


    if (pattern) {
      rules.push({ pattern, message: 'Incorrect format' });
    }


    return rules;
  }

  function renderInput() {
    switch (type) {
      case 'textarea':
        const onChangeTextArea = (evt: any) => {
          if (onChange) {
            onChange(evt);
          }
        };
        return (
          <Input.TextArea
            autoSize={{ minRows: 2, maxRows: 4 }}
            onChange={onChangeTextArea}
            maxLength={config?.max}
            placeholder={placeholder}
          />
        );
      case 'search':
        return <Input.Search />;
      default:
        const onChangeInputText = (evt: any) => {
          if (onChange) {
            onChange(evt);
          }
        };
        return (
          <Input
            onChange={onChangeInputText}
                maxLength={maxLength || config?.max}
            disabled={disabled}
            placeholder={placeholder}
          />
        );
    }
  }

  function getLabel() {
    if (toolTipText) {
      return (
        <span>
          {label} &nbsp;
          <Tooltip title={toolTipText}>
            <QuestionCircleOutlined className='question-circle-outlined-tooltip'/>
          </Tooltip>
        </span>
      );
    }
    return label;
  }

  return useObserver(() => (
    <div className='form-item-container'>
      <Form.Item
        name={name}
        label={getLabel()}
        rules={getRules()}
        normalize={(value) => (name === 'email' ? value.replace(/\s+/g, '') : value)}
        validateStatus={validateStatus}
        help={help}
      >
        {renderInput()}
      </Form.Item>
    </div>
  ));
};
