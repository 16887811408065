
class Validator {
    /**
     * @param {object} rules rule object with key as name of input field in formData and array with required validators
     */
    constructor(rules) {
        this.rules = rules;
    }

    static required = (input) => !!(input.trim()) ? '' : 'required'

    static chars = (charsAmount) => (input) => !!(input.length >= charsAmount) ? '' : 'chars'
    
    static equals = (otherInput) => (input, form) => {
        const compareValue = form.get(otherInput);
        return input === compareValue ? '' : 'equals'
    };

    static email = (input) => {
        const test = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(input);
        return test ? '' : 'email'
    }
   
    

    /**
     * Validation public interface
     * @param {FormData} form   FormData with values to be validated
     * @returns {Array} with objects corresponding to the failed validations
     */
    validate(form) {

        let validationResult = [];

        for(let valuePairArray of form) {
            const [key, inputValue] = valuePairArray;
            const validators = this.rules[key] || [];
            const errors = validators.reduce((accum, currentValidator) => {
                const validated = currentValidator(inputValue, form);
                return validated ? [...accum, validated] : accum;
            }, []);
            if (!!errors.length) {
                validationResult.push({[key]: errors})
            }
        }

        return validationResult;
    }
}

export default Validator;
