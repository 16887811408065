import React, { FC, useState, useRef, useEffect } from 'react';
import { useObserver } from 'mobx-react-lite';
import { Table, Divider, Collapse, Empty } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useRootStore } from '../root/RootStoreHook';
import DeleteItemConfirm from '../shared/DeleteItemConfirm';
import CustomHeader from '../forms/CustomHeader';
import Achievements from './Achievements';
import HistoriesModal from './HistoriesModal';
import { HistoryItem } from './models/HistoryItem';
import CustomButtons from '../shared/CustomButtons';
import CustomAddRow from '../shared/CustomAddRow';
import HistoryRowExpanded from './HistoryRowExpanded';
import { Routes } from '../app/routing/Routes';
import { useRedirect } from '../hooks/HooksUtil';
import PossiblyEmptyField from '../shared/PossiblyEmptyField';
import BoldColumnTitle from '../shared/BoldColumnTitle';
import './histories.css';
import CustomPrompt from '../shared/CustomPrompt';

interface IProps {
  id?: any;
}

const { Panel } = Collapse;

const multiRowRender = ( value:any ) =>
  <Achievements>
    {value}
  </Achievements>
;

const HistoriesEntry: FC = () => {
  const rootStore = useRootStore();
  const { profile } = rootStore;
  const redirect = useRedirect();
  const history = profile!.history!;
  const [formChanged, setFormChanged] = useState<boolean>(false);

  const handleDeleteItem: FC<IProps> = ({ id }) => {
    setFormChanged(true);
    history.delete(id);
    return null;
  };

  const columns = [
    {
      title: <BoldColumnTitle title="Company" />,
      dataIndex: 'company',
      key: 'company',
    },
    {
      title: <BoldColumnTitle title="Role" />,
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: <BoldColumnTitle title="Start" />,
      dataIndex: 'start',
      key: 'start',
    },
    {
      title: <BoldColumnTitle title="End" />,
      dataIndex: 'end',
      key: 'end',
    },
    {
      title: <BoldColumnTitle title="Location" />,
      dataIndex: 'place',
      key: 'place',
      render: (text: string, item: HistoryItem) => <PossiblyEmptyField item={item.place} />,
    },
    {
      title: <BoldColumnTitle title="Achievement" />,
      dataIndex: 'achievement',
      key: 'achievement',
      className: 'achievement-column',
      render: multiRowRender
    },
    {
      title: <BoldColumnTitle title="Actions" />,
      key: 'action',
      render: (historyItem: HistoryItem) => (
        <>
          <EditOutlined key={`edit-history-${historyItem.id}`} onClick={() => history.edit(historyItem.id)} />
          <Divider type="vertical" />
          <DeleteItemConfirm
            key={`delete-history-${historyItem.id}`}
            onConfirm={() => handleDeleteItem({id:historyItem.id})}
          />
        </>
      ),
    },
  ];

  const onSubmit = async () => {
    await history.save();
    redirect.go(Routes.Profile);
  };
  
  const handleCancel = () => {
    setFormChanged(false);
  };

  const historiesDescription = 'Tell us about your work experience. After adding it, you will be able to include responsibilities and technologies to your history, by expanding its menu.';

  return useObserver(() => (
    <div 
      className='summary-display-container'
      onChange={()=>setFormChanged(true)}
    >
      <div className='header-container'>
        <CustomHeader title="Work Experience" />
        <p className='header-paragraph'>{historiesDescription}</p>
      </div>
      <CustomPrompt when={formChanged} />
      <CustomAddRow onCreate={history.create} />
      <div className="histories-large-table">
        <Table
          columns={columns}
          dataSource={history!.sortedItemsForTable}
          expandedRowRender={(historyItem: HistoryItem) => <HistoryRowExpanded historyId={historyItem.id} />}
          pagination={false}
          scroll={{ y: 400 }}
          locale={{ emptyText: <Empty description="You didn't add any Work Experience yet." image={Empty.PRESENTED_IMAGE_SIMPLE} /> }}
        />
      </div>

      <div className="histories-small-table">
        <Collapse accordion defaultActiveKey={history!.sortedItemsForTable[0]?.company} bordered={false}>
          {history!.sortedItemsForTable.map((item) => {
            const name = `${item.company}${item.role && ` (${item.role})`}`;
            return (
              <Panel header={name} key={name}>
                <div className="histories-collapse-panel">
                  <Table
                    className="table-alternative histories-table-labels"
                    columns={columns.filter((column) => column.key !== 'company' && column.key !== 'role')}
                    dataSource={[item]}
                    pagination={false}
                    locale={{ emptyText: <Empty description="You didn't add any work experience yet." image={Empty.PRESENTED_IMAGE_SIMPLE} /> }}
                  />
                  <div className="histories-collapse-expanded-row">
                    <HistoryRowExpanded historyId={item.id} />
                  </div>
                </div>
              </Panel>
            );
          })}
        </Collapse>
      </div>
      {history.showModal && <HistoriesModal visible={history.showModal} onCancel={handleCancel} />}
      <CustomButtons shouldReload={rootStore.shouldReloadProfile} onSubmit={onSubmit} />
    </div>
  ));
};

export default HistoriesEntry;
