import React from 'react';
import { Modal, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const showErrorModal = (data: { Title: string; Errors: any , onOk?: Function }) => {
  function getContent(errors: any) {
    return (
      <>
        {Object.values(errors)
          .flat()
          .map((error, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index}>
              <ExclamationCircleOutlined /> <Typography.Text type="danger">{error}</Typography.Text>
            </div>
          ))}
      </>
    );
  }
  const {onOk} =data
  if(onOk){
    Modal.error({
      title: data.Title,
      content: data.Errors,
      onOk: ()=>{
        onOk()
      }
    });
  }else{
    Modal.error({
      title: data.Title,
      content: getContent(data.Errors),
    });
  }
};

export default showErrorModal;
