/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { FC, useState } from 'react';
import { useObserver } from 'mobx-react-lite';
import { Divider, Card } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { EditOutlined, HolderOutlined } from '@ant-design/icons';
import { SkillSummaryItem } from './SkillSummaryItem';
import { useRootStore } from '../../root/RootStoreHook';
import CustomHeader from '../../forms/CustomHeader';
import DeleteItemConfirm from '../../shared/DeleteItemConfirm';
import CustomAddRow from '../../shared/CustomAddRow';
import CustomDragableTable from '../../shared/CustomDragableTable';
import SkillSummaryModal from './SkillSummaryModal';
import CustomPrompt from '../../shared/CustomPrompt';
import './SkillSummaryEntry.css';

interface IProps {
  id?: any;
}

const SkillSummaryEntry: FC = () => {
  const root = useRootStore();
  const { skillSummary } = root.profile!;
  const [formChanged, setFormChanged] = useState<boolean>(false);

  const handleDeleteItem: FC<IProps> = ({ id }) => {
    setFormChanged(true);
    skillSummary?.delete(id);
    return null;
  };

  const columns: ColumnProps<SkillSummaryItem>[] = [
    {
      title: 'Group',
      dataIndex: 'skill',
      key: 'skill',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (item: SkillSummaryItem) => (
        <>
          <EditOutlined onClick={() => skillSummary!.edit(item.id)} />
          <Divider type="vertical" />
          <DeleteItemConfirm onConfirm={() => handleDeleteItem({ id: item.id })} />
          <Divider type="vertical" />
          <HolderOutlined />
        </>
      ),
    },
  ];

  const moveRow = (fromIndex: number, toIndex: number) => {
    root.dragAndDrop(fromIndex, toIndex, skillSummary!.items);
  };

  const skillSummaryText =
    'This section lists the technologies you have experience with. Organize them into groups: e.g. .Net (C#, C++, ASP.Net, .Net Core, VB.Net, MVC), Prototyping (Figma, Sketch, Adobe XD, InVision).';

  return useObserver(() => (
    <>
      <div
        className='skill-summary-container flex-column'
        onChange={() => setFormChanged(true)}
      >
        <CustomHeader title="Skill Summary" />
        <p className='skill-summary-text'>{skillSummaryText}</p>
      </div>
      <CustomPrompt when={formChanged} />
      <Card>
        <CustomAddRow onCreate={() => skillSummary!.create()} id="summary-button"/>
        <CustomDragableTable
          style={{}}
          draggableKey="SkillSummary"
          columns={columns}
          dataSource={skillSummary!.sortedItemsForTable}
          moveRow={moveRow}
          className="table-alternative skill-summary-table-labels"
          locale="You didn’t add any skills yet."
        />
      </Card>
      {skillSummary!.showModal && <SkillSummaryModal visible={skillSummary!.showModal} />}
    </>
  ));
};

export default SkillSummaryEntry;
