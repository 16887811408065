import React, { FC, ReactNode } from 'react';
import { Row, Col } from 'antd';

interface IProps {
  first: ReactNode;
  second: ReactNode;
  third: ReactNode;
}
const ThreeColumnsRow: FC<IProps> = ({ first, second, third }) => (
  <Row justify="space-around" gutter={[16, 8]}>
    <Col span={8}>{first}</Col>
    <Col span={8}>{second}</Col>
    <Col span={8}>{third}</Col>
  </Row>
);

export default ThreeColumnsRow;
