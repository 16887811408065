/* eslint-disable react/button-has-type */
/* eslint-disable prefer-promise-reject-errors */
import { MessageOutlined, LoadingOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { Spin } from 'antd';

import './ModalFeedback.css';

export const ModalFeedback = () => {

  const initialFeedback = { message : '' };
  const initialResultMessage = {
    message : '',
    ok: false
  };

  const [showContainer, setShowContainer] = useState(false);
  const [feedback, setFeedback] = useState(initialFeedback);
  const [resultMessage, setResultMessage] = useState(initialResultMessage);
  const [loading, setShowLoading] = useState(false);


  const handleShowContainer = () => {
    setShowContainer(!showContainer);
  };
  const handleCloseModal = () => {
    setShowContainer( false );
    setFeedback(initialFeedback);
    setResultMessage({ ...resultMessage, message:'' });
  };
  const handleTextAreaMessage = (event: { currentTarget: { value: any }}) => {
    setFeedback({ ...feedback, message : event.currentTarget.value });
  };

  const handleSend =  async ()=> {
    const sendMessage = (mge: string) : Promise<string> => {
      console.log(mge);
      // TODO: Here to apply logic to send message to back-end side.
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          
          return Math.random() < 0.5 
            ? resolve('Thanks for you comment :)')
            : reject('Sorry, there was an error in our services :(');

        }, 3000);
      });
    };

    try {
      setResultMessage({ ...resultMessage, message:'' });
      setShowLoading(true);
      const result = await sendMessage(feedback.message);
      setFeedback(initialFeedback);
      setResultMessage({
        message : result,
        ok: true
      });
    } catch (error) {
      setResultMessage({
        message : error as string,
        ok: false
      });
    } finally {
      setShowLoading(false);
    }

  };


  const antIcon = <LoadingOutlined className='loading-outlined' spin />;

  return (
    <div className={`modalFeedback__container ${showContainer ? '-show' : ''}`}>
      <button 
        className='button__feedback'   
        onClick={handleShowContainer}   
      >
        Feedback<MessageOutlined 
          className='message-outlined'
        />
      </button>
      <section className='modalFeedback__section'>
        { loading && (
          <div className='loading__container'>
            <Spin indicator={antIcon} />
          </div>
        ) }        
        <div className='modalFeedback__section-header'>
          <span><strong>Help us improve!</strong></span>
          <button
            className='button__close'
            onClick={handleCloseModal}
          >X
          </button>
        </div>
        <hr className='modalFeedback__section-hr'/>
        <div className='modalFeedback__section-content'>
          <span>Leave your comments below</span>
          <textarea 
            maxLength={100}
            placeholder='Enter text here...'
            onChange={handleTextAreaMessage}
            value={feedback.message}
          />
        </div>
        <div className='modalFeedback__section-footer'>
          <span className={resultMessage.ok ? 'success' : 'error'}>
            <small><strong>{ resultMessage.message }</strong></small>
          </span>
          <button
            className={feedback.message.trim().length > 0 
              ? 'button__send' 
              : 'button__send disabled'}
            onClick={handleSend}
          >Send
          </button>
        </div>
             
        
      </section>
    </div>
  );
};
         