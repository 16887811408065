/* eslint-disable react/no-danger */
import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { HighlightItem } from './models/HighlightItem';
import { useRootStore } from '../root/RootStoreHook';
import CustomCard from '../shared/CustomCard';
import { createBoldMarkup } from '../richtext/richtext';
import { Routes } from '../app/routing/Routes';
import rol, { isRol } from '../login/roles';
import './HighlightsDisplay.css';

interface IProps {
  infoExtra?: any | undefined;
  sameId?:boolean | undefined;
}
const HighlightsDisplay: FC<IProps> = ({ infoExtra, sameId }) => {
  const { profile, user } = useRootStore();
  const userRole = user?.rol;
  const { highlight } = profile!;

  return useObserver(() => (
    <CustomCard
      title="Experience Highlights"
      hasData={highlight!.items.length > 0}
      pathForEdit={Routes.Highlights}
      subTitle={infoExtra}
      canEdit={isRol([rol.ADMIN, rol.USER], userRole) || sameId}
      noDataDescription="You didn’t add any highlights yet."
    >
      <ul>
        {highlight!.sortedItems.map((item: HighlightItem) => (
          <li
            className='highlight-item'
            key={item.id}
            dangerouslySetInnerHTML={createBoldMarkup(item.description)}
          />
        ))}
      </ul>
    </CustomCard>
  ));
};

export default HighlightsDisplay;
