import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import PptxEntry from './PptxEntry';
import DocxEntry from './DocxEntry';
import './TemplateDisplay.css'

const TemplateDisplay: FC = () => {
  return useObserver(() => {
    return (
      <div className="largeMaxWidth custom-header-item">
        <div className='container-grid'>
          <DocxEntry />
          <PptxEntry />
        </div>
      </div>
    );
  });
};

export default TemplateDisplay;
