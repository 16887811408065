import { Instance, types, flow, onSnapshot } from 'mobx-state-tree';
import { axiosInstance } from '../../http/agent';
import { getRootStore } from '../root/RootStoreUtils';
import { LevelHelper } from '../forms/custom-level-slider/Level';
import { SkillsConfig } from './SkillsConfig';
import { GeneratorHelper } from '../shared/Helpers';
import { SkillItem } from './models/SkillItem';
import { textConfig } from 'components/profile/models/Config';

export const SkillsStore = types
    .model('SkillsStore', {
        items: types.array(SkillItem),
        showModal: types.optional(types.boolean, false),
        minLength: types.optional(types.number, 1),
        selectedItem: types.maybe(types.reference(SkillItem)),
        config: types.optional(SkillsConfig, {
            skillName: textConfig(80),
            skillLevel: { required: true },
            yearsExperience: { min: 1, max: 30, required: true },
            skillClassificationId: { required: true },
            skillCategoryId: { required: true },
        }),
    })
    .views((self) => ({
        get validationMessage(): string {
            return `At least ${self.minLength} Skill is required.`;
        },
        get initialValues() {
            return {
                skillName: self.selectedItem?.skillName ?? '',
                skillLevel: self.selectedItem ? LevelHelper.getLevelKey(self.selectedItem.skillLevel) : 0,
                yearsExperience: self.selectedItem?.yearsExperience ?? 1,
                skillClassificationId: self.selectedItem?.skillClassificationId ?? null ,
                skillCategoryId: self.selectedItem?.skillCategoryId ?? null, 
            };
        },
        get canContinue(): boolean {
            return self.items.length > 0;
        },
        get sortedItems(): SkillItem[] {
            return self.items.slice().sort((a: SkillItem, b: SkillItem) => a.order - b.order);
        },
        get sortedItemsForTable() {
            return this.sortedItems.map((x: SkillItem) => ({ key: x.id, ...x }));
        },
        get dataToSubmit() {
            return {
                items: this.sortedItems.map((item, index) => ({
                    skillName: item.skillName,
                    skillLevel: item.skillLevel,
                    yearsExperience: item.yearsExperience,
                    skillClassificationId: item.skillClassificationId,
                    skillCategoryId: item.skillCategoryId,
                    order: index + 1,
                })),
            };
        },
    }))
    .actions((self) => ({
        save: flow(function* save(): any {
            const { profile, showLoading, hideLoading, setShouldReloadProfile } = getRootStore(self);
            const data = {
                profileId: profile!.id,
                ...self.dataToSubmit,
            };
            showLoading();
            try {
                yield axiosInstance.put(`/skill/${profile!.id}`, data);
                self.selectedItem = undefined;
                setShouldReloadProfile(false);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
            } finally {
                hideLoading();
            }
        }),
        openModal() {
            self.showModal = true;
        },
        closeModal() {
            self.showModal = false;
        },
        delete(id: number) {
            const item = self.items.find((x: SkillItem) => x.id === id)!;
            self.items.remove(item);
        },
        add(item: SkillItem) {
            const newItem = SkillItem.create({
                id: GeneratorHelper.getNextId(self.items.slice()),
                skillName: item.skillName,
                skillLevel: item.skillLevel,
                yearsExperience: item.yearsExperience,
                skillClassificationId: item.skillClassificationId,
                skillCategoryId: item.skillCategoryId,
                order: GeneratorHelper.getNextOrder(self.items.slice()),
            });

            self.items.push(newItem);
        },
        update(item: SkillItem) {
            const index = self.items.findIndex((x: SkillItem) => x === self.selectedItem);
            self.items[index].skillName = item.skillName;
            self.items[index].skillLevel = item.skillLevel;
            self.items[index].yearsExperience = item.yearsExperience;
            self.items[index].skillClassificationId = item.skillClassificationId;
            self.items[index].skillCategoryId = item.skillCategoryId;
        },
        addOrUpdate(item: SkillItem) {
            if (self.selectedItem) {
                this.update(item);
            } else {
                this.add(item);
            }
            this.closeModal();
        },
        setSelectedItem(item?: SkillItem) {
            self.selectedItem = item;
        },
        create() {
            this.setSelectedItem();
            this.openModal();
        },
        edit(id: number) {
            const item = self.items.find((x: SkillItem) => x.id === id);
            this.setSelectedItem(item);
            this.openModal();
        },
        afterCreate() {
            const { setShouldReloadProfile } = getRootStore(self);
            onSnapshot(self.items, () => {
                setShouldReloadProfile(true);
            });
        },
    }));

export interface SkillsStore extends Instance<typeof SkillsStore> {}
