import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useRootStore } from '../root/RootStoreHook';
import CustomCard from '../shared/CustomCard';
import { Routes } from '../app/routing/Routes';
import rol, { isRol } from '../login/roles';
import './PersonalInfoDisplay.css';

interface IProps {
  infoExtra?: any | undefined;
  sameId?: boolean | undefined;
}
const PersonalInfoDisplay: FC<IProps> = ({ infoExtra, sameId }) => {
  const { profile, dropdownOptions, user } = useRootStore();
  const userRole = user?.rol;
  const personalInfo = profile?.personalInfo;
  const { getPositionValue, getCommunityValue, getStudioValue, getCommunitiesValue, getAdditionalPositionsValues } = dropdownOptions!;
  
    return useObserver(() => (
        <CustomCard
            title="Personal Information"
            hasData={personalInfo!.hasData}
            pathForEdit={Routes.PersonalInfo}
            subTitle={infoExtra}
            canEdit={isRol([rol.ADMIN, rol.USER], userRole) || sameId}
            noDataDescription="You didn’t add personal information yet."
        >
      <>
                <div className='custom-card-container'>
                    <strong className='custom-card-container-name'>{`${personalInfo!.fullName}, ID ${personalInfo!.cognizantId}`}</strong>
          <div className='custom-card-container-email'>
            {personalInfo!.email ? (
              <span className='custom-card-email'> {personalInfo!.email}</span>
            ) : null}
            {personalInfo!.phone ? (
              <span className='custom-card-phone'> {personalInfo!.phone}</span>
            ) : null}
          </div>
        </div>
        <p>
          {Array.isArray(personalInfo?.additionalPositionPersonalInfoId) && personalInfo?.additionalPositionPersonalInfoId.length ? (
           <span>{getAdditionalPositionsValues(personalInfo!.additionalPositionPersonalInfoId!)}</span>) : 
              <span> {getPositionValue(personalInfo!.positionId!)}</span>
          }
          {Array.isArray(personalInfo?.communityPersonalInfoId) && personalInfo?.communityPersonalInfoId.length ? (
            <span> | {getCommunitiesValue(personalInfo!.communityPersonalInfoId!)}</span>
          ) :  <span> | {getCommunityValue(personalInfo!.communityId!)}</span>}
          {getStudioValue(personalInfo!.studioId!) ? <span> | {getStudioValue(personalInfo!.studioId!)}</span> : null}
        </p>
      </>
    </CustomCard>
  ));
};

export default PersonalInfoDisplay;
