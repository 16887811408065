import React, { FC, useEffect, useRef, useState } from 'react'
import CustomTable from 'components/reusable/table'
import { ColumnType } from 'antd/lib/table'
import BoldColumnTitle from 'components/shared/BoldColumnTitle';
import { Button, Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { reportsInstance } from 'http/agent';
import DownloadCsvButton from 'components/reusable/downloadCsvButton';
interface IEmailReport {
    key: number
    Email: string,
    NormalizedUserName: string
}

const AccessReport = () => {
    const [searchText, updateSearchText] = useState<string | null>(null)
    const [reportData, setReportData] = useState<IEmailReport[]>([])
    const searchReportInfo = async () => {
        
        try {
            const response = await reportsInstance.get('/login');
            const records = response.data
            setReportData(
                records.map((currentValue: any, index: any) => { return { ...currentValue, key: index } })
            )
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        searchReportInfo()
    }, [])

    const getColumnSearch = ({ dataIndex, title, searchInput, handleSearch, handleReset, selectFilterText, data }: any) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${title.toLowerCase()}`}
                    value={selectedKeys[0]}
                    onChange={(e: any) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, clearFilters)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex, clearFilters, "reset")}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex, clearFilters, "search")}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: any) => <SearchOutlined className={filtered ? 'filterIcon_filtered' : 'filterIcon'} />,
        onFilter: (value: any, record: any) => {
            return record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : undefined;
        },
        onFilterDropdownVisibleChange: (visible: any) => {
            if (visible) setTimeout(() => (searchInput.current as any).select());
        },
    });
    const selectFilterText = () => !searchText;

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any, clearFilters: any, selectedButton: string) => {
        if (selectedButton === "reset") {
            handleReset(dataIndex, clearFilters);
        }
        confirm();
        updateSearchText(selectedKeys[0]);
    };
    const handleReset = (_dataIndex: any, clearFilters: any) => {
        updateSearchText('');
        clearFilters();
    };

    const searchInput = useRef(null);
    const csvHeaders = [{ label: 'Username', key: 'NormalizedUserName' }, { label: "Email", key: "Email" }]
    const tableColumns: ColumnType<any>[] = [
        {
            title: <BoldColumnTitle title="Username" />,
            dataIndex: 'NormalizedUserName',
            ...getColumnSearch({ dataIndex: 'NormalizedUserName', title: 'Username', searchInput, handleSearch, handleReset, selectFilterText, data: reportData }),
            defaultSortOrder: 'ascend',
            sorter: (a: IEmailReport, b: IEmailReport) => {
                return a.NormalizedUserName.localeCompare(b.NormalizedUserName)
            },
            width: '40px',
            ellipsis: true,
        },
        {
            title: <BoldColumnTitle title="Email" />,
            dataIndex: 'Email',
            width: '40px',
            ellipsis: true,
            sorter: (a: IEmailReport, b: IEmailReport) => {
                return a.NormalizedUserName.localeCompare(b.NormalizedUserName)
            },
        },
    ];

    return (
    <>
        <DownloadCsvButton data={reportData} headers={csvHeaders} filename={'access_report.csv'}/>
        <CustomTable tableColumns={tableColumns} data={reportData} />
    </>)
}

export default AccessReport