import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { FC } from "react";
import { CSVLink } from "react-csv";
import { LabelKeyObject } from "react-csv/components/CommonPropTypes";

type props ={
    data:object[],
    headers:LabelKeyObject[],
    filename:string
}
const DownloadCsvButton = ({data,headers,filename}:props) => {
    return (
        <CSVLink data={data} headers={headers} filename={filename}>
            <Button type="primary"  shape="circle" icon={<DownloadOutlined />} />
        </CSVLink>  
    )
}

export default DownloadCsvButton