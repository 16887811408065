interface IItem {
  id: number;
}

interface IOrder {
  order: number;
}

function getNextId(items: IItem[]): number {
  const nextId =
    items.reduce((acc, currentItem) => {
      if (currentItem.id > acc) {
        acc = currentItem.id;
      }
      return acc;
    }, 0) + 1;
  return nextId;
}

function getNextOrder(items: IOrder[]): number {
  const nextOrder =
    items.reduce((acc, currentItem) => {
      if (currentItem.order > acc) {
        acc = currentItem.order;
      }
      return acc;
    }, 0) + 1;

  return nextOrder;
}

function setValidationMessage(password: string) {
  const message = ['New Password must contain: '];

  if (!/^(?=.{6,}$)/.test(password)) {
    message.push('at least six characters');
  }

  if (!/^(?=.*[a-z])/.test(password)) {
    message.push('at least one letter');
  }

  if (!/^(?=.*[A-Z])/.test(password)) {
    message.push('at least one letter in uppercase');
  }

  if (!/^(?=.*[0-9])/.test(password)) {
    message.push('at least one numeric character');
  }

  if (!/^(?=.*\W)/.test(password)) {
    message.push('at least one special character');
  }

  let finalMessage = '';

  if (message.length > 1) {
    for (let i = 0; i < message.length; i++) {
      if (i === 0) {
        finalMessage += message[i];
      } else if (i === 1) {
        
        finalMessage += ` ${message[i]}`;
      } else if (i === message.length - 1) {
        finalMessage += `, ${message[i]}.`;
      } else {
        finalMessage += `, ${message[i]}`;
      }
    }
  } 

  return finalMessage;
}

export const GeneratorHelper = {
  getNextId,
  getNextOrder,
  setValidationMessage
};
