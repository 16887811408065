import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { Form, Select } from 'antd';
import { Rule } from 'antd/lib/form';
import './CustomSelect.css';

const { Option } = Select;
export type CustomSelectConfig = {
  min?: number;
  max?: number;
  required: boolean;
  email?: boolean;
  whitespace?: boolean;
};
export interface IProps {
  name: string;
  label: string;
  config: CustomSelectConfig;
  options: { id: number; description: string }[];
  onSelect?: (value: any) => void;
  template?: boolean;
  selectMode?: 'multiple' | 'tags';
  placeholder?: string;
  defaultValue?: string | string[] | number | number[];
}

export const CustomSelect: FC<IProps> = ({
  name,
  label,
  options,
  config,
  onSelect,
  template,
  placeholder,
  selectMode,
  defaultValue,
}) => {
  function getRules(): Rule[] {
    const { required } = config;
    const rules: Rule[] = [{ required }];
    return rules;
  }

  return useObserver(() => (
    <Form.Item name={name} label={label} rules={getRules()}>
      <Select
        onSelect={(value) => onSelect && onSelect(value)}
        placeholder={placeholder || 'Select Option...'}
        className='select'
        mode={selectMode}
        defaultValue={defaultValue}
      >
        {options.map(({ id, description }) =>
          template ? (
            <Option key={description} value={description}>
              {description}
            </Option>
          ) : (
            <Option key={id} value={id}>
              {description}
            </Option>
          )
        )}
      </Select>
    </Form.Item>
  ));
};
