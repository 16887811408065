import React, { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useObserver } from 'mobx-react-lite';
import { AppLayout } from '../layout/Layout';
import { RootStoreProvider } from './RootStoreProvider';
import { RootStore } from './RootStore';
import App from '../app/App';
import Loading from '../loading/Loading';
import ScrollToTop from '../shared/ScrollToTop';
interface IProps {
  rootStore?: RootStore;
}

export const Root: FC<IProps> = ({ rootStore }) => {
  return useObserver(() => {
    if (!rootStore) return <Loading show />;

    return (
      <RootStoreProvider value={rootStore}>
        <BrowserRouter>
          <AppLayout>
            <ScrollToTop />
            <App />
          </AppLayout>
        </BrowserRouter>
      </RootStoreProvider>
    );
  });
};
