import React, { useCallback, useMemo, useState, FC } from 'react';
import isHotkey from 'is-hotkey';
import { Editable, withReact, useSlate, Slate, ReactEditor } from 'slate-react';
import { Editor, createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { HighlightOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { Button, Toolbar } from './components';

interface IKey {
  [key: string]: string;
}

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
} as IKey;

export function createBoldMarkup(source: string) {
  return { __html: source };
}

const isMarkActive = (editor: ReactEditor, format: string): boolean => {
  const marks = Editor.marks(editor) as any;
  return marks && marks[format];
};

const getNodeFromString = (value: string) => {
  if (value.length === 0) return [{ text: '' }];
  const valueReplaced = value.replace(/<b>/g, '#').replace(/<\/b>/g, '#');
  const result = [{ text: '', bold: false }];
  let bold = false;
  let text = '';

  for (let i = 0; i < valueReplaced.length; i++) {
    const letter = valueReplaced.charAt(i);
    if (letter === '#') {
      if (text.length > 0) {
        result.push({ text, bold });
        text = '';
      }
      bold = !bold;
    } else {
      text += letter;
    }
  }
  if (text.length > 0) {
    result.push({ text, bold });
  }

  return result;
};

const initialValue = (description: string) => [
  {
    type: 'paragraph',
    children: getNodeFromString(description),
  },
];

const toggleMark = (newEditor: any, format: string): void => {
  const isActive = isMarkActive(newEditor, format);

  if (isActive) {
    Editor.removeMark(newEditor, format);
  } else {
    Editor.addMark(newEditor, format, true);
  }
};

const Element = ({ attributes, children, element }: any) => {
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>;
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Leaf: FC = ({ attributes, children, leaf }: any) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }
  return <span {...attributes}>{children}</span>;
};

interface IRichTextProps {
  description: string;
  onValueChange: (value: string) => void;
}

const RichText: FC<IRichTextProps> = ({ description, onValueChange }) => {
  const [value, setValue] = useState(initialValue(description));
  const renderElement = useCallback((callbackProps) => <Element {...callbackProps} />, []);
  const renderLeaf = useCallback((callbackProps) => <Leaf {...callbackProps} />, []);
  const editor = useMemo<ReactEditor>(() => withHistory(withReact(createEditor())), []);

  const getStringFromValue = (text: any): string => {
    let stringValue = '';
    text.forEach((v: any) =>
      v.children.forEach((c: any) => {
        if (c.bold) {
          stringValue += `<b>${c.text}</b>`;
        } else {
          stringValue += `${c.text}\n`;
        }
      })
    );
    return stringValue;
  };

  return (
    <Slate
      editor={editor}
      value={value}
      onChange={(newValue: any) => {
        setValue(newValue);
        onValueChange(getStringFromValue(newValue));
      }}
    >
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        spellCheck
        autoFocus
        onKeyDown={(event: any) => {
          // eslint-disable-next-line no-restricted-syntax
          for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event)) {
              event.preventDefault();
              const mark = HOTKEYS[hotkey];
              toggleMark(editor, mark);
            }
          }
        }}
      />
    </Slate>
  );
};

export default RichText;
