import React, { FC } from 'react';
import { Form } from 'antd';
import CustomFormForModal from '../../../forms/CustomFormForModal';
import CustomModal from '../../../shared/CustomModal';
import { CustomInput } from '../../../forms/CustomInput';
import { useRootStore } from '../../../root/RootStoreHook';
import { options } from '../DropdownOptionsDisplay';

interface IProps {
  visible: boolean;
}

const AdditionalPositionModal: FC<IProps> = ({ visible }) => {
  const { dropdownOptions } = useRootStore();
  const { selectedAdditionalPositionItem, config, addOrUpdateAdditionalPosition } = dropdownOptions!;
  const [form] = Form.useForm();

  const onOk = () => {
    form
      .validateFields()
      .then((values: any) => {
        addOrUpdateAdditionalPosition(values);
      })
      .catch(() => {});
  };

  const getTitle = (selectedAdditionalPositionItem ? 'Update ' : 'Add ') + options.ADDITIONAL_POSITION;

  return (
    <CustomModal
      visible={visible}
      title={getTitle}
      onOk={onOk}
      onCancel={() => {
        dropdownOptions!.test();
        dropdownOptions!.closeModal(options.ADDITIONAL_POSITION);
      }}
    >
      <CustomFormForModal form={form} initialValues={selectedAdditionalPositionItem}>
        <CustomInput name="description" label="Description" config={config.id} />
      </CustomFormForModal>
    </CustomModal>
  );
};

export default AdditionalPositionModal;
