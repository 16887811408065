import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { Divider, Card, Row } from 'antd';
import Table, { ColumnProps } from 'antd/lib/table';
import { EditOutlined } from '@ant-design/icons';
import { SkillSummaryItem } from './SkillSummaryItem';
import { useRootStore } from '../../root/RootStoreHook';
import CustomHeader from '../../forms/CustomHeader';
import DeleteItemConfirm from '../../shared/DeleteItemConfirm';
import CustomAddRow from '../../shared/CustomAddRow';
import CustomDragableTable from '../../shared/CustomDragableTable';
import SkillSummaryModal from './SkillSummaryModal';
import './SkillSummaryDisplay.css';

const SkillSummaryDisplay: FC = () => {
  const { profile } = useRootStore();
  const { skillSummary } = profile!;

  const columns: ColumnProps<SkillSummaryItem>[] = [
    {
      title: 'Group',
      dataIndex: 'skill',
      key: 'skill',
    },
    {
      title: 'Technologies',
      dataIndex: 'description',
      key: 'description',
    },
  ];

  return useObserver(() => (
    <>
      <h3 className='skill-summary-title'>Skills Summary</h3>
      <Table
        showHeader={true}
        columns={columns}
        dataSource={skillSummary!.sortedItemsForTable}
        className="table-alternative skill-summary-table-labels"
        pagination={false}
        scroll={{ y: 240 }}
      />
      <br />
    </>
  ));
};
//   return useObserver(() => (
//     <Card>
//       {skillSummary!.sortedItems.map((item: SkillSummaryItem) => (
//         <p key={item.id}>
//           <b>{item.skill}: </b>
//           {item.description}
//         </p>
//       ))}
//     </Card>
//   ));

export default SkillSummaryDisplay;
