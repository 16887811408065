import { Instance, types, flow, onSnapshot } from 'mobx-state-tree';
import { axiosInstance } from '../../http/agent';
import { getRootStore } from '../root/RootStoreUtils';
import { HighlightItem } from './models/HighlightItem';
import { GeneratorHelper } from '../shared/Helpers';
import { Config, defaults, textAreaConfig } from 'components/profile/models/Config';
const descriptionConfig = textAreaConfig();

export const HighlightConfig = types.model('HighlightItem', {
  description: Config,
});
export const HighlightsStore = types
  .model('HighlightsStore', {
    items: types.array(HighlightItem),
    showModal: types.optional(types.boolean, false),
    minLength: types.optional(types.number, descriptionConfig.min || 0),
    selectedItem: types.maybe(types.reference(HighlightItem)),
    config: types.optional(HighlightConfig, {
      description: descriptionConfig,
    }),
  })
  .views((self) => ({
    get initialValues() {
      return {
        description: self.selectedItem?.description ?? '',
      };
    },
    get canContinue(): boolean {
      return self.items.length >= self.minLength;
    },
    get validationMessage(): string {
      return `At least ${self.minLength} Highlight is required.`;
    },
    get sortedItemsForTable() {
      return this.sortedItems.map((x: HighlightItem) => ({ key: x.id, ...x }));
    },
    get sortedItems(): HighlightItem[] {
      return self.items.slice().sort((a: HighlightItem, b: HighlightItem) => a.order - b.order);
    },
    get dataToSubmit() {
      return { items: this.sortedItems.map((item, index) => ({ description: item.description, order: index + 1 })) };
    },
  }))
  .actions((self) => ({
    addOrUpdate(highlightItem: HighlightItem) {
      if (self.selectedItem) {
        this.update(highlightItem.description);
      } else {
        this.add(highlightItem.description);
      }
      this.closeModal();
    },
    openModal() {
      self.showModal = true;
    },
    closeModal() {
      self.showModal = false;
    },
    delete(id: number) {
      const item = self.items.find((x: HighlightItem) => x.id === id)!;
      self.items.remove(item);
    },
    setSelectedItem(item?: HighlightItem) {
      self.selectedItem = item;
    },
    add(description: string) {
      const item = HighlightItem.create({
        id: GeneratorHelper.getNextId(self.items.slice()),
        description,
        order: GeneratorHelper.getNextOrder(self.items.slice()),
      });

      self.items.push(item);
    },
    create() {
      this.setSelectedItem();
      this.openModal();
    },
    update(description: string) {
      const index = self.items.findIndex((x: HighlightItem) => x === self.selectedItem);
      self.items[index].description = description;
    },
    edit(id: number) {
      const item = self.items.find((x: HighlightItem) => x.id === id);
      this.setSelectedItem(item);
      this.openModal();
    },
    save: flow(function* save(): any {
      const { profile, showLoading, hideLoading, setShouldReloadProfile } = getRootStore(self);

      const data = {
        profileId: profile!.id,
        ...self.dataToSubmit,
      };

      showLoading();
      try {
        yield axiosInstance.put(`/highlight/${profile!.id}`, data);
        self.selectedItem = undefined;
        setShouldReloadProfile(false);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      } finally {
        hideLoading();
      }
    }),
    afterCreate() {
      const { setShouldReloadProfile } = getRootStore(self);
      onSnapshot(self.items, () => {
        setShouldReloadProfile(true);
      });
    },
  }));

export interface HighlightsStore extends Instance<typeof HighlightsStore> {}
