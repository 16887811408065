/* eslint-disable react/no-danger */
import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { Table, List, Space, Empty, Collapse } from 'antd';
import { useRootStore } from '../root/RootStoreHook';
import CustomCard from '../shared/CustomCard';
import { ResponsabilityItem } from './models/ResponsabilityItem';
import { HistoryItem } from './models/HistoryItem';
import { createBoldMarkup } from '../richtext/richtext';
import { Routes } from '../app/routing/Routes';
import PossiblyEmptyField from '../shared/PossiblyEmptyField';
import BoldColumnTitle from '../shared/BoldColumnTitle';
import Achievements from './Achievements';
import './histories.css';
import rol, { isRol } from '../login/roles';

interface IProps {
  infoExtra?: any | undefined;
  sameId?: boolean | undefined;
}

const { Panel } = Collapse;

const multiRowRender = ( value:any ) =>
  <Achievements>
    {value}
  </Achievements>
;

const expandedRow = (historyItem: HistoryItem) => {
  const hasResponsabilities = historyItem.responsabilities.length > 0;
  return (
    <Space direction="vertical" className="histories-expanded-row">
      {hasResponsabilities ? (
        <List
          header={<div className="histories-expanded-title">Responsibilities</div>}
          size="large"
          bordered
          dataSource={historyItem.responsabilities
            .slice()
            .sort((a: ResponsabilityItem, b: ResponsabilityItem) => a.order - b.order)}
          renderItem={(item: ResponsabilityItem) => (
            <List.Item key={`responsability-${item.id}`}>
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={createBoldMarkup(item.name)} />
            </List.Item>
          )}
        />
      ) : (
        <List header={<div className="histories-expanded-title">Responsibilities</div>} size="large" bordered>
          <List.Item key="responsability-empty" className="centered-empty">
            <Empty description="You didn't add any responsibilities yet." image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </List.Item>
        </List>
      )}
    </Space>
  );
};

const HistoriesDisplay: FC<IProps> = ({ infoExtra, sameId }) => {
  const { profile, user } = useRootStore();
  const userRole = user?.rol;
  const history = profile?.history;
  const columns = [
    {
      title: <BoldColumnTitle title="Company" />,
      dataIndex: 'company',
      key: 'company',
    },
    {
      title: <BoldColumnTitle title="Role" />,
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: <BoldColumnTitle title="Start" />,
      dataIndex: 'start',
      key: 'start',
    },
    {
      title: <BoldColumnTitle title="End" />,
      dataIndex: 'end',
      key: 'end',
    },
    {
      title: <BoldColumnTitle title="Location" />,
      dataIndex: 'place',
      key: 'place',
      render: (text: string, item: HistoryItem) => <PossiblyEmptyField item={item.place} />,
    },
    {
      title: <BoldColumnTitle title="Achievement" />,
      dataIndex: 'achievement',
      key: 'achievement',
      render: multiRowRender
    },
  ];

  return useObserver(() => (
    <CustomCard
      title="Work Experience"
      hasData={history!.items.length > 0}
      pathForEdit={Routes.Histories}
      subTitle={infoExtra}
      canEdit={isRol([rol.ADMIN, rol.USER], userRole) || sameId}
      noDataDescription="You didn't add any work experience yet."
    >
      <div className="histories-large-table">
        <Table
          className='histories-large-table-item'
          columns={columns}
          dataSource={history!.sortedItemsForTable}
          pagination={false}
          expandedRowRender={expandedRow}
          expandable={{
            rowExpandable: (record) => record.responsabilities.length > 0 || record.technologies.length > 0,
          }}
          scroll={{ y: 300 }}
        />
      </div>

      <div className="histories-small-table">
        <Collapse accordion defaultActiveKey={history!.sortedItemsForTable[0]?.company} bordered={false}>
          {history!.sortedItemsForTable.map((item) => {
            const name = `${item.company}${item.role && ` (${item.role})`}`;
            return (
              <Panel header={name} key={name}>
                <div className="histories-collapse-panel">
                  <Table
                    className="table-alternative histories-table-labels"
                    columns={columns.filter((column) => column.key !== 'company' && column.key !== 'role')}
                    dataSource={[item]}
                    pagination={false}
                    scroll={{ y: 240 }}
                  />
                  {expandedRow(item)}
                </div>
              </Panel>
            );
          })}
        </Collapse>
      </div>
    </CustomCard>
  ));
};

export default HistoriesDisplay;
