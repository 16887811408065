import React, { ClassAttributes, FC, Props } from 'react';
import { Table } from 'antd';
import { useObserver } from 'mobx-react-lite';
import { ColumnType } from 'antd/lib/table/interface';
import { Type } from 'typescript';

const CustomTable: FC<{tableColumns:ColumnType<any>[],data:any,size?:string}> = (props) => {
  const {tableColumns, data,size} = props
    return useObserver(() => {

    return (
      <div className={size}>
        <Table
          pagination={{
            position: ['bottomRight'],
            total:data.length,
            defaultPageSize:10,
            defaultCurrent:1,
          }} 
          columns={tableColumns as ColumnType<any>[] }
          dataSource={data}
        />
      </div>
    );
  });
};

export default CustomTable;
