import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useRootStore } from '../../../root/RootStoreHook';
import { options } from '../DropdownOptionsDisplay';
import DropdownOptionsEntry from '../DropdownOptionsEntry';
import LevelModal from './LevelModal';

const LevelEntry: FC = () => {
  const { dropdownOptions } = useRootStore();

  return useObserver(() => (
    <>
      <DropdownOptionsEntry option={options.LEVEL} dropdownItems={dropdownOptions!.levelsForDropdown} />
      {dropdownOptions!.showLevelModal && <LevelModal visible={dropdownOptions!.showLevelModal} />}
    </>
  ));
};

export default LevelEntry;
