import { useHistory } from 'react-router-dom';
import { Routes } from '../app/routing/Routes';

export function useRedirect() {
  const history = useHistory();
  return {
    go: (route: Routes, id: string | number | undefined = undefined) => {
      if (id) {
        history.push(`${route}/${id}`);
      } else {
        history.push(route);
      }
    },
    back: () => {
      history.goBack();
    },
  };
}
