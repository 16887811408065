import React, { FC, useEffect, useState } from 'react';
import './LandingPage.css';
import { useLocation, useHistory } from 'react-router-dom';
import { Col, Row } from 'antd';
import CognizantLogo from '../../assets/cognizantLogoInverted.svg';
import CVCreatorLogo from '../../assets/CVcreatorInverted.svg';
import { Button, Select, Typography } from 'antd';
import axios from 'axios';
import { Routes } from 'components/app/routing/Routes';
import { useRootStore } from 'components/root/RootStoreHook';
import FourOhFour from 'components/four-oh-four/FourOhFour';
import rol, { isRol } from 'components/login/roles';
import { setTimeout } from 'timers';

const { Title } = Typography;

interface Template {
  value: string;
  label: string;
  key: string;
}

const LandingPage: FC = () => {
  const rootStore = useRootStore();
  const { user, document: rootStoreDocument } = rootStore;
  const [templates, setTemplates] = useState([]);
  const [_404, set404] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  async function fetchData() {
    const response = await axios.get('/api/template/all');
    const { templates } = await response.data;
    const optionsTemplate = templates
      .filter((temp: { status: boolean }) => temp.status)
      .map((temp: { description: string; type: string; id: string }) => ({
        label: temp.description,
        value: temp.type,
        key: temp.id,
      }));
    setTemplates(optionsTemplate);
  }

  async function fetchProfile() {
    const cognizantId = Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1));
    axios.get(`/api/Profile/GetLandingProfile/${cognizantId}`).then(({data}) => 
    {
      setProfileId(data?.id);
      const downloadParam = searchParams.get('download');
      if (downloadParam && rootStore.isUserLogged) {
        setTimeout(() => {
          handleDownload({value: downloadParam} as Template, data?.id);
          rootStore.hideLoading();
          setIsLoading(false);
        }, 10);
      }else{
        setIsLoading(false);
      }
    } ).catch(_ => set404(true));
  }

  const location = useLocation();
  const history = useHistory();
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null | undefined>();

  const searchParams = new URLSearchParams(location.search);
  const [profileId, setProfileId] = useState<number>();

  useEffect(() => {
    Promise.all([fetchProfile(), fetchData()]);
  }, []);

  const handleChange = (item: Template) => {
    setSelectedTemplate(item);
  };
  const handleDownload = (type: Template | null | undefined | string, profile?: number) => {
    if ((rootStore.isUserLogged && isRol([rol.ADMIN], user?.rol)) || isRol([rol.MANAGER], user?.rol)) {
      const docType = typeof type === 'string' ? type : String(type?.value);
      rootStoreDocument!.downloadDocument([profile || profileId!], docType, true);
    } else {
      const url = { pathname: `${Routes.Profile}/${profile || profileId!}`, search: `?landing=true&download=${type}&from=${location.pathname + location.search}` };
      history.push(url);
    }
  };
  const handleDisplayResume = () => {
    const url = { pathname: `${Routes.Profile}/${profileId!}`, search: '?landing=true' };
    history.push(url);
  };

  return (
    _404 ? <span className='landing-404'><FourOhFour /> </span>:
    <div id="landing">
      <Row gutter={16} align="middle">
        <Col xs={12} sm={10} md={6} lg={6} xl={6} className="gutter-box">
          <img src={CognizantLogo} className="cognizant-logo" />
        </Col>
        <Col xs={12} sm={10} md={6} lg={6} xl={6} className="gutter-box">
          <img src={CVCreatorLogo} className="cvcreator-logo" />
        </Col>
      </Row>
      <Row gutter={16} align="middle">
        <Col xs={24} sm={20} md={12} lg={12} xl={12} className="gutter-box">
          <Title style={{ color: '#FFFFFF' }} level={4}>
            Welcome to CV Creator!
          </Title>
          <p>
            CV Creator is a web application developed by R&D Latam that centralized employees' resumes and facilitate
            keeping their profiles updated. This tool enables managers to share updated information with our clients in
            different formats and layouts in an agile and timely way.
          </p>
        </Col>
      </Row>
      <Row gutter={16} align="middle">
        <Col xs={20} sm={16} md={8} lg={7} xl={7} className="gutter-box">
          <Button disabled={isLoading} className="display-button" shape="round" size="large" onClick={handleDisplayResume}>
            Display Overview Resume
          </Button>
        </Col>
      </Row>
      <Row gutter={16} align="middle">
        <Col xs={20} sm={16} md={8} lg={7} xl={7} className="gutter-box">
          <Title style={{ color: '#FFFFFF' }} level={4}>
            Download Resume
          </Title>
          <Select
            disabled={isLoading}
            size="large"
            placeholder="Choose a resume template"
            value={selectedTemplate}
            style={{ width: '100%' }}
            onChange={handleChange}
            options={templates}
            className="select-template"
          />
        </Col>
      </Row>
      <Row gutter={16} align="top">
        <Col xs={20} sm={16} md={8} lg={7} xl={7} className="gutter-box" style={{ textAlign: 'right' }}>
          <Button
            className="download-button"
            shape="round"
            size="large"
            disabled={!selectedTemplate}
            onClick={() => handleDownload(selectedTemplate)}
          >
            Download
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default LandingPage;
