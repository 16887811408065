import React, { FC, useEffect } from 'react';
import { useObserver } from 'mobx-react-lite';
import { Form, Row } from 'antd';
import { CustomSelect } from '../../../forms/CustomSelect';
import { CustomInput } from '../../../forms/CustomInput';
import { useRootStore } from '../../../root/RootStoreHook';
import { sortBy } from '../../../app/Utils';
import CustomModal from '../../../shared/CustomModal';
import CustomFormForModal from '../../../forms/CustomFormForModal';
import { patterns } from 'components/profile/models/Config';

interface IProps {
  visible: boolean;
  closeModal: () => void;
  refreshData: () => void;
  userPersonalInfo: {
    firstName: string;
    lastName: string;
    email: string;
    positionId: string;
    additionalPositionId: string;
    communityId: string;
    studioId: string;
    cognizantId: string;
  };
  userItem: string;
}

const UserInfoModal: FC<IProps> = ({ visible, closeModal, userPersonalInfo, refreshData, userItem }) => {
  const [form] = Form.useForm();
  const { dropdownOptions, profile } = useRootStore();
  const rootStore = useRootStore();
  const { config } = profile!.personalInfo!;
  const hasCognizantId = !!userPersonalInfo.cognizantId;

  useEffect(() => {
    form.resetFields();
  }, [userPersonalInfo, form]);
  const onSubmit = async () => {
    form
      .validateFields()
      .then(async (values: any) => {
        const { firstName, lastName, positionId, additionalPositionId, studioId, communityId, cognizantId } = values;
        const email = userItem;
        await rootStore.user?.editUserPersonalInfo(
          email,
          firstName,
          lastName,
          positionId,
          additionalPositionId,
          studioId,
          communityId,
          cognizantId
        );
        closeModal();
        form.resetFields();
        refreshData();
      })
      .catch(() => {});
  };

  const onCancel = () => {
    closeModal();
    form.resetFields();
  };
  return useObserver(() => (
    <CustomModal
      okText="Edit User"
      visible={visible}
      title="Edit User Information"
      onCancel={onCancel}
      width="600px"
      onOk={onSubmit}
    >
      <div>
        <Row justify="center">
          <CustomFormForModal form={form} initialValues={userPersonalInfo}>
            <CustomInput
              name="firstName"
              label="First Name"
              config={config.firstName}
              pattern={patterns.text.nonSpecial}
              onChange={(evt: any) => evt.currentTarget.value}
            />
            <CustomInput
              name="lastName"
              label="Last Name"
              config={config.lastName}
              pattern={patterns.text.nonSpecial}
              onChange={(evt: any) => evt.currentTarget.value}
            />
            <CustomInput
              label="Email"
              name="email"
              disabled
              placeholder="e.g. email@cognizant.com"
              config={config.email}
              pattern={patterns.text.email}
            />
            <CustomSelect
              name="positionId"
              label="Position"
              options={dropdownOptions!.positionOptions.sort(sortBy('description'))}
              config={config.positionId}
            />
            <CustomSelect
              name="studioId"
              label="Studio"
              options={dropdownOptions!.studioOptions.sort(sortBy('description'))}
              config={config.studioId}
            />
            <CustomSelect
              name="communityId"
              label="Community"
              options={dropdownOptions!.communityOptions.sort(sortBy('description'))}
              config={config.communityId}
            />
            <CustomSelect
              name="additionalPositionId"
              label="Additional Position"
              options={dropdownOptions!.additionalPositionOptions.sort(sortBy('description'))}
              config={config.additionalPositionId}
            />

            <CustomInput
              name="cognizantId"
              label="Cognizant Id"
              disabled={hasCognizantId}
              config={config.cognizantId}
              pattern={patterns.numbers}
            />
          </CustomFormForModal>
        </Row>
      </div>
    </CustomModal>
  ));
};

export default UserInfoModal;
