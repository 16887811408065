import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.less';
import 'mobx-react-lite/batchingForReactDom';
import { Root } from './components/root/Root';
import { RootStore, createRootStore } from './components/root/RootStore';

let store: RootStore | undefined;

function renderApp(Component: typeof Root) {
  ReactDOM.render(<Component rootStore={store} />, 
    document.getElementById('root'));
}

createRootStore().then((newStore) => {
  store = newStore;
  renderApp(Root);
});

if (module.hot) {
  module.hot.accept('./components/root/Root', () => {
    // eslint-disable-next-line global-require
    renderApp(require('./components/root/Root').Root);
  });
}

renderApp(Root);
