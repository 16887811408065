/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { FC, useState } from 'react';
import { useObserver } from 'mobx-react-lite';
import { Card, Divider } from 'antd';
import { EditOutlined, HolderOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import { useRootStore } from '../root/RootStoreHook';
import CustomHeader from '../forms/CustomHeader';
import EducationModal from './EducationModal';
import { EducationItem } from './EducationStore';
import CertificationEntry from '../certification/CertificationEntry';
import DeleteItemConfirm from '../shared/DeleteItemConfirm';
import CustomAddRow from '../shared/CustomAddRow';
import CustomDragableTable from '../shared/CustomDragableTable';
import BoldColumnTitle from '../shared/BoldColumnTitle';
import CustomPrompt from '../shared/CustomPrompt';
import './EducationEntry.css';

interface IProps {
  id?: any;
}

const EducationEntry: FC = () => {
  const root = useRootStore();
  const { education } = root.profile!;
  const [formChanged, setFormChanged] = useState<boolean>(false);

  function openModal(itemId?: number) {
    const item = education!.items.find((x: EducationItem) => x.id === itemId);
    education!.setSelectedItem(item);
    education!.openModal();
  }

  const handleDeleteItem: FC<IProps> = ({ id }) => {
    setFormChanged(true);
    education?.delete(id);
    return null;
  };

  const columns: ColumnProps<EducationItem>[] = [
    {
      title: <BoldColumnTitle title="Start" />,
      dataIndex: 'start',
      key: 'start',
    },
    {
      title: <BoldColumnTitle title="End" />,
      dataIndex: 'end',
      key: 'end',
    },
    {
      title: <BoldColumnTitle title="Career" />,
      dataIndex: 'career',
      key: 'career',
    },
    {
      title: <BoldColumnTitle title="Institution" />,
      dataIndex: 'institution',
      key: 'institution',
    },
    {
      title: <BoldColumnTitle title="Location" />,
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: <BoldColumnTitle title="Actions" />,
      key: 'actions',
      render: (item: EducationItem) => (
        <>
          <EditOutlined onClick={() => openModal(item.id)} />
          <Divider type="vertical" />
          <DeleteItemConfirm onConfirm={() => handleDeleteItem({ id: item.id })} />
          <Divider type="vertical" />
          <HolderOutlined />
        </>
      ),
    },
  ];

  const moveRow = (fromIndex: number, toIndex: number) => {
    root.dragAndDrop(fromIndex, toIndex, education!.items);
  };

  return useObserver(() => (
    <div className='education-entry-container' onChange={() => setFormChanged(true)}>
      <CustomHeader title="Education" />
      <CustomPrompt when={formChanged} />
      <Card>
        <CustomAddRow onCreate={() => openModal(undefined)} id="education"/>
        <CustomDragableTable
          style={{}}
          draggableKey="Education"
          columns={columns}
          dataSource={education!.sortedItemsForTable}
          moveRow={moveRow}
          className="table-alternative education-table-labels"
          locale="You didn’t add any education yet."
        />
      </Card>
      <CertificationEntry />
      {education!.showModal && <EducationModal visible={education!.showModal} />}
    </div>
  ));
};

export default EducationEntry;
