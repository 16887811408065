import React, { FC, useState } from 'react';
import { Form, Button, Space } from 'antd';
import { useObserver } from 'mobx-react-lite';
import validateMessages from './CustomValidationMessages';
import CustomHeader from './CustomHeader';
import CustomPrompt from '../shared/CustomPrompt';
import { useWizardMode } from '../stepper/WizardMode';
import { formItemLayout, tailFormItemLayout } from './CustomFormLayout';
import { useRedirect } from '../hooks/HooksUtil';
import './CustomForm.css';

export declare type FormLayout = 'horizontal' | 'inline' | 'vertical';

interface IProps {
  name: string;
  title: string;
  initialValues?: any;
  onSubmit: (values: any) => void; 
  onError?: (errorInfo: any) => void;
  layout?: FormLayout;
}

const CustomForm: FC<IProps> = ({ name, title, onSubmit, children, initialValues, onError }) => {
  const redirect = useRedirect();
  const wizardMode = useWizardMode();
  const [formChanged, setFormChanged] = useState<boolean>(false);

  return useObserver(() => (
    <div className='custom-form-container'>
      <CustomPrompt when={formChanged} />
      <CustomHeader title={title} />
      <Form
        name={name}
        {...formItemLayout}
        onFinish={onSubmit}
        validateMessages={validateMessages}
        initialValues={initialValues}
        onFinishFailed={onError}
        onValuesChange={() => setFormChanged(true)}
        scrollToFirstError
      >
        {children}
        {wizardMode ? (
          <></>
        ) : (
          <Form.Item {...tailFormItemLayout}>
            <Space className='custom-form-item'>
              <Button onClick={redirect.back}>Back</Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Space>
          </Form.Item>
        )}
      </Form>
    </div>
  ));
};

export default CustomForm;
