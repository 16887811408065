/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useObserver } from 'mobx-react-lite';
import { Space, Button, Progress } from 'antd';
import { useParams, useLocation } from 'react-router-dom';
import { ExclamationCircleTwoTone, WarningTwoTone, CheckCircleTwoTone } from '@ant-design/icons';
import rol, { isRol } from '../login/roles';
import PersonalInfoDisplay from '../personal-info/PersonalInfoDisplay';
import SummaryDisplay from '../summary/SummaryDisplay';
import HighlightsDisplay from '../highlights/HighlightsDisplay';
import SkillsDisplay from '../skills/SkillsDisplay';
import EducationDisplay from '../education/EducationDisplay';
import HistoriesDisplay from '../histories/HistoriesDisplay';
import { useRootStore } from '../root/RootStoreHook';
import { Routes } from '../app/routing/Routes';
import { useRedirect } from '../hooks/HooksUtil';
import { tabKeys } from '../sidebar/Sidebar';
import DownloadMenu from '../download-menu/DownloadMenu';
import INFO_TO_DISPLAY from './contants';
import './ProfileDisplay.css';
import CopyButton from '../reusable/copyButton';
import showErrorModal from 'components/notifications/ErrorModal';

const ProfileDisplay: FC = () => {
  const [sameId, setSameId] = useState(false);
  const [cognizantId, setCognizantID] = useState('');
  const rootStore = useRootStore();
  const {
    profile,
    user,
    shouldReloadProfile,
    stepper,
    setShouldReloadProfile,
    updateForcedTab,
    setIsInProfile,
  } = rootStore;
  const { profileId }: any = useParams();
  const redirect = useRedirect();
  const location = useLocation();
  const [notAllowUser, setNotAllowUser] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const userRole = user?.rol;

  const searchParams = new URLSearchParams(location.search);
  const landing = searchParams.get('landing');
  const download = searchParams.get('download');

  const goToWizard = () => {
    stepper.resetStep();
    redirect.go(Routes.Wizard);
  };

  const [profilePercentage, setProfilePercentage] = useState({
    personalInformation: 0,
    highlights: 0,
    skills: 0,
    professionalHistory: 0,
    educationCertifications: 0,
    summary: 0,
  });

  // #region useEffect
  useEffect(() => {
    setProfilePercentage(profile?.getPercentageBySection());
  }, [
    profilePercentage.personalInformation,
    profilePercentage.highlights,
    profilePercentage.skills,
    profilePercentage.professionalHistory,
    profilePercentage.educationCertifications,
    profilePercentage.summary,
  ]);

  const onOk = () => {
    console.log('close');
  };

  useEffect(() => {
    if (!profile?.completed) {
      redirect.go(Routes.Wizard);
    }
    profile?.userId && user?.id && setSameId(Number(profile?.userId) === Number(user.id));
    profile?.cognizantId && setCognizantID(`${profile.cognizantId || ''}`);

    if (isRol([rol.USER], userRole) && user?.idProfile !== profileId) {
      setNotAllowUser(true);
    } else if (landing && user?.idProfile !== profileId) {
      rootStore.getProfileById(profileId).finally(() => {
        redirect.go(Routes.Profile, profileId);
        updateForcedTab(tabKeys.PROFILES);
      });
    }

    if ((profileId && Number(profileId) !== profile!.id && !landing) || shouldReloadProfile) {
      rootStore
        .getProfileById(user?.idProfile)
        .catch(() => redirect.go(Routes.PageNotFound))
        .finally(() => setShouldReloadProfile(false));
    } else {
      updateForcedTab(tabKeys.PROFILE);
    }
    setIsInProfile(true);

    return () => {
      setIsInProfile(false);
    };
  }, [profileId, profile?.userId]);
  // #endregion

  return useObserver(() => {
    const shouldShowDownload = rootStore.isInProfile;

    if (!rootStore?.profile?.completed) {
      return (
        <div className="complete-your-profile-container">
          <Button type="primary" onClick={() => goToWizard()}>
            Complete your profile
          </Button>
        </div>
      );
    }

    const infoExtra = (data: any) => {
      let cssColor = '';
      let toolTipHelp = '';
      const setData = (color: string, tooltip: string) => {
        cssColor = color;
        toolTipHelp = tooltip;
      };
      const { INCOMPLETE_DATA, COMPLETE_DATA, PARCIAL_DATA, EMPTY_DATA } = INFO_TO_DISPLAY;
      if (data >= 75 && data <= 99) {
        setData(INCOMPLETE_DATA.COLOR.YELLOW, PARCIAL_DATA.MESSAGE(data));
      } else if (data < 75) {
        setData(EMPTY_DATA.COLOR.RED, EMPTY_DATA.MESSAGE);
      } else {
        setData(COMPLETE_DATA.COLOR.GREEN, COMPLETE_DATA.MESSAGE);
      }

      return (
        <div
          className="infoExtra_container"
          style={{
            color: cssColor,
            borderColor: cssColor,
            background: `linear-gradient(to left, rgba(255, 255, 255, 1), ${cssColor} 500%)`,
          }}
        >
          {data >= 75 && data <= 99 && <WarningTwoTone twoToneColor={cssColor} />}
          {data < 75 && <ExclamationCircleTwoTone twoToneColor={cssColor} />}
          {data === 100 && <CheckCircleTwoTone twoToneColor={cssColor} />}
          <span className="infoExtra_container-toolTip">{toolTipHelp}</span>
        </div>
      );
    };

    const showPercentageTotal = (percentage: number): ReactElement => {
      return (
        <p className="default-text">
          <b>
            Your profile is
            <span className="magenta-highlighted-text"> {percentage}% completed</span>
          </b>
        </p>
      );
    };

    if (landing && notAllowUser && !modalIsOpen) {
      showErrorModal({
        Title: 'Warning',
        Errors: download ? 'You don’t have permissions to download other resumes. Please, contact your admin.' : 'You don’t have permissions to see other profiles. Please, contact your admin.',
        onOk,
      });
      setModalIsOpen((current) => !current);
    }

    const percentage = profile?.getPercentageTotal(profilePercentage) ?? 0;
    return (
      <Space direction="vertical" className="space-container">
        <div style={{ display: 'flex' }}>
          <div style={{ flexGrow: '6', padding: '0 0 0 15%' }}>
            <Progress percent={percentage} showInfo={false} />
            {profile && showPercentageTotal(percentage)}
          </div>
          {cognizantId && <CopyButton cognizantId={cognizantId} /> || ''}
        </div>
        <PersonalInfoDisplay infoExtra={infoExtra(profilePercentage.personalInformation)} sameId={sameId} />
        <HighlightsDisplay infoExtra={infoExtra(profilePercentage.highlights)} sameId={sameId} />
        <SkillsDisplay infoExtra={infoExtra(profilePercentage.skills)} sameId={sameId} />
        <HistoriesDisplay infoExtra={infoExtra(profilePercentage.professionalHistory)} sameId={sameId} />
        <EducationDisplay infoExtra={infoExtra(profilePercentage.educationCertifications)} sameId={sameId} />
        <SummaryDisplay infoExtra={infoExtra(profilePercentage.summary)} sameId={sameId} />
        {shouldShowDownload && <DownloadMenu />}
      </Space>
    );
  });
};

export default ProfileDisplay;
