import React, { FC } from 'react';
import { Form } from 'antd';
import { useObserver } from 'mobx-react-lite';
import { FormInstance } from 'antd/lib/form/Form';
import validateMessages from './CustomValidationMessages';
import CustomHeader from './CustomHeader';
import { formItemLayout } from './CustomFormLayout';
import './CustomFormForStepper.css';

interface IProps {
  title: string;
  initialValues: any;
  form: FormInstance;
  onFieldsChange?: () => void;
}
const CustomFormForStepper: FC<IProps> = ({ title, form, children, initialValues, onFieldsChange }) =>
  useObserver(() => (
    <div className='container'>
      <CustomHeader title={title} />
      <Form
        name={title}
        form={form}
        onFieldsChange={onFieldsChange}
        validateMessages={validateMessages}
        initialValues={initialValues}
        scrollToFirstError
        {...formItemLayout}
      >
        {children}
      </Form>
    </div>
  ));

export default CustomFormForStepper;
