import React from 'react';
import { Modal } from 'antd';

const openNotificationModal = (
  title: string,
  content: string,
  type: 'success' | 'info' | 'warning' | 'error',
  onOkText?: React.ReactNode,
  onOk?: (...args: any[]) => any
) => {
  const properties = { title, content, onOkText, onOk };

  switch (type) {
    case 'success':
      Modal.success(properties);
      break;
    case 'info':
      Modal.info(properties);
      break;
    case 'warning':
      Modal.warning(properties);
      break;
    case 'error':
      Modal.error(properties);
      break;

    default:
      break;
  }
};

export default openNotificationModal;
