import React, { FC } from 'react';
import { Form } from 'antd';
import { useRootStore } from '../../root/RootStoreHook';
import CustomModal from '../../shared/CustomModal';
import CustomFormForModal from '../../forms/CustomFormForModal';
import { CustomInput } from '../../forms/CustomInput';
import { patterns, textConfig } from 'components/profile/models/Config';

interface IProps {
  visible: boolean;
}

const SkillSummaryModal: FC<IProps> = ({ visible }) => {
  const { profile } = useRootStore();
  const skillSummary = profile!.skillSummary!;

  const [form] = Form.useForm();

  const onOk = () => {
    form
      .validateFields()
      .then((values: any) => {
        skillSummary.addOrUpdate(values);
      })
      .catch(() => {});
  };

  return (
    <CustomModal
      visible={visible}
      title={skillSummary.selectedItem ? 'Update' : 'Add'}
      onOk={onOk}
      onCancel={() => skillSummary.closeModal()}
    >
      <CustomFormForModal form={form} initialValues={skillSummary.initialValues}>
        <CustomInput
          name="skill"
          label="Group"
          config={textConfig()}
          pattern={patterns.text.technology}
          onChange={(evt: any) => evt.currentTarget.value}
          placeholder="e.g. Management Tools "
        />
        <CustomInput
          name="description"
          label="Technologies"
          config={textConfig()}
          pattern={patterns.text.technology}
          onChange={(evt: any) => evt.currentTarget.value}
          placeholder="e.g. Jira, VSTS, Azure DevOps"
        />
      </CustomFormForModal>
    </CustomModal>
  );
};

export default SkillSummaryModal;
