import React, { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PersonalInfoEntry from '../personal-info/PersonalInfoEntry';
import ProfileDisplay from '../profile/ProfileDisplay';
import SummaryEntry from '../summary/SummaryEntry';
import HighlightsEntry from '../highlights/HighlightsEntry';
import SkillsEntry from '../skills/SkillsEntry';
import EducationEntry from '../education/EducationEntry';
import HistoriesEntry from '../histories/HistoriesEntry';
import MultipleDownloadReports from '../admin/reports/feature/multipleDownload';
import AccessReport from '../admin/reports/feature/access';
import CustomViewReport from '../admin/reports/feature/customView';
import UsageReport from '../admin/reports/feature/usagePercentaje';
import UserManagement from '../admin/profiles/UserManagement';
import Stepper from '../stepper/Stepper';
import DropdownOptions from '../admin/dropdown-options/DropdownOptionsDisplay';
import PositionEntry from '../admin/dropdown-options/Position/PositionEntry';
import AdditionalPositionEntry from '../admin/dropdown-options/AdditionalPosition/AdditionalPositionEntry';
import LevelEntry from '../admin/dropdown-options/Level/LevelEntry';
import CommunityEntry from '../admin/dropdown-options/Community/CommunityEntry';
import FourOhFour from '../four-oh-four/FourOhFour';
import LoginEntry from '../login/LoginEntry';
import { Routes } from './routing/Routes';
import rol, { isRol } from '../login/roles';
import TemplateDisplay from '../admin/document-templates/TemplateDisplay';
import Profiles from '../admin/profiles/Profiles';
import { useRootStore } from '../root/RootStoreHook';


const AppRouter: FC = () => {
  const rootStore = useRootStore();

  const { user } = rootStore;
  const userRole = user?.rol;
  return (
    <Switch>
      <Route path={`${Routes.Profile}/:profileId?`} component={ProfileDisplay} />
      <Route path={Routes.PersonalInfo} component={PersonalInfoEntry} />
      <Route path={Routes.Summary} component={SummaryEntry} /> 
      <Route path={Routes.Highlights} component={HighlightsEntry} />
      <Route path={Routes.Skills} component={SkillsEntry} />
      <Route path={Routes.Education} component={EducationEntry} />
      <Route path={Routes.Histories} component={HistoriesEntry} />
      <Route exact path={Routes.Login} component={LoginEntry} />
      <Route exact path={`${Routes.Wizard}/:profileId?`} component={Stepper} />
      <Route
        exact
        path={Routes.Profiles} 
        render={() => (!isRol([rol.USER], userRole) ? <Profiles /> : <Redirect to={Routes.Main} />)}
      /> 
      <Route 
        exact 
        path={Routes.MultipleDownload} 
        render={() => (isRol([rol.ADMIN, rol.LEAD, rol.MANAGER], userRole) ? 
          <MultipleDownloadReports /> : 
          <Redirect to={Routes.Main} />
        )}
      />
       <Route 
        exact 
        path={Routes.Completion} 
        render={() => (isRol([rol.ADMIN, rol.LEAD, rol.MANAGER], userRole) ? 
          <UsageReport /> : 
          <Redirect to={Routes.Main} />
        )}
      />
       <Route 
        exact 
        path={Routes.Access} 
        render={() => (isRol([rol.ADMIN, rol.LEAD, rol.MANAGER], userRole) ? 
          <AccessReport /> : 
          <Redirect to={Routes.Main} />
        )}
      />
       <Route 
        exact 
        path={Routes.CustomSearch} 
        render={() => (isRol([rol.ADMIN, rol.LEAD, rol.MANAGER], userRole) ? 
          <CustomViewReport /> : 
          <Redirect to={Routes.Main} />
        )}
      />
      <Route
        exact
        path={Routes.UserManagement}
        render={() => (isRol([rol.ADMIN], userRole) ? 
          <UserManagement /> : 
          <Redirect to={Routes.Main} />
        )}
      />
      <Route exact path={Routes.Main} component={ProfileDisplay} />
      <Route exact path={Routes.Dropdown} component={DropdownOptions} />
      <Route exact path={Routes.Position} component={PositionEntry} />
      <Route exact path={Routes.AdditionalPosition} component={AdditionalPositionEntry} />
      <Route exact path={Routes.Community} component={CommunityEntry} />
      <Route exact path={Routes.Level} component={LevelEntry} />
      <Route exact path={Routes.Template} component={TemplateDisplay} />
      <Route
        exact
        path={Routes.Login}
        render={() => (!isRol([rol.USER], userRole) ? 
          <Redirect to={Routes.Profiles} /> : 
          <Redirect to={Routes.Main} />)}
      />
      <Route path={Routes.PageNotFound} component={FourOhFour} />
      <Redirect to={Routes.PageNotFound} />
    </Switch>
  );
};

export default AppRouter;
