import { Instance, types, onSnapshot } from 'mobx-state-tree';
import { getRootStore } from '../root/RootStoreUtils';
import { GeneratorHelper } from '../shared/Helpers';

export const EducationItem = types.model('EducationItem', {
  id: types.identifierNumber,
  location: types.maybeNull(types.string),
  start: types.string,
  end: types.string,
  institution: types.string,
  career: types.string,
  order: types.number,
});

export interface EducationItem extends Instance<typeof EducationItem> {}

export const EducationStore = types
  .model('EducationStore', {
    items: types.array(EducationItem),
    showModal: types.optional(types.boolean, false),
    minLength: types.optional(types.number, 1),
    selectedItem: types.maybe(types.reference(EducationItem)),
  })
  .views((self) => ({
    get validationMessage(): string {
      return `At least ${self.minLength} Education is required.`;
    },
    get initialValues() {
      return {
        location: self.selectedItem?.location ?? '',
        start: self.selectedItem?.start ?? '',
        end: self.selectedItem?.end ?? '',
        career: self.selectedItem?.career ?? '',
        institution: self.selectedItem?.institution ?? '',
      };
    },
    get canContinue(): boolean {
      return self.items.length > 0;
    },
    get sortedItems(): EducationItem[] {
      return self.items.slice().sort((a: EducationItem, b: EducationItem) => a.order - b.order);
    },
    get sortedItemsForTable() {
      return this.sortedItems.map((x: EducationItem) => ({ key: x.id, ...x }));
    },
    get dataToSubmit() {
      return {
        educationItems: this.sortedItems.map((item, index) => ({
          career: item.career,
          institution: item.institution,
          location: item.location,
          start: item.start,
          end: item.end,
          order: index + 1,
        })),
      };
    },
  }))
  .actions((self) => ({
    addOrUpdate(educationItem: EducationItem) {
      if (self.selectedItem) {
        this.update(educationItem);
      } else {
        this.add(educationItem);
      }
      this.closeModal();
    },
    openModal() {
      self.showModal = true;
    },
    closeModal() {
      self.showModal = false;
    },
    add(educationItem: EducationItem) {
      const item = EducationItem.create({
        id: GeneratorHelper.getNextId(self.items.slice()),
        location: educationItem.location,
        start: educationItem.start,
        end: educationItem.end,
        career: educationItem.career,
        institution: educationItem.institution,
        order: GeneratorHelper.getNextOrder(self.items.slice()),
      });

      self.items.push(item);
    },
    update(item: EducationItem) {
      const index = self.items.findIndex((x: EducationItem) => x === self.selectedItem);
      self.items[index].location = item.location;
      self.items[index].end = item.end;
      self.items[index].start = item.start;
      self.items[index].institution = item.institution;
      self.items[index].career = item.career;
    },
    delete(itemId: number) {
      const item = self.items.find((x: EducationItem) => x.id === itemId);
      if (item) self.items.remove(item);
    },
    setSelectedItem(item?: EducationItem) {
      self.selectedItem = item;
    },
    afterCreate() {
      const { setShouldReloadProfile } = getRootStore(self);
      onSnapshot(self.items, () => {
        setShouldReloadProfile(true);
      });
    },
  }));

export interface EducationStore extends Instance<typeof EducationStore> {}
