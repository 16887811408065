/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useState } from 'react';
import { useObserver } from 'mobx-react-lite';
import { Card, Typography, Empty, Popover } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Routes } from '../app/routing/Routes';
import { useRedirect } from '../hooks/HooksUtil';
import './CustomCard.css';

interface IProps {
  title: string;
  pathForEdit: Routes;
  hasData: boolean;
  subTitle?: string | undefined;
  canEdit?: boolean;
  noDataDescription?: string;
}

const CustomCard: FC<IProps> = ({ title, pathForEdit, hasData, subTitle, children, canEdit = true, noDataDescription }) => {
  const redirect = useRedirect();

  function edit() {
    redirect.go(pathForEdit);
  }

  const [showData, setShowData] = useState(true);

  const styleBody = showData ? {} : { padding: 0 };

  return useObserver(() => (
    <Card
      title={
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div>
          <Typography.Title level={4} className='typography-title'>
            {title}
            {subTitle && (
              <>
                <br />
                {subTitle}
              </>
            )}
          </Typography.Title>
        </div>
      }
      className='card'
      extra={
        canEdit && (
          <Popover content="Edit" placement="top">
            <EditOutlined onClick={edit} />
          </Popover>
        )
      }
      bodyStyle={styleBody}
    >
      {showData && <div>{hasData ? <>{children}</> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={noDataDescription} />}</div>}
    </Card>
  ));
};

export default CustomCard;
