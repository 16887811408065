/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { FC, useState } from 'react';
import { Form } from 'antd';
import CustomFormForModal from '../forms/CustomFormForModal';
import TwoColumnsRow from '../forms/TwoColumnsRow';
import ThreeColumnsRow from '../forms/ThreeColumnsRow';
import { CustomInput } from '../forms/CustomInput';
import { useRootStore } from '../root/RootStoreHook';
import CustomModal from '../shared/CustomModal';
import openNotification from '../notifications/Notification';
import { Config, patterns, textAreaConfig, textConfig } from '../profile/models/Config';

interface IProps {
  visible: boolean;
  onCancel?: () => void;
}

const HistoriesModal: FC<IProps> = ({ visible, ...otros }) => {
  const { profile } = useRootStore();
  const history = profile!.history!;
  const [form] = Form.useForm();
  const { onCancel } = otros;
  const [dateValidation, setDateValidation] = useState<'success' | 'error' | undefined>(undefined);
  const [dateErrorMessage, setDateErrorMessage] = useState<'Input a valid date range' | 'Date format should be the same in both fields' | undefined>();
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const getConfig = (required: boolean) => {
    const config: Config = {
      min: undefined,
      max: undefined,
      required,
      email: false,
      whitespace: false
    };

    return config;
  };

  const onDateChange = () => {
    var currentTime = new Date();
    var currentYear = currentTime.getFullYear();
    const values = form.getFieldsValue();
    const { start, end } = values;
    if (patterns.date.startFormat.test(start) && patterns.date.endFormat.test(end)) {
      const startSplit = start.split('/');
      const endSplit = end.split('/');
      let endSplitFormatted = endSplit[0].toLowerCase();
      if(endSplit[0] <= currentYear && startSplit[0] <= currentYear || endSplitFormatted === 'ongoing'){
        if (endSplit[0] > startSplit[0] ||
          (endSplit[0] === startSplit[0] && !endSplit[1] && !startSplit[1]) ||
          (endSplit[0] === startSplit[0] && endSplit[1] >= startSplit[1])
          || endSplitFormatted === 'ongoing') {
          setDateValidation('success');
          setDateErrorMessage(undefined);
          setButtonDisabled(false);
        } else {
          setDateValidation('error');
          setDateErrorMessage('Input a valid date range');
        }
        if (start.includes('/') && !end.includes('/') || !start.includes('/') && end.includes('/')) {
          setDateValidation('error');
          setDateErrorMessage('Date format should be the same in both fields');
          setButtonDisabled(true);
        }
      }else {
        setDateValidation('error');
        setDateErrorMessage(undefined);
      }
    } else {
      setDateValidation(undefined);
      setDateErrorMessage(undefined);
    }
  };

  const handleCancelBtn = () => {
    onCancel?.();
    history.closeModal();
  };

  const onOk = () => {
    if (dateValidation === 'success' || dateValidation === undefined) {
      form
        .validateFields()
        .then((values: any) => {
          history.addOrUpdate(values);
          if (
            !history.selectedItem ||
            (history.selectedItem.responsabilities.length === 0 && history.selectedItem.technologies.length === 0)
          )
            openNotification(
              'Reminder',
              'You can now add responsibilities to your history by expanding its menu.',
              'info',
              5
            );
        })
        .catch(() => { });
    }
  };

  return (
    <CustomModal
      visible={visible}
      title={history.selectedItem ? 'Update' : 'Add'}
      onOk={onOk}
      onCancel={handleCancelBtn}
      disabled={buttonDisabled}
    >
      <CustomFormForModal form={form} initialValues={history.initialValues}>
        <ThreeColumnsRow
          first={<CustomInput name="company" label="Company" pattern={patterns.text.nonSpecial} config={textConfig()} onChange={(evt: any) => evt.currentTarget.value} />}
          second={<CustomInput name="role" label="Role" pattern={patterns.text.technology} config={textConfig()} onChange={(evt: any) => evt.currentTarget.value} />}
          third={<CustomInput name="place" label="Location" pattern={patterns.text.nonSpecial} config={textConfig(30, true)} onChange={(evt: any) => evt.currentTarget.value} />}
        />

        <TwoColumnsRow
          first={
            <CustomInput
              name="start"
              label="Start"
              required
              pattern={patterns.date.startFormat}
              config={getConfig(true)}
              validateStatus={dateValidation}
              toolTipText="YYYY or YYYY/MM"
              onChange={onDateChange}
            />
          }
          second={
            <CustomInput
              name="end"
              label="End"
              required
              pattern={patterns.date.endFormat}
              config={getConfig(true)}
              validateStatus={dateValidation}
              help={dateErrorMessage}
              toolTipText="YYYY, YYYY/MM or Ongoing"
              onChange={onDateChange}
            />
          }
        />

        <CustomInput
          type="textarea"
          name="achievement"
          label="Achievement"
          config={textAreaConfig(true)}
          pattern={patterns.text.technology}
          onChange={(evt: any) => evt.currentTarget.value}
        />

      </CustomFormForModal>
    </CustomModal>
  );
};

export default HistoriesModal;
