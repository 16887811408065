import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import PositionEntry from './Position/PositionEntry';
import AdditionalPositionEntry from './AdditionalPosition/AdditionalPositionEntry';
import CommunityEntry from './Community/CommunityEntry';
import LevelEntry from './Level/LevelEntry';
import StudioEntry from './Studio/StudioEntry';
import './DropdownOptionsDisplay.css'

export const options = {
  POSITION: 'Position',
  SKILL_CLASSIFICATION: 'Skill Classification',
  SKILL_CATEGORY: 'Skill Category',
  ADDITIONAL_POSITION: 'Additional position',
  LEVEL: 'Level',
  COMMUNITY: 'Community',
  STUDIO: 'Studio',
};

const DropdownOptionsDisplay: FC = () => {
  return useObserver(() => {
    return (
      <div className="largeMaxWidth custom-header-item">
        <div className='container-grid'>
          <PositionEntry />
          <AdditionalPositionEntry />
          <LevelEntry />
          <CommunityEntry />
          <StudioEntry />
        </div>
      </div>
    );
  });
};

export default DropdownOptionsDisplay;
