import React, { FC, useRef } from 'react';
import { useObserver } from 'mobx-react-lite';
import { Divider } from 'antd';
import { EditOutlined, HolderOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import { useRootStore } from '../root/RootStoreHook';
import { HighlightItem } from './models/HighlightItem';
import DeleteItemConfirm from '../shared/DeleteItemConfirm';
import HighlightModal from './HighlightModal';
import CustomHeader from '../forms/CustomHeader';
import CustomButtons from '../shared/CustomButtons';
import CustomAddRow from '../shared/CustomAddRow';
import CustomDragableTable from '../shared/CustomDragableTable';
import { Routes } from '../app/routing/Routes';
import { useRedirect } from '../hooks/HooksUtil';
import { useWizardMode } from '../stepper/WizardMode';
import BoldColumnTitle from '../shared/BoldColumnTitle';
import CustomPrompt from '../shared/CustomPrompt';

interface IProps {
  id?: any;
}

const HighlightsEntry: FC = () => {
  const root = useRootStore();
  const highlight = root.profile!.highlight!;
  const redirect = useRedirect();
  const wizardMode = useWizardMode();

  const formChanged = useRef(false);
  const setFormChanged = (value: boolean) => {
    formChanged.current = value;
  };

  const onSubmit = async () => {
    await highlight.save();
    redirect.go(Routes.Profile);
  };

  const handleDeleteItem: FC<IProps> = ({ id }) => {
    setFormChanged(true);
    highlight.delete(id);
    return null;
  };

  const handleCancel = () => {
    setFormChanged(false);
  };

  const moveRow = (fromIndex: number, toIndex: number) => {
    if (fromIndex !== toIndex) {
      setFormChanged(true);
    }
    root.dragAndDrop(fromIndex, toIndex, highlight!.items);
  };
  const columns: ColumnProps<HighlightItem>[] = [
    {
      title: <BoldColumnTitle title="Description" />,
      dataIndex: 'description',
      key: 'description',
      render: (text: string, record: HighlightItem) => (
        // eslint-disable-next-line react/no-danger
        <div>
          {record.description}
        </div>
      ),
    },
    {
      title: <BoldColumnTitle title="Actions" />,
      key: 'actions',
      align: 'right',
      render: (item: HighlightItem) => (
        <div className="optional-vertical-divider">
          <EditOutlined onClick={() => highlight.edit(item.id)} />
          <Divider type="vertical" />
          <DeleteItemConfirm onConfirm={() => handleDeleteItem({ id: item.id })} />
          <Divider type="vertical" />
          <HolderOutlined />
        </div>
      ),
    },
  ];

  const highlightsDescription =
    'In this section you should list the key elements of your experience. Add each highlight in a separate item.';

  return useObserver(() => (
    <div className={wizardMode ? 'largeMaxWidth' : 'mediumMaxWidth'} onChange={() => setFormChanged(true)}>
      <div className='header-container'>
        <CustomHeader title="Highlights" />
        <p className='header-paragraph'>{highlightsDescription}</p>
      </div>
      <CustomPrompt when={formChanged.current} />
      <CustomAddRow onCreate={() => highlight.create()} id="highlights"/>
      <CustomDragableTable
        draggableKey="Highlight"
        columns={columns}
        dataSource={highlight.sortedItemsForTable}
        moveRow={moveRow}
        showHeader={false}
        style={{}}
        locale="You didn’t add any highlight yet."
      />
      {highlight.showModal && <HighlightModal visible={highlight.showModal} onCancel={handleCancel} />}
      <CustomButtons onSubmit={onSubmit} />
    </div>
  ));
};

export default HighlightsEntry;
