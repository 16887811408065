/* eslint-disable no-nested-ternary */
import { Instance, types, flow, cast } from 'mobx-state-tree';
import { axiosInstance } from '../../../http/agent';
import { DropdownConfig } from './DropdownConfig';
import { GeneratorHelper } from '../../shared/Helpers';
import { options } from './DropdownOptionsDisplay';
import { sortBy } from '../../app/Utils';

const DropdownItem = types.model('DropdownItem', {
    id: types.identifierNumber,
    description: types.string,
    personalInfoIds: types.array(types.number),
});
export interface DropdownItem extends Instance<typeof DropdownItem> { }

export const PositionItem = DropdownItem.named('PositionItem');
export const SkillClassificationItem = DropdownItem.named('SkillClassificationItem');
export const SkillCategoryItem = DropdownItem.named('SkillCategoryItem');
export const LevelItem = DropdownItem.named('LevelItem');
export const CommunityItem = DropdownItem.named('CommunityItem');
export const RoleItem = DropdownItem.named('RoleItem');
export const AdditionalPositionItem = DropdownItem.named('AdditionalPositionItem');
export const StudioItem = DropdownItem.named('StudioItem');

export interface PositionItem extends Instance<typeof PositionItem> { }
export interface SkillClassificationItem extends Instance<typeof SkillClassificationItem> { }
export interface SkillCategoryItem extends Instance<typeof SkillCategoryItem> { }
export interface LevelItem extends Instance<typeof LevelItem> { }
export interface CommunityItem extends Instance<typeof CommunityItem> { }
export interface AdditionalPositionItem extends Instance<typeof AdditionalPositionItem> { }
export interface StudioItem extends Instance<typeof StudioItem> { }
export interface RoleItem extends Instance<typeof RoleItem> { }

export const DropdownOptionsStore = types

    .model('DropdownOptionsStore', {
        positionOptions: types.array(PositionItem),
        skillClassificationOptions: types.array(SkillClassificationItem),
        skillCategoryOptions: types.array(SkillCategoryItem),
        communityOptions: types.array(CommunityItem),
        roleOptions: types.array(RoleItem),
        additionalPositionOptions: types.array(AdditionalPositionItem),
        levelOptions: types.array(LevelItem),
        studioOptions: types.array(StudioItem),
        selectedPositionItem: types.maybe(types.reference(PositionItem)),
        selectedSkillClassificationItem: types.maybe(types.reference(SkillClassificationItem)),
        selectedSkillCategoryItem: types.maybe(types.reference(SkillCategoryItem)),
        selectedAdditionalPositionItem: types.maybe(types.reference(AdditionalPositionItem)),
        selectedLevelItem: types.maybe(types.reference(LevelItem)),
        selectedCommunityItem: types.maybe(types.reference(CommunityItem)),
        selectedStudioItem: types.maybe(types.reference(StudioItem)),
        showPositionModal: types.optional(types.boolean, false),
        showSkillCategoryModal: types.optional(types.boolean, false),
        showSkillClassificationModal: types.optional(types.boolean, false),
        showAdditionalPositionModal: types.optional(types.boolean, false),
        showLevelModal: types.optional(types.boolean, false),
        showCommunityModal: types.optional(types.boolean, false),
        showDropdownOptionModal: types.optional(types.boolean, false),
        showStudioModal: types.optional(types.boolean, false),
        completed: types.array(types.boolean),
        config: types.optional(DropdownConfig, {
            id: { required: true },
        }),
    })
    .views((self) => ({
        get positionsForDropdown() {
            return self.positionOptions.map((x: PositionItem) => ({ key: x.id, ...x }));
        },
        get skillCategoriesForDropdown() {
            return self.skillCategoryOptions.map((x: SkillCategoryItem) => ({ key: x.id, ...x }));
        },
        get skillClassificationForDropdown() {
            return self.skillClassificationOptions.map((x: SkillClassificationItem) => ({ key: x.id, ...x }));
        },
        get additionalPositionsForDropdown() {
            return self.additionalPositionOptions.map((x: AdditionalPositionItem) => ({ key: x.id, ...x }));
        },
        get levelsForDropdown() {
            return self.levelOptions.map((x: LevelItem) => ({ key: x.id, ...x }));
        },
        get communitiesForDropdown() {
            return self.communityOptions.map((x: CommunityItem) => ({ key: x.id, ...x }));
        },
        get studiosForDropdown() {
            return self.studioOptions.map((x: PositionItem) => ({ key: x.id, ...x }));
        },
        get communityFilters() {
            return self.communityOptions.map((x) => ({ value: x.id, text: x.description })).sort(sortBy('text'));
        },
        get positionFilters() {
            return self.positionOptions.map((x) => ({ value: x.id, text: x.description })).sort(sortBy('text'));
        },
        get skillClassificationFilters() {
            return self.skillClassificationOptions.map((x) => ({ value: x.id, text: x.description })).sort(sortBy('text'));
        },
        get skillCategoryFilters() {
            return self.skillCategoryOptions.map((x) => ({ value: x.id, text: x.description })).sort(sortBy('text'));
        },
        get additionalPositionFilters() {
            return self.additionalPositionOptions.map((x) => ({ value: x.id, text: x.description })).sort(sortBy('text'));
        },
        get rolesFilters() {
            return self.roleOptions.map((x) => ({ value: x.id, text: x.description })).sort(sortBy('text'));
        },
        getPositionValue(positionId: number): string {
            return self.positionOptions.find((x) => x.id === positionId)?.description || '';
        },
        getSkillClassificationValue(skillClassificationId: number | null): string {
            return self.skillClassificationOptions.find((x) => x.id === skillClassificationId)?.description || '';
        },
        getSkillCategoryValue(skillCategoryId: number | null): string {
            return self.skillCategoryOptions.find((x) => x.id === skillCategoryId)?.description || '';
        },
        getStudioValue(studioId: number): string {
            return self.studioOptions.find((x) => x.id === studioId)?.description || '';
        },
        getAdditionalPositionValue(additionalPositionId: number): string {
            return self.additionalPositionOptions.find((x) => x.id === additionalPositionId)?.description || '';
        },
        getAdditionalPositionsValues(additionalPositionId: any): string {
            const addicionalPositions: any = self.additionalPositionOptions.filter((x) => additionalPositionId.includes(x.id))
            let descriptionTitle = ''
            if (addicionalPositions.length) {
                descriptionTitle = addicionalPositions.map((item: any) => item.description).join(", ")
            }

            return descriptionTitle
        },
        getCommunityValue(communityId: any): string {
            const communities: any = self.communityOptions.find((x) => communityId === x.id);
            return communities?.description || '';
        },
        getCommunitiesValue(communityId: any): string {
            const communities: any = self.communityOptions.filter((x) => communityId.includes(x.id))
            let descriptionTitle = ''
            if (communities.length) {
                descriptionTitle = communities.map((item: any) => item.description).join(", ")
            }
            return descriptionTitle
        },
        getRoleValue(roleId: number): string {
            return self.roleOptions.find((x) => x.id === roleId)?.description || '';
        },
        getLevelValue(levelId: number): string {
            return self.levelOptions.find((x) => x.id === levelId)?.description || '';
        },
    }))
    .actions((self) => ({
        setSelectedPositionItem(item?: PositionItem) {
            self.selectedPositionItem = item;
        },
        setSelectedSkillClassificationItem(item?: SkillClassificationItem) {
            self.selectedSkillClassificationItem = item;
        },
        setSelectedSkillCategoryItem(item?: SkillCategoryItem) {
            self.selectedSkillCategoryItem = item;
        },
        setSelectedAdditionalPositionItem(item?: AdditionalPositionItem) {
            self.selectedAdditionalPositionItem = item;
        },
        setSelectedCommunityItem(item?: CommunityItem) {
            self.selectedCommunityItem = item;
        },
        setSelectedLevelItem(item?: LevelItem) {
            self.selectedLevelItem = item;
        },
        setSelectedStudioItem(item?: StudioItem) {
            self.selectedStudioItem = item;
        },
        openOptionModal(option: string, itemId?: number) {
            let selectedItem;
            switch (option) {
                case options.POSITION:
                    selectedItem = self.positionOptions.find((x: PositionItem) => x.id === itemId);
                    this.setSelectedPositionItem(selectedItem);
                    self.showPositionModal = true;
                    break;
                case options.SKILL_CATEGORY:
                    selectedItem = self.skillCategoryOptions.find((x: SkillCategoryItem) => x.id === itemId);
                    this.setSelectedSkillCategoryItem(selectedItem);
                    self.showSkillCategoryModal = true;
                    break;
                case options.SKILL_CLASSIFICATION:
                    selectedItem = self.skillClassificationOptions.find((x: SkillClassificationItem) => x.id === itemId);
                    this.setSelectedSkillClassificationItem(selectedItem);
                    self.showSkillClassificationModal = true;
                    break;

                case options.ADDITIONAL_POSITION:
                    selectedItem = self.additionalPositionOptions.find((x: AdditionalPositionItem) => x.id === itemId);
                    this.setSelectedAdditionalPositionItem(selectedItem);
                    self.showAdditionalPositionModal = true;
                    break;
                case options.LEVEL:
                    selectedItem = self.levelOptions.find((x: LevelItem) => x.id === itemId);
                    this.setSelectedLevelItem(selectedItem);
                    self.showLevelModal = true;
                    break;
                case options.COMMUNITY:
                    selectedItem = self.communityOptions.find((x: CommunityItem) => x.id === itemId);
                    this.setSelectedCommunityItem(selectedItem);
                    self.showCommunityModal = true;
                    break;
                case options.STUDIO:
                    selectedItem = self.studioOptions.find((x: StudioItem) => x.id === itemId);
                    this.setSelectedStudioItem(selectedItem);
                    self.showStudioModal = true;
                    break;
                default:
                    break;
            }
        },
        test() { },
        closeModal(option: string) {
            switch (option) {
                case options.POSITION:
                    self.showPositionModal = false;
                    this.setSelectedPositionItem(undefined);
                    break;
                case options.SKILL_CATEGORY:
                    self.showSkillCategoryModal = false;
                    this.setSelectedSkillCategoryItem(undefined);
                    break;
                case options.SKILL_CLASSIFICATION:
                    self.showSkillClassificationModal = false;
                    this.setSelectedSkillClassificationItem(undefined);
                    break;
                case options.ADDITIONAL_POSITION:
                    self.showAdditionalPositionModal = false;
                    this.setSelectedAdditionalPositionItem(undefined);
                    break;
                case options.LEVEL:
                    self.showLevelModal = false;
                    this.setSelectedLevelItem(undefined);
                    break;
                case options.COMMUNITY:
                    self.showCommunityModal = false;
                    this.setSelectedCommunityItem(undefined);
                    break;
                case options.STUDIO:
                    self.showStudioModal = false;
                    this.setSelectedStudioItem(undefined);
                    break;
                default:
                    break;
            }
        },
        async addPosition(description: string) {
            const newPositionItem: PositionItem = {
                id: GeneratorHelper.getNextId(self.positionOptions.slice()),
                description,
                personalInfoIds: cast([]),
            };
            this.storePosition(newPositionItem);
            this.persistPosition(newPositionItem);
        },
        async addSkillCategory(description: string) {
            const newskillCategoryItem: SkillCategoryItem = {
                id: GeneratorHelper.getNextId(self.skillCategoryOptions.slice()),
                description,
                personalInfoIds: cast([]),
            };
            this.storeSkillCategory(newskillCategoryItem);
            this.persistSkillCategory(newskillCategoryItem);
        },
        async addSkillClassification(description: string) {
            const newSkillClassificationItem: SkillClassificationItem = {
                id: GeneratorHelper.getNextId(self.skillClassificationOptions.slice()),
                description,
                personalInfoIds: cast([]),
            };
            this.storeSkillClassification(newSkillClassificationItem);
            this.persistSkillClassification(newSkillClassificationItem);
        },
        async persistPosition(newPositionItem: PositionItem) {
            try {
                await axiosInstance.post('/admin/position', newPositionItem);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
                const deleteItem = self.positionOptions.find((x) => x.id === newPositionItem.id);
                this.deleteStoredPositionItem(deleteItem!);
            }
        },
        async persistSkillCategory(newSkillCategoryItem: SkillCategoryItem) {
            try {
                await axiosInstance.post('/admin/SkillCategory', newSkillCategoryItem);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
                const deleteItem = self.skillCategoryOptions.find((x) => x.id === newSkillCategoryItem.id);
                this.deleteStoredSkillCategoryItem(deleteItem!);
            }
        },
        async persistSkillClassification(newSkillClassificationItem: SkillClassificationItem) {
            try {
                await axiosInstance.post('/admin/SkillClassification', newSkillClassificationItem);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
                const deleteItem = self.skillClassificationOptions.find((x) => x.id === newSkillClassificationItem.id);
                this.deleteStoredSkillClassificationItem(deleteItem!);
            }
        },
        storePosition(newPositionItem: PositionItem) {
            self.positionOptions.push(newPositionItem);
        },
        storeSkillClassification(newSkillClassificationItem: SkillClassificationItem) {
            self.skillClassificationOptions.push(newSkillClassificationItem);
        },
        storeSkillCategory(newSkillCategoryItem: SkillCategoryItem) {
            self.skillCategoryOptions.push(newSkillCategoryItem);
        },
        updatePosition: flow(function* updatePosition(id: number, description: string): any {
            try {
                const index = self.positionOptions.findIndex((x: PositionItem) => x === self.selectedPositionItem!);
                yield axiosInstance.put('/admin/position', { id, description });
                self.positionOptions[index].description = description;
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }
        }),
        updateSkillCategory: flow(function* updateSkillCategory(id: number, description: string): any {
            try {
                const index = self.skillCategoryOptions.findIndex((x: SkillCategoryItem) => x === self.selectedSkillCategoryItem!);
                yield axiosInstance.put('/admin/SkillCategory', { id, description });
                self.skillCategoryOptions[index].description = description;
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }
        }),
        updateSkillClassification: flow(function* updateSkillClassification(id: number, description: string): any {
            try {
                const index = self.skillClassificationOptions.findIndex((x: SkillClassificationItem) => x === self.selectedSkillClassificationItem!);
                yield axiosInstance.put('/admin/SkillClassification', { id, description });
                self.skillClassificationOptions[index].description = description;
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }
        }),
        addOrUpdatePosition(item: PositionItem) {
            if (self.selectedPositionItem) {
                this.updatePosition(self.selectedPositionItem.id, item.description);
            } else {
                this.addPosition(item.description);
            }
            this.closeModal(options.POSITION);
        },
        addOrUpdateSkillClassification(item: SkillClassificationItem) {
            if (self.selectedSkillClassificationItem) {
                this.updateSkillClassification(self.selectedSkillClassificationItem.id, item.description);
            } else {
                this.addSkillClassification(item.description);
            }
            this.closeModal(options.SKILL_CLASSIFICATION);
        },
        addOrUpdateSkillCategory(item: SkillCategoryItem) {
            if (self.selectedSkillCategoryItem) {
                this.updateSkillCategory(self.selectedSkillCategoryItem.id, item.description);
            } else {
                this.addSkillCategory(item.description);
            }
            this.closeModal(options.SKILL_CATEGORY);
        },
        deleteStoredPositionItem(item: PositionItem) {
            self.positionOptions.remove(item);
        },
        deleteStoredSkillCategoryItem(item: SkillCategoryItem) {
            self.skillCategoryOptions.remove(item);
        },
        deleteStoredSkillClassificationItem(item: SkillClassificationItem) {
            self.skillClassificationOptions.remove(item);
        },
        async deletePosition(id: number) {
            try {
                const item = self.positionOptions.find((x: PositionItem) => x.id === id)!;
                if (item.personalInfoIds.length > 0) {
                    return;
                }
                await axiosInstance.delete(`/admin/position/${id}`);
                this.deleteStoredPositionItem(item);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }
        },
        async deleteSkillCategory(id: number) {
            try {
                const item = self.skillCategoryOptions.find((x: SkillCategoryItem) => x.id === id)!;
                if (item.personalInfoIds.length > 0) {
                    return;
                }
                await axiosInstance.delete(`/admin/SkillCategory/${id}`);
                this.deleteStoredSkillCategoryItem(item);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }
        },
        async deleteSkillCLassification(id: number) {
            try {
                const item = self.skillClassificationOptions.find((x: SkillClassificationItem) => x.id === id)!;
                if (item.personalInfoIds.length > 0) {
                    return;
                }
                await axiosInstance.delete(`/admin/SkillClassification/${id}`);
                this.deleteStoredSkillClassificationItem(item);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }
        },
        addLevel(description: string) {
            const newLevelItem: LevelItem = {
                id: GeneratorHelper.getNextId(self.levelOptions.slice()),
                description,
                personalInfoIds: cast([]),
            };
            self.levelOptions.push(newLevelItem);
            this.persistLevel(newLevelItem);
        },
        async persistLevel(newLevelItem: LevelItem) {
            try {
                await axiosInstance.post('/admin/level', newLevelItem);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
                const deleteItem = self.levelOptions.find((x) => x.id === newLevelItem.id);
                this.deleteStoredLevelItem(deleteItem!);
            }
        },
        updateLevel: flow(function* updateLevel(id: number, description: string): any {
            try {
                const index = self.levelOptions.findIndex((x: LevelItem) => x === self.selectedLevelItem);
                yield axiosInstance.put('/admin/level', { id, description });
                self.levelOptions[index].description = description;
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }
        }),
        addOrUpdateLevel(item: LevelItem) {
            if (self.selectedLevelItem) {
                this.updateLevel(self.selectedLevelItem.id, item.description);
            } else {
                this.addLevel(item.description);
            }
            this.closeModal(options.LEVEL);
        },
        deleteStoredLevelItem(item: LevelItem) {
            self.levelOptions.remove(item);
        },
        async deleteLevel(id: number) {
            try {
                const item = self.levelOptions.find((x: LevelItem) => x.id === id)!;
                if (item.personalInfoIds.length > 0) {
                    return;
                }
                await axiosInstance.delete(`/admin/level/${id}`);
                this.deleteStoredLevelItem(item);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }
        },
        addCommunity(description: string) {
            const newCommunityItem: CommunityItem = {
                id: GeneratorHelper.getNextId(self.communityOptions.slice()),
                description,
                personalInfoIds: cast([]),
            };
            self.communityOptions.push(newCommunityItem);
            this.persistCommunity(newCommunityItem);
        },
        async persistCommunity(newCommunityItem: CommunityItem) {
            try {
                await axiosInstance.post('/admin/community', newCommunityItem);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
                const deleteItem = self.communityOptions.find((x) => x.id === newCommunityItem.id);
                this.deleteStoredCommunityItem(deleteItem!);
            }
        },
        updateCommunity: flow(function* updateCommunity(id: number, description: string): any {
            try {
                const index = self.communityOptions.findIndex((x: CommunityItem) => x === self.selectedCommunityItem);
                yield axiosInstance.put('/admin/community', { id, description });
                self.communityOptions[index].description = description;
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }
        }),
        addOrUpdateCommunity(item: CommunityItem) {
            if (self.selectedCommunityItem) {
                this.updateCommunity(self.selectedCommunityItem.id, item.description);
            } else {
                this.addCommunity(item.description);
            }
            this.closeModal(options.COMMUNITY);
        },
        deleteStoredCommunityItem(item: CommunityItem) {
            self.communityOptions.remove(item);
        },
        async deleteCommunity(id: number) {
            try {
                const item = self.communityOptions.find((x: CommunityItem) => x.id === id)!;
                if (item.personalInfoIds.length > 0) {
                    return;
                }
                await axiosInstance.delete(`/admin/community/${id}`);
                this.deleteStoredCommunityItem(item);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }
        },
        addAdditionalPosition(description: string) {
            const newAdditionalPositionItem: AdditionalPositionItem = {
                id: GeneratorHelper.getNextId(self.additionalPositionOptions.slice()),
                description,
                personalInfoIds: cast([]),
            };
            self.additionalPositionOptions.push(newAdditionalPositionItem);
            this.persistAdditionalPosition(newAdditionalPositionItem);
        },
        async persistAdditionalPosition(newAdditionalPositionItem: AdditionalPositionItem) {
            try {
                await axiosInstance.post('/admin/additional-position', newAdditionalPositionItem);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
                const deleteItem = self.additionalPositionOptions.find((x) => x.id === newAdditionalPositionItem.id);
                this.deleteStoredAdditionalPositionItem(deleteItem!);
            }
        },
        updateAdditionalPosition: flow(function* updateAdditionalPosition(id: number, description: string): any {
            try {
                const index = self.additionalPositionOptions.findIndex(
                    (x: AdditionalPositionItem) => x === self.selectedAdditionalPositionItem
                );
                yield axiosInstance.put('/admin/additional-position', { id, description });
                self.additionalPositionOptions[index].description = description;
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }
        }),
        addOrUpdateAdditionalPosition(item: AdditionalPositionItem) {
            if (self.selectedAdditionalPositionItem) {
                this.updateAdditionalPosition(self.selectedAdditionalPositionItem.id, item.description);
            } else {
                this.addAdditionalPosition(item.description);
            }
            this.closeModal(options.ADDITIONAL_POSITION);
        },
        deleteStoredAdditionalPositionItem(item: AdditionalPositionItem) {
            self.additionalPositionOptions.remove(item);
        },
        async deleteAdditionalPosition(id: number) {
            try {
                const item = self.additionalPositionOptions.find((x: AdditionalPositionItem) => x.id === id)!;
                if (item.personalInfoIds.length > 0) {
                    return;
                }
                await axiosInstance.delete(`/admin/additional-position/${id}`);
                this.deleteStoredAdditionalPositionItem(item);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }
        },
        addStudio(description: string) {
            const newStudioItem: StudioItem = {
                id: GeneratorHelper.getNextId(self.studioOptions.slice()),
                description,
                personalInfoIds: cast([]),
            };
            self.studioOptions.push(newStudioItem);
            this.persistStudio(newStudioItem);
        },
        async persistStudio(newStudioItem: StudioItem) {
            try {
                await axiosInstance.post('/admin/studio', newStudioItem);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
                const deleteItem = self.studioOptions.find((x) => x.id === newStudioItem.id);
                this.deleteStoredStudioItem(deleteItem!);
            }
        },
        updateStudio: flow(function* updateStudio(id: number, description: string): any {
            try {
                const index = self.studioOptions.findIndex((x: StudioItem) => x === self.selectedStudioItem);
                yield axiosInstance.put('/admin/studio', { id, description });
                self.studioOptions[index].description = description;
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }
        }),
        addOrUpdateStudio(item: StudioItem) {
            if (self.selectedStudioItem) {
                this.updateStudio(self.selectedStudioItem.id, item.description);
            } else {
                this.addStudio(item.description);
            }
            this.closeModal(options.STUDIO);
        },
        deleteStoredStudioItem(item: StudioItem) {
            self.studioOptions.remove(item);
        },
        async deleteStudio(id: number) {
            try {
                const item = self.studioOptions.find((x: StudioItem) => x.id === id)!;
                if (item.personalInfoIds.length > 0) {
                    return;
                }
                await axiosInstance.delete(`/admin/studio/${id}`);
                this.deleteStoredStudioItem(item);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }
        },
        deleteOption(option: string, itemId: number) {
            switch (option) {
                case options.POSITION:
                    this.deletePosition(itemId);
                    break;
                case options.SKILL_CATEGORY:
                    this.deleteSkillCategory(itemId);
                    break;
                case options.SKILL_CLASSIFICATION:
                    this.deleteSkillCLassification(itemId);
                    break;
                case options.ADDITIONAL_POSITION:
                    this.deleteAdditionalPosition(itemId);
                    break;
                case options.LEVEL:
                    this.deleteLevel(itemId);
                    break;
                case options.COMMUNITY:
                    this.deleteCommunity(itemId);
                    break;
                case options.STUDIO:
                    this.deleteStudio(itemId);
                    break;
                default:
                    break;
            }
        },
    }));

export interface DropdownOptionsStore extends Instance<typeof DropdownOptionsStore> { }

export async function getDropdownOptions() {
    const { data } = await axiosInstance.get('/dropdown-options');
    const {
        positionOptions,
        skillClassificationOptions,
        skillCategoryOptions,
        studioOptions,
        communityOptions,
        additionalPositionOptions,
        levelOptions,
        roleOptions,
    } = data;

    return {
        positionOptions,
        skillClassificationOptions,
        skillCategoryOptions,
        studioOptions,
        communityOptions,
        additionalPositionOptions,
        levelOptions,
        roleOptions,
    };
}
