/* eslint-disable import/order */
import React, { FC, useEffect, useState} from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useObserver } from 'mobx-react-lite';
import { Button, Layout } from 'antd';
import { useRootStore } from '../root/RootStoreHook';
import { ReactComponent as Logo } from '../../assets/CVcreator.svg';
import './navbar.css';
import { UserOutlined } from '@ant-design/icons';

const { Header } = Layout;


const Navbar: FC = () => {
  const rootStore = useRootStore();
  const { user } = rootStore;
  const location = useLocation();
  const [hiddenUser, setHiddenUser] = useState(false);
  
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  
  useEffect(() => {
    scrollToTop();
    if (location.pathname ==='/wizard') {
      setHiddenUser(true);
    } else{
      setHiddenUser(false);
    }
  }, [location]);

  return useObserver(() => {
    return (
      <Header className="header">
        <Button className="burger" onClick={() => rootStore.setIsSidebarOpen()}>
          <div className="line" />
          <div className="line" />
          <div className="line" />
        </Button>
        <a
          href="https://www.cognizantsoftvision.com/"
          aria-label="Cognizant Softvision"
          target="_blank"
          rel="noopener noreferrer"
          className="link"
        >
          <Logo className="logo" />
        </a>
        <div className={`header__user ${hiddenUser && 'hidden_user'}`}>
          <NavLink exact to={`/profile/${user?.idProfile}`}>
            <span className="header__user-text">
              {`${user?.displayName}`}
            </span>
            <UserOutlined className="header__user-icon" />
          </NavLink>
        </div>
      </Header>
    );
  });
};

export default Navbar;
