import { Instance, types, flow } from 'mobx-state-tree';
import { axiosInstance } from '../../http/agent';
import { getRootStore } from '../root/RootStoreUtils';
import { PersonalInfoConfig } from './PersonalInfoConfig';
import { Config, textConfig } from 'components/profile/models/Config';

export const PersonalInfoStore = types
  .model('PersonalInfoStore', {
    firstName: types.string,
    lastName: types.string,
    positionId: types.maybeNull(types.number),
    studioId: types.maybeNull(types.number),
    communityId: types.maybeNull(types.number),
    communityPersonalInfoId: types.maybeNull(types.array(types.number)),
    additionalPositionId: types.maybeNull(types.number),
    additionalPositionPersonalInfoId: types.maybeNull(types.array(types.number)),
    email: types.string,
    phone: types.string,
    cognizantId: types.maybeNull(types.string),
    config: types.optional(PersonalInfoConfig, {
      firstName: textConfig(),
      lastName: textConfig(),
      positionId: { required: true } as Config,
      studioId: { required: true } as Config,
      communityId: { required: true } as Config,
      communityPersonalInfoId: { required: false } as Config,
      additionalPositionId: { required: false } as Config,
      additionalPositionPersonalInfoId: { required: false } as Config,
      email: { email: true, max: 80, min: 1, required: true } as Config,
      phone: { min: 8, max: 30 } as Config,
      cognizantId: { required: true, max: 10, min: 3 } as Config,
    }),
  })
  .actions((self) => ({
    updateStore(form: any): void {
      self.firstName = form.firstName;
      self.lastName = form.lastName;
      self.positionId = form.positionId;
      self.studioId = form.studioId;
      self.communityId = Array.isArray(form.communityPersonalInfoId)
        ? form.communityPersonalInfoId[0]
        : form.communityPersonalInfoId;
      self.communityPersonalInfoId = form.communityPersonalInfoId;
      self.additionalPositionId = Array.isArray(form.additionalPositionPersonalInfoId)
        ? form.additionalPositionPersonalInfoId[0]
        : form.additionalPositionPersonalInfoId;
      self.additionalPositionPersonalInfoId = form.additionalPositionPersonalInfoId;
      self.email = form.email;
      self.phone = form.phone;
      self.cognizantId = `${form.cognizantId || self.cognizantId}`;
    },
  }))
  .views((self) => ({
    get hasData(): boolean {
      return (
        self.firstName?.length > 0 &&
        self.lastName?.length > 0 &&
        self.positionId! > 0 &&
        self.studioId! > 0 &&
        self.email !== ''
      );
    },

    get fullName(): string {
      return `${self.firstName} ${self.lastName}`;
    },

    get formValues(): any {
      return {
        firstName: self.firstName,
        lastName: self.lastName,
        positionId: self.positionId ?? undefined,
        studioId: self.studioId ?? undefined,
        communityId: self.communityId ?? undefined,
        communityPersonalInfoId: self.communityPersonalInfoId ?? undefined,
        additionalPositionId: self.additionalPositionId ?? undefined,
        additionalPositionPersonalInfoId: self.additionalPositionPersonalInfoId ?? undefined,
        email: self.email,
        phone: self.phone,
        cognizantId: `${self.cognizantId || ''}`
      };
    },
  }))
  .actions((self) => ({
    save: flow(function* save(values: any): any {
      self.updateStore(values);
      const { showLoading, hideLoading, profile, user } = getRootStore(self);
      const username = user?.displayName;
      const data = self.formValues;

      Object.assign(data, {
        profileId: profile!.id,
        DisplayName: username,
      });
      try {
        showLoading();
        yield axiosInstance.put(`/personal-info/${profile!.id}`, {
          ...data,
          additionalPositionPersonalInfoId: values.additionalPositionPersonalInfoId,
          communityPersonalInfoId: values.communityPersonalInfoId,
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      } finally {
        hideLoading();
      }
    }),
  }));

export interface PersonalInfoStore extends Instance<typeof PersonalInfoStore> {}
