import React, { FC } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './LoadingIcon.css';

const icon = <LoadingOutlined className='loading-outlined' spin />;

const LaodingIcon: FC = () => <Spin indicator={icon} />;

export default LaodingIcon;
