/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { FC, useEffect } from 'react';
import { Button } from 'antd';
import { useRootStore } from '../root/RootStoreHook';
import { useRedirect } from '../hooks/HooksUtil';
import { Routes } from '../app/routing/Routes';
import rol, { isRol } from '../login/roles';
import { ReactComponent as Arrow } from '../../assets/right-arrow.svg';
import { tabKeys } from '../sidebar/Sidebar';
import Hero from '../../assets/four-oh-four.png';
import './fourOhFour.css';

const FourOhFour: FC = () => {
  const redirect = useRedirect();
  const rootStore = useRootStore();

  const { user } = rootStore;
  const userRole = user?.rol;

  useEffect(() => {
    document.getElementById('hero')?.addEventListener('load', () => {
      document.getElementById('four-oh-four-content')?.classList.add('show');
    });
    if (rootStore.forcedTab !== '') rootStore.updateForcedTab(tabKeys.FOUR_OH_FOUR);
  }, []);

  const handleClick = () => redirect.go(isRol([rol.ADMIN], userRole) ? Routes.Profiles : Routes.Profile);

  return (
    <>
      <div className="imageContainer">
        <img className="image" id="hero" alt="" src={Hero} />
      </div>

      <div className="content" id="four-oh-four-content">
        <div className="text">
          <h1>404 Error</h1>
          <h2>Oh No! You’ve uncovered a page that doesn’t exist</h2>
          <h4>Don't worry though, our engineers have designed and engineered something that can help.</h4>
        </div>

        <div className="backToHome">
          <Arrow />
          <div className="gradientContainer">
            <Button onClick={handleClick}>Go back home</Button>
            <div className="gradient">
              <div className="gradientAnimation" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FourOhFour;
