import React from 'react';
import { Routes } from '../../app/routing/Routes';
import { useRedirect } from '../../hooks/HooksUtil';
import { useRootStore } from '../../root/RootStoreHook';
import { ReactComponent as Arrow } from '../../../assets/right-arrow-gradient.svg';

const InnerSidebarItem = ({
  closeSettingsMenu,
  route,
  text,
}: {
  closeSettingsMenu: () => void;
  route: Routes;
  text: string;
}) => {
  const rootStore = useRootStore();
  const { setIsSidebarOpen } = rootStore;
  const redirect = useRedirect();

  const handleRedirect = () => {
    setIsSidebarOpen();
    closeSettingsMenu();
    redirect.go(route);
  };
  return (
    <button type="button" onClick={() => handleRedirect()}>
      <div>
        <div className="left-arrow">
          <div className="grow">
            <div className="line" />
            <Arrow />
          </div>
        </div>

        <div className="right-arrow">{text}</div>
      </div>
    </button>
  );
};

export default InnerSidebarItem;
