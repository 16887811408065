/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Instance, types, flow, onPatch } from 'mobx-state-tree';
import moment from 'moment';
import { axiosInstance } from '../../http/agent';
import { HistoryItem } from './models/HistoryItem';
import { getRootStore } from '../root/RootStoreUtils';
import { GeneratorHelper } from '../shared/Helpers';

export const HistoriesStore = types
  .model('HistoriesStore', {
    items: types.array(HistoryItem),
    showModal: types.optional(types.boolean, false),
    selectedItem: types.maybe(types.reference(HistoryItem)),
    minLength: types.optional(types.number, 1),
  })
  .views((self) => ({
    get validationMessage(): string {
      return `At least ${self.minLength} Work Experience is required.`;
    },
    get sortedItems(): HistoryItem[] {
      return self.items.slice().sort((a: HistoryItem, b: HistoryItem) => b.order - a.order);
    },
    get sortedItemsbyEnd(): HistoryItem[] {
      const getFormat = (date: string) => (date.length > 4 ? 'YYYY/MM' : 'YYYY');
      const onGoingHistories = self.items.filter((i) => !moment(i.end, getFormat(i.end)).isValid());
      const finishedHistories = self.items
        .filter((i) => moment(i.end, getFormat(i.end)).isValid())
        .sort((a: HistoryItem, b: HistoryItem) => new Date(b.end).getTime() - new Date(a.end).getTime());
      return onGoingHistories.concat(finishedHistories);
    },
    get canContinue(): boolean {
      return self.items.length > 0;
    },
    get sortedItemsForTable() {
      return this.sortedItemsbyEnd.map((x) => ({ key: x.id, ...x }));
    },
    get initialValues() {
      return {
        company: self.selectedItem?.company ?? '',
        role: self.selectedItem?.role ?? '',
        place: self.selectedItem?.place ?? '',
        start: self.selectedItem?.start ?? '',
        end: self.selectedItem?.end ?? '',
        achievement: self.selectedItem?.achievement ?? '',
      };
    },
    get dataToSubmit() {
      return {
        items: this.sortedItems.map((item, index) => ({
          company: item.company,
          role: item.role,
          place: item.place,
          start: item.start,
          end: item.end,
          achievement: item.achievement,
          responsabilities: item.responsabilities,
          technologies: item.technologies,
          order: index + 1,
        })),
      };
    },
  }))
  .actions((self) => ({
    save: flow(function* save(): any {
      const { profile, showLoading, hideLoading, setShouldReloadProfile } = getRootStore(self);
      const data = {
        profileId: profile!.id,
        ...self.dataToSubmit,
      };
      showLoading();
      try {
        yield axiosInstance.put(`/history/${profile!.id}`, data);
        self.selectedItem = undefined;
        setShouldReloadProfile(false);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      } finally {
        hideLoading();
      }
    }),

    openModal() {
      self.showModal = true;
    },
    closeModal() {
      self.showModal = false;
    },
    getHistory(id: number) {
      return self.items.find((x) => x.id === id)!;
    },
    delete(id: number) {
      this.setSelectedItem(undefined);
      self.items.remove(self.items.find((x: HistoryItem) => x.id === id)!);
    },
    edit(id: number) {
      const item = self.items.find((x: HistoryItem) => x.id === id);
      this.setSelectedItem(item);
      this.openModal();
    },
    create() {
      this.setSelectedItem();
      this.openModal();
    },
    add(historyItem: HistoryItem) {
      const item = HistoryItem.create({
        id: GeneratorHelper.getNextId(self.items.slice()),
        company: historyItem.company,
        role: historyItem.role,
        place: historyItem.place,
        start: historyItem.start,
        end: historyItem.end,
        achievement: historyItem.achievement,
        responsabilities: [],
        technologies: [],
        order: GeneratorHelper.getNextOrder(self.items.slice()),
      });
      self.items.push(item);
    },
    update(historyItem: HistoryItem) {
      const index = self.items.findIndex((x: HistoryItem) => x === self.selectedItem);
      self.items[index].company = historyItem.company;
      self.items[index].role = historyItem.role;
      self.items[index].place = historyItem.place;
      self.items[index].start = historyItem.start;
      self.items[index].end = historyItem.end;
      self.items[index].achievement = historyItem.achievement;
    },
    addOrUpdate(historyItem: HistoryItem) {
      if (self.selectedItem) {
        this.update(historyItem);
      } else {
        this.add(historyItem);
      }
      this.closeModal();
    },
    setSelectedItem(item?: HistoryItem) {
      self.selectedItem = item;
    },
    createResponsability(historyId: number) {
      this.setSelectedItem(this.getHistory(historyId));
      self.selectedItem!.setSelectedResponsability(undefined);
      self.selectedItem!.openResponsabilityModal();
    },
    editResponsability(historyId: number, responsabilityId: number) {
      this.setSelectedItem(this.getHistory(historyId));
      const responsability = self.selectedItem!.responsabilities.find((x) => x.id === responsabilityId);
      self.selectedItem!.setSelectedResponsability(responsability);
      self.selectedItem!.openResponsabilityModal();
    },
    createTechnology(historyId: number) {
      this.setSelectedItem(this.getHistory(historyId));
      self.selectedItem!.setSelectedTechnology(undefined);
      self.selectedItem!.openTechnologyModal();
    },
    editTechnology(historyId: number, technologyId: number) {
      this.setSelectedItem(this.getHistory(historyId));
      const technology = self.selectedItem!.technologies.find((x) => x.id === technologyId);
      self.selectedItem!.setSelectedTechnology(technology);
      self.selectedItem!.openTechnologyModal();
    },
    deleteResponsability(historyId: number, responsabilityId: number) {
      const history = this.getHistory(historyId);
      history.setSelectedResponsability(undefined);
      const responsability = history?.responsabilities?.find((x) => x.id === responsabilityId);
      if (responsability) {
        history?.responsabilities?.remove(responsability);
      }
    },
    deleteTechnology(historyId: number, technologyId: number) {
      const history = this.getHistory(historyId);
      history.setSelectedTechnology(undefined);
      const technology = history?.technologies?.find((x) => x.id === technologyId);
      if (technology) {
        history?.technologies?.remove(technology);
      }
    },
    afterCreate() {
      const { setShouldReloadProfile } = getRootStore(self);
      onPatch(self.items, (p) => {
        if (
          !p.path.includes('showResponsabilityModal') &&
          !p.path.includes('selectedResponsabilityItem') &&
          !p.path.includes('showTechnologyModal') &&
          !p.path.includes('selectedTechnologyItem')
        )
          setShouldReloadProfile(true);
      });
    },
  }));

export interface HistoriesStore extends Instance<typeof HistoriesStore> {}
