import React, { FC, useState } from 'react';
import { Form } from 'antd';
import CustomFormForModal from '../forms/CustomFormForModal';
import { CustomInput } from '../forms/CustomInput';
import { useRootStore } from '../root/RootStoreHook';
import CustomModal from '../shared/CustomModal';
import ThreeColumnsRow from '../forms/ThreeColumnsRow';
import { textConfig, patterns } from '../profile/models/Config';

interface IProps {
  visible: boolean;
}

type ValidationStatus = 'success' | 'error' | undefined;

const EducationModal: FC<IProps> = ({ visible }) => {
  const { profile } = useRootStore();
  const education = profile!.education!;
  const [form] = Form.useForm();
  const [dateValidation, setDateValidation] = useState<ValidationStatus>(undefined);
  const [locationValidation, setLocationValidation] = useState<ValidationStatus>(undefined);
  const [dateErrorMessage, setDateErrorMessage] = useState<'Input a valid date range' | 'Date format should be the same in both fields' | undefined>();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { date:{startFormat, endFormat }, text} = patterns;

  const onDateChange = () => {
    var currentTime = new Date();
    var currentYear = currentTime.getFullYear();
    const values = form.getFieldsValue();
    const { start, end } = values;

    if (startFormat.test(start) && endFormat.test(end)) {
      const startSplit = start.split('/');
      const endSplit = end.split('/');
      let endSplitFormatted = endSplit[0].toLowerCase();
      if ((endSplit[0] <= currentYear && startSplit[0] <= currentYear) || endSplitFormatted === 'ongoing') {
        if (
          endSplit[0] > startSplit[0] ||
          (endSplit[0] === startSplit[0] && !endSplit[1] && !startSplit[1]) ||
          (endSplit[0] === startSplit[0] && endSplit[1] >= startSplit[1]) ||
          endSplitFormatted === 'ongoing'
        ) {
          setDateValidation('success');
          setDateErrorMessage(undefined);
          setButtonDisabled(false);
        } else {
          setDateValidation('error');
          setDateErrorMessage('Input a valid date range');
          setButtonDisabled(true);
        }
        if (start.includes('/') && !end.includes('/') || !start.includes('/') && end.includes('/')) {
          setDateValidation('error');
          setDateErrorMessage('Date format should be the same in both fields');
          setButtonDisabled(true);
        }
      } else {
        setDateValidation('error');
        setDateErrorMessage(undefined);
        setButtonDisabled(true);
      }
    } else {
      setDateValidation(undefined);
      setDateErrorMessage(undefined);
      setButtonDisabled(true);
    }
  };

  const onLocationChange = () => {
    const values = form.getFieldsValue();
    const { location } = values;

    if (location !== '') {
      setLocationValidation('success');
      setButtonDisabled(false);
    } else {
      setLocationValidation('error');
      setButtonDisabled(true);
    }
  };

  const onOk = () => {
    if (
      dateValidation === 'success' ||
      (dateValidation === undefined && locationValidation === 'success') ||
      locationValidation === undefined
    ) {
      form
        .validateFields()
        .then((values: any) => {
          education.addOrUpdate(values);
        })
        .catch(() => {});
    }
  };

  return (
    <CustomModal
      visible={visible}
      title={education.selectedItem ? 'Update education' : 'Add education'}
      onOk={onOk}
      onCancel={() => education.closeModal()} 
      disabled={buttonDisabled}    
      >
      <CustomFormForModal form={form} initialValues={education.initialValues}>
        <CustomInput
          name="career"
          label="Career"
          config={textConfig(150)}
          onChange={(evt: any) => evt.currentTarget.value}
          pattern={text.technology}
        />
        <CustomInput
          name="institution"
          label="Institution"
          config={textConfig()}
          pattern={text.nonSpecial}
          onChange={(evt: any) => evt.currentTarget.value}
        />

        <ThreeColumnsRow
          first={
            <CustomInput
              name="location"
              label="Location"
              required
              validateStatus={locationValidation}
              onChange={onLocationChange}
              config={textConfig()}
              pattern={text.nonSpecial}
              toolTipText="Buenos Aires or Rio de Janeiro, Brasil or Online - CDMX"
            />
          }
          second={
            <CustomInput
              name="start"
              label="Start"
              required
              pattern={startFormat}
              validateStatus={dateValidation}
              onChange={onDateChange}
              config={textConfig()}
              toolTipText="YYYY or YYYY/MM"
            />
          }
          third={
            <CustomInput
              name="end"
              label="End"
              required
              pattern={endFormat}
              validateStatus={dateValidation}
              onChange={onDateChange}
              config={textConfig()}
              help={dateErrorMessage}
              toolTipText="YYYY, YYYY/MM or Ongoing"
            />
          }
        />
      </CustomFormForModal>
    </CustomModal>
  );
};

export default EducationModal;
