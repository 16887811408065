import ModuleRender from '../ModuleRender';
import React, { FC, useEffect, useState } from 'react';
import { FormInstance } from 'antd';

import { useRootStore } from '../../root/RootStoreHook';
import { Module } from '../models/Enums';

let initialPercentage = {
  personalInformation: { complete: 0 },
  summary: { complete: 0 },
  highlight: { complete: 0 },
  skills: { complete: 0 },
  education: { complete: 0 },
  professionalHistory: { complete: 0 },
};

const StepperBody: FC<{ form: FormInstance<any>; module: Module }> = ({ form, module }) => {
  const [, setPercentageInProgress] = useState(initialPercentage);

  const { personalInformation, summary, highlight, skills, education, professionalHistory } = initialPercentage;
  useEffect(() => {
    setPercentageInProgress(initialPercentage);
  }, [
    personalInformation.complete,
    summary.complete,
    highlight.complete,
    skills.complete,
    education.complete,
    professionalHistory.complete,
  ]);

  const [initialValues, setInitialValues] = useState<any>();
  const { profile } = useRootStore();

  useEffect(() => {
    setCurrentProgress();
    setInitialValuesByModule(module);
  }, [module]);

  function setInitialValuesByModule(module: Module): void {
    switch (module) {
      case Module.PersonalInfo:
        setInitialValues(profile?.personalInfo?.formValues);
        break;
      case Module.Summary:
        setInitialValues({aboutMe: profile?.summary?.aboutMe});
      default:
        break;
    }
  }

  function setCurrentProgress(): void {
    initialPercentage = {
      personalInformation: { complete: 0 },
      summary: { complete: 0 },
      highlight: { complete: 0 },
      skills: { complete: 0 },
      education: { complete: 0 },
      professionalHistory: { complete: 0 },
    };

    const getPercengateInProgress = (fields: any): number => {
      let percentage = 0;
      let totalProperties = 0;
      let valueNoValid = 0;

      for (let i = 0, keys = Object.keys(fields); i < keys.length; i++) {
        const property = keys[i];
        const value = fields[property];
        totalProperties = keys.length;
        if (value === undefined || value === null || value === '') valueNoValid++;
      }

      percentage = totalProperties && (100 / totalProperties) * (totalProperties - valueNoValid);

      return percentage;
    };

    if (profile!.personalInfo!.formValues) {
      const fields = profile!.personalInfo?.formValues;
      initialPercentage.personalInformation.complete = getPercengateInProgress(fields);
    }

    if (profile!.summary!.formValues) {
      const fields = profile!.summary!.formValues;
      initialPercentage.summary.complete = getPercengateInProgress(fields);
    }

    if (profile?.highlight?.items.length) initialPercentage.highlight.complete = 100;

    if (profile?.skillSummary?.items.length) initialPercentage.skills.complete += 50;
    if (profile?.skill?.items.length) initialPercentage.skills.complete += 50;

    if (profile?.education?.items.length) initialPercentage.education.complete += 50;
    if (profile?.certification?.items.length) initialPercentage.education.complete += 50;

    if (profile?.history?.items.length) initialPercentage.professionalHistory.complete = 100;
  }

  return <ModuleRender module={module} form={form} initialValues={initialValues} />;
};

export default StepperBody;
