import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useRootStore } from '../../../root/RootStoreHook';
import { options } from '../DropdownOptionsDisplay';
import DropdownOptionsEntry from '../DropdownOptionsEntry';
import AdditionalPositionModal from './AdditionalPositionModal';

const AdditionalPositionEntry: FC = () => {
  const { dropdownOptions } = useRootStore();

  return useObserver(() => (
    <>
      <DropdownOptionsEntry
        option={options.ADDITIONAL_POSITION}
        dropdownItems={dropdownOptions!.additionalPositionsForDropdown}
      />
      {dropdownOptions!.showAdditionalPositionModal && (
        <AdditionalPositionModal visible={dropdownOptions!.showAdditionalPositionModal} />
      )}
    </>
  ));
};

export default AdditionalPositionEntry;
