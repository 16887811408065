import React, { useState, useRef, useEffect, FC } from 'react';
import { Routes } from '../app/routing/Routes';
import { useRedirect } from '../hooks/HooksUtil';
import openNotification from '../notifications/Notification';
import { useRootStore } from '../root/RootStoreHook';
import rol, { isRol } from './roles';
import { init } from '../../web-components/login';
import 'web-components/login';
import 'web-components/custom-elements';

import './login.css';

// import carnivalIlustrationMobile from '../../assets/carnival-illustration-mobile.png';
import CvCreatorLogo from '../../assets/CVcreator.svg';
import cognizantLogo from '../../assets/cognizantLogo.svg';
import { useHistory, useLocation } from 'react-router-dom';

declare global {
  interface Window {
    chrome: any;
  }
}

const Title: FC<{ slot: string }> = ({ slot }) => (
  <div slot={slot} className="title-container">
    {/* <img src={carnivalIlustrationMobile} alt="Softvision logo" className="carnival-img-mb" /> */}
    <div className="logo">
      <img className="cvCreator-logo" src={CvCreatorLogo} alt="CeveCreator logo" />
      <img className="cognizant-logo" src={cognizantLogo} alt="cognizant logo 2" />
    </div>
  </div>
);

const Login = () => {
  const rootStore = useRootStore();
  const redirect = useRedirect();
  const [disabledButton, setDisabledButton] = useState(false);
  const ceRef: any = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const landing = searchParams.get('landing');
  const download = searchParams.get('download');
  const from = searchParams.get('from');

  const onUserValidated = async () => {
    const user = rootStore.getSession();

    if(user)
    {
      await onSuccess({ detail: { user } });
    }

  };

  const onSuccess = async (event: { detail: { user: any } }) => {
    const { user } = event.detail;
    const { email, pass, data } = user;
    
    rootStore.setSession(user);

    rootStore.login(email, pass, data);
    if (landing) {
      if (download && (isRol([rol.ADMIN], data.rol) || isRol([rol.MANAGER], data.rol))) {
        history.push(from+'?download='+download);
        rootStore.showLoading();
      } else {
        history.push(location);
      }
    } else {
      redirect.go(isRol([rol.ADMIN], data.rol) ? Routes.Profiles : Routes.Profile);
    }
    window.location.reload();
  };

  const onResetEmail = async (event: { detail: { user: any } }) => {
    const { user } = event.detail;
    const { email } = user;
    try {
      await rootStore.restorePassword(email);
    } catch (error) {
      console.log(error);
    }
  };

  const getIndicators = () =>
    !!window.chrome && (!window.chrome.webstore || window.chrome.runtime) ? (
      <>
        <ol>
          <li>
            At the top right, click More <span className="three-points"> </span> and then <b>Settings</b>.
          </li>
          <li>
            Click <b>Privacy and security</b> and then <b>Cookies and other site data</b>.{' '}
          </li>
          <li>
            Select an option <b>Allow 3rd party cookies</b> or <b>Allow all cookies</b>.{' '}
          </li>
          <li>Close and reload the browser.</li>
        </ol>
        <hr className="indicator-hr-margin-left" />
        <br />
        <h4 className="indicator-h4-margin-left">
          To unblock 3rd party cookies on Google Chrome <b>Incognito Mode</b>
        </h4>
        <ol>
          <li>
            Click the eye icon <span className="eye-blocked"> </span> at the right of your browser’s address bar.
          </li>
          <li>
            Click the <b>Site not working?</b> link to get to the 3rd party cookies enablement menu.
          </li>
          <li>
            {' '}
            Click <b>Allow cookies</b>.
          </li>
          <li>The browser should then refresh automatically.</li>
        </ol>
      </>
    ) : null;

  const onFailure = (event: { detail: { error: any } }) => {
    const { error } = event.detail;

    if (error === 'idpiframe_initialization_failed') {
      setDisabledButton(true);
      openNotification(
        'Attention',
        <>
          <p>Please enable third-party cookies. It is required for Google to securely authenticate the user. </p>
          {!!window.chrome && (!window.chrome.webstore || window.chrome.runtime) ? getIndicators() : ''}
        </>,
        'info',
        5000,
        undefined,
        { marginRight: 26 }
      );
    } else {
      openNotification(
        'Error',
        'An error occurred while trying to login with your Google account',
        'error',
        5,
        'bottomRight',
        { marginRight: 26 }
      );
    }
  };
  useEffect(() => {
    
    onUserValidated();

    if (ceRef.current) {
      ceRef.current.addEventListener('success', onSuccess);
      ceRef.current.addEventListener('failure', onFailure);
      ceRef.current.addEventListener('resetEmail', onResetEmail);
    }
    
    rootStore.hideLoading();
  }, []);

  return (
    <cwc-login
      ref={ceRef}
      prompt="Sign in"
      description="Easily create and edit your resume to fulfill our client needs."
      buttontext="Sign in"
      disabled={String(disabledButton)}
    >
      <Title slot="titleLogo" />
    </cwc-login>
  );
};

init();

export default Login;
